/**
 * Created by Topi on 29.08.2018
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {User} from "../../data-model/user.type";
import {GameSessionLog} from "../../data-model/game-session-log.type";
import {Content} from "../../data-model/content.type";
import {GameTimeslot} from "../../data-model/timeslot.type";
import {GameSeasonService} from "../../core/service/game-season.service";
import {first} from "rxjs/operators";
import {GameSeason} from "../../data-model/game-season.type";
import {Organization} from "../../data-model/organization.type";
const moment = require("moment");

@Component({
  selector: 'player-progress-v2',
  template: `
    <ng-scrollbar class="loge-scrollbar">
      <div class="progress-container">
        <sine-wave-graph
          *ngIf="visible"
          [visible]="visible"
          [currentUser]="currentUser"
          [userSessions]="userSessions"
          [userUpcomingSessions]="userUpcomingSessions"
          [organizationContent]="organizationContent"
          [requiredSeasonList]="requiredSeasonList"
          [showScore]="!currentOrganization?.hideScores"
          (modalOpened)="modalOpened = $event"
        ></sine-wave-graph>
      </div>
    </ng-scrollbar>
  `
})
export class PlayerProgressV2Component implements OnChanges {
  @Input() visible: boolean;
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() userSessions: GameSessionLog[];
  @Input() userUpcomingSessions: GameTimeslot[];
  @Input() organizationContent: Content;
  public requiredSeasonList: GameSeason[];

  @Output() modalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private gameSeasonService: GameSeasonService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.organizationContent || changes.userSessions || changes.userUpcomingSessions || changes.currentOrganization)
      && this.organizationContent && this.organizationContent.seasonNameOrder && this.userSessions && this.currentOrganization) {
      const seasonQuery = {
        orgId: this.currentOrganization._id,
        endTime: {$gte: moment()}
      };

      if (this.currentOrganization.organizationType !== 2) {
        seasonQuery['name'] = this.organizationContent.seasonNameOrder;
      }

      this.gameSeasonService.getOrganizationSeasonByQuery(
        seasonQuery)
        .pipe(
          first()
        )
        .subscribe((seasons) => {
          this.requiredSeasonList = seasons.filter((season) => {
            // Show all if organization is a test organization
            if (this.currentOrganization && this.currentOrganization.organizationType === 2) {
              return true;
            }

            // Remove if no available timeSlots
            if (!season.seasonTimeSlots || !season.seasonTimeSlots.length) {
              return false;
            }

            // Remove signed up
            if (this.userUpcomingSessions && this.userUpcomingSessions.length && this.userUpcomingSessions.findIndex(timeSlot => {
                return timeSlot.gameSeasonId && timeSlot.gameSeasonId.name === season.name;
              }) !== -1) {
              return false;
            }

            // Remove completed
            return this.userSessions.findIndex(sessionLog => {
              return sessionLog.session.gameSeasonId && sessionLog.session.gameSeasonId.name === season.name;
            }) === -1;
          });

          const reverseSortOrder = this.organizationContent.seasonNameOrder.slice(0).reverse();

          for (const seasonName of reverseSortOrder) {
            const nameArrayIndex = this.requiredSeasonList.findIndex(season => {
              return season.name === seasonName;
            });

            if (nameArrayIndex !== -1) {
              this.requiredSeasonList.unshift(this.requiredSeasonList.splice(nameArrayIndex, 1)[0]);
            }
          }
        });
    }
  }
}
