/**
 * Created by NordicEdu on 21.11.2016.
 */

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {GameSessionService} from "../../core/service/game-session.service";
import {Reaction, ReactionCounter} from "../../data-model/player.type";

@Component({
  selector: 'player-reaction',
  template: `
  <div class="reaction-container">    
    <span class="reactions">
      <span *ngIf="reactionAmounts?.thumbs > 0" class="reaction">
        <svg-reaction-emoji [emojiType]="'thumb'"></svg-reaction-emoji>
        <div class="reaction-count">{{reactionAmounts.thumbs}}</div>
      </span>
      <span *ngIf="reactionAmounts?.laughs > 0" class="reaction">
        <svg-reaction-emoji [emojiType]="'laugh'"></svg-reaction-emoji>
        <div class="reaction-count">{{reactionAmounts.laughs}}</div>
      </span>
      <span *ngIf="reactionAmounts?.bulbs > 0" class="reaction">
        <svg-reaction-emoji [emojiType]="'bulb'"></svg-reaction-emoji>
        <div class="reaction-count">{{reactionAmounts.bulbs}}</div>
      </span>
      <span *ngIf="reactionAmounts?.confused > 0" class="reaction">
        <svg-reaction-emoji [emojiType]="'confused'"></svg-reaction-emoji>
        <div class="reaction-count">{{reactionAmounts.confused}}</div>
      </span>
      
      <div *ngIf="voteUserId !== currentPlayerUserId" class="main-button-container">
        <svg-reaction-emoji class="reaction-button-main" [emojiType]="'thumb'" (click)="addReaction($event, voteUserId, currentPlayerUserId, 'thumb')"></svg-reaction-emoji>
        <div class="reaction-additional-buttons">
          <svg-reaction-emoji class="reaction-button" [emojiType]="'laugh'" (click)="addReaction($event, voteUserId, currentPlayerUserId, 'laugh')"></svg-reaction-emoji>
          <svg-reaction-emoji class="reaction-button" [emojiType]="'bulb'" (click)="addReaction($event, voteUserId, currentPlayerUserId, 'bulb')"></svg-reaction-emoji>
          <svg-reaction-emoji class="reaction-button" [emojiType]="'confused'" (click)="addReaction($event, voteUserId, currentPlayerUserId, 'confused')"></svg-reaction-emoji>
          <svg-reaction-emoji class="reaction-button" [emojiType]="'thumb'" (click)="addReaction($event, voteUserId, currentPlayerUserId, 'thumb')"></svg-reaction-emoji>
        </div>
      </div>
    </span>
  </div>
  `
})

export class PlayerReactionComponent implements OnChanges {
  @Input() voteUserId: string;
  @Input() currentPlayerUserId: string;
  @Input() reactions: Reaction[];
  @Input() fakeReactions: boolean;

  @Output() newReaction: EventEmitter<boolean> = new EventEmitter<boolean>();

  public reactionAmounts: ReactionCounter = {
    thumbs: 0,
    laughs: 0,
    bulbs: 0,
    confused: 0
  };

  constructor(
    private gameSessionService: GameSessionService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.voteUserId && this.reactions) {
      this.reactionAmounts = this.getReactionAmounts(this.reactions, this.voteUserId);
    }
  }

  public addReaction(event: Event, targetUserId: string, fromUserId: string, reactionType: string) {
    if (this.fakeReactions) {
      this.reactionAmounts = {
        thumbs: (this.reactionAmounts.thumbs === 1) ? 0 : (reactionType === "thumb" ? 1 : 0),
        laughs: (this.reactionAmounts.laughs === 1) ? 0 : (reactionType === "laugh" ? 1 : 0),
        bulbs: (this.reactionAmounts.bulbs === 1) ? 0 : (reactionType === "bulb" ? 1 : 0),
        confused: (this.reactionAmounts.confused === 1) ? 0 : (reactionType === "confused" ? 1 : 0)
      };

      event.stopPropagation();
      return;
    }

    this.gameSessionService.sendReactionMessage(targetUserId, fromUserId, reactionType);
    event.stopPropagation();
  }

  private getReactionAmounts(reactions: Reaction[], voteUserId: string): ReactionCounter {
    // Filter reactions belonging to vote
    reactions = reactions.filter(reaction => {
      return voteUserId === reaction.targetUserId;
    });

    let thumbs: number = 0;
    let laughs: number = 0;
    let bulbs: number = 0;
    let confused: number = 0;

    reactions.forEach(reaction => {
      switch (reaction.reactionType) {
        case "thumb":
          thumbs++;
          break;
        case "laugh":
          laughs++;
          break;
        case "bulb":
          bulbs++;
          break;
        case "confused":
          confused++;
          break;
        default:
          break;
      }
    });

    return {thumbs: thumbs, laughs: laughs, bulbs: bulbs, confused: confused};
  }
}
