import { Injectable }    from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad,
  Route, ActivatedRouteSnapshot, RouterStateSnapshot, Router
} from '@angular/router';

import {UserService} from "../app/core/service/user.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private userService: UserService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.userService.adminViewAccess()) {
      this.router.navigate(['/landing-page']);
    }

    return this.userService.adminViewAccess();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean  {
    return this.userService.adminViewAccess();
  }
}
