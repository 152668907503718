import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[filePreview]'
})

export class UploadImagePreviewDirective implements OnChanges {
  @Input() file: File;

  private defaultImage: string = "/assets/image/logo_transparent.png";
  private pdfPlaceholder: string = "/assets/image/logo_transparent.png";
  private reader: FileReader = new FileReader();

  constructor(
    private element: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.file) {
      return;
    }

    this.reader.onloadend = () => {
      if (this.file.type.startsWith('image')) {
        this.element.nativeElement.src = this.reader.result;
        return;
      }

      if (this.file.type === 'application/pdf') {
        this.element.nativeElement.src = this.pdfPlaceholder;
        return;
      }

      console.error("Unknown file type for preview", this.file);
      this.element.nativeElement.src = this.defaultImage;
    };

    this.reader.readAsDataURL(this.file);
  }
}
