/**
 * Created by janne on 30.8.2016.
 */
import {OnDestroy, Output, EventEmitter, Directive, Component} from '@angular/core';
const moment = require("moment");
import {Moment} from "moment";

@Component({
  selector: 'clock-component',
  template: ''
})
export class ClockComponent implements OnDestroy {
  @Output() onStop: EventEmitter<boolean> = new EventEmitter<boolean>();

  public timeText: string = "";
  public timeValues: {days: number, hours: number, minutes: number, seconds: number};

  public timeNormalized: number = 0.0;
  public diffToEnd: number = 0;

  protected endTime: Date;
  protected totalDiff: number;

  constructor() {
  }

  ngOnDestroy(): void {
    this.stop();
  }

  /**
   * Start the countdown
   * @param duration - Current time on the clock.
   * @param total - Total time of the clock. Used to normalize the progressbar.
   */
  public setTime(duration: number, total?: number): string {
    this.clear();

    const startTime: Moment = total ? moment().subtract(total - duration, "s") : moment();

    if (!total) {
      total = duration;
    }

    this.endTime = new Date(startTime.valueOf() + (total * 1000));
    this.totalDiff = this.endTime.valueOf() - startTime.valueOf();

    this.update();

    return this.getTimeText();
  }

  public setTimeWithDate(date: Moment | Date, total?: number): void {
    const remainingSeconds: number = moment(date).diff(moment(), "s");
    this.setTime(remainingSeconds, total);
  }

  public stop(): void {
    this.clear();

    this.timeText = "";
    this.timeNormalized = 0.0;
    this.diffToEnd = 0;

    this.onStop.emit();
  }

  public getTimeText(getEndTime: boolean = true): string {
    if (!this.timeValues || !this.endTime) {
      return "";
    }

    let timeString: string = getEndTime ? moment(this.endTime).format("HH:mm") : "";
    const keys: string[] = ['hours', 'minutes'];

    keys.forEach((key, index) => {
      if (index === 0) {
        timeString += " (";
      }

      timeString += (index > 0 ? " " : "") + this.timeValues[key] + (key === 'hours' ? 'h' : 'min');

      if (index === keys.length - 1) {
        timeString += ")";
      }
    });

    return timeString;
  }

  /**
   * Clear timer
   */
  protected clear() {
    this.endTime = null;
    this.totalDiff = 0;

    // Make sure requestAnimationFrame won't be called anymore
    this.diffToEnd = 0;
  }

  protected update(): void {
    if (!this.endTime) {
      return;
    }

    this.diffToEnd = this.endTime.valueOf() - new Date().valueOf();
    this.updateText();

    if (this.diffToEnd <= 0) {
      this.stop();
    }
    else {
      requestAnimationFrame(this.update.bind(this));
    }
  }

  /**
   * Parse time as XX:XX:XX:XX and update
   */
  protected updateText(): void {
    const days = Math.floor(this.diffToEnd / 86400000);
    const hours = Math.floor((this.diffToEnd / 3600000) - (days * 24));
    const minutes = Math.floor((this.diffToEnd / 60000) - (hours * 60 + days * 1440));
    const seconds = Math.floor((this.diffToEnd % 60000) / 1000);

    this.timeValues = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };

    if (days <= 0 && hours <= 0) {
      this.timeText =
        (minutes < 10 ? '0' + minutes : minutes) + ":" +
        (seconds < 10 ? '0' + seconds : seconds);
    }
    else if (days <= 0) {
      this.timeText =
        (hours < 10 ? '0' + hours : hours) + ":" +
        (minutes < 10 ? '0' + minutes : minutes) + ":" +
        (seconds < 10 ? '0' + seconds : seconds);
    }
    else {
      this.timeText =
        (days < 10 ? '0' + days : days) + ":" +
        (hours < 10 ? '0' + hours : hours) + ":" +
        (minutes < 10 ? '0' + minutes : minutes) + ":" +
        (seconds < 10 ? '0' + seconds : seconds);
    }

    this.timeNormalized = 1 - (this.totalDiff !== 0 ? (this.diffToEnd / this.totalDiff ) : 0.0);
  }
}
