/**
 * Created by TopSal on 28.6.2017.
 */

import {Pipe, Injectable, PipeTransform} from "@angular/core";

@Pipe({
  name: 'arrayToString',
  pure: true
})
@Injectable()
export class ArrayToStringPipe implements PipeTransform {
  transform(array: any[], options: {key: string}): string {
    if (!array || !array.length) {
      return "";
    }

    return array
      .map(item => {
        if (options && options.key) {
          return item[options.key] ? item[options.key].toString() : "Unknown";
        }

        return item ? item.toString() : "Unknown";
      })
      .filter(item => !!item)
      .join(", ");
  }
}
