import * as protooClient from "protoo-client";
import * as mediasoupClient from "mediasoup-client";
import { BehaviorSubject, Subscription } from "rxjs";
import { AudioDeviceInfo, AudioDeviceService, DeviceInfo } from "../../app/core/service/audio-device.service";
import { filter } from "rxjs/operators";
import { getChatRoomUrl, ROOM_OPTIONS } from "../../config/mediasoup.config";

export interface Consumer {
  consumerId: string;
  userId: string;
  track: MediaStreamTrack;
}

export interface UserAudio {
  consumer: string;
  stream: MediaStream;
  element: HTMLAudioElement;
}

export default class MediasoupRoom {
  readonly roomId: string;
  readonly peerName: string;

  public micProducer;

  public roomState: string;
  public protooConnectionState: string;
  public consumerTracks: Consumer[] = [];

  public userAudioSubject$: BehaviorSubject<UserAudio> = new BehaviorSubject<UserAudio>(undefined);
  public notificationsSubject$: BehaviorSubject<{ type: string, userId: string, extra?: any }> = new BehaviorSubject<{ type: string, userId: string }>(undefined);
  public errorsSubject$: BehaviorSubject<{ name: string, message: string, extra: string }> = new BehaviorSubject<{ name: string, message: string, extra: string }>(undefined);
  public personalTrackChanged$: BehaviorSubject<MediaStreamTrack> = new BehaviorSubject<MediaStreamTrack>(undefined);

  private device: any;

  private inputDevice: DeviceInfo;
  private outputDevice: DeviceInfo;

  private protooClient: protooClient.Peer;
  private mediasoupRoom: mediasoupClient.Room;

  private sendTransport;
  private recvTransport;

  private audioElements: UserAudio[] = [];
  private currentMuteStatus: boolean;

  private subscriptions: Subscription[] = [];
  private audioDeviceService: AudioDeviceService;

  private consoleLogPrefix: string = "VOICE CHAT: ";

  constructor(
    roomId: string,
    peerName: string,
    device: any,
    audioDeviceService: AudioDeviceService,
    isTest?: boolean
  ) {
    this.roomId = roomId;
    this.peerName = peerName;
    this.device = device;

    this.protooClient = new protooClient.Peer(new protooClient.WebSocketTransport(getChatRoomUrl(peerName, roomId)));
    this.mediasoupRoom = new mediasoupClient.Room(ROOM_OPTIONS);

    this.audioDeviceService = audioDeviceService;

    this.subscriptions.push(
      audioDeviceService.selectedDevices$
        .pipe(
          filter(selectedDevices => !!selectedDevices)
        )
        .subscribe((selectedDevices: AudioDeviceInfo) => {
          if (!this.roomState) {
            // State not yet set (will be set by connectToRoom())
            this.inputDevice = selectedDevices.input;
            this.outputDevice = selectedDevices.output;
            return;
          }

          this.setNewInputDevice(selectedDevices.input);
          this.setNewOutputDevice(selectedDevices.output);
        })
    );


    this.connectToRoom();
  }

  public setNewInputDevice(newInputDevice: DeviceInfo): void {
    if (!newInputDevice || this.inputDevice?.deviceId === newInputDevice?.deviceId) {
      // Unchanged
      return;
    }

    this.inputDevice = newInputDevice;

    this.replaceProducerTrack()
      .then(() => {
        console.log(this.consoleLogPrefix + "Set new input device", newInputDevice?.label);
      })
      .catch(err => {
        console.error(this.consoleLogPrefix + err.reason);
      });
  }

  public setNewOutputDevice(newOutputDevice: DeviceInfo): void {
    if (!newOutputDevice || this.outputDevice?.deviceId === newOutputDevice.deviceId) {
      // Unchanged
      return;
    }

    this.outputDevice = newOutputDevice;

    for (const userAudio of this.audioElements) {
      // @ts-ignore - setSinkId is still experimental
      userAudio.element.setSinkId(newOutputDevice.deviceId);
    }

    console.log(this.consoleLogPrefix + "Set new output device", newOutputDevice?.label);
  }

  public toggleAndReturnMic(): boolean {
    if (!this.micProducer) {
      return false;
    }

    if (this.micProducer.paused) {
      this.micProducer.resume();
    } else {
      this.micProducer.pause();
    }

    return this.micProducer.paused;
  }

  public muteMic(): boolean {
    if (!this.micProducer) {
      return false;
    }

    this.micProducer.pause();
    return this.micProducer.paused;
  }

  public unmuteMic(): boolean {
    if (!this.micProducer) {
      return false;
    }

    this.micProducer.resume();
    return this.micProducer.paused;
  }

  public closeConnection() {
    if (this.roomState === "closed") {
      return;
    }

    this.roomState = "closed";

    this.mediasoupRoom.leave();
    this.protooClient.close();

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /*public restartIce() {
    if (this.restartInProgress) {
      return;
    }

    this.restartInProgress = true;

    return Promise.resolve()
      .then(() => {
        this.mediasoupRoom.restartIce();

        setTimeout(() => {
          this.restartInProgress = false;
        }, 500);
      })
      .catch((err) => {
        console.error('Restart ICE failed', err);

        this.errorsSubject$.next({
          name: err.name,
          message: err.message,
          extra: "Restart ICE failed"
        });

        this.restartInProgress = false;
      });
  }*/

  public checkIdentical(sessionId: string, userId: string): boolean {
    return sessionId === this.roomId && userId === this.peerName;
  }

  public updateConsumerTrack(consumerId: string, peerName: string, track: MediaStreamTrack): void {
    const consumerMatch = this.consumerTracks.findIndex(consumerValue => {
      return consumerValue.consumerId === consumerId;
    });

    if (consumerMatch !== -1) {
      // Update existing track
      this.consumerTracks[consumerMatch].track = track;
      return;
    }

    // Add new
    this.consumerTracks.push({
      consumerId: consumerId,
      userId: peerName,
      track: track
    });
  }

  public muteAudio(muteStatus: boolean): void {
    this.currentMuteStatus = muteStatus;

    if (muteStatus) {
      for (const audioElem of this.audioElements) {
        audioElem.element.pause();
      }

      return;
    }

    for (const audioElem of this.audioElements) {
      this.playAudioElement(audioElem, "un-mute");
    }
  }

  private connectToRoom() {
    this.roomState = "connecting";

    this.protooClient.on("open", () => {
      this.protooConnectionState = "open";
      this.joinRoom();
    });

    this.protooClient.on("disconnected", () => {
      this.protooConnectionState = "disconnected";

      // Leave Room.
      try {
        this.mediasoupRoom.remoteClose({ cause: "websocket disconnected" });
      } catch (err) {
        console.error(this.consoleLogPrefix + "Error closing room", err);

        this.errorsSubject$.next({
          name: err.name,
          message: err.message,
          extra: "Failed closing room"
        });
      }

      this.roomState = "connecting";
    });

    this.protooClient.on("close", () => {
      if (this.roomState === "closed") {
        return;
      }

      this.closeConnection();
    });

    this.protooClient.on("request", (request, accept, reject) => {
      switch (request.method) {
        case "mediasoup-notification": {
          accept();

          if (this.mediasoupRoom.closed) {
            // Don't receive notification if room has just been closed
            break;
          }

          const notification = request.data;
          this.mediasoupRoom.receiveNotification(notification);

          this.notificationsSubject$.next({
            type: notification.method,
            userId: notification.name ? notification.name : notification.peerName
          });

          break;
        }

        default: {
          console.error(this.consoleLogPrefix + "Unknown protoo method", request.method, request.data);
          reject(404, "unknown method");
        }
      }
    });
  }

  private joinRoom() {
    this.mediasoupRoom.removeAllListeners();

    this.mediasoupRoom.on("close", (originator, appData) => {
      if (originator === "remote") {
        this.roomState = "closed";

        return;
      }
    });

    this.mediasoupRoom.on("request", (request, callback, err) => {
      this.protooClient.send("mediasoup-request", request)
        .then(callback)
        .catch(sendErr => {
          this.errorsSubject$.next({
            name: sendErr.name,
            message: sendErr.message,
            extra: "Failed sending Mediasoup request to WebSocket server"
          });
        });
    });

    this.mediasoupRoom.on("notify", (notification) => {
      this.protooClient.send("mediasoup-notification", notification)
        .catch(() => {
        });
    });

    this.mediasoupRoom.on("newpeer", (peer) => {
      this.handlePeer(peer);
    });

    this.mediasoupRoom.join(this.peerName)
      .then(() => {
        this.sendTransport = this.mediasoupRoom.createTransport("send", { media: "SEND_MIC_WEBCAM" });
        this.recvTransport = this.mediasoupRoom.createTransport("recv", { media: "RECV" });

        // this.sendTransport.on('close', (originator) => {
        // });
        // this.recvTransport.on('close', (originator) => {
        // });
      })
      .then(() => {
        // Add microphone
        Promise.resolve()
          .then(() => {
            this.setMicProducer()
              .catch((err) => {
                // No need to log twice
                // console.log(this.consoleLogPrefix + "Failed setting micProducer", err);
              });
          });
      })
      .then(() => {
        this.roomState = "connected";

        const peers = this.mediasoupRoom.peers;

        for (const peer of peers) {
          this.handlePeer(peer);
        }

        this.notificationsSubject$.next({
          type: "selfConnected",
          userId: null,
          extra: {
            peers: peers.map(peer => {
              return peer.name;
            })
          }
        });
      })
      .catch((err) => {
        console.error(this.consoleLogPrefix + "Failed to join Mediasoup Room", err);

        this.errorsSubject$.next({
          name: err.name,
          message: err.message,
          extra: "Failed to join Mediasoup Room"
        });

        this.closeConnection();
      });
  }

  private handlePeer(peer: any) {
    for (const consumer of peer.consumers) {
      this.handleConsumer(consumer, peer.name);
    }

    peer.on("close", (originator) => {
      if (this.mediasoupRoom.joined) {
        console.log(this.consoleLogPrefix + peer.name, "left the room");
      }
    });

    peer.on("newconsumer", (consumer) => {
      console.log(this.consoleLogPrefix + peer.name, "joined the room");
      this.handleConsumer(consumer, peer.name);
    });
  }

  private handleConsumer(consumer: any, peerName: string) {
    consumer.on("close", (originator) => {
      console.log(this.consoleLogPrefix + "Connection closed by", originator, peerName);
    });

    consumer.on("pause", (originator) => {
      console.log(this.consoleLogPrefix + "Connection paused by", originator, peerName);
    });

    consumer.on("resume", (originator) => {
      console.log(this.consoleLogPrefix + "Connection resumed by", originator, peerName);
    });

    consumer.on("effectiveprofilechange", (profile) => {
      // No need to do anything
    });

    if (consumer.supported) {
      consumer.receive(this.recvTransport)
        .then((track: MediaStreamTrack) => {
          this.updateConsumerTrack(consumer.id, peerName, track);
          this.createNewAudioElement(peerName, track);
        })
        .catch((err) => {
          console.error(this.consoleLogPrefix + "Unexpected error while receiving a new Consumer", err);

          this.errorsSubject$.next({
            name: err.name,
            message: err.message,
            extra: "Unexpected error while receiving a new Consumer"
          });
        });
    }
  }

  private replaceProducerTrack() {
    if (!this.micProducer) {
      return Promise.reject({reason: this.consoleLogPrefix + "Can't replace track of non-existing producer"});
    }

    return Promise.resolve()
      .then(() => {
        const constrains: MediaStreamConstraints = {
          audio: {
            sampleRate: 48000,
            deviceId: this.inputDevice ? this.inputDevice.deviceId : "communications"
          },
          video: false
        };

        return this.audioDeviceService.getUserMediaStream(constrains)
          .then((result: {
            stream?: MediaStream,
            error?: string
          }) => result.stream);
      })
      .then((stream: MediaStream) => {
        if (!stream) {
          return Promise.reject(new Error("Unable to get voice stream!"));
        }

        const track = stream.getAudioTracks()[0];
        this.micProducer.replaceTrack(track)
          .then(() => {
            track.stop();
            this.personalTrackChanged$.next(this.micProducer.track);
          });
      });
  }

  private setMicProducer() {
    if (this.micProducer) {
      console.warn(this.consoleLogPrefix + "MicProduces already set, skip creating new");
      return Promise.reject(new Error("MicProducer already exists"));
    }

    if (!navigator?.mediaDevices?.getUserMedia) {
      return Promise.reject(new Error("No getUserMedia API support"));
    }

    let producer;

    return Promise.resolve()
      .then(() => {
        const constrains: MediaStreamConstraints = {
          audio: {
            sampleRate: 48000,
            deviceId: this.inputDevice ? this.inputDevice.deviceId : "communications"
          },
          video: false
        };

        return this.audioDeviceService.getUserMediaStream(constrains)
          .then((result: {
            stream?: MediaStream,
            error?: string
          }) => result.stream);
      })
      .then((stream: MediaStream) => {
        if (!stream) {
          return Promise.reject(new Error("Unable to get voice stream!"));
        }

        const track = stream.getAudioTracks()[0];
        producer = this.mediasoupRoom.createProducer(track, null, { source: "mic" });

        track.stop();
        return producer.send(this.sendTransport);

      })
      .then(() => {
        this.micProducer = producer;
        this.personalTrackChanged$.next(producer.track);

        producer.on("close", () => {
          console.log(this.consoleLogPrefix + "Microphone closed");
          this.micProducer = null;
        });

        producer.on("pause", (originator) => {
          console.log(this.consoleLogPrefix + "Microphone paused by", originator);
        });

        producer.on("close", () => {
          console.log(this.consoleLogPrefix + "MicProducer closed");
          this.micProducer = null;
        });

        producer.on("resume", (originator) => {
          console.log(this.consoleLogPrefix + "Microphone resumed by", originator);
        });

        producer.on("handled", () => {
          console.log(this.consoleLogPrefix + "Microphone transport stated");
        });

        producer.on("unhandled", () => {
          console.log(this.consoleLogPrefix + "Microphone transport stopped");
        });

        producer.on("stats", (stats) => {
          console.log(this.consoleLogPrefix + "Received RTC stats", stats);
        });
      })
      .then(() => {
        this.notificationsSubject$.next({
          type: "micProducerCreated",
          userId: null
        });
      })
      .catch((err) => {
        console.error(this.consoleLogPrefix + "Creating MicProducer failed", err);

        this.errorsSubject$.next({
          name: err.name,
          message: err.message,
          extra: "Failed to create MicProducer"
        });

        if (producer) {
          producer.close();
        }

        throw err;
      });
  }

  private createNewAudioElement(peerName: string, newTrack: MediaStreamTrack): void {
    const elementIndex: number = this.audioElements.findIndex((element) => {
      return element.consumer === peerName;
    });

    if (elementIndex !== -1) {
      // Remove old
      this.audioElements[elementIndex].element.remove();
      this.audioElements.splice(elementIndex, 1);
      console.log(this.consoleLogPrefix + "Removed previous instance of audio element for", peerName);
    }

    // Create new stream
    const consumerStream: MediaStream = new MediaStream;
    consumerStream.addTrack(newTrack);

    // Create new element
    const consumerAudioElem: HTMLAudioElement = new Audio();
    consumerAudioElem.srcObject = consumerStream;

    if (this.outputDevice) {
      // @ts-ignore - setSinkId is still experimental
      consumerAudioElem.setSinkId(this.outputDevice.deviceId);
    }

    const newUserAudio: UserAudio = {
      consumer: peerName,
      stream: consumerStream,
      element: consumerAudioElem
    };

    this.audioElements.push(newUserAudio);

    console.log(this.consoleLogPrefix + "Created new audio element for", peerName);

    // Play audio, unless muted
    if (!this.currentMuteStatus) {
      this.playAudioElement(newUserAudio, "new");
    }
  }

  private playAudioElement(userAudio: UserAudio, status?: string): void {
    if (!userAudio || !userAudio.element) {
      return;
    }

    userAudio.element.play()
      .then(() => {
        console.log(this.consoleLogPrefix + "Playing element. Reason:", status);

        if (userAudio) {
          this.userAudioSubject$.next(userAudio);
        }
      })
      .catch(err => {
        console.log(this.consoleLogPrefix + "Error playing element: Reason:", status, err);

        if (status !== "retry") {
          setTimeout(() => {
            this.playAudioElement(userAudio, "retry");
          }, 1000);
        }
      });
  }
}
