import {Component, OnDestroy} from '@angular/core';
import {NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet, RoutesRecognized} from '@angular/router';

import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "./core/service/user.service";
import {OrganizationService} from "./core/service/organization.service";
import {ContentService} from "./core/service/content.service";
import {GameSessionAutoJoinerService} from "./core/service/game-session-auto-joiner.service";
import {User} from "./data-model/user.type";
import {GameSessionService} from "./core/service/game-session.service";
import {filter, first, takeUntil} from "rxjs/operators";
import {DataUpdateService} from "./core/service/data-update.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import {SocketService} from "./core/service/socket.service";
import { LocalStorageService } from "./core/service/local-storage.service";
import { Content } from "./data-model/content.type";
import { Organization } from "./data-model/organization.type";

@Component({
  selector: 'renewal-app',
  template: `
    <link rel="stylesheet" *ngIf="styleUrl" [attr.href]="styleUrl" media="none" onload="if (media !== 'all') media='all'">

    <div class="main-view animated">
      <div class="router-view">
        <router-outlet></router-outlet>
      </div>
    </div>

    <loading-indicator
      [requirements]="{
        routes: !loadingRouteConfig,
        user: userChecked
      }"
    ></loading-indicator>

    <custom-confirm
    ></custom-confirm>

    <notification-pop-up
      [currentUser]="currentUser"
      [currentOrg]="currentOrg"
    ></notification-pop-up>
  `
})
export class AppComponent extends OnDestroyMixin {
  public loadingRouteConfig: boolean;
  public sheetLoaded: boolean;

  public currentUser: User;
  public currentOrg: Organization;
  public userChecked: boolean;

  public styleUrl: SafeResourceUrl;

  public supportedLanguages: string[] = ['en', 'fi', 'sv'];
  public preLoginRoutes: string[] = ["/recover-password/", "/activation/", "/update-password/"];

  constructor(private router: Router,
              private userService: UserService,
              private organizationService: OrganizationService,
              private sanitizer: DomSanitizer,
              private translate: TranslateService,
              private contentService: ContentService,
              private gameSessionService: GameSessionService,
              private gameSessionAutoJoinerService: GameSessionAutoJoinerService,
              private dataUpdateService: DataUpdateService,
              private socketService: SocketService,
              private localStorageService: LocalStorageService
  ) {
    // OnDestroyMixin
    super();

    // Setup translations
    this.translate.addLangs(this.supportedLanguages);

    // Show loading
    this.router.events
      .pipe(
        untilComponentDestroyed(this),
        filter(event => {
          return (
            event instanceof RoutesRecognized ||
            event instanceof RouteConfigLoadStart ||
            event instanceof RouteConfigLoadEnd
          );
        })
      )
      .subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;

          setTimeout(() => {
            if (this.loadingRouteConfig) {
              this.loadingRouteConfig = false;
            }
          }, 5000);

          return;
        }

        if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
          return;
        }

        if (event instanceof RoutesRecognized) {
          if (event.urlAfterRedirects === "/welcome") {
            const langIndex: number = this.supportedLanguages.indexOf(event.url.slice(1));

            // Use route language
            if (langIndex !== -1) {
              const routeLang: string = this.supportedLanguages[langIndex];
              this.translate.use(routeLang);
              this.translate.setDefaultLang(routeLang);
            }

            // Use browser language
            else {
              this.userBrowserLang();
            }
          } else if (this.preLoginRoutes.find(route => event.url.indexOf(route) !== -1)) {
            this.userBrowserLang();
          }

          // Switch language back if it was overridden by discussion season
          if (event.urlAfterRedirects === "/landing-page" && this.currentUser && this.translate.currentLang) {
            const currentLang: string = this.translate.currentLang.substr(this.translate.currentLang.length - 2);

            if (currentLang !== this.currentUser.language) {
              this.contentService.setLanguage(this.currentUser.language, this.currentUser);
            }
          }
        }
      });

    this.organizationService.currentOrganization$
      .pipe(
        untilComponentDestroyed(this),
        filter(org => !!org)
      )
      .subscribe(org => {
        this.currentOrg = org;
        this.checkNavigateToGreeting();
      });

    this.userService.currentUser$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(user => {
        if (user === undefined) {
          this.currentUser = undefined;
          this.userService.update();

          return;
        }

        this.userChecked = true;

        if (!user) {
          this.currentUser = null;

          if (location.href.indexOf('landing-page') !== -1 || location.href.indexOf('game-session') !== -1 || location.href.indexOf('bi-report') !== -1) {
            // Navigate non-logged-in user back to login page
            this.router.navigate(['/welcome']);
          }

          return;
        }

        if (this.currentUser && this.currentUser._id.toString() === user._id.toString()) {
          // Same user, do nothing
          return;
        }

        this.currentUser = user;

        if (!user.cookieConsent?.youtube) {
          const consentGivenBeforeLogin: string | null = this.localStorageService.getItem("cookieConsent.youtube");

          if (consentGivenBeforeLogin) {
            console.log("Save cookie consent given before login to user", consentGivenBeforeLogin);

            this.userService.saveUser(
              user._id,
              {
                cookieConsent: {
                  youtube: new Date(consentGivenBeforeLogin)
                }
              }
            );
          }
        }

        // Connect socket
        this.socketService.createSocket();

        // Get info on user auth types
        this.userService.getAuthInfo()
          .pipe(
            first()
          )
          .subscribe();

        // Setup auto-join after socket
        this.socketService.connectionStatus$
          .pipe(
            filter(status => status === "connected"),
            first()
          )
          .subscribe((status) => {
            this.gameSessionAutoJoinerService.init();
          });

        this.contentService.loadOrgTranslations(user.language, user.orgId, true, true);
        this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/static/style/' + user.orgId + '.css');

        const nonRedirectRoutes: string[] = ["dashboard", "create-test-org", "progress-report"];
        if (!!nonRedirectRoutes.find(item => window.location.href.indexOf(item) !== -1)) {
          // Don't redirect from dashboard or create-test-org
          return;
        }

        this.checkNavigateToGreeting();
        this.checkActiveDiscussion();
      });
  }

  private checkNavigateToGreeting() {
    if (!this.currentOrg || !this.currentUser) {
      // Wait for the other
      return;
    }

    const validPrivacyPolicy: boolean = !!this.currentUser.privacyPolicyAccepted;
    // const validDepartment: boolean = !!this.currentUser.department || !this.currentOrg.departments.length;
    // const validLocation: boolean = !!this.currentUser.location || !this.currentOrg.locations.length;
    // const validName: boolean = !!this.currentUser.name || this.currentOrg.anonymizeNewUsers;

    // Greeting page is only shown during the first login (no privacy policy accepted yet)
    if (!validPrivacyPolicy) {
      this.router.navigate(['/greeting']);
    }
  }

  private checkActiveDiscussion() {
    this.gameSessionService.sessionDoc$
      .pipe(
        first()
      )
      .subscribe(
        currentSession => {
          if (!currentSession) {
            // Navigate to landing-page unless user has an active discussion
            this.router.navigate(['/landing-page']);
          }
        }
      );
  }

  public userBrowserLang(): void {
    const browserLang: string = this.translate.getBrowserLang() || 'en';
    const browserLangIndex: number = this.supportedLanguages.indexOf(browserLang);
    const useLanguageIndex: number = browserLangIndex !== -1 ? browserLangIndex : 0;
    const useLanguage = this.supportedLanguages[useLanguageIndex];

    this.translate.use(useLanguage);
    this.translate.setDefaultLang(useLanguage);
    this.contentService.loadAllTranslations();
  }
}
