import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Organization} from "../../data-model/organization.type";
import {NetworkUser, User} from "../../data-model/user.type";
import {GameSessionLog, UserReactions} from "../../data-model/game-session-log.type";
import {UserSurveyAnswer} from "../../data-model/survey-answer.type";
import {takeUntil} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import {MeasureAnswer} from "../../data-model/measure-question.interface";
import {MeasureService} from "../../core/service/measure.service";

@Component({
  selector: 'user-statistics-row',
  template: `
    <div class="statistics-area" [ngStyle]="{'background': backGround}" [ngClass]="additionalClass">
      <div class="header-title">
        {{'user-statistics.' + statisticsType | translate}}
      </div>

      <div class="gradient-line"></div>

      <div *ngIf="statisticsType === 'overview'">
        <div class="discussions-overview">
          <circle-graph
            [chartValue]="userSessions ? userSessions.length : 0"
            [circleSize]="'5vmin'"
            [halfCircleSize]="'2.5vmin'"
            [valueTextSize]="'3vmin'"
            [organizationColor]="true"
          ></circle-graph>

          <div class="discussion-overview-text">
            {{'user-statistics.overview-sessions' | translate}}
          </div>
        </div>

        <reaction-statistics
          *ngIf="userReactions?.length"
          [userReactions]="userReactions"
        ></reaction-statistics>
      </div>

      <ng-container *ngIf="statisticsType === 'engagement'">
        <div class="thumb-container" *ngIf="scaleMeasureAnswers">
          <div class="thumb-flex-area">
            <button *ngIf="scaleMeasureAnswers.length >= 2" class="icon-button" (click)="changeEngagementIndex(false)">
              <span class="next-page-arrow arrow-left"></span>
            </button>

            <svg version="1.1" viewBox="0 0 310.825 384.315" xmlns="http://www.w3.org/2000/svg" [ngStyle]="measureAnswers?.length && {width: '5vmin'}">
              <!-- Empty thumb -->
              <g>
                <path
                  class="thumb-empty"
                  d="M168.211,317.801c22.641-50.396,45.275-100.795,67.94-151.18c0.834-1.854,0.946-3.664,0.845-5.666
                c-0.46-9.119-1.101-18.225-2.149-27.29c-1.238-10.708-2.883-21.368-4.077-32.08c-0.375-3.359-0.846-6.708-1.306-10.058
                c-0.362-2.635-0.713-5.272-1.007-7.915c-0.377-3.386-0.66-6.782-0.729-10.201c-0.058-2.913,0.305-5.771,1.18-8.508
                c0.256-0.8,0.549-1.591,0.896-2.369c0.64-1.435,1.296-2.861,1.961-4.283c4.902-10.48,10.409-20.669,15.237-31.194
                c1.706-3.718,3.417-7.495,5.329-11.149c0.361-0.69,0.607-1.308,0.741-1.887c0.138-0.595,0.157-1.149,0.031-1.688
                c-0.071-0.304-0.186-0.603-0.347-0.903c-0.283-0.527-0.717-1.06-1.296-1.624c-0.159-0.154-0.329-0.312-0.511-0.471
                c-0.48-0.422-1.032-0.863-1.681-1.338c-1.548-1.134-3.07-2.459-4.729-3.307c-9.489-4.848-19.363-6.161-29.733-2.89
                c-1.181,0.373-2.332,0.797-3.468,1.247c-6.612,2.618-12.488,6.487-17.876,11.074c-3.607,3.07-6.807,6.692-9.808,10.425
                c-0.364,0.453-0.714,0.914-1.065,1.374c-7.56,9.903-12.163,21.396-16.929,32.738c-2.667,6.347-4.256,13.151-6.268,19.769
                c-0.081,0.267-0.172,0.492-0.262,0.719c-0.773,1.942-1.898,2.457-3.651,1.554c-0.28-0.144-0.575-0.324-0.888-0.539
                c-0.709-0.488-1.495-0.876-2.283-1.235c-0.059-0.026-0.116-0.057-0.174-0.083c-3.885-1.747-7.77-3.495-11.653-5.243
                c-14.893-6.702-29.791-13.393-44.694-20.07c-6.263-2.807-12.522-5.623-18.792-8.417c-7.278-3.244-15.076-4.287-22.835-2.778
                c-2.735,0.531-5.292,1.565-7.668,3.006c-2.125,1.289-4.106,2.9-5.921,4.808c-0.662,0.696-1.267,1.415-1.841,2.147
                c-1.473,1.881-2.694,3.875-3.79,5.966c-0.497,0.947-0.97,1.912-1.432,2.895c-1.637,3.482-2.272,6.983-2.156,10.549
                c0.023,0.713,0.077,1.429,0.158,2.147c0.163,1.438,0.438,2.887,0.81,4.351c0.726,2.862,1.536,5.68,2.505,8.461
                c0.21,0.605,0.317,1.145,0.32,1.626s-0.098,0.904-0.304,1.275c-0.411,0.742-1.24,1.274-2.489,1.649
                c-3.479,1.043-7.118,1.797-10.34,3.384c-1.036,0.51-2.036,1.077-3,1.696c-0.483,0.31-0.957,0.633-1.421,0.969
                c-2.325,1.681-4.432,3.684-6.325,5.957c-0.363,0.436-0.699,0.878-1.023,1.325c-0.074,0.103-0.147,0.207-0.22,0.311
                c-0.295,0.42-0.575,0.844-0.837,1.273c-0.015,0.025-0.032,0.049-0.046,0.074c-2.86,4.728-3.733,9.991-2.927,15.495
                c0.991,6.768,3.595,13.073,8.589,18.095c0.591,0.595,1.12,1.255,1.655,1.903c2.54,3.076,2.316,3.82-1.201,5.848
                c-3.721,2.146-7.333,4.401-10.313,7.654c-4.017,4.385-5.226,9.652-4.884,15.191c0.4,6.466,2.51,12.583,6.312,17.899
                c2.059,2.877,4.604,5.413,6.984,8.052c2.347,2.602,2.265,3.28-0.491,5.37c-4.125,3.129-8.148,6.403-9.647,11.683
                c-2.049,7.218-2.248,14.463-0.342,21.779c0.58,2.228,1.542,4.272,2.309,6.408c0.192,0.535,0.038,0.995-0.172,1.465l0.01,0.02
                c1.031,0.333,1.663,1.055,2.186,1.994c2.846,5.108,7.452,8.272,12.517,10.731c6.037,2.933,12.176,5.673,18.363,8.278
                c42.057,17.712,83.3,37.249,125.044,55.662C166.609,321.271,166.649,321.277,168.211,317.801z"/>
                <path
                  class="thumb-empty"
                  d="M308.755,193.352c-19.116-8.476-38.181-17.066-57.235-25.681c-1.802-0.815-3.08-1.18-3.763,1.226
                c-0.27,0.951-0.99,1.765-1.404,2.687c-17.564,39.076-35.166,78.135-52.597,117.271c-1.784,4.006-3.052,8.284-5.071,12.193
                c-8.43,16.316-15.195,33.39-22.943,50.016c-2.634,5.651-2.564,5.683,3.052,8.206c18.144,8.152,36.305,16.265,54.408,24.507
                c2.253,1.025,3.113,0.631,4.105-1.59c13.756-30.816,27.606-61.589,41.431-92.375c13.824-30.785,27.63-61.578,41.526-92.332
                C311.251,195.297,311.045,194.367,308.755,193.352z"/>
              </g>

              <clipPath id="thumb-clip-path">
                <rect
                  width="100%"
                  height="100%"
                  [attr.y]="(100 - (scaleMeasureAnswers[selectedEngagementIndex] ?
                    ((scaleMeasureAnswers[selectedEngagementIndex].measureScaleAnswer/scaleMeasureAnswers[selectedEngagementIndex].measure['measureQuestion'].scale)*100)
                    : 0) + '%')"
                  [ngStyle]="{'transition': 'y 0.3s ease-out'}"
                ></rect>
              </clipPath>

              <!-- Thumb fill -->
              <g clip-path="url(#thumb-clip-path)">
                <path
                  class="thumb-fill"
                  d="M168.211,317.801c22.641-50.396,45.275-100.795,67.94-151.18c0.834-1.854,0.946-3.664,0.845-5.666
                  c-0.46-9.119-1.101-18.225-2.149-27.29c-1.238-10.708-2.883-21.368-4.077-32.08c-0.375-3.359-0.846-6.708-1.306-10.058
                  c-0.362-2.635-0.713-5.272-1.007-7.915c-0.377-3.386-0.66-6.782-0.729-10.201c-0.058-2.913,0.305-5.771,1.18-8.508
                  c0.256-0.8,0.549-1.591,0.896-2.369c0.64-1.435,1.296-2.861,1.961-4.283c4.902-10.48,10.409-20.669,15.237-31.194
                  c1.706-3.718,3.417-7.495,5.329-11.149c0.361-0.69,0.607-1.308,0.741-1.887c0.138-0.595,0.157-1.149,0.031-1.688
                  c-0.071-0.304-0.186-0.603-0.347-0.903c-0.283-0.527-0.717-1.06-1.296-1.624c-0.159-0.154-0.329-0.312-0.511-0.471
                  c-0.48-0.422-1.032-0.863-1.681-1.338c-1.548-1.134-3.07-2.459-4.729-3.307c-9.489-4.848-19.363-6.161-29.733-2.89
                  c-1.181,0.373-2.332,0.797-3.468,1.247c-6.612,2.618-12.488,6.487-17.876,11.074c-3.607,3.07-6.807,6.692-9.808,10.425
                  c-0.364,0.453-0.714,0.914-1.065,1.374c-7.56,9.903-12.163,21.396-16.929,32.738c-2.667,6.347-4.256,13.151-6.268,19.769
                  c-0.081,0.267-0.172,0.492-0.262,0.719c-0.773,1.942-1.898,2.457-3.651,1.554c-0.28-0.144-0.575-0.324-0.888-0.539
                  c-0.709-0.488-1.495-0.876-2.283-1.235c-0.059-0.026-0.116-0.057-0.174-0.083c-3.885-1.747-7.77-3.495-11.653-5.243
                  c-14.893-6.702-29.791-13.393-44.694-20.07c-6.263-2.807-12.522-5.623-18.792-8.417c-7.278-3.244-15.076-4.287-22.835-2.778
                  c-2.735,0.531-5.292,1.565-7.668,3.006c-2.125,1.289-4.106,2.9-5.921,4.808c-0.662,0.696-1.267,1.415-1.841,2.147
                  c-1.473,1.881-2.694,3.875-3.79,5.966c-0.497,0.947-0.97,1.912-1.432,2.895c-1.637,3.482-2.272,6.983-2.156,10.549
                  c0.023,0.713,0.077,1.429,0.158,2.147c0.163,1.438,0.438,2.887,0.81,4.351c0.726,2.862,1.536,5.68,2.505,8.461
                  c0.21,0.605,0.317,1.145,0.32,1.626s-0.098,0.904-0.304,1.275c-0.411,0.742-1.24,1.274-2.489,1.649
                  c-3.479,1.043-7.118,1.797-10.34,3.384c-1.036,0.51-2.036,1.077-3,1.696c-0.483,0.31-0.957,0.633-1.421,0.969
                  c-2.325,1.681-4.432,3.684-6.325,5.957c-0.363,0.436-0.699,0.878-1.023,1.325c-0.074,0.103-0.147,0.207-0.22,0.311
                  c-0.295,0.42-0.575,0.844-0.837,1.273c-0.015,0.025-0.032,0.049-0.046,0.074c-2.86,4.728-3.733,9.991-2.927,15.495
                  c0.991,6.768,3.595,13.073,8.589,18.095c0.591,0.595,1.12,1.255,1.655,1.903c2.54,3.076,2.316,3.82-1.201,5.848
                  c-3.721,2.146-7.333,4.401-10.313,7.654c-4.017,4.385-5.226,9.652-4.884,15.191c0.4,6.466,2.51,12.583,6.312,17.899
                  c2.059,2.877,4.604,5.413,6.984,8.052c2.347,2.602,2.265,3.28-0.491,5.37c-4.125,3.129-8.148,6.403-9.647,11.683
                  c-2.049,7.218-2.248,14.463-0.342,21.779c0.58,2.228,1.542,4.272,2.309,6.408c0.192,0.535,0.038,0.995-0.172,1.465l0.01,0.02
                  c1.031,0.333,1.663,1.055,2.186,1.994c2.846,5.108,7.452,8.272,12.517,10.731c6.037,2.933,12.176,5.673,18.363,8.278
                  c42.057,17.712,83.3,37.249,125.044,55.662C166.609,321.271,166.649,321.277,168.211,317.801z"/>
                <path
                  class="thumb-fill"
                  d="M308.755,193.352c-19.116-8.476-38.181-17.066-57.235-25.681c-1.802-0.815-3.08-1.18-3.763,1.226
                  c-0.27,0.951-0.99,1.765-1.404,2.687c-17.564,39.076-35.166,78.135-52.597,117.271c-1.784,4.006-3.052,8.284-5.071,12.193
                  c-8.43,16.316-15.195,33.39-22.943,50.016c-2.634,5.651-2.564,5.683,3.052,8.206c18.144,8.152,36.305,16.265,54.408,24.507
                  c2.253,1.025,3.113,0.631,4.105-1.59c13.756-30.816,27.606-61.589,41.431-92.375c13.824-30.785,27.63-61.578,41.526-92.332
                  C311.251,195.297,311.045,194.367,308.755,193.352z"/>
              </g>
            </svg>

            <button *ngIf="scaleMeasureAnswers.length >= 2" class="icon-button" (click)="changeEngagementIndex(true)">
              <span class="next-page-arrow arrow-right"></span>
            </button>
          </div>

          <h2 class="thumb-number" *ngIf="scaleMeasureAnswers[selectedEngagementIndex]">
            {{scaleMeasureAnswers[selectedEngagementIndex].measureScaleAnswer}}/{{scaleMeasureAnswers[selectedEngagementIndex].measure['measureQuestion'].scale}}
          </h2>

          <div class="engagement-area" (mouseenter)="showFullEngagementQuestion = true" (mouseleave)="showFullEngagementQuestion = false">
            <div class="engagement-question">
              {{scaleMeasureAnswers[selectedEngagementIndex] ?
              ('"' + scaleMeasureAnswers[selectedEngagementIndex].measure['measureQuestion'].prompt + '"') :
              ('user-statistics.no-engagement-question' | translate)}}
            </div>

            <div class="engagement-question-full" [ngClass]="{'visible': showFullEngagementQuestion}" (mouseleave)="showFullEngagementQuestion = false">
              {{scaleMeasureAnswers[selectedEngagementIndex] ?
              ('"' + scaleMeasureAnswers[selectedEngagementIndex].measure['measureQuestion'].prompt + '"') :
              ('user-statistics.no-engagement-question' | translate)}}
            </div>
          </div>
        </div>

        <div *ngIf="measureAnswers?.length">
          <div class="sub-header-title">
            {{'user-statistics.measures' | translate}}
          </div>

          <div class="sub-header-line"></div>

          <user-measure
            [measureAnswers]="measureAnswers"
            [currentUser]="currentUser"
          ></user-measure>
        </div>
      </ng-container>

      <network-graph
        *ngIf="statisticsType === 'network'"
        [currentUser]="currentUser"
        [currentOrganization]="currentOrganization"
        [userSessions]="userSessions"
        [organizationUsers]="organizationUsers"
      ></network-graph>
    </div>
  `,
})
export class UserStatisticsRowComponent extends OnDestroyMixin implements OnChanges {
  @Input() backGround: string;
  @Input() statisticsType: string;
  @Input() pureTextContent: string;
  @Input() additionalClass: string;

  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() userSessions: GameSessionLog[];
  @Input() organizationUsers: NetworkUser[];
  @Input() userReactions: UserReactions[];

  @Input() userSurveyAnswers: UserSurveyAnswer[];
  @Input() maxEngagementValue: number = 5;

  public selectedEngagementIndex: number = 0;
  public measureAnswers: MeasureAnswer[];
  public scaleMeasureAnswers: MeasureAnswer[];

  public showFullEngagementQuestion: boolean;

  constructor(private measureService: MeasureService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser && this.statisticsType === "engagement") {
      const userMeasureQuery = {
        org: this.currentUser.orgId,
        user: this.currentUser._id,
        isTest: {$ne: true}
      };

      this.measureService.getMeasureAnswers(userMeasureQuery)
        .pipe(
          untilComponentDestroyed(this),
        )
        .subscribe(
          (measureAnswers: MeasureAnswer[]) => {
            this.measureAnswers = measureAnswers;

            this.scaleMeasureAnswers = measureAnswers.filter(item => {
              return item.measureScaleAnswer > 0;
            });
          }
        );
    }
  }

  public changeEngagementIndex(forward: boolean) {
    if (!this.scaleMeasureAnswers || !this.scaleMeasureAnswers.length) {
      return;
    }

    if (forward) {
      const newIndex = this.selectedEngagementIndex + 1;
      this.selectedEngagementIndex = this.scaleMeasureAnswers.length > newIndex ? newIndex : 0;
      return;
    }
    if (!forward) {
      const newIndex = this.selectedEngagementIndex - 1;
      this.selectedEngagementIndex = newIndex > -1 ? newIndex : this.scaleMeasureAnswers.length - 1;
    }
  }

  /*public changeEngagementIndex(forward: boolean) {
    if (!this.userSurveyAnswers || !this.userSurveyAnswers.length) {
      return;
    }

    if (forward) {
      const newIndex = this.selectedEngagementIndex + 1;
      this.selectedEngagementIndex = this.userSurveyAnswers.length > newIndex ? newIndex : 0;
      return;
    }
    if (!forward) {
      const newIndex = this.selectedEngagementIndex - 1;
      this.selectedEngagementIndex = newIndex > -1 ? newIndex : this.userSurveyAnswers.length - 1;
    }
  }*/
}
