/**
 * Created by janne on 1.9.2016.
 */
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from "app/core/service/user.service";
import {GameSessionService} from "app/core/service/game-session.service";
import {User} from "../../data-model/user.type";
import {Organization} from "../../data-model/organization.type";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'user-button-bar',
  templateUrl: './user-button-bar.component.html'
})

export class UserButtonBarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() showClose: boolean;
  @Input() hideSettings: boolean;
  @Input() showHelpButton: boolean;
  @Input() exitButtonType: string;
  @Input() exitDisabled: boolean;
  @Input() currentTab: string;
  @Input() disableLanguage: boolean;

  @Output() exitTutorialEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showHelpEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleMiniAdminEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onExitPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showSettingsMenu: boolean;
  public showLanguageMenu: boolean;
  public showAdminButton: boolean;
  public showBiButton: boolean;
  public showingHelp: boolean;

  constructor(protected userService: UserService,
              private router: Router,
              private gameSessionService: GameSessionService,
              public translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentUser'] && this.currentUser) {
      this.showAdminButton = this.userService.adminViewAccess();
    }

    if ((changes['currentUser'] || changes['currentOrganization']) && this.currentUser && this.currentOrganization) {
      this.showBiButton = this.currentOrganization.biViewers?.includes(this.currentUser.email);
    }

    if (changes.currentTab && this.currentTab === 'update-profile') {
      this.showSettingsMenu = true;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  public returnToLandingPage(): void {
    this.router.navigate(['/landing-page']);
  }

  public exitTutorial(): void {
    this.exitTutorialEmitter.emit(true);
  }

  public checkTutorialLocation(): boolean {
    return window.location.href.indexOf('game-tutorial') !== -1;
  }

  public checkIsLandingPage(): boolean {
    return window.location.href.indexOf('landing-page') !== -1;
  }

  public emitToggleHelp() {
    this.showingHelp = !this.showingHelp;
    this.showHelpEmitter.emit(this.showingHelp);
  }

  public toggleMiniAdmin() {
    this.toggleMiniAdminEmitter.emit();
  }

  public onPressExit(): void {
    if (this.exitButtonType === "discussion") {
      this.exitDiscussion();
    }

    this.onExitPressed.emit();
    this.router.navigate(['/landing-page']);
  }

  public exitDiscussion(): void {
    this.gameSessionService.leaveDiscussionRoom("button-leave-discussion");
  }

  /**
   * Navigate to admin page
   */
  public openAdminPage(): void {
    // Disconnect from session if connected
    if (this.gameSessionService.isConnected()) {
      this.gameSessionService.leaveDiscussionRoom("admin-leave-discussion");
    }

    this.router.navigate(['/admin']);
  }

  public openBiReport() {

    // TODO: Originally wanted to open report to another tab. Needs the LOGE navigation to be reworked before the below
    //       lines can be uncommented.
    
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/#/bi-report/'])).replace('%23', '#');
    // window.open(url, '_blank');
    
    this.router.navigate(['/bi-report']);
  }
}
