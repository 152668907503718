import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OrganizationService } from 'app/core/service/organization.service';
import { UserService } from 'app/core/service/user.service';
import { Observable } from 'rxjs';
import { map, skip, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BiGuardGuard implements CanActivate {

  
  constructor(
    private userService: UserService,
    private orgService: OrganizationService
    ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const currentOrgContent = this.orgService.currentOrgContent$.getValue();

    if (!!currentOrgContent) {
      return !!currentOrgContent.pbiIframe;
    } else {
      return this.orgService.currentOrgContent$.pipe(skip(1), take(1), map(val => { return !!val?.pbiIframe; }));
    }
  }
}
