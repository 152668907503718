import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import MediasoupRoom from "../../../../lib/mediasoup/mediasoup-room";
import { User } from "../../../data-model/user.type";
import * as _ from "lodash";

import { getDeviceInfo, isDeviceSupported } from "mediasoup-client";
import { filter } from "rxjs/operators";
import { AudioDeviceInfo, AudioDeviceService, AvailableDevices } from "../../../core/service/audio-device.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import Timer = NodeJS.Timer;

const hark = require("hark");

@Component({
  selector: "audio-device-test",
  template: `
    <!-- Show placeholder icon while no tests active -->
    <span *ngIf="!chatRoom && !audioTestActive" class="content-big-icon glyphicon glyphicon-headphones"></span>

    <div class="device-test-container" [ngStyle]="{flexGrow: 1}" *ngIf="chatRoom || audioTestActive">
      <!-- Show either speech volume or current duration for audio -->
      <div class="device-test-bar">
        <div class="device-test-bar-fill"
             [ngStyle]="{width: (chatRoom ? rtcVolumePercentage : ((audioCurrentTime / audioDuration) * 100 || 0)) + '%'}"
        ></div>
      </div>

      <div class="flex-row-centered device-test-info">
          <span class="one-line-text" [ngStyle]="{width: '16vw'}">
            {{"audio-device-test.using-device" | translate}} <b>{{(chatRoom ? selectedDevices?.input?.label : selectedDevices?.output?.label) || "Default"}}</b>.
          </span>

        <button
          class="next-device-button"
          *ngIf="(chatRoom ? availableDevices?.input?.length : availableDevices?.output?.length) > 1"
          (click)="switchDevice(chatRoom ? 'input' : 'output')"
        >
          {{"audio-device-test.next-device" | translate}}
        </button>
      </div>

      <ng-container *ngIf="chatRoom">
        <!-- Not working messages-->
        <span *ngIf="voiceError">
            {{voiceError}}
          </span>

        <span *ngIf="!rtcMicHeard && !rtcMicAccepted">
            {{"audio-device-test.please-speak" | translate}}
          </span>

        <span *ngIf="!rtcMicHeard && !rtcMicAccepted && rtcMicNotHeard">
            {{"audio-device-test.speech-not-detected" | translate}}
          </span>

        <!-- Working-ish messages -->
        <span *ngIf="rtcMicHeard && !rtcMicAccepted">
              {{"audio-device-test.low-volume" | translate}}
            </span>
        <span *ngIf="rtcMicAccepted">
              <span class="glyphicon glyphicon-ok"></span>
          {{"audio-device-test.speech-detected" | translate}}
            </span>
      </ng-container>

      <ng-container *ngIf="audioTestActive">
            <span *ngIf="!audioHeard">
              {{"audio-device-test.output-prompt" | translate}}
            </span>

        <span *ngIf="audioHeard">
              <span class="glyphicon glyphicon-ok"></span>
          {{"audio-device-test.audio-detected" | translate}}
            </span>

        <div class="flex-row-centered" *ngIf="!audioHeard" [ngStyle]="{marginTop: '1vmin'}">
          <raised-css-button
            [ngStyle]="{marginRight: '1vmin'}"
            [fontSize]="'1.5vmin'"
            [buttonText]="'audio-device-test.output-heard'"
            (onClick)="saveDeviceChanges('output')"
          ></raised-css-button>

          <raised-css-button
            [fontSize]="'1.5vmin'"
            [buttonText]="'audio-device-test.output-not-heard'"
            (onClick)="switchDevice('output')"
          ></raised-css-button>
        </div>
      </ng-container>
    </div>

    <div class="device-test-row">
      <!-- Start UDP-connection and microphone test -->
      <raised-css-button
        [ngStyle]="{width: '45%'}"
        [buttonClasses]="'full-width'"
        [buttonText]="chatRoom ? 'audio-device-test.stop-test' : 'audio-device-test.start-input-test'"
        [iconClass]="'custom-icon icon-mic-on'"
        [buttonDown]="!!chatRoom"
        [disabled]="audioTestActive"
        [marginBottom]="'2vmin'"
        (onClick)="toggleChatRoomTest(!chatRoom)"
      ></raised-css-button>

      <!-- Start output (speaker, headphone) test -->
      <raised-css-button
        [ngStyle]="{width: '45%'}"
        [buttonClasses]="'full-width'"
        [buttonText]="audioTestActive ? 'audio-device-test.stop-test' : 'audio-device-test.start-output-test'"
        [iconClass]="'custom-icon icon-audio-loud'"
        [buttonDown]="audioTestActive"
        [disabled]="!!chatRoom"
        [marginBottom]="'2vmin'"
        (onClick)="toggleAudioTest(!audioTestActive)"
      ></raised-css-button>
    </div>

    <!-- The audio element is hidden from view -->
    <audio #audioOption loop>
      <source src='/assets/sound/tropical_island.mp3' type="audio/mp3">
    </audio>
  `,
  styles: []
})
export class AudioDeviceTestComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild("circle", { static: true }) circleElement: ElementRef;
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;

  @Input() currentUser: User;

  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public chatRoom: MediasoupRoom;
  public localStream: MediaStream;
  public localSpeech: any;

  public voiceDetectedTimer: Timer;
  public voiceNotHeardTimer: Timer;
  public volumeChangeTimer: Timer;
  public preConnectMessage: string;

  public voiceError: string;
  public deviceError: string;

  public selectedDevices: AudioDeviceInfo;
  public availableDevices: AvailableDevices;

  // Mic test
  public rtcVolumePercentage: number;
  public rtcMicHeard: boolean;
  public rtcMicNotHeard: boolean;
  public rtcMicAccepted: boolean;

  // Headphone/speaker test
  public audioTestActive: boolean;
  public audioPlaying: boolean;
  public audioHeard: boolean;
  public audioDuration: number;
  public audioCurrentTime: number;
  public updateTimeListenerFunction: any = this.updateTime.bind(this); // Bind this to updateTime for removing listener

  constructor(
    private audioDeviceService: AudioDeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.audioDeviceService.availableDevices$
      .pipe(
        untilComponentDestroyed(this),
        filter(availableDevices => !!availableDevices)
      )
      .subscribe((availableDevices: AvailableDevices) => {
        if (!availableDevices?.input?.length && !availableDevices?.output?.length) {
          console.warn("No devices detected", availableDevices);
          return;
        }

        this.availableDevices = availableDevices;
      });

    this.audioDeviceService.selectedDevices$
      .pipe(
        untilComponentDestroyed(this),
        filter(selectedDevices => !!selectedDevices)
      )
      .subscribe((selectedDevices: AudioDeviceInfo) => {
        if (selectedDevices?.output?.deviceId !== this.selectedDevices?.output?.deviceId) {
          // Output changed, set sinkId to audio element
          this.audioPlayerRef.nativeElement.setSinkId(selectedDevices?.output?.deviceId || "communications");

          if (this.audioTestActive) {
            // Reset heard status
            this.resetAudioState();

            // Start playing again if stopped
            if (!this.audioPlaying) {
              this.togglePlayAudio(true);
            }
          }
        }

        this.selectedDevices = _.clone(selectedDevices);
      });

    this.audioDeviceService.deviceError$
      .pipe(
        filter(errorMsg => !!errorMsg)
      )
      .subscribe((errorMsg: string) => {
        this.deviceError = errorMsg;
      });
  }

  ngOnDestroy(): void {
    if (this.chatRoom) {
      this.toggleChatRoomTest(false);
    }

    if (this.audioPlaying) {
      this.toggleAudioTest(false);
    }

    this.clearVoiceDetectedTimer();
  }

  public saveDeviceChanges(type?: "input" | "output"): void {
    if (type === "output") {
      // Show success message
      this.audioHeard = true;

      this.togglePlayAudio(false);
    }

    // Save changes
    this.audioDeviceService.saveUserAudioDeviceSelection(type);
  }

  public switchDevice(type: "input" | "output"): void {
    // Let device service handle switching
    this.audioDeviceService.switchVoiceDevice(type);
  }

  private updateTime(): void {
    this.audioCurrentTime = this.audioPlayerRef.nativeElement.currentTime;
  }

  public togglePlayAudio(start: boolean): void {
    console.log("togglePlayAudio", start);

    if (!this.audioDuration) {
      // Set duration if not yet set
      this.audioDuration = this.audioPlayerRef.nativeElement.duration;
    }

    if (start) {
      // Play audio and create listener for timeupdate
      this.audioPlayerRef.nativeElement.play();
      this.audioPlayerRef.nativeElement.addEventListener("timeupdate", this.updateTimeListenerFunction);
      this.audioPlaying = true;
    } else {
      // Pause audio and remove listener
      this.audioPlayerRef.nativeElement.pause();
      this.audioPlayerRef.nativeElement.removeEventListener("timeupdate", this.updateTimeListenerFunction);
      this.audioPlaying = false;
    }
  }

  public toggleAudioTest(start: boolean): void {
    if (start && this.chatRoom) {
      // Stop speech test if active
      this.closePersonalChatRoom();
    }

    this.resetAudioState();
    this.audioTestActive = start;

    // Toggle audio play/pause
    this.togglePlayAudio(start);
  }

  public stopListener(): void {
    if (this.localSpeech) {
      this.localSpeech.stop();
      this.localSpeech = null;
    }

    if (this.localStream) {
      this.audioDeviceService.stopUserMediaStream(this.localStream);
    }

    // Reset heard heard status as well
    this.resetSpeechState();
  }

  public resetSpeechState(): void {
    this.rtcVolumePercentage = 0;
    this.rtcMicHeard = false;
    this.rtcMicNotHeard = false;
    this.rtcMicAccepted = false;
  }

  public resetAudioState(): void {
    this.audioHeard = false;
    this.audioPlaying = false;
  }

  public clearChatRoomState(): void {
    // Reset messages
    this.voiceError = null;
    this.preConnectMessage = null;

    // Reset heard status
    this.resetSpeechState();
  }

  public closePersonalChatRoom(): void {
    if (this.chatRoom) {
      this.chatRoom.closeConnection();
      this.chatRoom = null;
    }

    this.stopListener();
    this.clearChatRoomState();
  }

  public checkConnectionError(): void {
    if (this.chatRoom && this.chatRoom.roomState === "connected") {
      // Connection successful
      return;
    }

    if (!this.preConnectMessage || this.preConnectMessage !== "chat-connecting") {
      // Message has changed
      return;
    }

    this.preConnectMessage = "room-start-failed";
  }

  public toggleChatRoomTest(start: boolean): void {
    console.log("toggleChatRoomTest", start, this.audioPlaying);
    if (start && this.audioPlaying) {
      console.log("Stop paly", this.audioPlaying);
      // Stop audio test
      this.toggleAudioTest(false);
    }

    if (!start) {
      this.closePersonalChatRoom();
      return;
    }

    this.startPersonalChatRoom();
  }

  // Create connection to RTC-server
  public startPersonalChatRoom() {
    if (!this.currentUser) {
      // Not allowed if not logged in
      return;
    }

    this.preConnectMessage = "chat-connecting";

    setTimeout(() => {
      this.checkConnectionError();
    }, 5000);

    const device: any = getDeviceInfo();

    if (!isDeviceSupported()) {
      console.error("Device is not supported by WebRTC!", device);
      this.preConnectMessage = "not-supported";

      return;
    }

    try {
      this.chatRoom = new MediasoupRoom(
        this.currentUser._id,
        this.currentUser._id,
        device,
        this.audioDeviceService,
        true
      );
    } catch (err) {
      console.error("Failed starting test chatRoom", err);

      this.preConnectMessage = "room-start-failed";

      return;
    }

    this.chatRoom.personalTrackChanged$
      .pipe(
        untilComponentDestroyed(this),
        filter(track => !!track)
      )
      .subscribe(() => {
        this.setLocalVoiceListener();
      });

    this.chatRoom.errorsSubject$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(error => {
        if (!error) {
          return;
        }

        this.voiceError = error.name + ": " + error.message;
        console.error("Voice chat room error", error);
      });
  }

  private clearVoiceDetectedTimer(): void {
    if (this.voiceDetectedTimer) {
      clearTimeout(this.voiceDetectedTimer);
      this.voiceDetectedTimer = null;
    }

    if (this.volumeChangeTimer) {
      clearTimeout(this.voiceDetectedTimer);
      this.voiceDetectedTimer = null;
    }

    if (this.voiceNotHeardTimer) {
      clearTimeout(this.voiceNotHeardTimer);
      this.voiceNotHeardTimer = null;
    }
  }

  // Listener for real RTC-connection
  private setLocalVoiceListener() {
    if (!this.chatRoom || !this.chatRoom.micProducer?.track) {
      console.error("No voice chat room found or microphone hasn't connected");
      return;
    }

    // Stop previous listeners
    this.stopListener();

    // Clear previous auto-switch timer
    this.clearVoiceDetectedTimer();

    this.localStream = new MediaStream;
    this.localStream.addTrack(this.chatRoom.micProducer?.track);

    // Create a hark instance to listen to the local audio stream volume
    this.localSpeech = hark(this.localStream, { play: false, interval: 200 });

    // Automatically switch device after 2s + 5s of no voice heard
    this.voiceDetectedTimer = setTimeout(() => {
      // Show message of not hearing 1s before changing
      this.rtcMicNotHeard = true;

      // Automatically switch device after showing message for 2s
      this.voiceNotHeardTimer = setTimeout(() => {
        this.audioDeviceService.switchVoiceDevice("input");
      }, 2 * 1000);
    }, 5 * 1000);

    // Wait for 500ms since some mics are weird
    this.volumeChangeTimer = setTimeout(() => {
      // Use 80 as min, since some devices emit white noise
      this.setVolumeChangeListener(80);
    }, 500);
  }

  private setVolumeChangeListener(mindBs: number): void {
    if (!this.localSpeech) {
      // Already stopped
      return;
    }

    this.localSpeech.on("volume_change", dBs => {
      if (dBs < -mindBs) {
        dBs = -mindBs;
      }

      if (dBs > 0) {
        dBs = 0;
      }

      this.rtcVolumePercentage = dBs + mindBs;

      if (!this.rtcMicHeard) {
        this.rtcMicHeard = this.rtcVolumePercentage > 0;

        if (this.rtcMicHeard) {
          // Clear auto-switch timer, since successfully heard
          this.clearVoiceDetectedTimer();
          this.successEmitter.emit(true);
        }
      }

      if (!this.rtcMicAccepted) {
        this.rtcMicAccepted = this.rtcVolumePercentage > 20;

        if (this.rtcMicAccepted) {
          // Save selection automatically
          this.saveDeviceChanges("input");
        }
      }
    });
  }
}
