/**
 * Created by janne on 27.9.2016.
 */

import {throwError, ReplaySubject, Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import { User, SystemAdmin, UserPermissions, NetworkInformation, ExtraUserData, AuthInfo } from "app/data-model/user.type";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Prize} from "../../data-model/prize-set.type";
import {catchError, map} from "rxjs/operators";
import {HardwareInfo} from "../../data-model/game-state.interface";
import { OrganizationNameIDPair } from "../../data-model/organization.type";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public static validPasswordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  public lastRefresh: Date;
  public hardwareInfo: HardwareInfo;
  public networkInfo: NetworkInformation;
  public timeZone: string;

  public currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(undefined);
  public currentUserSystemAdmin$: BehaviorSubject<SystemAdmin> = new BehaviorSubject<SystemAdmin>(null);
  public userUpdated$: ReplaySubject<string> = new ReplaySubject<string>();
  public fetchedUsers$: ReplaySubject<string> = new ReplaySubject<string>();

  public currentUserPermissions$: BehaviorSubject<UserPermissions> = new BehaviorSubject<UserPermissions>(null);

  // admin only
  public orgUsers$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  public orgUsersExtra$: BehaviorSubject<ExtraUserData[]> = new BehaviorSubject<ExtraUserData[]>(null);
  public allEmails$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  protected users = new Map<string, BehaviorSubject<User>>();
  private getOrgListUrl: string = '/api/user/list-by-organization/';
  private getOrgListGuestsUrl: string = '/api/guests/list-by-organization';
  private getlistEmailsUrl: string = '/api/user/list-emails/';
  private removeUrl: string = '/api/user/';
  private setPrizeSelectionURL: string = '/api/user/prize/';
  private isAdminUrl: string = '/api/user/is-system-admin/';
  private changePasswordUrl: string = '/api/user/password/';
  private recoveryStartUrl: string = 'api/user/start-recovery/';
  private recoveryCompleteUrl: string = 'api/user/complete-recovery/';
  private recoveryCheckUrl: string = 'api/user/recovery-key-valid/';
  private createLocalUrl: string = 'api/user/create-local/';
  private createBatchLocalUrl: string = 'api/user/create-local-batch/';
  private activateLocalUrl: string = 'api/user/activate/';
  private inDomainUrl: string = 'api/user/in-domain/';
  private activationCheckUrl: string = 'api/user/activation-key-valid/';
  private invitationUrl: string = 'api/user/invitation/';
  private changeAdminOrgUrl: string = 'api/user/change-admin-org/';
  private changeOrgUrl: string = 'api/user/change-sub-org/';
  private getUserSessionsUrl: string = '/api/user/user-serssion-listing/';
  private retryUserFetch: number = 0;

  constructor(private http: HttpClient,
              private translate: TranslateService,
              private router: Router) {
    this.lastRefresh = new Date();
    this.hardwareInfo = UserService.getHardwareInfo();
    this.networkInfo = UserService.getNetworkInfo();
    this.timeZone = UserService.getTimeZone();
  }

  public static getTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public static getHardwareInfo(): HardwareInfo {
    const userAgent = window.navigator.userAgent;
    const browsers = ['edge', 'chrome', 'firefox', 'safari', 'internet explorer'];

    function whatBrowser() {
      for (let i = 0; i < browsers.length; i++) {
        if (userAgent.toLowerCase().indexOf(browsers[i].toLowerCase()) !== -1) {
          return browsers[i];
        }
      }
      return 'unknown';
    }

    return {
      browser: whatBrowser(),
      language: window.navigator.language,
      os: window.navigator.userAgent.split('(')[1].split(')')[0],
      windowSize: window.outerWidth + 'x' + window.outerHeight
    };
  }

  public static getNetworkInfo(): NetworkInformation {
    const networkInfo: NetworkInformation = navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'];

    if (!networkInfo) {
      return null;
    }

    return {
      effectiveType: networkInfo.effectiveType,
      rtt: networkInfo.rtt,
      downlink: networkInfo.downlink,
      saveData: networkInfo.saveData,
      type: networkInfo.type,
      downlinkMax: networkInfo.downlinkMax
    };
  }

  public update(): void {
    this.fetchCurrentUser();
  }

  public logout(): void {
    this.http.get('/api/auth/logout')
      .subscribe(
        res => {
          this.currentUser$.next(undefined);
          this.currentUserSystemAdmin$.next(null);
          this.currentUserPermissions$.next(null);

          this.router.navigate(['/welcome']);
        },
        err => {
          console.error("Logout failed");
        }
      );
  }

  /**
   *
   * @param id - The ID of the User
   * @param forceFetch - Query the server for an up to date User zprofile even
   *  when the profile is found in the local cache. This will first return the
   *  current profile from the cache and when the query completes, the new
   *  profile will be pushed.
   * @returns {Observable<User>}
   */
  public getUser(id: string, forceFetch: boolean = false): Observable<User> {
    if (this.users.has(id)) {
      if (forceFetch) {
        this.fetchUser(id);
      }

      // The user was found in the cache
      return this.users.get(id).asObservable();
    }


    // The user is not in the cache. Get it from the server.
    const userSubject = new BehaviorSubject<User>(null);
    this.users.set(id, userSubject);

    this.fetchUser(id);

    return userSubject.asObservable();
  }

  // Fetch user, populate org if found
  public managerFetchUser(query: { _id?: string, name?: string, email?: string, orgId?: string }): Observable<User> {
    return this.http.put<User>("/api/user/get-user", query)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getAuthInfo(userId?: string): Observable<AuthInfo> {
    return this.http.get<AuthInfo>("/api/user/auth-type/" + (userId || ""))
      .pipe(
        map(res => {
          if (!userId) {
            // Want current user
            this.authInfo$.next(res);
          }

          return res;
        }),
        catchError(err => {
          if (!userId) {
            this.authInfo$.next(null);
          }

          return throwError(err);
        })
      );
  }

  /**
   * Update list of User emails
   */
  public updateEmailList(): void {
    this.http.get(this.getlistEmailsUrl)
      .subscribe(
        res => {
          this.allEmails$.next(res as string[]);
        },
        err => {
          console.error("Fetching user emails listing failed", err);
          this.allEmails$.next(null);
        }
      );
  }

  /**
   * Update list of organization users
   * @param {string} orgId
   */
  public getOrgUsers(orgId: string): void {
    this.orgUsers$.next(null);

    this.http.get(this.getOrgListUrl + orgId)
      .subscribe(
        res => {
          this.orgUsersExtra$.next(res as ExtraUserData[]);
          this.orgUsers$.next((res as ExtraUserData[]).map(value => {
            return value.user;
          }));
        },
        err => {
          console.error("Fetching users listing failed", err);
          this.orgUsers$.next([]);
        }
      );
  }

  /**
   * Update list of organization users
   * @param {string} orgId
   */
  public getOrgUserList(orgId: string): Observable<User[]> {
    return this.http.get(this.getOrgListUrl + orgId)
      .pipe(
        map(res => {
          return res as User[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Update list of organization users requested by guests
   * @param {string} orgId
   * @param {Object} query consists of user and password
   */
  public updateOrgUserListForGuests(query: Object): void {
    this.http.put(this.getOrgListGuestsUrl, query)
      .subscribe(
        res => {
          this.orgUsers$.next(res as User[]);
        },
        err => {
          console.error("Fetching users listing failed", err);
          this.orgUsers$.next(null);
        }
      );
  }


  /**
   * Create new local user (with email only)
   * @param {string} orgId
   * @param {string} email
   */
  public createLocalUser(orgId: string, email: string) {
    const body = JSON.stringify({
      email: email
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.createLocalUrl + orgId, body, options)
      .subscribe(
        res => this.getOrgUsers(orgId),
        err => console.error("Failed to create local user", email)
      );
  }

  /**
   * Create new local user
   * @param {string} orgId
   * @param {{string, string, string}} user
   */
  public createLocalUserWithName(orgId: string, user: { email: string, name: string, language: string }) {
    const body = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.createLocalUrl + orgId, body, options)
      .subscribe(
        res => this.getOrgUsers(orgId),
        err => console.error("Failed to create user with name", user.email)
      );
  }

  /**
   * Create a batch of local users
   * @param {string} orgId
   * @param {any[]} users
   */
  public createBatchLocalUsers(orgId: string, users: { email: string, name: string, language: string }[]) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.createBatchLocalUrl + orgId, users, options)
      .subscribe(
        res => this.getOrgUsers(orgId),
        err => console.error("Failed to create new users")
      );
  }

  /**
   * Check if User activation key is valid
   * @param {string} userId
   * @param {string} activationKey
   * @param {(error, res) => void} cb
   */
  public checkActivationInfo(userId: string, activationKey: string, cb: (error, res) => void) {
    this.http.get(this.activationCheckUrl + userId + "/" + activationKey)
      .subscribe(
        res => {
          cb(null, res === true);
        },
        err => {
          cb(err, null);
        }
      );
  }

  /**
   * Check if User's domain is in organization
   * @param {string} userId
   * @param {(error, res) => void} cb
   */
  public inOrgDomain(userId: string, cb: (error, res) => void) {
    this.http.get(this.inDomainUrl + userId)
      .subscribe(
        res => {
          cb(null, res === true);
        },
        err => {
          cb(err, null);
        }
      );
  }

  /**
   * Activate local user with activation key
   * @param {string} id
   * @param {string} key
   * @param {string} password
   * @param {(error) => void} cb
   */
  public activateLocalUser(id: string, key: string, password: string, cb: (error) => void) {
    const body = JSON.stringify({
      activationKey: key,
      password: password
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.activateLocalUrl + id, body, options)
      .subscribe(
        res => {
          cb(null);
        },
        err => {
          cb(err);
        }
      );
  }

  /**
   * Save changes to User
   * @param {string} id
   * @param {Object} changeSet
   */
  public saveUser(id: string, changeSet: Object): void {
    if (!id) {
      // Use currentUser id if not set
      id = this.currentUser$.value._id;

      if (!id) {
        console.error("No id for saving user", id);
        return;
      }
    }

    this.http.put(`/api/user/${id}`, changeSet)
      .subscribe(
        res => {
          // Also update currentUser if id's match
          if (id === this.currentUser$.value._id) {
            this.currentUser$.next(res as User);
            this.userUpdated$.next(id);
          } else {
            this.userUpdated$.next(id);
            this.fetchUser(id);
          }
        },
        err => {
          console.error("Failed to save user", id, err);
        }
      );
  }

  /**
   * Remove user
   * @param {User} user
   */
  public removeUser(user: User): void {
    this.http.delete(this.removeUrl + user._id)
      .subscribe(
        res => this.getOrgUsers(user.orgId),
        err => console.error("Failed to remove user", user._id)
      );
  }

  /**
   * Move test user to new organization
   * @param {string} userId
   * @param {string} newOrgId
   */
  public moveUser(userId: string, newOrgId: string): Observable<User> {
    return this.http.put<User>("/api/user/move-user", {userId: userId, newOrgId: newOrgId})
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      );
  }

  /**
   * Change User password
   * @param {string} currentPwd
   * @param {string} newPwd
   * @param {(error: boolean, res: any) => void} cb
   */
  public changePassword(currentPwd: string, newPwd: string, cb: (error: boolean, res: any) => void) {
    if (!this.currentUser$ || !this.currentUser$.value) {
      return cb(true, null);
    }
    const body = JSON.stringify({
      password: currentPwd,
      newPassword: newPwd
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.put(this.changePasswordUrl + this.currentUser$.value._id, body, options)
      .subscribe(
        res => {
          cb(false, res);
        },
        err => {
          cb(true, err);
        }
      );
  }

  /**
   * Start password recovery by sending email to user
   * @param {string} email
   */
  public startPasswordRecovery(email: string): Observable<boolean> {
    return this.http.get(this.recoveryStartUrl + encodeURI(email))
      .pipe(
        map(res => {
          return !!res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Make sure recovery key is still valid
   * @param {string} userId
   * @param {string} recoveryKey
   */
  public checkRecoveryInfo(userId: string, recoveryKey: string): Observable<boolean> {
    return this.http.get(this.recoveryCheckUrl + userId + "/" + recoveryKey)
      .pipe(
        map((res: boolean) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Finish password recovery by changing password
   * @param {string} userId
   * @param {string} recoveryKey
   * @param {string} newPassword
   * @param {(error) => void} cb
   */
  public completePasswordRecovery(userId: string, recoveryKey: string, newPassword: string, cb: (error) => void) {
    const body = JSON.stringify({
      newPassword: newPassword,
      recoveryKey: recoveryKey
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.recoveryCompleteUrl + userId, body, options)
      .subscribe(
        res => {
          cb(null);
        },
        err => {
          cb(err);
        }
      );
  }

  /**
   * Set prize selected for User
   * @param {number} prizeSetIndex
   * @param {string} prize
   */
  public setPrizeSelectionCurrentUser(prizeSetIndex: number, prize: Prize) {
    const body = JSON.stringify({
      prizeUpdatePackage: {
        userId: this.currentUser$.value._id,
        prize: prize,
        prizeListIndex: prizeSetIndex
      }
    });

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.setPrizeSelectionURL, body, options)
      .subscribe(
        res => this.update(),
        err => console.error("Failed to set prize selection for user", this.currentUser$.value._id)
      );
  }

  /**
   * Check admin privileges
   * @returns {boolean}
   */
  public isCurrentUserSystemAdmin(): boolean {
    if (!this.currentUser$ || !this.currentUser$.value || !this.currentUserPermissions$ || !this.currentUserPermissions$.value) {
      return false;
    }

    return this.currentUserPermissions$.value.admin;
  }

  /**
   * Check admin and manager privileges
   * @returns {boolean}
   */
  public checkCurrentUserEditPrivileges(orgId?: string): boolean {
    const permissions: UserPermissions = this.currentUserPermissions$?.value;

    if (!this.currentUser$?.value || !permissions) {
      return false;
    }

    if (permissions.admin) {
      return true;
    }

    if (!orgId) {
      // Check current org
      return permissions.orgManager;
    }

    return permissions.managedOrganizations?.length ? !!permissions.managedOrganizations.find((managedOrg: OrganizationNameIDPair) => {
      return managedOrg?._id?.toString() === orgId?.toString();
    }) : false;
  }

  public adminViewAccess(): boolean {
    if (!this.currentUser$ || !this.currentUser$.value || !this.currentUserPermissions$ || !this.currentUserPermissions$.value) {
      return false;
    }

    return this.currentUserPermissions$.value.admin || !!this.currentUserPermissions$.value.managedOrganizations?.length;
  }

  /**
   * Check admin, manager and game manager privileges
   * @returns {boolean}
   */
  public checkGameManager(): boolean {
    if (!this.currentUser$ || !this.currentUser$.value || !this.currentUserPermissions$ || !this.currentUserPermissions$.value) {
      return false;
    }

    return this.currentUserPermissions$.value.admin || this.currentUserPermissions$.value.orgManager || this.currentUserPermissions$.value.gameManager;
  }

  /**
   * Change organization for admin User
   * @param {string} orgId
   */
  public changeAdminOrg(orgId: string) {
    this.http.get(this.changeAdminOrgUrl + orgId)
      .subscribe(
        (res: {status: string}) => {
          if (res.status === "Success") {
            this.fetchCurrentUser();
            this.getOrgUsers(orgId);
          }
        },
        err => {
          console.error("Failed to change organization", err);
        }
      );
  }

  public changeUserOrg(subOrgId: string) {
    this.http.get(this.changeOrgUrl + subOrgId)
      .subscribe(
        res => {
          this.fetchCurrentUser();
        },
        err => {
          console.error("Failed to change sub-organization", err);
        }
      );
  }

  /**
   * Re-send invite email to non-activated User
   * @param {string} userId
   * @returns {Observable<boolean>}
   */
  public invitation(userId: string): Observable<boolean> {
    return this.http.get(this.invitationUrl + userId)
      .pipe(
        map(() => {
          return true;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Edit User language
   * @param {string} lang
   */
  saveLanguage(lang: string) {
    if (!this.currentUser$ && !this.currentUser$.value) {
      console.error("Couldn't save user language");
      return;
    }

    this.saveUser(
      this.currentUser$.value._id,
      {
        name: this.currentUser$.value.name,
        department: this.currentUser$.value.department,
        additionalInfo: this.currentUser$.value.additionalInfo,
        language: lang
      }
    );
  }

  public listAdminUserIds(): Observable<string[]> {
    return this.http.get('/api/user/list-admins/')
      .pipe(
        map(res => {
          return res as string[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public listAdminUserIdsGuest(userInput: Object): Observable<string[]> {
    return this.http.put('/api/guests/list-admins/', userInput)
      .pipe(
        map(res => {
          return res as string[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public sendContactEmail(body: Object): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.post('/api/user/send-contact-email/', body, options)
      .pipe(
        map(res => {
          return res as string;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Check if given email should log-in with password or azure
  public getUserLoginType(email: string): Observable<{ status: number }> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.put('/api/user/user-login-type/', {email: email}, options)
      .pipe(
        map(res => {
          return res as { status: number };
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Update list of organization users
   * @param {string} user
   */
  public getUserSessions(user: ExtraUserData): void {
    this.http.get(this.getUserSessionsUrl + user.user._id)
      .subscribe(
        res => {
          const newVal = res as ExtraUserData;
          user.sessions = newVal.sessions ?? [];
          user.upcomingSessions = newVal.upcomingSessions ?? [];
        },
        err => {
          console.error("Fetching users listing failed", err);
          user.sessions = [];
          user.upcomingSessions = [];
        }
      );
  }

  /**
   * Get current user
   */
  protected fetchCurrentUser(): void {
    this.http.get<{ permissions: UserPermissions, user: User }>('/api/user/current')
      .subscribe(
        res => {
          this.currentUserPermissions$.next(res.permissions);
          this.currentUser$.next(res.user);

          if (res.user) {
            this.updateHardware(res.user);
            this.fetchIsAdmin();
          }
        },
        err => {
          if (err.status === 401) {
            this.currentUserPermissions$.next(null);
            this.currentUserSystemAdmin$.next(null);
            this.currentUser$.next(null);
          } else {
            console.error("Fetching user failed. Error:", err);

            if (this.retryUserFetch < 5) {
              this.fetchCurrentUser();
              this.retryUserFetch++;
            }
          }
        }
      );
  }

  /**
   * Get User admin status
   */
  protected fetchIsAdmin(): void {
    this.http.get(this.isAdminUrl)
      .subscribe(
        res => {
          const data: SystemAdmin = res as SystemAdmin;
          this.currentUserSystemAdmin$.next(data);
        },
        err => {
          console.error("Fetching user failed. Error:", err);
          this.currentUserSystemAdmin$.next(null);
        }
      );
  }

  /**
   * Fetch user
   * @param {string} id
   */
  protected fetchUser(id: string): void {
    if (!id) {
      return;
    }

    this.http.get(`/api/user/${id}`)
      .subscribe(
        doc => {
          if (this.users.has(id)) {
            this.users.get(id).next(doc as User);
          } else {
            this.users.set(id, new BehaviorSubject<User>(doc as User));
          }
          this.fetchedUsers$.next(id);
        },
        err => {
          console.error(err);
        }
      )
    ;
  }

  private updateHardware(user: User): void {
    if (!user) {
      return;
    }

    if (!this.timeZone && !this.hardwareInfo && !this.networkInfo) {
      // Nothing to check
      return;
    }

    const newTimeZone: boolean = !user.timeZone || user.timeZone !== this.timeZone;

    const newHardware: boolean = !user.hardwareInfo || (
      this.hardwareInfo && (
        user.hardwareInfo.browser !== this.hardwareInfo.browser &&
        user.hardwareInfo.language !== this.hardwareInfo.language &&
        user.hardwareInfo.os !== this.hardwareInfo.os
      )
    );

    const newNetwork = !user.networkInfo || (
      this.networkInfo && (
        user.networkInfo.downlink !== this.networkInfo.downlink ||
        user.networkInfo.rtt !== this.networkInfo.rtt ||
        user.networkInfo.effectiveType !== this.networkInfo.effectiveType
      )
    );

    if (newTimeZone || newHardware || newNetwork) {
      const changeSet = {};

      if (newTimeZone) {
        changeSet['timeZone'] = this.timeZone;
      }

      if (newHardware) {
        changeSet['hardwareInfo'] = this.hardwareInfo;
      }

      if (newNetwork) {
        changeSet['networkInfo'] = this.networkInfo;
      }

      this.saveUser(
        user._id,
        changeSet
      );
    }
  }


}
