import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";
import {HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import {ChartsModule} from "ng2-charts";
import {FileUploadModule} from "ng2-file-upload";
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ColorPickerModule} from "ngx-color-picker";
import {DragScrollModule} from 'ngx-drag-scroll';
import {QuillModule} from 'ngx-quill';
import {MatExpansionModule} from '@angular/material/expansion';

import {LogeDate} from "./pipe/logeDate";
import {NewLinePipe} from "./pipe/newline.pipe";
import {OrderByPipe} from "./pipe/orderBy.pipe";
import {WeekFilterPipe} from "./pipe/weekFilter.pipe";
import {SeasonNamePipe} from "./pipe/seasonName.pipe";
import {KeyFilterPipe} from "./pipe/keyFilter.pipe";
import {WeekdayFilterPipe} from "./pipe/weekdayFilter.pipe";
import {SafeHtmlPipe} from "./pipe/safe-html.pipe";

import {AutoFocusDirective} from "./directive/auto-focus.directive";
import {NetworkGraphDraggableDirective} from "./component/network-graph/network-graph-draggable.directive";
import {ElementClickedDirective} from "./directive/element-clicked.directive";

import {NeduDatepickerComponent} from "./component/datepicker.component";
import {ToggleButtonComponent} from "./component/toggle-button.component";
import {NeduTimepickerComponent} from "./component/timepicker.component";
import {UserButtonBarComponent} from "./component/user-button-bar.component";
import {FileSelectorModalComponent} from "./component/file-selector-modal.component";
import {AudioDisplayVolumeComponent} from "./component/voice-chat/audio-display-volume.component";
import {YoutubePlayerComponent} from "./component/youtube-player.component";
import {ParticipantInfoCard} from "./component/participant-info-card.component";
import {ProfilePictureUploadComponent} from "./component/profile-picture-upload.component";
import {LogoBadgeComponent} from "./component/logo-badge.component";
import {ClockRoundComponent} from "./component/clock-round.component";
import {LanguageChangeBarComponent} from "./component/language-change-bar.component";
import {ConfirmModalComponent} from "./component/confirm-modal.component";
import {SvgBallComponent} from "./component/pompula.component";
import {SvgBarComponent} from "./component/pompula-bar.component";
import {PieChartComponent} from "./component/pie-chart.component";
import {DashedCircleComponent} from "./component/dashed-circle.component";
import {ImageCropModalComponent} from "./component/image-crop-modal.component";
import {IntroductionTutorialComponent} from "./component/introduction-tutorial.component";
import {SurveyComparisonComponent} from "./component/survey-comparison.component";
import {CustomTooltipComponent} from "./component/custom-tooltip.component";
import {CircleGraphComponent} from "./component/circle-graph.component";
import {LargeDrawerComponent} from "./component/large-drawer.component";
import {SvgReactionEmojiComponent} from "./component/reaction-emoji.component";
import {UpcomingSessionsComponent} from "./component/upcoming-sessions.component";
import {NgScrollbarModule} from "ngx-scrollbar";
import {SessionJoinModalComponent} from "./component/session-join-modal.component";
import {PrizeSelection} from "./component/prize-selection.component";
import {CircleDataContainerComponent} from "./component/circle-data-container.component";
import {UserStatisticsComponent} from "./component/user-statistics.component";
import {NetworkGraphComponent} from "./component/network-graph/network-graph.component";
import {CircleXtraDataContainer} from "./component/circle-xtra-data-container.component";
import {ChairmanAnnouncementComponent} from "./component/chairman-announcement.component";
import {PlayerProgressV2Component} from "./component/player-progress-v2.component";
import {LineGraphComponent} from "./component/line-graph.component";
import {BarChartComponent} from "./component/bar-chart.component";
import {SpotlightRelativeComponent} from "./component/spotlight-relative.component";
import {SineWaveSvgComponent} from "./component/sine-wave-svg.component";
import {AudioChatRoomComponent} from "./component/mediasoup/audio-chatroom.component";
import {AudioPeerComponent} from "./component/voice-chat/audio-peer.component";
import {UserStatisticsRowComponent} from "./component/user-statistics-row.component";
import {ReactionStatisticsComponent} from "./component/reaction-statistics.component";
import {EmailContactFormComponent} from "./component/email-contact-form.component";
import {CalendarTimeSlotPickerComponent} from "./component/calendar-time-slot-picker.component";
import {CenteredPopUpComponent} from "./component/centered-pop-up.component";
import {LandingCheckboxComponent} from "./component/landing-checkbox.component";
import {MiniAdminComponent} from "./component/mini-admin.component";
import {RaisedCssButtonComponent} from "./component/raised-css-button.component";
import {UserSettingsComponent} from "./component/user-settings.component";
import {ReversePipe} from "./pipe/reverse.pipe";
import {MaterialSelectorComponent} from "./component/material-selector.component";
import {DiscussionProgressComponent} from "./component/discussion-progress.component";
import {NextDiscussionInfoComponent} from "./component/next-discussion-info.component";
import {AutoResizeDirective} from "./directive/auto-resize.directive";
import { ScoreDisplayComponent } from './component/score-display.component';
import { TutorialSelectorComponent } from './component/tutorial/tutorial-selector.component';
import { TutorialSlideShowComponent } from './component/tutorial/tutorial-slide-show.component';
import { TutorialAnimationComponent } from './component/tutorial/tutorial-animation.component';
import {WrittenAnswerComponent} from "./component/written-answer.component";
import { UserProfileCardComponent } from './component/user-profile-card.component';
import {QuestionFeedbackComponent} from "./component/question-feedback.component";
import { TutorialMultiSelectComponent } from './component/tutorial/tutorial-multi-select.component';
import { TutorialScalingComponent } from './component/tutorial/tutorial-scaling.component';
import { TutorialPollComponent } from './component/tutorial/tutorial-poll.component';
import { DraggableListComponent } from './component/draggable-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserMeasureComponent } from './component/user-measure.component';
import { PlayerReasoningComponent } from './component/player-reasoning.component';
import {PlayerReactionComponent} from "./component/player-reaction.component";
import { ImagePreloadDirective } from './directive/image-preload.directive';
import { QuillCustomComponent } from './component/quill-component.component';
import {UploadImagePreviewDirective} from "./directive/upload-image-preview.directive";
import { AudioCircleComponent } from './component/voice-chat/audio-circle.component';
import { DropdownContainerComponent } from './component/dropdown-container.component';
import { IdlePopUpComponent } from './component/idle-pop-up.component';
import { LineChartComponent } from './component/line-chart.component';
import { FlexTableComponent } from './component/flex-table.component';
import { DiscussionFeedbackComponent } from './component/discussion-feedback.component';
import {ScaleRatingComponent} from "./component/scale-rating.component";
import { NotificationPopUpComponent } from './component/notification-pop-up.component';
import { LoadingIndicatorComponent } from './component/loading-indicator.component';
import {ClockComponent} from "./component/clock.component";
import {UrlCheckPipe} from "./pipe/url-check.pipe";
import {NewMultiSelectComponent} from "./component/question-types/new-multi-select.component";
import {ArrayToStringPipe} from "./pipe/arrayToString.pipe";
import { DiscussionCardComponent } from './component/discussion-card.component';
import { CustomConfirmComponent } from './component/custom-confirm.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { DurationPipe } from './pipe/duration.pipe';
import { TagSelectorComponent } from './component/tag-selector.component';
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { FirstTimeLoginComponent } from './component/first-time-login.component';
import { AudioDeviceSelectorComponent } from './component/voice-chat/audio-device-selector.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MediaExpansionPanelComponent } from "./component/media-expansion-panel.component";
import { AudioDeviceTestComponent } from "./component/voice-chat/audio-device-test.component";
import { StartStopButtonComponent } from './component/start-stop-button.component';
import { IFrameSanitationPipe } from './pipe/i-frame-sanitation.pipe';

@NgModule({
  declarations: [
    // Directives
    AutoFocusDirective,
    NetworkGraphDraggableDirective,
    ElementClickedDirective,
    AutoResizeDirective,
    ImagePreloadDirective,
    UploadImagePreviewDirective,

    // Components
    NeduDatepickerComponent,
    ToggleButtonComponent,
    NeduTimepickerComponent,
    UserButtonBarComponent,
    FileSelectorModalComponent,
    AudioDisplayVolumeComponent,
    YoutubePlayerComponent,
    ParticipantInfoCard,
    ProfilePictureUploadComponent,
    LogoBadgeComponent,
    ClockRoundComponent,
    LanguageChangeBarComponent,
    SpotlightRelativeComponent,
    ConfirmModalComponent,
    ImageCropModalComponent,
    IntroductionTutorialComponent,
    SurveyComparisonComponent,
    CustomTooltipComponent,
    CircleGraphComponent,
    LargeDrawerComponent,
    UpcomingSessionsComponent,
    SvgBallComponent,
    SvgBarComponent,
    SvgReactionEmojiComponent,
    PieChartComponent,
    DashedCircleComponent,
    SessionJoinModalComponent,
    CircleDataContainerComponent,
    CircleXtraDataContainer,
    UserStatisticsComponent,
    NetworkGraphComponent,
    BarChartComponent,
    ChairmanAnnouncementComponent,
    PlayerProgressV2Component,
    LineGraphComponent,
    SineWaveSvgComponent,
    AudioChatRoomComponent,
    AudioPeerComponent,
    UserStatisticsRowComponent,
    ReactionStatisticsComponent,
    EmailContactFormComponent,
    CalendarTimeSlotPickerComponent,
    CenteredPopUpComponent,
    LandingCheckboxComponent,
    MiniAdminComponent,
    RaisedCssButtonComponent,
    UserSettingsComponent,
    MaterialSelectorComponent,
    DiscussionProgressComponent,
    NextDiscussionInfoComponent,
    ScoreDisplayComponent,
    TutorialSelectorComponent,
    TutorialSlideShowComponent,
    TutorialAnimationComponent,
    WrittenAnswerComponent,
    UserProfileCardComponent,
    QuestionFeedbackComponent,
    TutorialMultiSelectComponent,
    TutorialScalingComponent,
    TutorialPollComponent,
    DraggableListComponent,
    UserMeasureComponent,
    PlayerReasoningComponent,
    PlayerReactionComponent,
    QuillCustomComponent,
    AudioCircleComponent,
    DropdownContainerComponent,
    IdlePopUpComponent,
    PrizeSelection,
    LineChartComponent,
    FlexTableComponent,
    DiscussionFeedbackComponent,
    ScaleRatingComponent,
    NotificationPopUpComponent,
    LoadingIndicatorComponent,
    ClockComponent,
    NewMultiSelectComponent,
    DiscussionCardComponent,
    CustomConfirmComponent,
    TagSelectorComponent,
    MediaExpansionPanelComponent,
    AudioDeviceTestComponent,

    // Pipes
    LogeDate,
    NewLinePipe,
    OrderByPipe,
    ReversePipe,
    WeekFilterPipe,
    SeasonNamePipe,
    KeyFilterPipe,
    WeekdayFilterPipe,
    SafeHtmlPipe,
    UrlCheckPipe,
    ArrayToStringPipe,
    DurationPipe,
    FirstTimeLoginComponent,
    AudioDeviceSelectorComponent,
    StartStopButtonComponent,
    IFrameSanitationPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    HttpClientJsonpModule,
    ChartsModule,
    MatButtonModule,
    FileUploadModule,
    MatTooltipModule,
    MatSliderModule,
    ImageCropperModule,
    MatAutocompleteModule,
    NgScrollbarModule,
    ColorPickerModule,
    TranslateModule,
    DragScrollModule,
    MatExpansionModule,
    QuillModule.forRoot({
      modules: {
        mediaResize: true
      }
    }),
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule
  ],
  providers: [
    NewLinePipe,
    ArrayToStringPipe,
  ],
  exports: [
    // Directives
    AutoFocusDirective,
    NetworkGraphDraggableDirective,
    ElementClickedDirective,
    AutoResizeDirective,
    ImagePreloadDirective,
    UploadImagePreviewDirective,

    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ChartsModule,
    MatButtonModule,
    FileUploadModule,
    MatTooltipModule,
    MatSliderModule,
    ImageCropperModule,
    MatAutocompleteModule,
    NgScrollbarModule ,
    ColorPickerModule,
    DragScrollModule,
    QuillModule,
    DragDropModule,
    MatExpansionModule,

    // Components
    NeduDatepickerComponent,
    ToggleButtonComponent,
    NeduTimepickerComponent,
    UserButtonBarComponent,
    FileSelectorModalComponent,
    AudioDisplayVolumeComponent,
    YoutubePlayerComponent,
    ParticipantInfoCard,
    ProfilePictureUploadComponent,
    LogoBadgeComponent,
    ClockRoundComponent,
    LanguageChangeBarComponent,
    SpotlightRelativeComponent,
    ConfirmModalComponent,
    ImageCropModalComponent,
    IntroductionTutorialComponent,
    SurveyComparisonComponent,
    CustomTooltipComponent,
    CircleGraphComponent,
    LargeDrawerComponent,
    UpcomingSessionsComponent,
    SvgBallComponent,
    SvgBarComponent,
    SvgReactionEmojiComponent,
    PieChartComponent,
    DashedCircleComponent,
    SessionJoinModalComponent,
    CircleDataContainerComponent,
    CircleXtraDataContainer,
    UserStatisticsComponent,
    NetworkGraphComponent,
    BarChartComponent,
    ChairmanAnnouncementComponent,
    PlayerProgressV2Component,
    LineGraphComponent,
    SineWaveSvgComponent,
    AudioChatRoomComponent,
    AudioPeerComponent,
    UserStatisticsRowComponent,
    ReactionStatisticsComponent,
    EmailContactFormComponent,
    CalendarTimeSlotPickerComponent,
    CenteredPopUpComponent,
    LandingCheckboxComponent,
    MiniAdminComponent,
    RaisedCssButtonComponent,
    UserSettingsComponent,
    MaterialSelectorComponent,
    DiscussionProgressComponent,
    NextDiscussionInfoComponent,
    ScoreDisplayComponent,
    TutorialSelectorComponent,
    TutorialSlideShowComponent,
    TutorialAnimationComponent,
    WrittenAnswerComponent,
    UserProfileCardComponent,
    QuestionFeedbackComponent,
    DraggableListComponent,
    UserMeasureComponent,
    PlayerReasoningComponent,
    PlayerReactionComponent,
    QuillCustomComponent,
    AudioCircleComponent,
    DropdownContainerComponent,
    IdlePopUpComponent,
    PrizeSelection,
    LineChartComponent,
    FlexTableComponent,
    DiscussionFeedbackComponent,
    ScaleRatingComponent,
    NotificationPopUpComponent,
    LoadingIndicatorComponent,
    ClockComponent,
    NewMultiSelectComponent,
    DiscussionCardComponent,
    CustomConfirmComponent,
    TagSelectorComponent,
    FirstTimeLoginComponent,
    AudioDeviceTestComponent,

    // Pipes
    LogeDate,
    NewLinePipe,
    OrderByPipe,
    ReversePipe,
    WeekFilterPipe,
    SeasonNamePipe,
    KeyFilterPipe,
    WeekdayFilterPipe,
    SafeHtmlPipe,
    UrlCheckPipe,
    ArrayToStringPipe,
    IFrameSanitationPipe
  ]
})
export class SharedModule {
}
