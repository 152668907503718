import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {NetworkInformation, User} from "../../data-model/user.type";
import {HardwareInfo} from "../../data-model/game-state.interface";
import {UserService} from "../../core/service/user.service";
import Timeout = NodeJS.Timeout;

export interface LoadingRequirements {
  user?: boolean;
  sessions?: boolean;
  organization?: boolean;
  content?: boolean;
  styles?: boolean;
  routes?: boolean;
}

@Component({
  selector: 'loading-indicator',
  template: `
    <div
      class="main-loading-indicator animated short"
      [ngClass]="loaded && 'fadeOut'"
    >
      <div class="loading-indicator full-page"></div>

      <div class="loading-list animated fadeInUpSmall" *ngIf="invalidValues?.length && currentValue">
        <div class="animated" [ngClass]="animationToggle ? 'fadeInUp' : 'fadeOutUp'">
          <b>{{'loading-indicator.loading-label' | translate}}</b>
          {{'loading-indicator.' + currentValue | translate}}
        </div>
      </div>

      <div class="loading-problem animated fadeInUpSmall" *ngIf="showRefreshButton">
        {{'loading-indicator.loading-problem' | translate}}

        <raised-css-button
          [buttonText]="'loading-indicator.refresh-button'"
          (onClick)="reloadPage()"
          [fontSize]="'1.5vw'"
          [marginTop]="'0.5vw'"
          [marginBottom]="'0'"
        ></raised-css-button>
      </div>
    </div>
  `,
  styles: []
})
export class LoadingIndicatorComponent implements OnChanges, OnDestroy {
  @Input() currentUser: User;
  @Input() requirements: LoadingRequirements;
  @Input() loaded: boolean;

  @Output() allValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public invalidValues: string[];
  public animationToggle: boolean = true;
  public currentValue: string;

  public showRefreshButton: boolean;

  public timers: Timeout[] = [];
  private currentIndex: number = -1;

  constructor(
    private userService: UserService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requirements && this.requirements) {
      this.setMessageIfInvalid();
    }
  }

  ngOnDestroy(): void {
    this.invalidValues = [];

    for (const timer of this.timers) {
      clearTimeout(timer);
    }
  }

  public sendEmailIfError(timeOut: number = 10000): void {
    this.timers.push(
      setTimeout(() => {
        if (this.invalidValues.length) {
          this.showRefreshButton = true;
        }
      }, timeOut)
    );
  }

  public reloadPage(): void {
    this.sendErrorEmail();
    location.reload();
  }

  private setMessageIfInvalid(): void {
    if (this.checkRequirements()) {
      // All good
      this.loaded = true;
      this.invalidValues = [];
      this.allValid.emit(true);
      return;
    }

    this.loaded = false;
    const invalidValues: string[] = [];
    for (const key of Object.keys(this.requirements)) {
      if (!this.requirements[key]) {
        invalidValues.push(key);
      }
    }

    this.invalidValues = invalidValues;

    if (!this.currentValue) {
      // Start displaying which parts are still loading after 1 second
      setTimeout(() => {
        if (!this.currentValue) {
          this.loopInvalidValues();
          this.sendEmailIfError();
        }
      }, 1000);
    }
  }

  private checkRequirements(): boolean {
    if (!this.requirements) {
      return false;
    }

    for (const key of Object.keys(this.requirements)) {
      if (!this.requirements[key]) {
        return false;
      }
    }

    return true;
  }

  private loopInvalidValues(): void {
    if (!this.invalidValues || !this.invalidValues.length) {
      this.currentValue = null;
      this.currentIndex = -1;
      return;
    }

    this.currentIndex++;

    if (this.currentIndex >= this.invalidValues.length) {
      this.currentIndex = 0;
    }

    this.currentValue = this.invalidValues[this.currentIndex];
    this.animationToggle = true;

    setTimeout(() => {
      if (this.invalidValues.length > 1 || (this.invalidValues.length === 1 && this.invalidValues[0] !== this.currentValue)) {
        this.animationToggle = false;

        setTimeout(() => {
          this.loopInvalidValues();
        }, 600);
      }
    }, 3400); // Total 4s
  }

  private sendErrorEmail() {
    const location: string = window.location.hash.substring(2);
    const hardware: HardwareInfo = UserService.getHardwareInfo();
    const network: NetworkInformation = UserService.getNetworkInfo();

    this.userService.sendContactEmail({
      subject: "LOGE automated message: Stuck loading",
      emailContent: "User got stuck in " + location + ". Missing: " + this.invalidValues,
      siteLocation: location,
      browser: hardware ? hardware.browser : "Unknown",
      network: network ? ("Network estimate: " + network.effectiveType + ", " + network.downlink + "MB/s") : "Unknown"
    }).subscribe(() => {});
  }
}
