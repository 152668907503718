import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit, SimpleChanges, OnChanges
} from '@angular/core';

import {ForceDirectedGraph} from "./force-directed-graph";
import {NetworkItemType} from "../../../data-model/network-item.type";
import {NetworkLinkType} from "../../../data-model/network-link.type";
import {NetworkUser, User} from "../../../data-model/user.type";
import {Organization} from "../../../data-model/organization.type";
import {GameSessionLog} from "../../../data-model/game-session-log.type";

@Component({
  selector: 'network-graph',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="no-network" *ngIf="!networkLinks?.length">{{'network.no-network' | translate}}</div>
    <svg #svg [attr.height]="graphSize?.height" [attr.width]="graphSize?.width"
         (window:resize)="getViewportDimensions()">
      <g>
        <g *ngFor="let link of networkLinks">
          <line
            class="link-line"
            [attr.x1]="link.source.x"
            [attr.y1]="link.source.y"
            [attr.x2]="link.target.x"
            [attr.y2]="link.target.y"
          ></line>
        </g>
        <g *ngFor="let item of networkItems; let itemIndex = index" [draggableNode]="item" [draggableInGraph]="networkGraph" [radius]="item.r">
          <g
            [ngStyle]="{position: 'relative'}"
            [attr.transform]="'translate(' + item.x + ',' + item.y + ')'"
            id="network-item-{{itemIndex}}"
          >
            <circle
              class="network-item"
              [ngClass]="{'self': itemIndex === 0}"
              cx="0"
              cy="0"
              [attr.r]="item.r"
            ></circle>

            <circle
              class="network-initial-item"
              cx="0"
              cy="0"
              [attr.r]="item.r >= 6 ? (item.r - 6) : 0"
            ></circle>

            <foreignObject
              [attr.x]="-item.r*0.9" [attr.y]="-item.r*0.9" [attr.width]="item.r*0.9*2 "
              [attr.height]="item.r*0.9*2" class="network-foreign-object network-text">
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="network-title-div">
                {{item.initials}}
              </xhtml:div>
            </foreignObject>

            <ng-container *ngIf="item.user && item.user.imagePath">
              <clipPath id="networkClipPath_{{item.user._id}}">
                <circle
                  class="network-initial-item"
                  cx="0"
                  cy="0"
                  [attr.r]="item.r >= 6 ? (item.r - 6) : 0"
                />
              </clipPath>

              <image
                [attr.clip-path]="'url(#networkClipPath_' + item.user._id + ')'"
                class="network-image"
                [attr.width]="(item.r-6)*2"
                [attr.height]="(item.r-6)*2"
                [attr.x]="-item.r+6"
                [attr.y]="-item.r+6"
                [attr.href]="item.user.imagePath + '?' + imageIdentifier"
                (error)="item.user.imagePath = ''"
              />
            </ng-container>

            <foreignObject *ngIf="itemHovered === item.id" [attr.y]="item.r + 10"
                           [attr.width]="200" [attr.height]="200"
                           id="network-object-{{itemIndex}}"
                           class="network-foreign-object"
                           [ngStyle]="{'z-index': '1'}">
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="network-tooltip">
                <ng-container *ngIf="item.user">
                  <h3>{{item.user.name}}</h3>
                  {{item.isCurrentUser ? ('network.self' | translate) : ('network.connection' | translate: {amount: item.linkCount})}}
                </ng-container>
                <ng-container *ngIf="!item.user">
                  <h3>{{item.id}}</h3>
                  {{item.isCurrentUser ? ('network.self' | translate) : ('network.department-connection' | translate: {amount: item.linkCount})}}
                </ng-container>
              </xhtml:div>
            </foreignObject>
          </g>
        </g>
      </g>
    </svg>
  `
})
export class NetworkGraphComponent implements AfterViewInit, OnChanges {
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() userSessions: GameSessionLog[];
  @Input() organizationUsers: NetworkUser[];

  public networkItems: NetworkItemType[];
  public networkLinks: NetworkLinkType[];
  public graphSize: { width: number, height: number };

  public networkGraph: ForceDirectedGraph;

  public itemHovered: string;

  public imageIdentifier: number = 0;

  public imageError: boolean;
  private resizeComplete: boolean = true;

  constructor(private ref: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser) {
      this.updateImageIdentifier();
    }

    // If everything loaded
    if (this.currentUser && this.currentOrganization && this.userSessions && this.organizationUsers && !this.networkGraph) {
      const currentUserNetwork: { identifier: string, amountOfDiscussions: number, user: NetworkUser, initials: string}[] = [];
      const networkType: string = this.currentOrganization.userNetworkType || 'user';

      if (networkType === 'department') {
        this.userSessions.forEach(log => {
          this.currentOrganization.departments.forEach(department => {
            const hasDepartmentUser = log.session.players.findIndex(userId => {
              const userObject = this.organizationUsers.find(user => {
                return user._id === userId;
              });

              return userObject && userObject.department === department.name;
            }) !== -1;

            if (hasDepartmentUser) {
              const departmentMatch = currentUserNetwork.findIndex(networkDepartment => {
                return networkDepartment.identifier === department.name;
              });

              if (departmentMatch !== -1) {
                currentUserNetwork[departmentMatch].amountOfDiscussions++;
              }
              else {
                currentUserNetwork.push({
                  identifier: department.name,
                  amountOfDiscussions: 1,
                  user: undefined,
                  initials: undefined
                });
              }
            }
          });
        });
      }

      else {
        this.userSessions.forEach(log => {
          log.session.players.forEach(userId => {
            if (userId !== this.currentUser._id) {
              const playerMatch = currentUserNetwork.findIndex(networkUser => {
                return networkUser.user._id === userId;
              });

              if (playerMatch !== -1) {
                currentUserNetwork[playerMatch].amountOfDiscussions++;
              }
              else {
                const userObject = this.organizationUsers.find(user => {
                  return user._id === userId;
                });

                if (userObject) {
                  currentUserNetwork.push({
                    identifier: userId,
                    amountOfDiscussions: 1,
                    user: userObject,
                    initials: User.getInitials(userObject.name)
                  });
                }
              }
            }
          });
        });
      }

      if (currentUserNetwork.length > 8) {
        // Only display first 8 matches
        currentUserNetwork.splice(8);
      }

      let totalNetworkDiscussions: number = 0;
      for (const networkEntry of currentUserNetwork) {
        totalNetworkDiscussions += networkEntry.amountOfDiscussions;
      }

      const personalNode: NetworkItemType = new NetworkItemType(this.currentUser, totalNetworkDiscussions, true, 1,
        User.getInitials(this.currentUser.name));

      const nodes = [personalNode];
      const links = [];

      currentUserNetwork.forEach(networkEntry => {
        const newNode = new NetworkItemType(
          networkEntry.user ? networkEntry.user : networkEntry.identifier,
          totalNetworkDiscussions,
          false,
          networkEntry.amountOfDiscussions,
          networkEntry.initials
        );

        nodes.push(newNode);
        links.push(new NetworkLinkType(newNode, nodes[0]));
      });

      this.networkItems = nodes;
      this.networkLinks = links;

      this.graphSize = {
        width: (25 * window.innerHeight) / 100,
        height: (25 * window.innerHeight) / 100
      };

      // Create network graph
      this.networkGraph = new ForceDirectedGraph(nodes, links, this.graphSize);

      // Bind change detection on each tick
      this.networkGraph.ticker.subscribe(() => {
        this.ref.markForCheck();
      });

      this.networkGraph.initSimulation(this.graphSize);
      this.updateGraph();
      this.resizeText();
      this.addMouseListeners();
    }
  }

  ngAfterViewInit() {
    this.getViewportDimensions();
  }

  public addMouseListeners(): void {
    if (!this.networkItems) {
      return;
    }

    if (!document.getElementById('network-item-0')) {
      setTimeout(() => {
        this.addMouseListeners();
      }, 100);

      return;
    }

    this.networkItems.forEach((item, index) => {
      const itemElement = document.getElementById('network-item-' + index);

      if (itemElement) {
        itemElement.onmouseenter = () => {
          this.handleHover(item.id);
        };

        itemElement.onmouseleave = () => {
          this.handleHover(null);
        };
      }
    });
  }

  public resizeText() {
    if (!this.resizeComplete) {
      return;
    }

    const networkContainerElements = document.getElementsByClassName("network-text");
    const networkTitleElements = document.getElementsByClassName("network-title-div");

    if (!networkContainerElements || !networkTitleElements) {
      return;
    }

    this.resizeComplete = false;

    [].forEach.call(networkContainerElements, (elem, index) => {
      const titleElement = <HTMLElement>networkTitleElements[index];

      if (titleElement) {
        let titleRect = titleElement.getBoundingClientRect();
        const containerRect = elem.getBoundingClientRect();

        const maxFontSize: number = containerRect.height / 2;
        let sizeVariable: number = 0.5;

        while ((titleRect.height > containerRect.height - 20 || titleRect.width > containerRect.width - 20) && sizeVariable < 100) {
          titleElement.style.fontSize = maxFontSize - sizeVariable + 'px';

          titleRect = titleElement.getBoundingClientRect();
          sizeVariable += 0.5;
        }

        if (sizeVariable === 0.5) {
          while ((titleRect.height < containerRect.height - 20 && titleRect.width < containerRect.width - 20) && sizeVariable < 100) {
            const newSize = +(titleElement.style.fontSize.slice(0, -2)) + sizeVariable;
            titleElement.style.fontSize = newSize + 'px';

            titleRect = titleElement.getBoundingClientRect();
            sizeVariable += 0.5;
          }
        }
      }
    });

    this.resizeComplete = true;
  }

  public getViewportDimensions() {
    if (this.graphSize && this.graphSize.height === (25 * window.innerHeight) / 100) {
      return;
    }

    this.graphSize = {
      width: (25 * window.innerHeight) / 100,
      height: (25 * window.innerHeight) / 100
    };

    this.updateGraph();
  }

  public updateGraph(retry?: number): void {
    if (!this.networkGraph || !this.graphSize) {

      if (!retry || retry < 10) {
        retry = retry ? retry + 1 : 1;

        setTimeout(() => {
          this.updateGraph(retry);
        }, 1000);
      }

      return;
    }

    this.networkGraph.updateSimulation(this.graphSize);
    this.resizeText();
  }

  public handleHover(itemId: string) {
    this.itemHovered = itemId;
  }

  public updateImageIdentifier() {
    this.imageIdentifier = Math.random() * Math.random() * Math.random();
  }
}
