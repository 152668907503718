import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {User} from "../../data-model/user.type";
import {first} from "rxjs/operators";
import {OrganizationService} from "../../core/service/organization.service";
import {Organization, OrganizationNameIDPair} from "../../data-model/organization.type";
import {UserService} from "../../core/service/user.service";
import {GameSessionService} from "../../core/service/game-session.service";
import {GeneralUtils} from "../../../lib/general-utils";
import {GameTutorialService} from "../../core/service/game-tutorial.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: 'user-settings',
  template: `
    <div
      class="user-settings-container"
      (elementClicked)="clickedOutside($event)"
    >
      <div [ngStyle]="{'display': 'flex', 'justify-content': 'space-between', 'align-items': 'flex-start'}">
        <h1 [ngStyle]="{'margin': '0'}">
          {{'settings.title' | translate}}
        </h1>

        <raised-css-button
          [buttonText]="'user-button-bar.logout' | translate"
          [fontSize]="'2vh'"
          (onClick)="buttonBarLogout()"
          [marginTop]="'0'"
          [marginBottom]="'0'"
        ></raised-css-button>
      </div>

      <div class="settings-selection">
        <div class="setting-title">
          {{'settings.update-profile' | translate}}
        </div>

        <div class="setting-area vertical-flex-container">
          <div class="profile-center-container">
            <user-profile-card
              [userDoc]="{
                _id: currentUser?._id,
                orgId: currentUser?.orgId,
                name: newName,
                department: newDepartment,
                location: newLocation,
                additionalInfo: newInfo,
                imagePath: currentUser?.imagePath
              }"
              [editable]="true"
              [zIndex]="1"
            ></user-profile-card>
          </div>

          <label>{{'participant-info-card.name' | translate}}</label>
          <input class="bordered-input" id="userName" [(ngModel)]="newName" (ngModelChange)="checkProfileChanges()">

          <ng-container *ngIf="availableDepartments?.length">
            <label>{{'participant-info-card.department' | translate}}</label>
            <select class="bordered-input" [(ngModel)]="newDepartment" (ngModelChange)="checkProfileChanges()">
              <option *ngFor="let department of availableDepartments | orderBy"
                      [selected]="currentUser.department === department"
                      [ngValue]="department"
              >
                {{department}}
              </option>
            </select>
          </ng-container>

          <ng-container *ngIf="availableLocations?.length">
            <label>{{'participant-info-card.location' | translate}}</label>
            <select class="bordered-input" [(ngModel)]="newLocation" (ngModelChange)="checkProfileChanges()">
              <option *ngFor="let location of availableLocations | orderBy"
                      [selected]="currentUser.location === location"
                      [ngValue]="location"
              >
                {{location}}
              </option>
            </select>
          </ng-container>

          <label>{{'participant-info-card.additional-info' | translate}}</label>
          <input class="bordered-input" id="additionalInfo" [(ngModel)]="newInfo" name="additional-info" type="text" (ngModelChange)="checkProfileChanges()">

          <div [ngStyle]="{marginTop: '1vmin', fontSize: '1.7vmin', textAlign: 'left'}">{{'participant-info-card.profile-picture-tip' | translate}}</div>

          <!--<picture-upload
            [pictureType]="'avatar'"
            [uploaderType]="'button'"
            [userDoc]="currentUser"
            [editable]="true"
          ></picture-upload>-->

          <raised-css-button
            *ngIf="showProfileUpdateButton"
            [disabled]="!checkProfileChanged()"
            [buttonText]="profileSaveStatus | translate"
            [fontSize]="'3vh'"
            (onClick)="updateProfile()"
            [marginTop]="'1vh'"
            [marginBottom]="'1vh'"
          ></raised-css-button>
        </div>

        <div class="setting-title" *ngIf="passwordChangeAllowed">
          {{'settings.title-password' | translate}}
        </div>

        <div class="setting-area" *ngIf="passwordChangeAllowed">
          <div class="password-inputs">
            <div class="input-label">
              <label for="oldPwd">{{'settings.current-password' | translate}}</label>
              <input id="oldPwd" name="current-password" type="password" autocomplete="new-password" [(ngModel)]="oldPassword" (ngModelChange)="validatePassword()">
            </div>

            <div class="input-label">
              <label for="password">{{'settings.password' | translate}}</label>
              <input id="password" name="new-password" type="password" autocomplete="new-new-password" [(ngModel)]="newPassword" (ngModelChange)="validatePassword()">
            </div>

            <div class="input-label">
              <label for="password-confirm">{{'settings.password-confirm' | translate}}</label>
              <input id="password-confirm" name="confirm-new-password" autocomplete="new-confirm-password" type="password" [(ngModel)]="passwordCheck" (ngModelChange)="validatePassword()">
            </div>
          </div>

          <div *ngIf="passwordErrorMessage && passwordErrorMessage !== 'empty'" [ngStyle]="{'font-size': '1.8vmin'}">
            <ng-container *ngIf="passwordErrorMessage === 'mismatch'">
              {{'activation-view.error-mismatch' | translate}}
            </ng-container>

            <ng-container *ngIf="passwordErrorMessage === 'requirements-not-met'">
              {{'activation-view.error-too-short' | translate}}
            </ng-container>
          </div>

          <raised-css-button
            *ngIf="oldPassword && newPassword && passwordCheck"
            [disabled]="passwordErrorMessage"
            [buttonText]="saveStatus | translate"
            [fontSize]="'3vh'"
            (onClick)="updatePassword()"
            [marginTop]="'1vh'"
            [marginBottom]="'1vh'"
          ></raised-css-button>
        </div>

        <ng-container *ngIf="availableSubOrganizations?.length > 1">
          <div class="setting-title">
            {{'settings.change-sub-organization' | translate}}
          </div>

          <div class="setting-area">
            <select class="bordered-input" [(ngModel)]="currentSubOrgSelection"
                    (ngModelChange)="setOwnOrganization(currentSubOrgSelection)">
              <option *ngFor="let subOrg of availableSubOrganizations | orderBy: ['name']"
                      [selected]="currentUser.orgId.toString() === subOrg._id"
                      [ngValue]="subOrg"
              >
                {{subOrg.name}}
              </option>
            </select>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class UserSettingsComponent extends OnDestroyMixin implements OnChanges, OnInit {
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() allowedElementIds: string[];

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public availableSubOrganizations: Organization[];
  public currentSubOrgSelection: Organization;
  public passwordChangeAllowed: boolean;

  public availableDepartments: string[];
  public availableLocations: string[];
  public showProfileUpdateButton: boolean;

  public newName: string;
  public newDepartment: string;
  public newLocation: string;
  public newInfo: string;
  public profileSaveStatus: string = 'participant-info-card.save';
  public saveStatus: string = 'settings.save-password';

  public oldPassword: string;
  public newPassword: string;
  public passwordCheck: string;
  public passwordErrorMessage: string;

  constructor(
    private organizationService: OrganizationService,
    private userService: UserService,
    private gameSessionService: GameSessionService,
    private tutorialService: GameTutorialService
  ) {
    super();
  }

  ngOnInit() {
    this.userService.authInfo$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(info => {
        this.passwordChangeAllowed = info?.local;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentOrganization && this.currentOrganization) {
      this.setAvailableDepartments(this.currentOrganization);
    }

    if (changes.currentUser && this.currentUser) {
      this.getSubOrganizations();

      this.newName = this.currentUser.name;
      this.newDepartment = this.currentUser.department;
      this.newLocation = this.currentUser.location;
      this.newInfo = this.currentUser.additionalInfo;

      if (this.profileSaveStatus === 'participant-info-card.saving') {
        this.profileSaveStatus = 'participant-info-card.saved';

        setTimeout(() => {
          this.showProfileUpdateButton = false;
        }, 6000);
      }
    }
  }

  public getSubOrganizations() {
    if (!this.currentUser) {
      return;
    }

    this.organizationService.getAllowedOrganizations(this.currentUser.orgId, this.currentUser.email)
      .pipe(
        first()
      )
      .subscribe(
        subOrganizations => {
          if (!subOrganizations || subOrganizations.length <= 1) {
            this.availableSubOrganizations = null;
            return;
          }

          this.availableSubOrganizations = subOrganizations;
          this.setOrgSelection();
        }
      );
  }

  public setAvailableDepartments(organization: Organization): void {
    this.availableDepartments = organization.departments
      .filter(department => department && department.name.trim())
      .map(department => department.name);

    this.availableLocations = organization.locations
      .filter(location => location && location.name.trim())
      .map(location => location.name);
  }

  public setOwnOrganization(subOrg: OrganizationNameIDPair): void {
    const confirmation: boolean = confirm("Are you sure you want to change your sub-organization to " + subOrg.name);
    if (confirmation === true) {
      this.userService.changeUserOrg(subOrg._id);
    } else {
      // Reset to original selection
      setTimeout(() => {
        this.setOrgSelection();
      }, 100);
    }
  }

  public buttonBarLogout(): void {
    if (this.gameSessionService.isConnected()) {
      this.gameSessionService.leaveDiscussionRoom("logout-leave-discussion");
    }

    this.userService.logout();
  }

  public clickedOutside(elementId: string) {
    if (GeneralUtils.clickedOutside(elementId, this.allowedElementIds)) {
      this.emitClose();
    }
  }

  public emitClose() {
    this.onClose.emit(false);
  }

  public validatePassword(): void {
    if (!this.oldPassword || !this.newPassword || !this.passwordCheck) {
      this.passwordErrorMessage = 'empty';
      return;
    }

    if (this.newPassword !== this.passwordCheck) {
      this.passwordErrorMessage = 'mismatch';
      return;
    }

    if (!UserService.validPasswordRegex.test(this.newPassword)) {
      this.passwordErrorMessage = 'requirements-not-met';
      return;
    }

    this.passwordErrorMessage = null;
  }

  public updatePassword(): void {
    this.saveStatus = 'settings.saving';

    this.userService.changePassword(this.oldPassword, this.newPassword, (err, res) => {
      if (err) {
        this.saveStatus = 'settings.password-failed';
        return;
      }

      this.saveStatus = 'settings.password-saved';
    });
  }

  public updateProfile(): void {
    const changeSet: {
      name?: string,
      department?: string,
      location?: string,
      additionalInfo?: string
    } = {};

    if (this.currentUser.name !== this.newName) {
      changeSet.name = this.newName;
    }

    if (this.currentUser.department !== this.newDepartment) {
      changeSet.department = this.newDepartment;
    }

    if (this.currentUser.location !== this.newLocation) {
      changeSet.location = this.newLocation;
    }

    if (this.currentUser.additionalInfo !== this.newInfo) {
      changeSet.additionalInfo = this.newInfo;
    }

    if (Object.keys(changeSet).length === 0) {
      return;
    }

    this.profileSaveStatus = 'participant-info-card.saving';

    this.userService.saveUser(
      this.currentUser._id,
      changeSet
    );

    this.tutorialService.setIntroTutorialAsCompleted('update-profile', this.currentUser);
  }

  public checkProfileChanged(): boolean {
    return (
      this.currentUser.name !== this.newName ||
      this.currentUser.department !== this.newDepartment ||
      this.currentUser.location !== this.newLocation ||
      this.currentUser.additionalInfo !== this.newInfo
    );
  }

  public checkProfileChanges(): void {
    this.showProfileUpdateButton = this.checkProfileChanged();
  }

  private setOrgSelection(): void {
    this.currentSubOrgSelection = this.availableSubOrganizations.find(subOrg => {
      return subOrg._id === this.currentUser.orgId;
    });

    this.setAvailableDepartments(this.currentSubOrgSelection);
  }
}
