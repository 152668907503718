

import {CoreModule} from "./core/core.module";

import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {routing} from "./app.routing";
import {AppComponent} from './app.component';
import {WelcomeView} from "./view/welcome.view";
import {GreetingView} from "./view/greeting.view";
import {SettingsView} from "./view/settings.view";
import {RecoverView} from "./view/recover.view";
import {ActivationView} from "./view/activation.view";
import {TermsOfServiceView} from "./view/tos.view";
import {DomainErrorView} from "./view/domain-error.view";
import {PrivacyPolicyView} from "./view/privacy-policy.view";
import {SharedModule} from "./shared/shared.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {GlobalErrorHandler} from "./error-handling/error-handler.service";
import {LandingPageView} from "./view/landing-page.view";
import { BiReportView } from './view/bi-report.view';

export function factoryFunction(http: HttpClient) {
  return new TranslateHttpLoader(http, 'static/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    CoreModule.forRoot(),
    SharedModule,

    routing,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: factoryFunction,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,

    WelcomeView,
    GreetingView,
    SettingsView,
    RecoverView,
    ActivationView,
    TermsOfServiceView,
    DomainErrorView,
    PrivacyPolicyView,
    LandingPageView,
    BiReportView
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ]
})
export class AppModule {

}
