import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GameTutorialService, TutorialItem} from "../../../core/service/game-tutorial.service";

@Component({
  selector: 'tutorial-selector',
  template: `
    <div class="tutorial-border" *ngIf="tutorialVisible && !slimTutorial">
      <div class="tutorial-container">
        <div class="tutorial-header">
          <h1>{{'new-tutorial.pop-up-header' | translate}}</h1>

          <button (click)="closePopUp()">
            <span class="glyphicon glyphicon-remove"></span>
          </button>
        </div>

        <div class="gradient-line"></div>

        <div class="tutorial-selector-area">
          <div class="selector-area-flex">
            <ng-container *ngIf="!selectedTutorial">
              <div class="tutorial-bar-selector"
                   (click)="selectTutorialBar(tutorial)"
                   *ngFor="let tutorial of availableTutorials"
                   [ngClass]="{'tutorial-bar-hidden': selectedTutorial}"
              >
                <div class="image-container" *ngIf="tutorial.image">
                  <img [src]="tutorial.image" alt="">
                </div>

                <h3>
                  {{'new-tutorial.' + tutorial.title | translate}}
                </h3>
              </div>
            </ng-container>

            <div class="current-tutorial" *ngIf="selectedTutorial">
              <div class="tutorial-header">
                <button class="left" (click)="unselectTutorial()">
                  <span class="glyphicon glyphicon-arrow-left"></span>
                </button>

                <h2>{{'new-tutorial.' + selectedTutorial.title | translate}}</h2>
              </div>

              <div class="tutorial-content">
                <tutorial-slide-show
                  [slideShowContent]="selectedTutorial.content"
                ></tutorial-slide-show>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tutorial-selector-area" *ngIf="slimTutorial">
      <div class="selector-area-flex">
        <div class="tutorial-bar-selector slim"
             (click)="selectTutorialBar(tutorial, true)"
             *ngFor="let tutorial of availableTutorials"
        >
          <div class="image-container" *ngIf="tutorial.image">
            <img [src]="tutorial.image" alt="">
          </div>

          <h3>
            {{'new-tutorial.' + tutorial.title | translate}}
          </h3>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class TutorialSelectorComponent implements OnChanges {
  @Input() tutorialVisible: boolean;
  @Input() slimTutorial: boolean;
  @Input() outsideSelection: TutorialItem;

  @Output() tutorialCloseEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tutorialSelectionEmitter: EventEmitter<TutorialItem> = new EventEmitter<TutorialItem>();

  public availableTutorials: TutorialItem[] = this.tutorialService.availableTutorials;
  public selectedTutorial: TutorialItem;

  constructor(private tutorialService: GameTutorialService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outsideSelection && this.outsideSelection) {
      this.selectedTutorial = this.outsideSelection;
      this.tutorialVisible = true;
    }
  }

  public closePopUp(): void {
    this.tutorialCloseEmitter.emit();
    this.tutorialVisible = false;
  }

  public unselectTutorial(): void {
    this.selectedTutorial = null;
  }

  public selectTutorialBar(tutorial: TutorialItem, emit?: boolean): void {
    if (emit) {
      this.tutorialSelectionEmitter.emit(tutorial);
      return;
    }

    if (!this.selectedTutorial) {
      this.selectedTutorial = tutorial;
      return;
    }

    this.selectedTutorial = tutorial.title !== this.selectedTutorial.title ? tutorial : null;
  }
}
