import { Injectable }    from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad,
  Route, ActivatedRouteSnapshot, RouterStateSnapshot }    from '@angular/router';
import {GameSessionService} from "../app/core/service/game-session.service";

@Injectable()
export class SessionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private gameSessionService: GameSessionService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return !!this.gameSessionService.sessionDoc$.value;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean  {
    return !!this.gameSessionService.sessionDoc$.value;
  }
}
