/**
 * Created by janne on 30.8.2016.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../core/service/user.service";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import { filter, first, takeUntil } from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import {DeviceDetectorService} from "ngx-device-detector";
import Timer = NodeJS.Timer;
import { GeneralUtils } from "../../lib/general-utils";

@Component({
  selector: 'welcome-view',
  template: `
    <div class="floating-logo-badge">
      <logo-badge></logo-badge>
    </div>

    <div class="floating-user-button-bar">
      <user-button-bar
        [showClose]="false"
      ></user-button-bar>
    </div>

    <div class="login-area animated short fadeInUpSmall">
      <div class="login-flex" *ngIf="currentMenu === 'invalidBrowser'">
        <h1>{{'welcome-view.warning' | translate}}!</h1>
        <h1 class="current-browser-message" [innerHtml]="'welcome-view.current-browser' | translate: {browser: currentBrowser}"></h1>

        <div class="instructions">{{'welcome-view.wrong-browser' | translate}}</div>

        <div class="login-buttons">
          <raised-css-button
            [buttonText]="'welcome-view.download'"
            [translateOptions]="{browser: 'Firefox'}"
            [fontSize]="'3vmin'"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [ngStyle]="{width: '49%'}"
            [buttonClasses]="'full-width'"
            (onClick)="openNewTab('https://www.google.com/chrome/')"
          ></raised-css-button>

          <raised-css-button
            [buttonText]="'welcome-view.download'"
            [translateOptions]="{browser: 'Chrome'}"
            [fontSize]="'3vmin'"
            [disabled]="sendingStarted"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [ngStyle]="{width: '49%'}"
            [buttonClasses]="'full-width'"
            (onClick)="openNewTab('https://www.firefox.com/')"
          ></raised-css-button>
        </div>

        <!--<p [ngStyle]="{'cursor':'pointer', 'font-size': 'small'}" (click)="currentMenu = 'login'">
          {{'welcome-view.continue' | translate}}</p>-->
      </div>

      <!--      <h1>{{'welcome-view.title' | translate}}</h1>-->

      <ng-container *ngIf="currentMenu=='login'">
        <div class="login-flex" [ngStyle]="{flex: 0.9}">
          <h1>Welcome to LOGE!</h1>

          <div class="animated short" [ngClass]="{fadeInUpSmallGrow: invalidLogin, fadeOutUpSmallGrow: !invalidLogin}"
               [ngStyle]="invalidLogin === undefined && {display: 'none'}">
            <p class="text-danger">
              {{'welcome-view.login-unauthorized' | translate}}
            </p>
          </div>

          <form class="form">
            <div class="form-group">
              <div class="form-label">
                {{'welcome-view.email-label' | translate}}
              </div>

              <input name="username" type="email" class="text-input" (keydown)="enterActivate($event, 'submitLogin')"
                     [(ngModel)]="email" (ngModelChange)="checkUserLoginType($event)">
            </div>

            <div class="form-group animated short" [ngClass]="{fadeInUpSmallGrow: showPasswordInput, fadeOutUpSmallGrow: !showPasswordInput}"
              [ngStyle]="showPasswordInput === undefined && {display: 'none'}"
            >
              <div class="form-label">
                {{'welcome-view.password-label' | translate}}
              </div>

              <input name="password" type="password" class="text-input" (keydown)="enterActivate($event, 'submitLogin')"
                     [(ngModel)]="password">

              <div class="links">
                <a *ngIf="!recoverySent" (click)="openMenu('recover')" href="#">
                  {{'welcome-view.login-recover' | translate}}
                </a>
              </div>
            </div>
          </form>

          <!--<div class="links" *ngIf="showPasswordInput">
            <a *ngIf="!recoverySent" (click)="openMenu('recover')" href="#">
              {{'welcome-view.login-recover' | translate}}
            </a>
          </div>-->

          <raised-css-button
            *ngIf="!showPasswordInput && userLoginType !== 1"
            [buttonText]="'welcome-view.login-next'"
            [fontSize]="'3vmin'"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [animatedAppear]="true"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
            (onClick)="showPasswordInput = true"
          ></raised-css-button>

          <raised-css-button
            *ngIf="userLoginType === 2"
            [buttonText]="'welcome-view.login-with-o365'"
            [fontSize]="'3vmin'"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [animatedAppear]="true"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
            (onClick)="redirectToO365(email)"
          ></raised-css-button>

          <raised-css-button
            *ngIf="userLoginType === 1"
            [buttonText]="'welcome-view.redirect-to-o365'"
            [fontSize]="'3vmin'"
            [disabled]="true"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [animatedAppear]="true"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
          ></raised-css-button>

          <raised-css-button
            *ngIf="showPasswordInput"
            [buttonText]="'welcome-view.login-submit'"
            [fontSize]="'3vmin'"
            [marginTop]="'2vmin'"
            [marginBottom]="'0.1vmin'"
            [animatedAppear]="true"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
            (onClick)="submitLogin()"
          ></raised-css-button>

          <div class="legal-text">
            By registering to or using the service you accept our

            <a target="_blank" href="/static/legal/Privacy_policy_LOGE.pdf" title="{{'player-progress-view.in-new-tab' | translate}}">
              Privacy Policy
            </a>

            and

            <a target="_blank" href="/static/legal/Terms_of_use_LOGE.pdf" title="{{'player-progress-view.in-new-tab' | translate}}">
              Terms of Use
            </a>
          </div>
        </div>

        <div class="vertical-line"></div>

        <div class="login-flex">
          <div class="instructions front-page-margin" [innerHTML]="'welcome-view.instructions' | translate"></div>

          <youtube-player
            [ngStyle]="{width: '100%'}"
            [youTubeId]="'nw0kdlV-NIM'"
          ></youtube-player>
        </div>
      </ng-container>

      <div class="login-flex" *ngIf="currentMenu=='recover' || currentMenu=='sending' || currentMenu=='sent' || currentMenu=='send-failed'">
        <h1>{{'welcome-view.' + currentMenu + '-title' | translate}}</h1>
        <div class="instructions" [innerHtml]="'welcome-view.' + currentMenu + '-info' | translate"></div>

        <ng-container *ngIf="currentMenu=='recover'">
          <p *ngIf="invalidLogin" class="text-danger">{{'welcome-view.recover-error' | translate}}</p>
          <div class="form">
            <div class="form-group">
              <div for="emailInput" class="form-label">{{'welcome-view.email-label' | translate}}</div>
              <input id="emailInput" type="email" class="text-input" [(ngModel)]="email" (keydown)="enterActivate($event, 'submitRecovery')">
            </div>
          </div>

          <div class="login-buttons">
            <raised-css-button
              [buttonText]="'welcome-view.return' | translate"
              [marginTop]="'2vmin'"
              [marginBottom]="'0.1vmin'"
              [fontSize]="'3vmin'"
              [ngStyle]="{width: '49%'}"
              [buttonClasses]="'full-width'"
              (onClick)="openMenu('login')"
            ></raised-css-button>

            <raised-css-button
              [buttonText]="'welcome-view.recover-submit'"
              [disabled]="sendingStarted"
              [marginTop]="'2vmin'"
              [marginBottom]="'0.1vmin'"
              [fontSize]="'3vmin'"
              [ngStyle]="{width: '49%'}"
              [buttonClasses]="'full-width'"
              (onClick)="submitRecovery()"
            ></raised-css-button>
          </div>
        </ng-container>

        <raised-css-button
          *ngIf="currentMenu=='sending' || currentMenu=='sent' || currentMenu=='send-failed'"
          [buttonText]="'welcome-view.return' | translate"
          [marginTop]="'2vmin'"
          [fontSize]="'3vmin'"
          [marginBottom]="'0.1vmin'"
          [buttonClasses]="'full-width'"
          (onClick)="openMenu('login')"
        ></raised-css-button>
      </div>
    </div>
  `
})
export class WelcomeView extends OnDestroyMixin implements OnInit {
  public currentMenu: string = "login";
  // public safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/nw0kdlV-NIM?autoplay=0&rel=0&ps=docs&showinfo=0');
  public hoveredElementId: string;
  public sendingStarted: boolean;
  public waitForUser: boolean;

  public browserNames = {
    chrome: "Google Chrome",
    firefox: "Firefox",
    safari: "Safari",
    opera: "Opera",
    ie: "Internet Explorer",
    'ms-edge': "Edge",
    "ms-edge-chromium": "Edge Chromium"
  };

  public currentBrowser: string;

  public userLoginType: number = -1;
  public showPasswordInput: boolean;
  public showNextButton: boolean;
  public azureRedirectTimeOut: Timer;

  public email: string;
  public password: string;
  public invalidLogin: boolean;
  public recoverySent: boolean;
  public preventRedirect: boolean;

  constructor(private http: HttpClient,
              protected router: Router,
              protected userService: UserService,
              protected device: DeviceDetectorService,
              // private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.userService
      .currentUser$
      .pipe(
        filter(user => !!user),
        first()
      )
      .subscribe(() => {
        // Stop redirect if user found
        this.preventRedirect = true;

        // Also clear timeout if set
        if (this.azureRedirectTimeOut) {
          clearTimeout(this.azureRedirectTimeOut);
        }
      });

    const currentBrowser: string = this.device.browser.toLowerCase();
    this.currentBrowser = this.browserNames[currentBrowser] || "Unknown";

    if (!currentBrowser) {
      return;
    }

    if (['chrome', 'firefox', 'ms-edge-chromium'].indexOf(currentBrowser) === -1) {
      this.currentMenu = 'invalidBrowser';
      console.error("Unsupported browser! " + this.currentBrowser);
    }
  }

  openMenu(name: string) {
    this.invalidLogin = false;
    this.currentMenu = name;
  }

  /**
   * Login
   */
  submitLogin() {
    this.waitForUser = true;

    const body = JSON.stringify({
      username: this.email,
      password: this.password
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post('/api/auth/login/email', body, options)
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(
        res => {
          // Update userDoc and wait for it to load
          this.userService.update();
        },
        (err: HttpErrorResponse) => {
          console.error("Unauthorized", err);
          this.waitForUser = false;
          this.invalidLogin = true;
        }
      );
  }

  /**
   * Start password recovery
   */
  submitRecovery() {
    this.sendingStarted = true;

    this.userService.startPasswordRecovery(this.email)
      .subscribe(
        () => {
          this.sendingStarted = false;
          this.recoverySent = true;
          this.currentMenu = 'sent';
        },
        () => {
          this.sendingStarted = false;
          this.currentMenu = 'send-failed';
          this.invalidLogin = true;
        });
  }

  /**
   * Allow submit by pressing Enter in addition to clicking the button
   * @param event
   * @param {string} fn
   */
  enterActivate(event, fn: string) {
    if (event.key === "Enter" && this[fn]) {
      this[fn]();
    }
  }

  public showTooltipHover(event: any) {
    if (!event || !event.target) {
      this.hoveredElementId = null;
      return;
    }

    this.hoveredElementId = event.target.id;
  }

  public checkUserLoginType(email: string): void {
    if (!GeneralUtils.isValidEmail(email)) {
      // Invalid email, do noting
      this.userLoginType = -1;

      return;
    }

    this.userService.getUserLoginType(email)
      .subscribe((type: { status: number }) => {
        if (this.azureRedirectTimeOut) {
          clearTimeout(this.azureRedirectTimeOut);
        }

        this.userLoginType = type ? type.status : -1;

        if (this.userLoginType === 1) {
          // Redirect to O365 login if using O365 or if no user but found matching domain
          this.azureRedirectTimeOut = setTimeout(() => {
            this.redirectToO365(email);
          }, 500);
        }
      });
  }

  public redirectToO365(email: string): void {
    if (this.preventRedirect) {
      console.log("Prevent redirect, already logged in!");
      return;
    }

    if (!email) {
      location.href = '/api/auth/login/o365/';
      return;
    }

    location.href = '/api/auth/login/o365-email/' + email;
  }

  public openNewTab(link: string): void {
    window.open(
      link,
      '_blank'
    );
  }
}
