/**
 * Created by tkok on 29.11.2016.
 */
import {Reaction} from "./player.type";
import {GameSeason} from "./game-season.type";
import {AnswerData, GameState, PlayerData} from "./game-state.interface";

export class GameSessionLog {
  constructor(public _id: string,
              public orgId: string,
              public session: LogSession,
              public entries: LogEntrySchema[],
              public derived: LogScore,
              public sessionIsForTesting: boolean,
              public reactions: SituationReactions[], // Filtered in server from entries
              public answerData: AnswerData[],
              public playerData: PlayerData[],
              public audioStatuses: AudioStatusEntry[],
              public chatLog: ChatLog[],
              public connectionLog: ConnectionLog[],
              public gameStateLog: GameState[]
  ) {

  }
}

export class UserReactions {
  constructor(
    public type: string, // thumb, laugh, bulb, confused
    public receivedAmount: number,
    public givenAmount: number
  ) {

  }

  static DefaultReactionArray() {
    return [
      new this("thumb", 0, 0),
      new this("laugh", 0, 0),
      new this("bulb", 0, 0),
      new this("confused", 0, 0)
    ];
  }
}

export class LogScore {
  constructor(
    public groupScore: number,
    public suggestionScore: number,
    public scalingScore: number,
    public pollScore: number
  ) {

  }
}

export interface ChatLog {
  sender: string;
  text: string;
  time: Date;
}

export interface ConnectionLog {
  user: string;
  status: number; // 0 disconnected, 1 connected
  time: Date;
}

export class SituationReactions {
  constructor(
    public situationId: string,
    public reactions: Reaction[]
  ) {

  }
}

export class LogSession {
  constructor(
    public _id: string,
    public beginTime: Date,
    public endTime: Date,
    public situations: any[],
    public players: any[],
    public participants: any[],
    public theme: string,
    public set: string,
    public numOfquestions: number,
    public gameSeasonId: GameSeason, // Populated
    public groupName: string
  ) {

  }
}

export class LogEntrySchema {
  constructor(public time: Date,
              public typeName: string,
              public data: any) {
  }
}

export class AudioStatusEntry {
  constructor(public time: Date,
              public typeName: string,
              public data: UserAudioStatus) {
  }
}

export class UserAudioStatus {
  constructor(public userId: string,
              public connected: boolean,
              public voiceDetected: boolean,
              public connections: {
                userId: string,
                connectionTimes: Date[],
                heard: boolean
              }[],
              public errors: {
                name: string,
                message?: string,
                extra?: string
              }[]) {
  }
}
