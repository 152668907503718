/**
 * Created by TopSal on 28.6.2017.
 */

import {Pipe, Injectable, PipeTransform} from "@angular/core";

@Pipe({
  name: 'newLine',
  pure: true
})
@Injectable()
export class NewLinePipe implements PipeTransform {
  /**
   * Replace newline characters with breaks
   * Also remove < and > to prevent text breakage
   * @param {string} text
   * @returns {string}
   */
  transform(text: string): string {
    if (!text || typeof text !== 'string') {
      return text;
    }

    return text
      .trim()
      .replace(/>/g, '\>')
      .replace(/</g, '\<')
      .replace(/\r\n/g, '<br/>')
      .replace(/\n/g, '<br/>');
  }
}
