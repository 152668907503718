import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {MaterialInterface} from "../../data-model/material.interface";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  public orgMaterial$: BehaviorSubject<MaterialInterface[]> = new BehaviorSubject(null);
  public testMaterial$: BehaviorSubject<MaterialInterface[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {

  }

  public static getViewPortSize(amount: number, type: string): number {
    const viewPortWidth: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const viewPortHeight: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    if (type === 'vw') {
      return (viewPortWidth * amount) / 100;
    }

    if (type === 'vh') {
      return (viewPortHeight * amount) / 100;
    }

    if (type === 'vmax') {
      const viewPortMax: number = viewPortWidth > viewPortHeight ? viewPortWidth : viewPortHeight;
      return (viewPortMax * amount) / 100;
    }

    // Return vmin
    const viewPortMin: number = viewPortWidth < viewPortHeight ? viewPortWidth : viewPortHeight;
    return (viewPortMin * amount) / 100;
  }

  public static parseViewPortWidth(stringWidth: string): number {
    const viewPortWidth: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (!stringWidth) {
      // Return max width if not set
      return (viewPortWidth * 50) / 100;
    }

    const numberWidth: number  = parseInt(stringWidth.slice(0, stringWidth.length - 2), 10); // Remove vw from end
    return (viewPortWidth * numberWidth) / 100;
  }

  public static pixelsToViewPort(pixelAmount: number, viewPortType: string = 'vw'): string {
    const viewPortHeight: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (viewPortType === 'vh') {
      return +(pixelAmount / viewPortHeight).toFixed(2) * 100 + viewPortType;
    }

    const viewPortWidth: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewPortType === 'vw') {
      return +(pixelAmount / viewPortWidth).toFixed(2) * 100 + viewPortType;

    }

    if (viewPortType === 'vmax') {
      const viewPortMax: number = viewPortWidth > viewPortHeight ? viewPortWidth : viewPortHeight;
      return +(pixelAmount / viewPortMax).toFixed(2) * 100 + viewPortType;
    }

    // Return vmin
    const viewPortMin: number = viewPortWidth < viewPortHeight ? viewPortWidth : viewPortHeight;
    return +(pixelAmount / viewPortMin).toFixed(2) * 100 + viewPortType;
  }

  /**
   * Get materials for organization
   * @param {string} orgId
   */
  public getOrgMaterial(orgId: string) {
    this.http.get('/api/material/' + orgId)
      .subscribe(
        res => {
          this.orgMaterial$.next(res as MaterialInterface[]);
        },
        (err: HttpErrorResponse) => {
          console.error("Failed getting material for organization", orgId, err);
          this.orgMaterial$.next(null);
        }
      );
  }

  public getTestMaterial(): void {
    this.http.get('/api/material/test/')
      .subscribe(
        res => {
          this.testMaterial$.next(res as MaterialInterface[]);
        },
        (err: HttpErrorResponse) => {
          console.error("Failed getting test material", err);
          this.testMaterial$.next(null);
        }
      );
  }

  public saveThemeMaterial(material: MaterialInterface) {
    const body = JSON.stringify(material);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};
    const postUrl = material._id ? '/api/material/' + material._id : '/api/material/';

    this.http.post(postUrl, body, options)
      .subscribe(
        res => {
          if (material.orgId) {
            this.getOrgMaterial(material.orgId);
          }
        },
        err => {
          console.error("Failed saving theme material", material.orgId, err);
        }
      );
  }

  public removeMaterial(material: MaterialInterface) {
    if (!material || !material._id) {
      return;
    }

    this.http.delete('/api/material/' + material._id)
      .subscribe(
        res => {
        },
        err => {
          console.error("Failed removing material", err);
        }
      );
  }
}

