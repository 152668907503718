import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import {GameSessionService} from "../app/core/service/game-session.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<any> {
  constructor(private gameSessionService: GameSessionService,
              private translate: TranslateService) {
  }

  canDeactivate() {
    if (window.location.href.indexOf('game-session') === -1) {
      const r: boolean = confirm(this.translate.instant('score-view.leave-session'));
      if (r === true) {
        this.gameSessionService.leaveDiscussionRoom("deactivate-guard");
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }
}
