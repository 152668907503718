import {Component, Input, OnInit} from '@angular/core';
import {PollChoiceSelection} from "../../../data-model/game-state.interface";

@Component({
  selector: 'tutorial-poll',
  template: `
    {{('situation.poll-max-choices' | translate) + ' ' + maxSelections}}

    <div class="poll-container" [ngStyle]="{transform: 'scale(0.9)'}" *ngIf="phase === 'personal'">
      <div class="poll-choices">
        <div *ngFor="let choice of [1, 2, 3]" class="poll-choice dark"
             (mouseenter)="setHoverStatus(choice)" (mouseleave)="setHoverStatus(-1)" (click)="selectChoice(choice)">
          <svg-ball-component [content]="''" [hovered]="choiceHovered === choice" [active]="checkSelected(choice)"
                              [ngStyle]="{'margin-right': '1rem'}"></svg-ball-component>
          {{'tutorial-situations.poll-choice-' + choice + '-text' | translate}}
        </div>

        <div class="poll-choice dark" [ngStyle]="{fontSize: '1.6vmin'}"
             (mouseenter)="setHoverStatus(4)" (mouseleave)="setHoverStatus(-1)" (click)="selectChoice(4)">
          <svg-ball-component [content]="''" [hovered]="choiceHovered === 4" [active]="checkSelected(4)"
                              [ngStyle]="{'margin-right': '1rem'}"></svg-ball-component>
          <div class="free-choice">
            {{'situation.poll-free-choice' | translate}}

            <input [auto-focus]="true" *ngIf="checkSelected(4)" [(ngModel)]="openReason" class="poll-input"
                   (click)="$event.stopPropagation()"
                   [placeholder]="'situation.poll-free-choice-placeholder' | translate"
            />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="phase === 'group'" [ngStyle]="{width: '55vmin'}">
      <dashed-circle [sortedChartValues]="tutorialUserAnswers"
                     [totalVotes]="5" [phase]="'group'"
                     (onChoiceHovered)="choiceHovered = $event"
                     [userIsChairman]="true"
                     [groupVote]="tutorialGroupVote"
                     [maxSelections]="maxSelections"
                     (onGroupChoiceSelected)="onGroupSelection($event)"
      ></dashed-circle>
    </div>
  `,
  styles: []
})
export class TutorialPollComponent implements OnInit {
  @Input() phase: string;

  public selections: number[] = [];
  public choiceHovered: number;
  public openReason: string = "";
  public maxSelections: number = 2;

  public tutorialGroupVote: PollChoiceSelection[] = [];
  public tutorialUserAnswers: PollChoiceSelection[] = [
    {
      choiceTitle: 'tutorial-situations.poll-choice-1-text',
      choiceId: "1",
      selections: 3
    },
    {
      choiceTitle: 'tutorial-situations.poll-choice-2-text',
      choiceId: "2",
      selections: 2
    },
    {
      choiceTitle: 'tutorial-situations.poll-choice-3-text',
      choiceId: "3",
      selections: 1
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

  public selectChoice(choice: number) {
    const selectionIndex: number = this.selections.indexOf(choice);
    if (selectionIndex === -1) {
      if (this.selections.length < this.maxSelections) {
        this.selections.push(choice);
      }

      return;
    }

    this.selections.splice(selectionIndex, 1);
  }

  public setHoverStatus(choice: number) {
    this.choiceHovered = choice;
  }

  public checkSelected(choice: number): boolean {
    return !!this.selections.find(value => {
      return value === choice;
    });
  }

  public onGroupSelection(selection: PollChoiceSelection) {
    if (!this.tutorialGroupVote || !this.tutorialGroupVote.length) {
      this.tutorialGroupVote = [selection];
      return;
    }

    const existingIndex: number = this.tutorialGroupVote.findIndex(existingSelection => {
      return existingSelection && selection.choiceTitle === existingSelection.choiceTitle;
    });

    if (existingIndex !== -1) {
      // Remove previous
      this.tutorialGroupVote.splice(existingIndex, 1);
      return;
    }

    if (this.tutorialGroupVote.length >= this.maxSelections && !selection.isOpen) {
      return;
    }

    this.tutorialGroupVote.push(selection);
  }
}
