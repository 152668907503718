import { Component, ElementRef, ViewChild, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocomplete } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "tag-selector",
  template: `
    <mat-form-field>
      <mat-label>
        {{inputLabel | translate}}
      </mat-label>

      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
          *ngFor="let item of tagList"
          [selectable]="true"
          [removable]="true"
          (removed)="removeItem(item)"
        >
          {{item}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input
          #tagsInput
          [placeholder]="currentInputPlaceholder"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addItem($event)"
          [ngClass]="{'value-not-allowed': newValuesNotAllowedFail}"
          [(ngModel)]="currentInputValue"
          (ngModelChange)="filterAllowedOptions()"
          (focus)="changeDetector.detectChanges()"
        >
      </mat-chip-list>

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of allowedOptions" [value]="item" (click)="selectItem(item)">
          {{item}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: []
})
export class TagSelectorComponent implements OnChanges, OnInit {
  @ViewChild("tagsInput") tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  @Input() tagList: string[] = [];
  @Input() optionList: string[] = [];
  @Input() inputLabel: string = "Tags";
  @Input() inputPlaceholder: string = "Select tags from the list or write your own...";

  @Input() allowNew: boolean = true;        // <------------------------------------ If true, allows the input of new tags
  @Input() allowMultiple: boolean = true;   // <------------------------------------ If true, allows the selection of multiple simultaneous tags

  @Output() tagListChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  currentInputPlaceholder: string;
  currentInputValue: string = "";

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public allowedOptions: string[] = [];

  newValuesNotAllowedFail: boolean = false;

  constructor(public changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionList && this.optionList) {
      this.filterAllowedOptions();
    }
  }

  ngOnInit(): void {
    this.currentInputPlaceholder = this.tagList.length === 0 ? this.inputPlaceholder : "...";
  }

  addItem(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // If new values aren't allowed
    if (!this.allowNew) {
      this.newValuesNotAllowedFail = true;
      return;
    }

    // Check if already selected
    if (!value || !value.trim() || this.tagList.indexOf(value) !== -1) {
      return;
    }

    // Trim, toLowerCase and add
    // Update allowed list
    this.tagList.push(value.trim().toLowerCase());
    this.filterAllowedOptions();

    // Reset the input
    if (input) {
      this.currentInputValue = "";
      input.value = this.currentInputValue;
    }

    this.currentInputPlaceholder = this.tagList.length === 0 ? this.inputPlaceholder : !this.allowNew ? "" : "...";

    this.tagListChange.emit(this.tagList);

  }


  public removeItem(item: string): void {

    const itemIndex: number = this.tagList.indexOf(item);

    if (itemIndex === -1) {
      // Item not found
      return;
    }

    this.tagList.splice(itemIndex, 1);

    this.currentInputPlaceholder = this.tagList.length === 0 ? this.inputPlaceholder : !this.allowNew ? "" : "...";

    this.tagListChange.emit(this.tagList);

    this.filterAllowedOptions();

  }


  public selectItem(value: string): void {

    if (!this.allowMultiple) {
      this.tagList.pop();
    }

    this.tagList.push(value);

    this.currentInputValue = "";
    this.tagsInput.nativeElement.value = this.currentInputValue;

    this.currentInputPlaceholder = this.tagList.length === 0 ? this.inputPlaceholder : !this.allowNew ? "" : "...";

    this.filterAllowedOptions();

    this.tagListChange.emit(this.tagList);
  }


  filterAllowedOptions(): void {

    this.allowedOptions = this.optionList.filter(item => {
      return this.tagList.indexOf(item) === -1;
    });

    this.allowedOptions = this.allowedOptions.filter(item => {
      return item.toLowerCase().includes(this.currentInputValue.toLowerCase());
    });

    this.newValuesNotAllowedFail = !this.allowNew && this.allowedOptions.length === 0;

    this.changeDetector.detectChanges();

  }
}
