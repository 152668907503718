/**
 * Created by janne on 28.9.2016.
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {GameSeason, PhaseTiming, SeasonWithTimeSlots} from "../../data-model/game-season.type";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GameSeasonService {
  public gameSeasons$: BehaviorSubject<GameSeason[]> = new BehaviorSubject<GameSeason[]>(null);
  public gameSeasonNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public phaseTiming$: BehaviorSubject<PhaseTiming> = new BehaviorSubject<PhaseTiming>(null);

  private getlistUrl: string = '/api/game-season/list/';
  private removeUrl: string = '/api/game-season/';
  private updateUrl: string = '/api/game-season/';
  private addUrl: string = '/api/game-season/';
  private getListNamesUrl: string = '/api/game-season/names/';
  private seasonQueryUrl: string = '/api/game-season/query-list/';

  constructor(private http: HttpClient) {
  }

  public fetchOrganizationSeasons(orgId: string): void {
    this.http.get(this.getlistUrl + orgId)
      .subscribe(
        res => {
          this.gameSeasons$.next(res as GameSeason[]);
        },
        err => {
          console.error("Fetching Seasons listing failed", err);
          this.gameSeasons$.next(null);
        }
      );
  }

  public fetchOrganizationExpiredSeasons(orgId: string): void {
    this.http.get('/api/game-season/list-old/' + orgId)
      .subscribe(
        res => {
          this.gameSeasons$.next(res as GameSeason[]);
        },
        err => {
          console.error("Fetching Seasons listing failed", err);
          this.gameSeasons$.next(null);
        }
      );
  }

  // Get duration in seconds
  public getSeasonDiscussionLength(seasonId: string): Observable<number> {
    // Check populated just in case
    seasonId = seasonId['_id'] ? seasonId['_id'] : seasonId;

    return this.http.get('/api/game-season/season-discussion-duration/' + seasonId)
      .pipe(
        map(res => {
          const response = res as {seconds: number};
          return response.seconds;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Remove season by ID
   * @param {GameSeason} season
   */
  public removeSeason(season: GameSeason): void {
    this.http.delete(this.removeUrl + season._id)
      .subscribe(
        res => {
          const removedSeason: GameSeason = res as GameSeason;
          const previousSeasons: GameSeason[] = this.gameSeasons$.value;

          const newSeasons: GameSeason[] = previousSeasons.filter(existingSeason => {
            return existingSeason._id !== removedSeason._id;
          });

          this.gameSeasons$.next(newSeasons);
        },
        err => console.error("Failed to create season", season.name)
      );
  }

  /**
   * Edit season
   * @param {GameSeason} season
   */
  public updateSeason(season: GameSeason): void {
    const body = JSON.stringify({season});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};
    this.http.put(this.updateUrl + season._id, body, options)
      .subscribe(
        res => {
          const editedSeason: GameSeason = res as GameSeason;
          const previousSeasons: GameSeason[] = this.gameSeasons$.value;

          const editedSeasonIndex: number = previousSeasons.findIndex(existingSeason => {
            return existingSeason._id.toString() === editedSeason._id.toString();
          });

          if (editedSeasonIndex === -1) {
            console.error("Failed to find edited situation from current array", season._id);
            return;
          }

          previousSeasons[editedSeasonIndex] = editedSeason;

          this.gameSeasons$.next(previousSeasons);
        },
        err => console.error("Failed to update season", season.name)
      );
  }

  /**
   * Create new season
   * @param {GameSeason} season
   */
  public addSeason(season: GameSeason): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(this.addUrl, season, options)
      .subscribe(
        res => {
          const newSeason: GameSeason = res as GameSeason;
          const previousSeasons: GameSeason[] = this.gameSeasons$.value;

          // Add new season as first
          previousSeasons.unshift(newSeason);

          this.gameSeasons$.next(previousSeasons);
        },
        err => console.error("Failed to create season", season.name)
      );
  }

  /**
   * Get organization default phase timing
   * @param {string} orgId
   * @returns {Observable<PhaseTiming>}
   */
  public getPhaseTiming(orgId: string): void {
    this.http.get('/api/phase-timing/' + orgId)
      .subscribe(
        res => {
          this.phaseTiming$.next(res as PhaseTiming);
        },
        err => {
          console.error("Fetching PhaseTiming listing failed", err);
          this.phaseTiming$.next(null);
        }
      );
  }

  /**
   * Save phase timing as organization default
   * @param {PhaseTiming} phaseTiming
   */
  public saveDefaultTiming(phaseTiming: PhaseTiming): void {
    const body = phaseTiming;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};
    this.http.post('/api/phase-timing/', body, options)
      .subscribe(
        res => this.phaseTiming$.next(res as PhaseTiming),
        err => console.error("Failed saving default phaseTiming")
      );
  }

  public getOrganizationSeasonNames(orgId: string): void {
    this.http.get(this.getListNamesUrl + orgId)
      .subscribe(
        res => {
          this.gameSeasonNames$.next(res as string[]);
        },
        err => {
          console.error("Fetching Season names listing failed", err);
          this.gameSeasonNames$.next(null);
        }
      );
  }

  public getOrganizationSeasonByQuery(query: Object): Observable<GameSeason[]> {
    return this.http.put(this.seasonQueryUrl, {query: query})
      .pipe(
        map(res => {
          return res as GameSeason[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getSeasonsWithTimeSlots(): Observable<SeasonWithTimeSlots[]> {
    return this.http.get<SeasonWithTimeSlots[]>("/api/game-season/seasons-with-slots")
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
