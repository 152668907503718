/**
 * Created by NordicEdu on 30/12/2016.
 */
import {
  Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, AfterViewInit, OnInit, ViewChild, ElementRef
} from "@angular/core";
import {YoutubeService} from "app/core/service/youtube-api.service";
import {filter, first} from "rxjs/operators";
import { YoutubeInfoInterface } from "../../data-model/youtube-info.interface";
import { YoutubeInfoService } from "../../core/service/youtube-info.service";
import { UserService } from "../../core/service/user.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { User } from "../../data-model/user.type";
import { LocalStorageService } from "../../core/service/local-storage.service";

export interface PlayerVars {
  origin?: string;
  enablejsapi?: 1 | 0;
  color?: 'white' | 'red';
  iv_load_policy?: 1 | 3;
  rel?: 0 | 1;
  loop?: 0 | 1;
  controls?: 0 | 1;
  autoplay?: 0 | 1;
}

@Component({
  selector: 'youtube-player',
  template: `
    <div class="youtube-player" [ngStyle]="{
      backgroundImage: (videoInfo?.snippet?.thumbnails?.high?.url ? 'url(' + videoInfo.snippet.thumbnails.high.url + ')' : ''),
      display: (youTubeBlocked ? 'none' : '')
    }">
      <div
        #youtubePlayerElement
        [id]="youTubeId + identifier"
      >
        <div
          class="pre-load-overlay"
        >
          <ng-container *ngIf="!playerInitiated">
            <div class="video-title">
              {{videoInfo?.snippet?.title}}
            </div>

            <div class="button-wrapper">
              <button class="play-button" (click)="createYouTubePlayer()">
                <img src="/assets/image/yt_play_button.svg" alt="Play">
              </button>
            </div>

            <div class="consent-info" *ngIf="showConsentMessage">
              <span [innerHTML]="'youtube-player.consent-info' | translate"></span>

              <label>
                <input type="checkbox" [(ngModel)]="rememberConsent">
                {{"youtube-player.remember-consent" | translate}}
              </label>
            </div>
          </ng-container>

          <ng-container *ngIf="playerInitiated">
            <div class="video-title">
              {{"youtube-player.loading-video" | translate}}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  `
})

export class YoutubePlayerComponent extends OnDestroyMixin implements OnChanges, OnDestroy, AfterViewInit, OnInit {
  @ViewChild('youtubePlayerElement', {static: true}) youtubePlayerElement: ElementRef;

  @Input() youTubeId: string;
  @Input() identifier: string = '_player';

  @Input() timeOut: number;
  @Input() disabled: boolean;
  @Input() pauseVideo: boolean;
  @Input() preloadVideo: boolean;

  public playerState: number = -1;
  @Output() onStateChange: EventEmitter<number> = new EventEmitter;
  @Output() onYTPlayerReady: EventEmitter<boolean> = new EventEmitter;

  public youTubePlayer: YT.Player;
  public videoInfo: YoutubeInfoInterface;

  public YTPlayerReady: boolean = false;

  public apiLoaded: boolean;
  public youTubeBlocked: boolean;

  public rememberConsent: boolean;
  public showConsentMessage: boolean = true;
  public playerInitiated: boolean;
  public playAfterApiReady: boolean;

  private currentUser: User;

  constructor(
    private youtubeService: YoutubeService,
    private youtubeInfoService: YoutubeInfoService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
  ) {
    // OnDestroyMixin
    super();
  }

  ngOnInit(): void {
    this.checkYouTubeCookieConsent();

    this.youtubeService.youTubeAllowed$
      .pipe(
        untilComponentDestroyed(this),
        filter(allowed => {
          if (allowed === undefined) {
            this.youtubeService.testBlocked();
          }

          return allowed !== undefined;
        }),
        first(),
      )
      .subscribe(allowed => {
        if (!allowed) {
          console.error("Could not connect to YouTube");
          this.youTubeBlocked = true;
          return;
        }

        this.loadApi();
      });
  }

  public loadApi(): void {
    this.youtubeService.youTubeApiReady$
      .pipe(
        untilComponentDestroyed(this),
        filter(apiReady => apiReady),
        first()
      )
      .subscribe(
        apiReady => {
          this.apiLoaded = apiReady;

          // Create player since API wasn't loaded before ngAfterViewInit
          if (this.preloadVideo || this.playAfterApiReady) {
            console.log("Api loaded, create player");
            this.playAfterApiReady = false;
            this.createYouTubePlayer();
          }
        }
      );
  }

  ngAfterViewInit(): void {
    if (this.apiLoaded) {
      // Create player if API has been previously loaded
      this.createYouTubePlayer();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.youTubeId || changes.identifier) && this.youTubeId && this.identifier) {
      this.pauseYouTubeVideo();
      this.cueVideo(this.youTubeId);

      // Fetch info so we can display overlay before user consent
      this.youtubeInfoService.fetchVideoInfo(this.youTubeId)
        .subscribe((videoInfo: YoutubeInfoInterface) => {
          this.videoInfo = videoInfo;
        });
    }

    if (changes.disabled && this.disabled && this.YTPlayerReady) {
      // Pause video if moving to assignment
      this.pauseYouTubeVideo();
    }

    if (changes.pauseVideo) {
      this.pauseYouTubeVideo();
    }
  }

  /**
   * Emit player ready
   */
  onReady() {
    this.YTPlayerReady = true;
    this.onYTPlayerReady.emit(true);

    if (!this.preloadVideo) {
      // Play video to prevent needing to press the play button twice
      this.playYouTubeVideo();
    }
  }

  /**
   * Listen to video states and pause when moving to situation
   * @param state
   */
  onPlayerStateChange(state) {
    /*
      -1 (unstarted)
      0 (ended)
      1 (playing)
      2 (paused)
      3 (buffering)
      5 (video cued)
    */

    // Stop playing if state changes to 1 (playing)
    // Even though video should be disabled
    if (state === 1 && this.disabled && this.youTubePlayer) {
      this.youTubePlayer.pauseVideo();
    }

    this.playerState = state;
    this.onStateChange.emit(state);
  }

  ngOnDestroy() {
  }

  public playYouTubeVideo() {
    if (!this.youTubePlayer || this.playerState === 1) {
      return;
    }

    this.youTubePlayer.playVideo();
  }

  public pauseYouTubeVideo() {
    if (!this.youTubePlayer || this.playerState !== 1) {
      return;
    }

    this.youTubePlayer.pauseVideo();
  }

  public cueVideo(videoId: string): void {
    if (!this.youTubePlayer) {
      return;
    }

    try {
      this.youTubePlayer.cueVideoById(videoId);
    } catch (e) {
      console.error("Failed to cue video", e);
    }
  }

  public saveYouTubeConsent(): void {
    if (!this.rememberConsent) {
      return;
    }

    // Set consent in LocalStorage
    this.localStorageService.setItem("cookieConsent.youtube", new Date().toISOString());

    if (!this.currentUser) {
      return;
    }

    this.userService.saveUser(
      this.currentUser._id,
      {
        cookieConsent: {
          youtube: new Date()
        }
      }
    );
  }

  public createYouTubePlayer() {
    // Hide consent message if not yet hidden
    if (this.showConsentMessage) {
      this.showConsentMessage = false;
      this.saveYouTubeConsent();
    }

    if (!this.apiLoaded) {
      // Set video to start after API is ready
      this.playAfterApiReady = true;

      // API haven't been loaded yet
      // This function will be called again after API has finished loading
      this.youtubeService.loadYouTubeApi();
      return;
    }

    if (!this.youTubeId || !this.identifier || !this.youtubePlayerElement) {
      // Missing information
      return;
    }

    this.playerInitiated = true;

    const playerVars: PlayerVars = {
      origin: "https://experience.loge.fi",
      enablejsapi: 1,
      color: 'white',
      iv_load_policy: 3,
      rel: 0
    };

    // This will append the YouTube player to element with matching ID, which is #youtubePlayerElement
    this.youTubePlayer = new YT.Player(this.youTubeId + this.identifier, {
      height: '100%',
      width: '100%',
      playerVars: playerVars,
      videoId: this.youTubeId,
      host: 'https://www.youtube-nocookie.com',
      events: {
        'onReady': (event) => {
          this.onReady();
        },
        'onStateChange': (event) => {
          this.onPlayerStateChange(event.data);
        }
      },
    });
  }

  private onConsentGiven(): void {
    // Hide consent message
    this.showConsentMessage = false;

    // Save consent
    this.saveYouTubeConsent();

    // Create YouTube player unless preload disabled
    if (this.preloadVideo) {
      this.createYouTubePlayer();
    }
  }

  private checkYouTubeCookieConsent(): void {
    const consentInLocalStorage: string | null = this.localStorageService.getItem("cookieConsent.youtube");

    if (consentInLocalStorage) {
      this.onConsentGiven();

      return;
    }

    // Otherwise check if logged in user has given consent
    this.userService.currentUser$
      .pipe(
        untilComponentDestroyed(this),
        filter(user => !!user),
        first()
      )
      .subscribe(user => {
        this.currentUser = user;

        if (!user.cookieConsent?.youtube) {
          // No consent given yet
          return;
        }

        this.onConsentGiven();
      });
  }
}

