/**
 * Created by janne on 30.8.2016.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrganizationService} from "../core/service/organization.service";
import {Router} from "@angular/router";
import {UserService} from "../core/service/user.service";
import {Organization} from "../data-model/organization.type";
import {User} from "../data-model/user.type";
import {filter, first} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { MediaUtils } from "../../lib/media-utils";
import { Content } from "../data-model/content.type";

@Component({
  selector: 'greeting-view',
  template: `
    <div class="greeting-container" [ngStyle]="pageStyle">
      <div class="floating-logo-badge">
        <logo-badge></logo-badge>
      </div>

      <div class="floating-user-button-bar">
        <user-button-bar
          [currentUser]="currentUser"
          [exitButtonType]="currentUser?.privacyPolicyAccepted ? 'greeting' : null"
        ></user-button-bar>
      </div>

      <div class="login-area animated short fadeInUpSmall">
        <div class="login-flex">
          <first-time-login
            [currentUser]="currentUser"
            [availableOrganizations]="allowedOrganizations"
          ></first-time-login>
        </div>
      </div>
    </div>
  `
})
export class GreetingView	extends OnDestroyMixin implements OnInit {
  public currentUser: User;

  public allowedOrganizations: Organization[];
  public selectedOrg: Organization;
  public selectedOrgContent: Content;
  public selectedDepartment: string = null;
  public selectedLocation: string = null;
  public privacyPolicyAccepted: boolean;

  public validSelection: boolean;
  public pageStyle: Object = {};

  constructor(private orgService: OrganizationService,
              private router: Router,
              private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.userService.currentUser$
      .pipe(
        untilComponentDestroyed(this),
        filter(user => !!user),
        first()
      )
      .subscribe(
        user => {
          const userIsAdmin: boolean = this.userService.checkCurrentUserEditPrivileges();

          this.currentUser = user;
          this.privacyPolicyAccepted = !!user.privacyPolicyAccepted;
          this.selectedDepartment = user.department;
          this.selectedLocation = user.location;

          this.orgService.getAllowedOrganizations(user.orgId, user.email)
            .pipe(
              untilComponentDestroyed(this),
              filter(organizations => !!organizations.length),
            )
            .subscribe(
              organizations => {
                this.allowedOrganizations = organizations;

                // Check is current organization is an allowed selection
                const userHasValidSubOrg = !!this.allowedOrganizations.find(subOrg => {
                  return subOrg._id.toString() === user.orgId.toString();
                });

                /*if (this.privacyPolicyAccepted && (this.selectedDepartment || this.selectedLocation) && (userIsAdmin || userHasValidSubOrg)) {
                  // All good, continue to landing-page
                  this.router.navigate(['/landing-page']);
                  return;
                }*/

                // Set first one as selected, if only one option
                if (this.allowedOrganizations.length === 1) {
                  this.selectedOrg = this.allowedOrganizations[0];
                  this.getContentForStyle();
                  this.setDepartmentAndLocation();
                  return;
                }

                // Select the one the user has currently, or the first one
                this.selectedOrg = this.allowedOrganizations.find(org => {
                  return org._id.toString() === user.orgId.toString();
                });

                if (!this.selectedOrg) {
                  // Select the first one, if the current one doesn't exist anymore
                  this.selectedOrg = this.allowedOrganizations[0];
                }

                this.getContentForStyle();
                this.setDepartmentAndLocation();
              }
            );
        }
      );
  }

  public getContentForStyle(): void {
    this.orgService.getOrganizationContent(this.selectedOrg._id)
      .pipe(
        first()
      )
      .subscribe(value => {
        this.selectedOrgContent = value;
        this.setPageStyle();
      });
  }

  public setPageStyle(): void {
    if (!this.selectedOrgContent || !this.selectedOrgContent.backGroundImage) {
      this.pageStyle = {};
      return;
    }

    this.pageStyle = {'background-image': "url('" + MediaUtils.getOrgFile(this.selectedOrgContent.backGroundImage, this.selectedOrgContent.orgId) + "')"};
  }

  public setDepartmentAndLocation(): void {
    if (this.selectedOrg.departments.length || this.selectedOrg.departments.length) {
      if (this.selectedOrg.departments.length === 1) {
        this.selectedDepartment = this.selectedOrg.departments[0].name;
      }

      if (this.selectedOrg.locations.length === 1) {
        this.selectedLocation = this.selectedOrg.locations[0].name;
      }
    }
    else {
      // Set default if nothing to select
      this.selectedDepartment = "Default";
    }

    this.validSelection = this.checkValid();
  }

  public selectSubOrganization(subOrg: Organization) {
    this.selectedOrg = subOrg;
    this.setDepartmentAndLocation();
  }

  public selectDepartment(department: string) {
    this.selectedDepartment = department;
    this.validSelection = this.checkValid();
  }

  public selectLocation(location: string) {
    this.selectedLocation = location;
    this.validSelection = this.checkValid();
  }

  public saveChanges() {
    if (!this.validSelection) {
      console.error("Selection not valid");
      return;
    }

    this.userService.saveUser(
      this.currentUser._id,
      {
        orgId: this.selectedOrg._id,
        department: this.selectedDepartment,
        location: this.selectedLocation,
        privacyPolicyAccepted: new Date()
      }
    );
  }

  public checkValid(): boolean {
    if (!this.currentUser) {
      // Should be logged in
      return false;
    }

    if (!this.privacyPolicyAccepted) {
      // Should accept privacy policy
      return false;
    }

    if (!this.selectedOrg) {
      // Should have a valid organization
      return false;
    }

    if (!this.selectedDepartment && !this.selectedLocation) {
      // If no locations nor departments in org, set as Default, and return true
      if (!this.selectedOrg.departments.length && !this.selectedOrg.locations.length) {
        this.selectedDepartment = "Default";
        return true;
      }

      // Should have either a department or a location
      return false;
    }

    return true;
  }

  public togglePrivacyPolicy(): void {
    this.privacyPolicyAccepted = !this.privacyPolicyAccepted;
    this.validSelection = this.checkValid();
  }
}
