import {NetworkItemType} from "./network-item.type";

export class NetworkLinkType implements d3.SimulationLinkDatum<NetworkItemType> {
  index?: number;

  source: NetworkItemType;
  target: NetworkItemType;

  constructor(source, target) {
    this.source = source;
    this.target = target;
  }
}
