import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {
  constructor(private http: HttpClient) {
    window.onerror = (msg: string, url: string, lineNo: number, columnNo: number, error: Error) => {
      if (msg === "ResizeObserver loop limit exceeded") {
        // This can be safely ignored
        return;
      }

      console.log("Window onError", msg, url, error);
    };

    window.document.addEventListener("error", (event: ErrorEvent) => {
      console.log("Window document onError", event);
    }, true);
  }

  logError(error: any) {
    console.error(error);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post('/api/error-handling/log', error, options)
      .subscribe(
        res => {},
        err => console.error(err)
      );
  }
}
