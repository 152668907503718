/**
 * Created by Topi on 27.7.2018.
 */
import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "app/core/service/user.service";
import {GameSessionService} from "app/core/service/game-session.service";
import {User} from "app/data-model/user.type";
import {MaterialService} from "app/core/service/material.service";
import {GameTutorialService, TutorialItem} from "../core/service/game-tutorial.service";
import {OrganizationService} from "../core/service/organization.service";
import {Organization} from "../data-model/organization.type";
import {GameTimeslotService} from "../core/service/timeslot.service";
import {GameSessionLog} from "../data-model/game-session-log.type";
import {Content} from "../data-model/content.type";
import {filter, first, takeUntil} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import {GameTimeslot} from "../data-model/timeslot.type";
import {MediaUtils} from "../../lib/media-utils";
import {GameSession} from "../data-model/game-session.interface";
import {IdleService} from "../core/service/idle.service";
import {GameSessionLogService} from "../core/service/game-session-log.service";
import {SocketService} from "../core/service/socket.service";
import { NotificationService } from "../core/service/notification.service";

const moment = require('moment');

@Component({
  selector: 'landing-page',
  templateUrl: './templates/landing-page.view.html'
})
export class LandingPageView extends OnDestroyMixin implements OnInit {
  public sessionJoinError: string;

  public currentUser: User;
  public userSessions: GameSessionLog[];
  public userUpcomingSessions: GameTimeslot[];
  public currentOrganization: Organization;
  public organizationContent: Content;
  public activeDiscussion: GameSession;
  public currentIntroTutorial: string;

  public showIntroPopUp: boolean;
  public showHelperAutomatically: boolean;
  public pageStyle: Object = {};

  public showMiniAdmin: boolean;
  public subOrganizationsChecked: boolean;
  public showInvalidOrganizationMessage: boolean;
  public showTutorialPopUp: boolean;

  public selectedTutorial: TutorialItem;
  public currentTab: string;
  public showIdleInfo: boolean;

  constructor(protected router: Router,
              public userService: UserService,
              public gameSessionService: GameSessionService,
              private materialService: MaterialService,
              private tutorialService: GameTutorialService,
              private orgService: OrganizationService,
              private timeSlotService: GameTimeslotService,
              private sessionLogService: GameSessionLogService,
              private idle: IdleService,
              private socketService: SocketService,
              private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService.currentUser$
      .pipe(
        untilComponentDestroyed(this),
        filter(user => !!user)
      )
      .subscribe(
        user => {
          this.currentUser = user;
        }
      );

    this.userService.currentUser$
      .pipe(
        untilComponentDestroyed(this),
        filter(user => !!user),
        first()
      )
      .subscribe(
        () => {
          this.init();
        }
      );
  }

  public hidePopUp(): void {
    this.showIntroPopUp = false;
  }

  public setPageStyle(): void {
    if (!this.organizationContent || !this.organizationContent.backGroundImage) {
      this.pageStyle = {};
      return;
    }

    this.pageStyle = {'background-image': "url('" + MediaUtils.getOrgFile(this.organizationContent.backGroundImage, this.organizationContent.orgId) + "')"};
  }

  public checkShowIntroPopUp(): void {
    if (this.showIntroPopUp !== undefined) {
      // Already checked
      return;
    }

    if (!this.currentUser || !this.organizationContent) {
      // Wait for load
      return;
    }

    if (this.currentUser.welcomePopUpShown) {
      this.showIntroPopUp = false;

      if (!this.organizationContent.welcomeContent || !this.organizationContent.welcomeContent.text) {
        // No custom content, but user has seen it
        return;
      }

      if (this.organizationContent.welcomeContent.updatedAt && moment(this.organizationContent.welcomeContent.updatedAt).isBefore(moment(this.currentUser.welcomePopUpShown))) {
        // User has seen the current content
        return;
      }

      if (this.organizationContent.welcomeContent['updated'] && moment(this.organizationContent.welcomeContent['updated']).isBefore(moment(this.currentUser.welcomePopUpShown))) {
        // User has seen the current content ( LEGACY )
        return;
      }

      this.showIntroPopUp = true;

      this.userService.saveUser(
        this.currentUser._id,
        {welcomePopUpShown: moment()}
      );
    }

    if (!this.currentUser.loggedInDates && this.currentUser.loggedInDates.length <= 1) {
      this.showHelperAutomatically = true;
      return;
    }

    this.showIntroPopUp = true;

    this.userService.saveUser(
      this.currentUser._id,
      {welcomePopUpShown: moment()}
    );

    /*if (this.organizationContent.welcomeContent && this.currentUser.loggedInDates.length > 1) {
      // Show if updated after last log-in
      this.showIntroPopUp = moment(this.organizationContent.welcomeContent.updatedAt).isAfter(moment(this.currentUser.loggedInDates[this.currentUser.loggedInDates.length - 2]));

      this.userService.saveUser(
        this.currentUser._id,
        {welcomePopUpShown: moment()}
      );

      return;
    }

    this.showIntroPopUp = false;*/
  }

  public updateNextDiscussion(): void {
    this.timeSlotService.updateCurrentUserJoinedList();
  }

  public invalidOrgLogout(): void {
    this.userService.logout();
  }

  public closeTutorialPopUp(): void {
    this.showTutorialPopUp = false;
    this.selectedTutorial = null;
    this.tutorialService.setIntroTutorialAsCompleted('session-tutorial', this.currentUser);
  }

  public setTutorial(tutorial: TutorialItem): void {
    this.selectedTutorial = tutorial;
  }

  public openTab(identifier: string): void {
    if (!identifier || this.currentTab === identifier) {
      this.currentTab = null;
      return;
    }

    this.currentTab = identifier;
  }

  private init() {
    this.gameSessionService.getCurrentSession();
    this.timeSlotService.updateCurrentUserJoinedList();

    this.sessionLogService.getUserSessionLogs()
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(logs => {
        this.userSessions = logs;
      });

    this.orgService.currentOrganization$
      .pipe(
        untilComponentDestroyed(this),
        filter(org => org !== undefined)
      )
      .subscribe(
        org => {
          this.currentOrganization = org;

          if (org === null) {
            // Organization is null only if it was not found
            this.showInvalidOrganizationMessage = true;
            return;
          }

          this.showInvalidOrganizationMessage = false;
          this.materialService.getOrgMaterial(org._id);
          this.userService.getOrgUsers(org._id);

          if (!this.subOrganizationsChecked) {
            this.checkSubOrganizations();
          }
        }
      );

    this.orgService.currentOrgContent$
      .pipe(
        untilComponentDestroyed(this),
        filter(content => !!content)
      )
      .subscribe(
        content => {
          this.organizationContent = content;
          this.setPageStyle();
          this.checkShowIntroPopUp();
        }
      );

    this.gameSessionService.sessionJoinError$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(
        joinError => {
          this.sessionJoinError = joinError;
        }
      );

    this.timeSlotService.currentUserJoinedGameTimeslots$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(
        timeSlots => {
          if (!timeSlots) {
            return;
          }

          this.userUpcomingSessions = timeSlots;
        }
      );

    this.gameSessionService.sessionDoc$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(sessionDoc => {
        this.activeDiscussion = sessionDoc;
      });

    this.idle.idleSubject
      .pipe(
        untilComponentDestroyed(this),
        filter(status => status === 'idle')
      )
      .subscribe(() => {
        this.idle.unsetIdle();
        this.showIdleInfo = true;
      });

    this.socketService.connectionStatus$
      .pipe(
        untilComponentDestroyed(this),
        filter(status => !!status),
      )
      .subscribe((status: string) => {
        if (status === "never-connected") {
          this.notificationService.setNotification({
            id: status,
            message: "no-session.never-connected-short",
            icon: "icon-audio-error",
            personal: true
          });

          // Store Websocket fail to userDoc
          if (!this.currentUser.websocketInfo || (this.currentUser.websocketInfo && this.currentUser.websocketInfo.success)) {
            this.userService.saveUser(
              this.currentUser._id,
              {
                websocketInfo: {
                  success: false
                }
              });
          }

          return;
        }

        if (status === "success") {
          this.notificationService.clearNotification("never-connected");

          // Check if user has failed connection before, and clear if not
          if (!this.currentUser.websocketInfo || (this.currentUser.websocketInfo && !this.currentUser.websocketInfo.success)) {
            this.userService.saveUser(
              this.currentUser._id,
              {
                websocketInfo: {
                  success: true
                }
              });
          }
        }
      });

    this.checkForMacChromeCombo();

    setTimeout(() => {
      // Just in case the sessions still aren't fetched (made basically for one specific user)
      if (!this.userSessions) {
        this.userSessions = [];
      }
    }, 5 * 1000);
  }

  private checkSubOrganizations(): void {
    if (!this.currentUser) {
      return;
    }

    this.subOrganizationsChecked = true;

    // Check sub-organizations
    this.orgService.getAllowedOrganizations(this.currentUser.orgId, this.currentUser.email)
      .pipe(
        first()
      )
      .subscribe(
        subOrganizations => {
          if (!subOrganizations || !subOrganizations.length) {
            return;
          }

          const userIsManager: boolean = this.userService.checkCurrentUserEditPrivileges();

          // Check is current organization is an allowed selection
          const validSubOrganization = !!subOrganizations.find(subOrg => {
            return subOrg._id.toString() === this.currentUser.orgId.toString();
          });

          if (!validSubOrganization && !userIsManager) {
            // Navigate user to select sub-organization
            this.router.navigate(['/greeting']);
          }
        }
      );
  }

  private checkForMacChromeCombo(): void {
    if (!this.currentUser || !this.currentUser.hardwareInfo) {
      // Invalid hardwareInfo
      return;
    }

    if (this.currentUser.hardwareInfo.chromeMacConfirmed) {
      // Use has already seen the Chrome/Mac notification
      return;
    }

    if (this.currentUser.hardwareInfo.browser !== "chrome" && this.currentUser.hardwareInfo.browser !== "chromium") {
      // No using Chrome
      return;
    }

    if (this.currentUser.hardwareInfo.os.indexOf("Macintosh") === -1) {
      // Not using macOS
      return;
    }

    this.notificationService.setNotification({
      id: "chrome-mac-info",
      message: "no-session.chrome-mac-notification",
      icon: "icon-audio-error",
      personal: true
    });
  }
}
