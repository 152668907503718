/**
 * Created by Topi on 02.11.2018
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {User} from "../../data-model/user.type";
import {Organization} from "../../data-model/organization.type";
import {UserService} from "../../core/service/user.service";
import {OrganizationService} from "../../core/service/organization.service";
import {GameSeasonService} from "../../core/service/game-season.service";
import {first} from "rxjs/operators";
import {GeneralUtils} from "../../../lib/general-utils";

@Component({
  selector: 'mini-admin',
  template: `
    <div
      class="pop-up-container mini-admin"
      [ngStyle]="{'width': customWidth}"
      (elementClicked)="clickedOutside($event)"
    >
      <button (click)="emitClose()" class="pop-up-close-button">
        <span class="glyphicon glyphicon-remove"></span>
      </button>

      <ng-container *ngIf="allowedUsersAmount > totalTestUsers">
        <h2 class="pop-up-header">
          {{'test-admin.invite-users' | translate}}
        </h2>

        <div class="gradient-line"></div>

        <div class="mini-admin-container">
        <span>
          {{'test-admin.invite-users-info' | translate: {
          allowedUsers: allowedUsersAmount,
          amountOfUsers: allowedUsersAmount - totalTestUsers
        } }}
        </span>

          <div class="participants-list">
            <ng-container
              *ngFor="let newUser of newUsers; trackBy: trackEmailByIndex; let i = index;">
              <input class="new-user-input" [(ngModel)]="newUsers[i]"
                     [placeholder]="'welcome-view.email-label' | translate">
            </ng-container>
          </div>

          <raised-css-button
            [buttonText]="'test-admin.add-users'"
            (onClick)="inviteTestUsers()"
            [disabled]="checkValidEmails()"
            [fontSize]="'2vmin'"
            [marginTop]="'0'"
            [marginBottom]="'0'"
          ></raised-css-button>
        </div>
      </ng-container>

      <h2 class="pop-up-header">
        {{'test-admin.manage-users' | translate}}
      </h2>

      <div class="gradient-line"></div>

      <div class="mini-admin-container">
        <span>
          {{'test-admin.manage-users-info' | translate}}
        </span>

        <div class="participants-list">
          <div class="existing-user activated" *ngFor="let existingUser of activatedTestUsers">
            {{existingUser.email}}
          </div>

          <div class="gradient-line" *ngIf="createdTestUsers?.length"></div>

          <div class="existing-user" *ngFor="let existingUser of createdTestUsers">
            {{existingUser.email}}

            <raised-css-button
              [buttonText]="(getResendStatus(existingUser._id) ? 'test-admin.re-send-activation-' + getResendStatus(existingUser._id) : 'test-admin.re-send-activation')"
              (onClick)="resendInvitation(existingUser)"
              [disabled]="!!getResendStatus(existingUser._id)"
              [fontSize]="'1.5vmin'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>
          </div>
        </div>
      </div>

      <!--<h2 class="pop-up-header">
        {{'test-admin.select-language' | translate}}
      </h2>

      <div class="gradient-line"></div>

      <div [ngStyle]="{'display': 'flex'}">
        <button class="flag-button select-flag-fi" [ngClass]="{'selected': currentOrganization?.defaultLanguage === 'fi'}" (click)="selectDefaultLanguage('fi')"></button>
        <button class="flag-button select-flag-en" [ngClass]="{'selected': currentOrganization?.defaultLanguage === 'en'}" (click)="selectDefaultLanguage('en')"></button>
      </div>-->
    </div>
  `
})

export class MiniAdminComponent implements OnChanges {
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() allowedElementIds: string[];
  @Input() customWidth: string;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public activatedTestUsers: User[];
  public createdTestUsers: User[];
  public totalTestUsers: number = 0;

  public newUsers: string[];
  public allowedUsersAmount: number = 8;

  public usersFetched: boolean;
  public emailReminderStatuses: { userId: string, status: string }[];

  constructor(private userService: UserService,
              private organizationService: OrganizationService,
              private gameSeasonService: GameSeasonService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser) {

    }

    if (changes.currentOrganization && this.currentOrganization) {
      this.userService.orgUsers$
        .subscribe(testOrgUsers => {
          if (!testOrgUsers) {
            if (!this.usersFetched) {
              this.usersFetched = true;
              this.userService.getOrgUsers(this.currentOrganization._id);
            }

            return;
          }

          this.totalTestUsers = testOrgUsers.length;
          this.newUsers = new Array(this.allowedUsersAmount - testOrgUsers.length).fill("");

          this.activatedTestUsers = [];
          this.createdTestUsers = [];

          testOrgUsers.forEach(user => {
            this.userService.getAuthInfo(user._id)
              .subscribe(info => {
                if (info.activated) {
                  this.activatedTestUsers.push(user);
                }
                else {
                  this.createdTestUsers.push(user);
                }
              });
          })
        });
    }
  }

  public emitClose() {
    this.onClose.emit(false);
  }

  public clickedOutside(elementId: string) {
    if (GeneralUtils.clickedOutside(elementId, this.allowedElementIds)) {
      this.emitClose();
    }
  }

  public inviteTestUsers() {
    for (const newUser of this.newUsers) {
      if (GeneralUtils.isValidEmail(newUser)) {
        this.userService.createLocalUserWithName(this.currentOrganization._id, {
          email: newUser,
          name: "",
          language: ""
        });
      }
    }
  }

  public checkValidEmails(): boolean {
    for (const newUser of this.newUsers) {
      if (GeneralUtils.isValidEmail(newUser)) {
        return true;
      }
    }

    return false;
  }

  public trackEmailByIndex(index: number): any {
    return index;
  }

  public getResendStatus(userId: string): string {
    if (!userId || !this.emailReminderStatuses) {
      return null;
    }

    const statusMessage: { userId: string, status: string } = this.emailReminderStatuses.find(status => {
      return status.userId === userId;
    });

    if (!statusMessage) {
      return null;
    }

    return statusMessage.status;
  }

  public resendInvitation(user: User) {
    if (!this.emailReminderStatuses) {
      this.emailReminderStatuses = [];
    }

    let statusIndex: number = this.emailReminderStatuses.findIndex(status => {
      return status.userId === user._id;
    });

    if (statusIndex === -1) {
      statusIndex = this.emailReminderStatuses.length;

      this.emailReminderStatuses.push({
        userId: user._id,
        status: "sending"
      });
    }
    else {
      this.emailReminderStatuses[statusIndex].status = "sending";
    }

    this.userService.invitation(user._id)
      .pipe(
        first()
      )
      .subscribe(
        () => {
          this.emailReminderStatuses[statusIndex].status = "sent";
        },
        err => {
          console.error("Failed re-sending activation email to user", user._id, err);
          this.emailReminderStatuses[statusIndex].status = "failed";

          // Hide after 10s
          setTimeout(() => {
            this.emailReminderStatuses[statusIndex].status = undefined;
          }, 10000);
        });
  }

  public selectDefaultLanguage(newLanguage: "en" | "fi" | "sv" | "ru") {
    if (!this.currentOrganization || this.currentOrganization.defaultLanguage === newLanguage) {
      return;
    }

    this.currentOrganization.defaultLanguage = newLanguage;
    this.organizationService.updateTestOrgLanguage(this.currentOrganization)
      .subscribe(orgDoc => {
        if (!orgDoc) {
          return;
        }

        this.gameSeasonService.fetchOrganizationSeasons(orgDoc._id);
      });
  }
}
