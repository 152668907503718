import {
  AfterViewInit,
  Directive,
  ElementRef, Input, OnChanges
} from '@angular/core';

@Directive({
  selector: '[auto-resize]'
})
export class AutoResizeDirective implements OnChanges, AfterViewInit {
  @Input('auto-resize') textString: string;
  @Input('rows') rows: number = 2;
  @Input('maxHeight') maxHeight: number;

  constructor(
    private elem: ElementRef
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resize();
    }, 200);
  }

  ngOnChanges(): void {
    this.resize();
  }

  private resize() {
    const textarea: HTMLTextAreaElement = this.elem.nativeElement as HTMLTextAreaElement;

    if (!textarea.rows) {
      textarea.rows = this.rows;
    }

    // Reset textarea height to auto that correctly calculate the new height
    textarea.style.height = 'auto';

    if (!this.maxHeight || this.maxHeight > textarea.scrollHeight) {
      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflow = 'hidden';

      return;
    }

    textarea.style.height = `${this.maxHeight}px`;
    textarea.style.overflow = 'scroll';
    textarea.style.overflowX = 'hidden';
  }
}
