/**
 * Created by janne on 17.10.2016.
 */

import {Observable} from "rxjs";
import {User} from "./user.type";

export interface PlayerStatus {
  online?: boolean;
  ready?: boolean;
  speaking?: boolean;
  writing?: boolean;

  audioConnected?: boolean;
  micEnabled?: boolean;
  micError?: string;
}

export class Player {
  // public isOnline: boolean = false;
  // public isReady: boolean = false;
  // public score: number = 0;

  public reactions: Reaction[] = [];

  public status: PlayerStatus = {
    online: false,
    ready: false,
    speaking: false,
    writing: false,
    audioConnected: false,
    micEnabled: false,
    micError: null
  };

  constructor(public userId: string,
              public user: Observable<User>,
              public spectator: boolean = false) {
  }
}

export interface Reaction {
  targetUserId: string;
  fromUserId: string;
  reactionType: string;
}

export interface ReactionCounter {
  thumbs: number;
  laughs: number;
  bulbs: number;
  confused: number;
}
