/**
 * Created by Topi on 08.09.2017.
 */
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {User} from "../../data-model/user.type";
import {ContentService} from "../../core/service/content.service";

@Component({
  selector: 'language-settings',
  template: `
    <div class="user-settings-container language">
      <button (click)="setLanguage('fi')" *ngIf="!allowedLanguages?.length || allowedLanguages.indexOf('fi') !== -1">
        <span class="flag flag-fi"></span>
      </button>
      
      <button (click)="setLanguage('en')" *ngIf="!allowedLanguages?.length || allowedLanguages.indexOf('en') !== -1">
        <span class="flag flag-en"></span>
      </button>
      
      <button (click)="setLanguage('sv')" *ngIf="!allowedLanguages?.length || allowedLanguages.indexOf('sv') !== -1">
        <span class="flag flag-sv"></span>
      </button>
    </div>
  `
})

export class LanguageChangeBarComponent implements OnChanges, OnDestroy {
  @Input() currentUser: User;
  @Input() noUser: boolean;
  @Input() allowedLanguages: string[];

  @Output() onOpen: EventEmitter<boolean> = new EventEmitter();

  constructor(private translate: TranslateService,
              private contentService: ContentService) {

  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.noUser && this.noUser) {
      const supportedLanguages: string[] = ['en', 'fi', 'sv'];
      const browserLang: string = this.translate.getBrowserLang() || 'en';
      const useLanguage = (supportedLanguages.indexOf(browserLang) !== -1) ? browserLang : supportedLanguages[0];
      this.contentService.setLanguage(useLanguage);
    }
  }

  /**
   * Change language
   * @param {string} lang
   */
  public setLanguage(lang: string): void {
    this.contentService.setLanguage(lang, this.currentUser);
  }
}
