/**
 * Created by Topi on 19.4.2018
 */

import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {IntroAssignmentType} from "../../data-model/introduction-tutorial.type";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {SurveyAnswer} from "../../data-model/survey-answer.type";
import {User} from "../../data-model/user.type";
import {UserService} from "./user.service";

export interface TutorialContent {
  phase: string;
  content: string[];
}

export interface TutorialItem {
  title: string;
  image: string; // Path
  content: TutorialContent;
}

@Injectable({
  providedIn: 'root'
})
export class GameTutorialService { // Also for introduction tutorial
  public tutorialApiRoute: string = "/api/tutorial-assignments/";

  public availableTutorials: TutorialItem[] = [
    {
      title: "discussion-basics",
      image: "/assets/image/tutorial_discussion-basics.svg",
      content: {
        phase: "situation-phases",
        content: [
          "question-general",
          "personal-phase",
          "group-phase",
          "review-phase",
          "clock-info"
        ]
      }
    },
    {
      title: "multi-select-question",
      image: "/assets/image/tutorial_multi-select-questions.svg",
      content: {
        phase: "multi-select-phases",
        content: [
          "multi-select-general",
          "multi-select-group",
          "multi-select-review"
        ]
      }
    },
    {
      title: "scaling-question",
      image: "/assets/image/tutorial_scaling-question.svg",
      content: {
        phase: "scaling-phases",
        content: [
          "scaling-general",
          "scaling-suggestions",
          "scaling-square",
          "scaling-line"
        ]
      }
    },
    {
      title: "poll-question",
      image: "/assets/image/tutorial_poll-question.svg",
      content: {
        phase: "poll-phases",
        content: [
          "poll-personal-phase",
          "poll-group-phase"
        ]
      }
    }
  ];

  public webSocketError$: BehaviorSubject<string> = new BehaviorSubject(null);
  public userMediaError$: BehaviorSubject<string> = new BehaviorSubject(null);
  public tutorialAssignments$: BehaviorSubject<IntroAssignmentType[]> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  // SESSION TUTORIAL

  public testWebSocket() {
    const protocol: string = location.protocol === 'https:' ? 'wss:' : 'ws:';
    let hostname: string;
    if (window.location.port === "80" || window.location.port === "443") {
      hostname = window.location.hostname;
    } else {
      hostname = window.location.hostname + ":" + window.location.port;
    }

    // const ws = new WebSocket('wss://experience.loge.fi/?tutorial=test');
    const ws = new WebSocket(`${protocol}//${hostname}/?type=test`);
    ws.binaryType = 'arraybuffer';

    const tutorialService = this;

    ws.onerror = function () {
      tutorialService.webSocketError$.next('error');
    };

    ws.onmessage = function () {
      tutorialService.webSocketError$.next('success');
      ws.close();
    };

    ws.onopen = function () {
      ws.send("Test");
    };
  }

  public testUserMedia() {
    const tutorialService = this;

    const userMediaSuccess = function (stream) {
      tutorialService.userMediaError$.next('success');
      stream.getAudioTracks()[0].stop();
    };

    const userMediaFailed = function (err) {
      tutorialService.userMediaError$.next(err.name);
    };

    navigator.getUserMedia({audio: true, video: false}, userMediaSuccess, userMediaFailed);
  }


  // INTRODUCTION TUTORIAL

  public setIntroTutorialAsCompleted(identifier: string, user: User): void {
    if (!user || !identifier) {
      return;
    }

    if (!user.completedIntroTutorials) {
      user.completedIntroTutorials = [];
    }

    const existingTutorial = user.completedIntroTutorials.find(item => {
      return item.identifier === identifier;
    });

    if (existingTutorial && existingTutorial.completed) {
      // Already completed
      return;
    }

    const newCompletedAssignment = existingTutorial ? existingTutorial : this.getIntroTutorialAssignment(identifier);

    if (!newCompletedAssignment) {
      // No valid assignment
      return;
    }

    newCompletedAssignment.completed = true;

    this.userService.saveUser(
      user._id,
      {
        newCompletedIntroTutorial: newCompletedAssignment
      }
    );
  }

  public getIntroTutorialAssignment(identifier: string): IntroAssignmentType {
    if (!identifier || !this.tutorialAssignments$.value) {
      return null;
    }

    return this.tutorialAssignments$.value.find(item => {
      return item.identifier === identifier;
    });
  }

  public getTutorialAssignments() {
    this.http.get(this.tutorialApiRoute)
      .subscribe(
        res => {
          this.tutorialAssignments$.next(res as IntroAssignmentType[]);
        },
        (err: HttpErrorResponse) => {
          console.error("Failed getting tutorial assignments", err);
          this.tutorialAssignments$.next(null);
        }
      );
  }

  public saveTutorialAssignment(assignment: IntroAssignmentType) {
    const route = assignment._id ? this.tutorialApiRoute + assignment._id : this.tutorialApiRoute;
    const body = JSON.stringify(assignment);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(route, body, options)
      .subscribe(
        () => {
          this.getTutorialAssignments();
        },
        err => {
          console.error("Failed saving tutorial assignment", err);
        }
      );
  }

  public saveTutorialEndingAnswers(answer: SurveyAnswer): void {
    const route = this.tutorialApiRoute + "save-user-answer";
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    this.http.post(route, answer, options)
      .subscribe(
        () => {
          // Saved
        },
        err => {
          console.error("Failed saving tutorial ending answer", err);
        }
      );
  }

  public checkCompletion(user: User, assignments: IntroAssignmentType[]): IntroAssignmentType[] {
    if (!user || !assignments || !assignments.length) {
      return;
    }

    if (user.completedIntroTutorials && user.completedIntroTutorials.length) {
      assignments.forEach(item => {
        const match = user.completedIntroTutorials.find(userItem => {
          return userItem.identifier === item.identifier;
        });

        if (match) {
          item.completed = match.completed;
        }
      });
    }

    return assignments;
  }

  public getSituationTypeTutorial(type: number, phase?: string): TutorialItem {
    if (type === 0) {
      return this.availableTutorials.find(item => {
        return item.title === "multi-select-question";
      });
    }

    if (type === 2) {
      return this.availableTutorials.find(item => {
        return item.title === "scaling-question";
      });
    }

    if (type === 3) {
      return this.availableTutorials.find(item => {
        return item.title === "poll-question";
      });
    }

    return;
  }
}
