import {Injectable} from '@angular/core';
import * as d3 from 'd3';

import {NetworkItemType} from "../../data-model/network-item.type";
import {ForceDirectedGraph} from "../../shared/component/network-graph/force-directed-graph";

@Injectable({
  providedIn: 'root'
})
export class D3Service {
  constructor() {

  }

  applyDraggableBehaviour(element, node: NetworkItemType, graph: ForceDirectedGraph, elementRadius: number) {
    const d3element = d3.select(element);

    function started() {
      d3.event.sourceEvent.stopPropagation();

      // Other elements move away from moved object
      if (!d3.event.active) {
        graph.simulation.alphaTarget(0.3).restart();
      }

      d3.event
        .on('drag', dragged)
        .on('end', ended);

      function dragged() {
        // Only allow dragging inside a circle container

        const containerRadius = graph.graphSize.width / 2;

        const x = d3.event.x - containerRadius;
        const y = d3.event.y - containerRadius;

        const l = Math.sqrt(x * x + y * y);
        const l_in = Math.min(containerRadius - elementRadius, l);

        node.fx = x / l * l_in + containerRadius;
        node.fy = y / l * l_in + containerRadius;
      }

      function ended() {
        if (!d3.event.active) {
          graph.simulation.alphaTarget(0);
        }

        node.fx = null;
        node.fy = null;
      }
    }

    d3element
      .call(d3.drag()
        .on('start', started));
  }
}
