import {Injectable} from "@angular/core";
import {Observable, Subject, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {GameTimeslot} from "../../data-model/timeslot.type";
import {GameSeason} from "../../data-model/game-season.type";
import {GameSessionService} from "./game-session.service";
import {GameSession} from "../../data-model/game-session.interface";
import Socket = SocketIOClient.Socket;
import { LogeNotification } from "./notification.service";

export interface DataUpdate {
  id?: string; // For confirming

  type: string;
  subType?: string;
  orgId?: string;

  // type: timeSlot
  timeSlot?: GameTimeslot;

  // type: gameSeason
  gameSeason?: GameSeason;
  createdTimeSlots?: GameTimeslot[];
  onGoingDiscussions?: GameSession[];
  lobbyDiscussions?: GameSession[];
  maxDuration?: number;

  // type: gameSession
  gameSession?: GameSession;

  // type: sessionStarted
  sessionId?: string;

  // type: notification
  notification?: LogeNotification;

  misc?: {
    cloneIds?: string[],
    forceStartedBy?: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class DataUpdateService {
  public connectionStatus$: Subject<string> = new Subject<string>();
  public hasConnected: boolean;
  private IOSocket: Socket;

  private retryAmount: number = 0;

  constructor(
    private http: HttpClient,
    private gameSessionService: GameSessionService
  ) {
  }

  // Admin-only
  public sendNotification(notification: DataUpdate): void {
    this.IOSocket.emit("notification", notification);
    // this.webSocketSubject.next(notification);
  }

  // public init() {
  //   if (this.retryAmount === 0) {
  //     this.hasConnected = false;
  //   }
  //
  //   const openObserver: NextObserver<Event> = {
  //     next: () => {
  //       this.connectionStatus$.next("success");
  //       this.hasConnected = true;
  //       this.retryAmount = 0;
  //     }
  //   };
  //
  //   const closeObserver: NextObserver<CloseEvent> = {
  //     next: (closeEvent) => {
  //       if (closeEvent.code === 1000) {
  //         // Normal disconnection
  //         return;
  //       }
  //
  //       // Try reconnecting
  //       console.log("Disconnected from data-update socket, retry connection", closeEvent.code);
  //       this.retryConnection();
  //     }
  //   };
  //
  //   const protocol: string = location.protocol === 'https:' ? 'wss:' : 'ws:';
  //   let hostname: string;
  //   if (window.location.port === "80" || window.location.port === "443") {
  //     hostname = window.location.hostname;
  //   } else {
  //     hostname = window.location.hostname + ":" + window.location.port;
  //   }
  //
  //   this.createDataToken()
  //     .subscribe(
  //       token => {
  //         this.IOSocket = io(
  //           `${protocol}//${hostname}/?type=data-update&token=${token}`
  //         );
  //
  //         this.IOSocket.on("connect", () => {
  //           this.connectionStatus$.next("success");
  //           this.IOSocket$.next(this.IOSocket);
  //           this.hasConnected = true;
  //           this.retryAmount = 0;
  //         });
  //
  //         this.IOSocket.on("data-update", (update) => {
  //           this.confirmReceived(update);
  //
  //           if (update.type === "timeSlot") {
  //             this.timeSlotUpdate$.next(update);
  //             return;
  //           }
  //
  //           if (update.type === "gameSeason") {
  //             this.gameSeasonUpdate$.next(update);
  //             return;
  //           }
  //
  //           if (update.type === "addedToSession" || update.type === "gameSession") {
  //             this.gameSessionService.getCurrentSession(true);
  //             return;
  //           }
  //         });
  //
  //         this.IOSocket.on("disconnect", (reason) => {
  //           console.log("Disconnected from data-update socket", reason);
  //         });
  //       });
  // }

  private createDataToken(): Observable<string> {
    return this.http.get<{ token: string }>("/api/user/create-data-token/")
      .pipe(
        map(res => {
          return res.token;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private retryConnection(waitTime: number = 1000): void {
    if (this.retryAmount > 10) {
      console.error("Tried reconnecting to data-update socket 10 times, stop trying");
      return;
    }

    if (this.retryAmount === 5 && !this.hasConnected) {
      // Show failed to connect notification
      this.connectionStatus$.next("never-connected");
    }

    console.log("Retry connection to data-update socket, try no.", this.retryAmount + "/10");

    setTimeout(() => {
      this.retryAmount += 1;

      if (this.IOSocket) {
        this.IOSocket.disconnect();
      }

      // this.init();
    }, waitTime * this.retryAmount);
  }

  private confirmReceived(update: DataUpdate): void {
    /*this.webSocketSubject.next({
      id: update.id,
      type: update.type
    });*/
  }
}
