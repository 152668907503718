import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {
  GeneralFeedbackAnswer,
  Measure,
  MeasureAnswer
} from "../../data-model/measure-question.interface";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  // public orgMeasures$: BehaviorSubject<Measure[]> = new BehaviorSubject(undefined);
  public orgDefaultMeasures$: BehaviorSubject<Measure[]> = new BehaviorSubject(undefined);
  public orgDiscussionMeasures$: BehaviorSubject<Measure[]> = new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient
  ) {

  }

  public getMeasures(query: Object): Observable<Measure[]> {
    return this.http.put('api/measure/organization-measures', query)
      .pipe(
        map(res => {
          return res as Measure[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getMeasureAnswers(query: Object): Observable<MeasureAnswer[]> {
    return this.http.put('api/measure/measure-answers', query)
      .pipe(
        map(res => {
          return res as MeasureAnswer[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public fetchOrgDefaultMeasures(orgId: string): void {
    this.http.put('api/measure/organization-default-measures', {orgId: orgId})
      .subscribe(
        (value: Measure[]) => {
          this.orgDefaultMeasures$.next(value);
        },
        (err: HttpErrorResponse) => {
          console.error("Error fetching organization measures", orgId, err);
          this.orgDefaultMeasures$.next([]);
        });
  }

  public fetchOrgDiscussionMeasures(orgId: string): void {
    this.http.put('api/measure/discussion-measure-questions', {orgId: orgId})
      .subscribe(
        (value: Measure[]) => {
          this.orgDiscussionMeasures$.next(value);
        },
        (err: HttpErrorResponse) => {
          console.error("Error fetching organization measures", orgId, err);
          this.orgDiscussionMeasures$.next([]);
        });
  }

  public updateDefaultMeasures(measures: Measure[], orgId: string): void {
    this.http.post('api/measure/update-default-measures/' + orgId, measures)
      .subscribe(
        (value: Measure[]) => {
          this.orgDefaultMeasures$.next(value);
        },
        (err: HttpErrorResponse) => {
          console.error("Error updating organization measures", measures, err);
          // this.orgMeasures$.next([]);
        });
  }

  public updateDiscussionMeasures(measures: Measure[], orgId: string): void {
    this.http.post('api/measure/update-discussion-measures/' + orgId, measures)
      .subscribe(
        () => {
          this.fetchOrgDiscussionMeasures(orgId);
        },
        (err: HttpErrorResponse) => {
          console.error("Error updating organization measures", measures, err);
          // this.orgMeasures$.next([]);
        });
  }

  public removeMeasures(measures: Measure[], orgId: string): void {
    this.http.post('api/measure/remove-measures/', measures)
      .subscribe(
        () => {
          this.fetchOrgDiscussionMeasures(orgId);
        },
        (err: HttpErrorResponse) => {
          console.error("Error updating organization measures", measures, err);
        });
  }

  public submitMeasureAnswers(measureAnswers: MeasureAnswer[]): Observable<MeasureAnswer[]> {
    return this.http.post('api/measure/submit-measure-answers/', measureAnswers)
      .pipe(
        map(res => {
          return res as MeasureAnswer[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateMeasureCompletion(measureUpdate: MeasureAnswer): Observable<MeasureAnswer> {
    return this.http.post('api/measure/update-measure-answer/', measureUpdate)
      .pipe(
        map(res => {
          return res as MeasureAnswer;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public submitGeneralFeedback(generalFeedback: GeneralFeedbackAnswer): Observable<GeneralFeedbackAnswer> {
    return this.http.post('api/general-feedback/submit-general-feedback/', generalFeedback)
      .pipe(
        map(res => {
          return res as GeneralFeedbackAnswer;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getGeneralFeedback(query: Object, populate?: string): Observable<GeneralFeedbackAnswer[]> {
    return this.http.put('api/general-feedback/get-general-feedback/', {query: query, populate: populate})
      .pipe(
        map(res => {
          return res as GeneralFeedbackAnswer[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
