import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserService } from "./user.service";
import { User } from "../../data-model/user.type";
import { filter } from "rxjs/operators";
import { NotificationService } from "./notification.service";
import { TranslateService } from "@ngx-translate/core";

// Group input and output from a device as once
export interface AudioDevice {
  readonly label: string;
  readonly groupId: string;

  isDefault?: boolean;
  isCommunications?: boolean;

  audioinput?: DeviceInfo;
  audiooutput?: DeviceInfo;
  videoinput?: DeviceInfo; // unused
}

// Basically InputDeviceInfo | MediaDeviceInfo, but without functions
// Stored in database for users
export interface DeviceInfo {
  readonly deviceId: string;
  readonly groupId: string;
  readonly kind: MediaDeviceKind;
  readonly label: string;
}

export interface AudioDeviceInfo {
  input: DeviceInfo;
  output: DeviceInfo;
}

export interface AvailableDevices {
  input: DeviceInfo[];
  output: DeviceInfo[];
}

@Injectable({
  providedIn: "root"
})
export class AudioDeviceService {
  public availableDevices: AvailableDevices = {
    input: [],
    output: []
  };

  public selectedDevices: AudioDeviceInfo = {
    input: undefined,
    output: undefined
  };

  public audioDevices$: BehaviorSubject<AudioDevice[]> = new BehaviorSubject<AudioDevice[]>([]);

  public deviceError$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public selectedDevices$: BehaviorSubject<AudioDeviceInfo> = new BehaviorSubject<AudioDeviceInfo>(this.selectedDevices);
  public availableDevices$: BehaviorSubject<AvailableDevices> = new BehaviorSubject<AvailableDevices>(this.availableDevices);
  public newDevice$: BehaviorSubject<AudioDeviceInfo> = new BehaviorSubject<AudioDeviceInfo>(undefined);

  private currentUser: User;

  private static testApiSupport(): boolean {
    if (navigator.mediaDevices &&
      (navigator.mediaDevices.getUserMedia
        || (navigator as any).mediaDevices.webkitGetUserMedia
        || (navigator as any).mediaDevices.mozGetUserMedia
        || (navigator as any).mediaDevices.msGetUserMedia)) {
      return true;
    }

    console.error("Browser does not have MediaDevices API support");
    return false;
  }

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.userService.currentUser$
      .subscribe((currentUser: User) => {
        this.currentUser = currentUser;
      });

    this.userService.currentUser$
      .pipe(
        filter(user => {
          if (!user?.audioSettings) {
            return false;
          }

          return (
            user.audioSettings.input?.deviceId !== this.selectedDevices.input?.deviceId ||
            user.audioSettings.output?.deviceId !== this.selectedDevices.output?.deviceId
          );
        })
      )
      .subscribe(() => {
        this.checkUserDeviceValidity();
      });

    if (!navigator?.mediaDevices) {
      console.error("MediaDevices not supported!");
      this.deviceError$.next("media-devices.not-supported");

      return;
    }

    // Get devices when starting
    this.getDevices().then(() => true);

    // Fires when a new input/output device is connected
    navigator.mediaDevices.ondevicechange = () => {
      this.getDevices().then(() => true);
    };
  }

  public checkUserDeviceValidity(): void {
    if (!this.currentUser) {
      // No user
      return;
    }

    if (!this.availableDevices.input.length && !this.availableDevices.output.length) {
      // No devices to check
      return;
    }

    const previouslySelectedDevices: AudioDeviceInfo = this.currentUser.audioSettings || {input: null, output: null};

    if (previouslySelectedDevices.input) {
      const selectedInputExists: boolean = !!this.availableDevices.input.find((input: DeviceInfo) => {
        return input.deviceId === previouslySelectedDevices.input.deviceId;
      });

      if (!selectedInputExists) {
        console.warn("Previously used microphone no longer connected, using first one in list.", previouslySelectedDevices?.input);
        previouslySelectedDevices.input = null;
      }
    }

    if (!previouslySelectedDevices.input) {
      // Use first one in list
      previouslySelectedDevices.input = this.availableDevices.input[0];
    }

    if (previouslySelectedDevices.output) {
      const selectedOutputExists: boolean = !!this.availableDevices.output.find((output: DeviceInfo) => {
        return output.deviceId === previouslySelectedDevices.output.deviceId;
      });

      if (!selectedOutputExists) {
        console.warn("Previously used headphones/speaker no longer connected, using first one in list", previouslySelectedDevices?.output);
        previouslySelectedDevices.output = null;
      }
    }

    if (!previouslySelectedDevices.output) {
      // Use first one in list
      previouslySelectedDevices.output = this.availableDevices.output[0];
    }

    // Set user devices
    this.selectedDevices = previouslySelectedDevices;
    this.selectedDevices$.next(this.selectedDevices);
  }

  public getDeviceName(label: string): string {
    if (!label) {
      return "";
    }

    // No brackets, likely Default or Communications
    if (label.indexOf("(") === -1) {
      return label;
    }

    // There's a pair of brackets inside the name, ie. "Realtek(R) Audio"
    if (label.split("(").length - 1 >= 3) {
      const step1: string = label.substr(label.indexOf("("), label.length - 1);
      return step1.substr(1, step1.lastIndexOf(")") - 1);
    }

    // The device name is between the first pair of brackets
    return label
      .split("(")[1]
      .split(")")[0];
  }

  public checkDevices(
    deviceInfo: (InputDeviceInfo | MediaDeviceInfo)[],
    type: MediaDeviceKind,
    skipDeviceIds: string[] = ["default", "communications"]
  ): DeviceInfo[] {
    const audioDeviceList: AudioDevice[] = [];

    for (const device of deviceInfo) {
      if (device.kind === "videoinput") {
        // Video not used
        continue;
      }

      let groupIdIndex: number = audioDeviceList.findIndex(prevDevice => prevDevice.groupId === device.groupId);

      // New device, add to list
      if (groupIdIndex === -1) {
        groupIdIndex = audioDeviceList.length;

        audioDeviceList.push({
          label: this.getDeviceName(device.label),
          groupId: device.groupId
        });
      }

      audioDeviceList[groupIdIndex][device.kind] = device;

      if (device.deviceId === "default") {
        audioDeviceList[groupIdIndex].isDefault = true;
      }

      if (device.deviceId === "communications") {
        audioDeviceList[groupIdIndex].isCommunications = true;
      }
    }

    // Get devices that match type and don't match skipped IDs
    const matchingDevices: DeviceInfo[] = deviceInfo.filter(info => info.kind === type && skipDeviceIds.indexOf(info.deviceId) === -1) as DeviceInfo[];

    // Get input/output list to compare to
    const compareNewTo: DeviceInfo[] = type === "audioinput" ? this.availableDevices.input : this.availableDevices.output;

    // If no devices detected previously, no need to check for new devices
    if (!compareNewTo.length) {
      return matchingDevices;
    }

    // Find new devices
    const newDevices: DeviceInfo[] = matchingDevices.filter((newDevice) => {
      return !compareNewTo.find((prevDevice) => newDevice.deviceId === prevDevice.deviceId);
    });

    if (newDevices?.length) {
      console.log("Detected new audio device(s)", type, newDevices);
      for (const device of newDevices) {
        this.notificationService.setNotification({
          id: device.deviceId,
          title: "audio-device-test.notification-title",
          message_html: "audio-device-test.notification-text-" + (device.kind === "audioinput" ? "input" : "output"),
          personal: true,
          confirm: true,
          customParams: {
            device: device,
            translateParams: {
              name: this.getDeviceName(device.label)
            }
          }
        });
      }

      // this.newDevice$.next(newDevices);
    }

    return matchingDevices;
  }

  public async getDevices(skipDeviceIds: string[] = ["default", "communications"]): Promise<AvailableDevices> {
    if (!navigator?.mediaDevices) {
      this.deviceError$.next("media-devices.not-supported");

      this.notificationService.setNotification({
        id: "media-devices.not-supported",
        title: "media-devices.error-title",
        message: "media-devices.not-supported",
        personal: true
      });

      return Promise.reject({ error: "MediaDevices not supported" });
    }

    await navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos: (InputDeviceInfo | MediaDeviceInfo)[]) => {
        const inputDevices: DeviceInfo[] = this.checkDevices(deviceInfos, "audioinput", skipDeviceIds);
        const outputDevices: DeviceInfo[] = this.checkDevices(deviceInfos, "audiooutput", skipDeviceIds);

        this.availableDevices.input = inputDevices;
        this.availableDevices.output = outputDevices;

        let errorMsg: string;
        if (!this.availableDevices.input.length && !this.availableDevices.output.length) {
          errorMsg = "media-devices.no-devices";
        } else if (!this.availableDevices.input.length) {
          errorMsg = "media-devices.no-input-devices";
        } else if (!this.availableDevices.output.length) {
          errorMsg = "media-devices.no-output-devices";
        }

        if (errorMsg) {
          this.deviceError$.next(errorMsg);

          this.notificationService.setNotification({
            id: errorMsg,
            title: "media-devices.error-title",
            message: errorMsg,
            personal: true
          });
        }

        this.checkUserDeviceValidity();
      })
      .catch((err) => {
        console.error("Unable to get mediaDevices", err);
        this.deviceError$.next("media-devices.enumerate-failed");

        this.notificationService.setNotification({
          id: "media-devices.enumerate-failed",
          title: "media-devices.error-title",
          message: "media-devices.enumerate-failed",
          personal: true
        });
      });

    this.availableDevices$.next(this.availableDevices);

    return this.availableDevices;
  }

  public switchVoiceDevice(type: "input" | "output", device?: DeviceInfo): void {
    if (!device) { // Switch to next device if no device given
      if (!this.availableDevices[type]?.length) {
        // No known available devices
        console.warn("Couldn't switch " + type + " device: No devices!");
        return;
      }

      // Find current index
      let currentIndex: number = this.selectedDevices[type]
        ? this.availableDevices[type].findIndex(device => device.deviceId === this.selectedDevices[type].deviceId)
        : -1;

      if (currentIndex >= this.availableDevices[type].length - 1) {
        // Start from beginning
        currentIndex = -1;
      }

      this.selectedDevices[type] = this.availableDevices[type][currentIndex + 1];
    } else { // Switch to wanted device
      this.selectedDevices[type] = device;
    }

    this.selectedDevices$.next(this.selectedDevices);
  }

  public saveUserAudioDeviceSelection(type?: "input" | "output"): void {
    if (!this.currentUser) {
      console.error("Failed saving Input/Output device selection: No user");
      return;
    }

    // Get previous settings
    const audioSettingChanges: AudioDeviceInfo = this.currentUser.audioSettings || this.selectedDevices;

    if (!type || type === "input") {
      // Save input changes
      audioSettingChanges.input = this.selectedDevices.input;
    }

    if (!type || type === "output") {
      // Save output changes
      audioSettingChanges.output = this.selectedDevices.output;
    }

    this.userService.saveUser(
      this.currentUser._id,
      {
        audioSettings: audioSettingChanges
      }
    );
  }

  public stopUserMediaStream(stream: MediaStream): void {
    if (!stream) {
      return;
    }

    const tracks: MediaStreamTrack[] = stream.getTracks();

    for (const track of tracks) {
      track.stop();
    }
  }

  public getUserMediaStream(constrains?: MediaStreamConstraints): Promise<{
    stream?: MediaStream,
    error?: string
  }> {
    if (!AudioDeviceService.testApiSupport()) {
      return new Promise(resolve => {
        resolve({
          error: "no-api-support"
        });
      });
    }

    if (!constrains) {
      // Use defaults
      constrains = {
        audio: {
          sampleRate: 48000
        },
        video: false
      };
    }

    // getUserMedia might be different depending on browser and version
    if (!navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia
        || (navigator as any).mediaDevices.webkitGetUserMedia
        || (navigator as any).mediaDevices.mozGetUserMedia
        || (navigator as any).mediaDevices.msGetUserMedia;
    }

    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia(
        constrains)
        .then(stream => {
          resolve({
            stream: stream
          });
        })
        .catch(err => {
          console.error("Failed to get user media for mic test", err);
          let errorMsg: string;

          if (err.name === "PermissionDismissedError" || err.name === "NotAllowedError") {
            errorMsg = "audio-chat-info.mic-blocked-short";
          } else if (err.name === "NotFoundError") {
            errorMsg = "audio-chat-info.mic-not-found";
          } else if (err.name === "NotReadableError") {
            errorMsg = "audio-chat-info.mic-not-readable";
          } else {
            console.error("Unknown error from getUserMedia", err.message);
            errorMsg = "audio-chat-info.mic-unknown-error";
          }

          this.notificationService.setNotification({
            id: errorMsg,
            title: "media-devices.error-title",
            message: errorMsg,
            personal: true,
            customParams: errorMsg === "audio-chat-info.mic-unknown-error" ? {
              errorMsg: err.message
            } : {}
          });

          resolve({
            error: errorMsg
          });

          if (errorMsg === "audio-chat-info.mic-unknown-error") {
            // Log to server
            throw new Error(err);
          }
        });
    });
  }
}
