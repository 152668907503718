/**
 * Created by janne on 2.9.2016.
 */
import {Component, Input} from '@angular/core';
import {ClockComponent} from "./clock.component";

@Component({
  selector: 'clock-round',
  template: `
    <div class="clock-container" [ngStyle]="customSize && {width: customSize, height: customSize}">
      <svg
        *ngIf="!hideCircle" class="clock-face"
        [attr.width]="'100%'"
        [attr.height]="'100%'"
        [attr.viewBox]="'0 0 100 100'"
      >
        <circle
          [attr.cx]="50"
          [attr.cy]="50"
          [attr.r]="50 - barWidth / 2"
          [attr.stroke-width]="barWidth"
          fill="none"
          class="progressbar-bg"
        />
        <path
          [attr.d]="arcData"
          [attr.stroke-width]="barWidth"
          fill="none"
          class="progressbar"
        />
      </svg>

      <div class="overlay">
        <div class="time-text unselectable">{{timeText}}</div>
      </div>
    </div>
  `
})
export class ClockRoundComponent extends ClockComponent {
  @Input() customSize: string | number;
  @Input() barWidth: number = 10;
  @Input() hideCircle: boolean;

  arcX: number;
  arcY: number;
  arcData: string;

  /**
   * Update clock arc
   */
  protected update(): void {
    super.update();

    if (!this.barWidth) {
      return;
    }

    const t: number = 1 - (this.timeNormalized > 0 ? this.timeNormalized : 0.0);

    const arcSize: number = (100 - this.barWidth);
    this.arcX = ((Math.sin(((1 - t) * 2 * Math.PI) + Math.PI) + 1) / 2) * arcSize + this.barWidth / 2;
    this.arcY = ((Math.cos(((1 - t) * 2 * Math.PI) + Math.PI) + 1) / 2) * arcSize + this.barWidth / 2;

    const arcRadius: number = 50 - this.barWidth / 2;
    this.arcData = `
      M ${50} ${this.barWidth / 2}
      A ${arcRadius} ${arcRadius} 0 ${t < 0.5 ? '0' : '1'} ${t < 0.5 ? '1' : '1'} ${this.arcX} ${this.arcY}
    `;
  }
}
