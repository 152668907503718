/**
 * Created by Nedu on 11.7.2017.
 */

import {Injectable} from "@angular/core";
import {EmailBody, CalendarBody} from "../../data-model/email.interface";
import {BehaviorSubject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EmailEditorService {

  public emailBodies$ = new BehaviorSubject<EmailBody[]>(null);
  public calendarBodies$ = new BehaviorSubject<CalendarBody[]>(null);

  constructor(private http: HttpClient) {
  }

  // Email body

  /**
   * Get email contents
   * @param {string} orgId
   */
  public getAllEmailBodies(orgId: string): void {
    this.http.get('/api/email/' + orgId)
      .subscribe(
        res => {
          this.emailBodies$.next(res as EmailBody[]);
        },
        err => {
          console.error("Fetching EmailBodies failed", err);
          this.emailBodies$.next(null);
        }
      );
  }

  /**
   * Save email contents
   * @param {EmailBody} body
   */
  public saveEmailBody(body: EmailBody): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};
    this.http.post('/api/email/save/', body, options)
      .subscribe(
        res => this.getAllEmailBodies(body.orgId),
        err => this.getAllEmailBodies(body.orgId)
      );
  }

  // Calendar links

  /**
   * Get calendar event contents
   * @param {string} orgId
   */
  public getCalendarData(orgId: string): void {
    this.http.get('/api/calendar/' + orgId)
      .subscribe(
        res => {
          this.calendarBodies$.next(res as CalendarBody[]);
        },
        err => {
          console.error("Fetching EmailBodies failed", err);
          this.calendarBodies$.next(null);
        }
      );
  }

  /**
   * Save calendar event contents
   * @param {CalendarBody} body
   */
  public saveCalendarBody(body: CalendarBody): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};
    this.http.post('/api/calendar/save/', body, options)
      .subscribe(
        res => this.getCalendarData(body.orgId),
        err => this.getCalendarData(body.orgId)
      );
  }
}
