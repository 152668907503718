import {
  Component, Input, OnChanges, SimpleChanges
} from '@angular/core';

@Component({
  selector: 'circle-graph',
  template: `
    <div class="circle-container"
         [ngStyle]="{'background': emptyColor, 'width': circleSize, 'height': circleSize}"
         [ngClass]="{'organization-color': organizationColor}"
    >

      <!--Create half-circle if percentage is over 50-->
      <div class="percentage-container" *ngIf="percentageInDegrees > 180"
           [ngStyle]="{'left': halfCircleSize, 'height': circleSize}"
      >
        <div class="slice"
             [ngClass]="{'organization-color': organizationColor}"
             [ngStyle]="{
                  'transform': 'rotate(180deg)', 'background': fillColor,
                  'width': halfCircleSize,
                  'height': circleSize,
                  'left': '-' + halfCircleSize,
                  'border-radius': halfCircleSize + ' 0 0 ' + halfCircleSize
                  }"
        ></div>
      </div>

      <!--Create half-circle if percentage is over 50-->
      <div class="percentage-container"
           [ngStyle]="{'transform': 'rotate(' + (percentageInDegrees > 180 ? 180 : 0) + 'deg)', 'left': halfCircleSize, 'height': circleSize}">
        <div class="slice"
             [ngClass]="{'organization-color': organizationColor}"
             [ngStyle]="{
                  'transform': 'rotate(' + (percentageInDegrees > 180 ? (percentageInDegrees - 180) : percentageInDegrees) + 'deg)',
                  'background': fillColor,
                  'width': halfCircleSize,
                  'height': circleSize,
                  'left': '-' + halfCircleSize,
                  'border-radius': halfCircleSize + ' 0 0 ' + halfCircleSize
                  }"
        ></div>
      </div>

      <div class="overlay-circle"
           [ngStyle]="{'background': overlayColor}"
           [ngClass]="{'organization-color': organizationColor}"
      >
        <div class="text-container" [ngStyle]="{'font-size': valueTextSize}">
          <ng-container *ngIf="!showPercentage">
            {{chartValue}}<ng-container *ngIf="showMaxValue">/{{maxValue}}</ng-container>
          </ng-container>
          <ng-container *ngIf="showPercentage">
            {{+(percentageValue).toFixed(1) + "%"}}
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class CircleGraphComponent implements OnChanges {

  @Input() chartValue: number;
  @Input() maxValue: number;

  @Input() circleSize: string;
  @Input() halfCircleSize: string;

  @Input() showMaxValue: boolean;
  @Input() showPercentage: boolean;

  public percentageInDegrees: number = 0;
  public percentageValue: number;

  @Input() fillColor: string;
  @Input() emptyColor: string;
  @Input() organizationColor: boolean;
  @Input() overlayColor: string;

  @Input() valueTextSize: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['chartValue'] || changes['maxValue']) && this.chartValue >= 0 && this.maxValue >= this.chartValue) {
      this.percentageInDegrees = (this.maxValue === 0) ? 0 : 360 * (this.chartValue / this.maxValue);
      this.percentageValue = (this.maxValue === 0) ? 0 : 100 * (this.chartValue / this.maxValue);
    }
  }
}
