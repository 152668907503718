import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from "@angular/router";
import {UserService} from "../core/service/user.service";
import {first} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: 'activation-view',
  template: `
    <div class="floating-user-button-bar">
      <user-button-bar></user-button-bar>
    </div>

    <div class="login-area">
      <div class="login-flex" *ngIf="!activationStatus">
        <h1>{{'activation-view.title' | translate}}</h1>

        <div class="instructions">
          {{'activation-view.instructions' | translate}}
        </div>

        <p *ngIf="error && error=='mismatch'" class="text-danger">{{'activation-view.error-mismatch' | translate}}</p>
        <p *ngIf="error && error=='empty'" class="text-danger">{{'activation-view.error-empty' | translate}}</p>
        <p *ngIf="error && error=='requirements-not-met'" class="text-danger">{{'activation-view.error-too-short' | translate}}</p>

        <div class="form">
          <div class="form-group">
            <div class="form-label">{{'activation-view.password-label' | translate}}</div>
            <input class="text-input" name="password" id="pwdInput" type="password" (keydown)="enterActivate($event, 'submit')"
                   [(ngModel)]="password">
          </div>
          <div class="form-group" [ngStyle]="{marginTop: '1vh'}">
            <div class="form-label">{{'activation-view.confirm-label' | translate}}</div>
            <input class="text-input" name="password" id="pwdConfirm" type="password" (keydown)="enterActivate($event, 'submit')"
                   [(ngModel)]="passwordConfirmation">
          </div>
        </div>

        <div class="flex-row-centered" [ngStyle]="{marginTop: '2vh', 'font-size': '1.5vmin'}">
          <span class="custom-checkbox" [ngStyle]="{marginLeft: 0, marginRight: '1vh', cursor: 'pointer'}" (click)="privacyPolicyAccepted = !privacyPolicyAccepted">
            <span *ngIf="privacyPolicyAccepted" class="glyphicon glyphicon-ok"></span>
          </span>

          <h3 [ngStyle]="{margin: 0}">{{'greeting-view.privacy-policy-prompt' | translate}}</h3>

          <a target="_blank" href="/static/legal/Privacy_policy_LOGE.pdf" title="{{'player-progress-view.in-new-tab' | translate}}">
            <h3 [ngStyle]="{margin: 0, marginLeft: '0.7vh', 'text-decoration': 'underline'}">{{'greeting-view.privacy-policy-link' | translate}}</h3>
          </a>

          <h3 [ngStyle]="{margin: 0, marginLeft: '0.7vh'}">{{'greeting-view.privacy-policy-and' | translate}}</h3>

          <a target="_blank" href="/static/legal/Terms_of_use_LOGE.pdf" title="{{'player-progress-view.in-new-tab' | translate}}">
            <h3 [ngStyle]="{margin: 0, marginLeft: '0.7vh', 'text-decoration': 'underline'}">{{'greeting-view.terms-of-user-link' | translate}}</h3>
          </a>
        </div>

        <raised-css-button
          [buttonText]="'activation-view.submit'"
          [disabled]="!password || !passwordConfirmation || !privacyPolicyAccepted"
          (onClick)="submit()"
          [fontSize]="'3vmin'"
          [marginTop]="'2vmin'"
          [marginBottom]="'0.5vmin'"
        ></raised-css-button>

        <raised-css-button
          *ngIf="showOfficeLogin"
          [buttonText]="'activation-view.office-login'"
          (onClick)="openLink('/api/auth/login/o365')"
          [fontSize]="'3vmin'"
          [marginTop]="'0.5vmin'"
          [marginBottom]="'0.5vmin'"
        ></raised-css-button>
      </div>

      <div class="sent" *ngIf="activationStatus === 'sent' || activationStatus === 'already-activated' || activationStatus === 'error'">
        <h1 *ngIf="activationStatus === 'sent' || activationStatus === 'already-activated'">{{'activation-view.sent-title' | translate}}</h1>
        <h1 *ngIf="activationStatus === 'error'">{{'activation-view.activation-error-title' | translate}}</h1>

        <div class="info-box">
          <ng-container *ngIf="activationStatus === 'sent'">{{'activation-view.sent-instructions' | translate}}</ng-container>
          <ng-container *ngIf="activationStatus === 'already-activated'">{{'activation-view.already-activated' | translate}}</ng-container>
          <ng-container *ngIf="activationStatus === 'error'">{{'activation-view.activation-error' | translate}}</ng-container>
        </div>

        <raised-css-button
          [buttonText]="'activation-view.sent-return'"
          (onClick)="openLink('/#/welcome')"
          [fontSize]="'3vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'0.5vmin'"
        ></raised-css-button>
      </div>
    </div>
  `
})
export class ActivationView extends OnDestroyMixin implements OnInit {

  public activationStatus: string;
  public privacyPolicyAccepted: boolean;
  protected password: string;
  protected passwordConfirmation: string;
  protected error: string = null;
  protected showOfficeLogin = false;

  protected userId: string;
  protected activationKey: string;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected userService: UserService) {
    super();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        untilComponentDestroyed(this),
        first(),
      )
      .subscribe((params: Params) => {
        this.userId = params['uid'];
        this.activationKey = params['key'];

        this.userService.checkActivationInfo(this.userId, this.activationKey, (err, res) => {
          if (err) {
            this.activationStatus = 'error';
          } else if (!res) {
            this.activationStatus = 'already-activated';
          }
        });

        this.userService.inOrgDomain(this.userId, (err, res) => {
          if (!err && res === true) {
            this.showOfficeLogin = true;
          }
        });
      });
  }

  enterActivate(event, fn: string) {
    if (event.key === "Enter" && this[fn]) {
      this[fn]();
    }
  }

  /**
   * Check password validity before activating local user
   */
  submit() {
    this.error = null;
    if (!this.password || !this.passwordConfirmation) {
      this.error = 'empty';
      return;
    }

    if (this.password !== this.passwordConfirmation) {
      this.error = 'mismatch';
      return;
    }

    if (!UserService.validPasswordRegex.test(this.password)) {
      this.error = 'requirements-not-met';
      return;
    }

    this.userService.activateLocalUser(this.userId, this.activationKey, this.password, (err) => {
      if (err) {
        this.error = "server";
      } else {
        this.activationStatus = 'sent';
      }
    });
  }

  public openLink(link: string): void {
    location.href = link;
  }
}
