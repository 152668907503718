/**
 * Created by Topi on 30.05.2018
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'svg-reaction-emoji',
  template: `
    <div class="svg-reaction-emoji-container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" enable-background="new 0 0 360 360" xml:space="preserve">
        <!-- Blue base circle -->
        <circle fill="#27314C" cx="188" cy="188" r="172"/>
        <circle fill="#3F4D7B" cx="172" cy="172" r="172"/>

        <!-- Thumb -->
        <g *ngIf="emojiType === 'thumb'">
          <path fill="#27314C" d="M78.115,209.347c0,0,3.344-35.671,47.933-60.195s41.245-90.293,41.245-90.293s46.818-18.95,36.786,59.08
            c0,0-10.033,37.901,5.574,37.901s54.621-4.459,64.654-2.229s18.95,28.983,0,44.589c0,0,17.836,20.065-4.459,37.901
            c0,0,17.836,24.524-8.918,32.327c0,0,15.606,18.95-8.918,26.753C252.012,295.181,66.968,330.852,78.115,209.347z"/>
          <path fill="#D4DBDC" d="M62.115,193.347c0,0,3.344-35.671,47.933-60.195s41.245-90.293,41.245-90.293s46.818-18.95,36.786,59.08
            c0,0-10.033,37.901,5.574,37.901s54.621-4.459,64.654-2.229s18.95,28.983,0,44.589c0,0,17.836,20.065-4.459,37.901
            c0,0,17.836,24.524-8.918,32.327c0,0,15.606,18.95-8.918,26.753C236.012,279.181,50.968,314.852,62.115,193.347z"/>
          <path fill="none" stroke="#3F4D7B" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M225,150c0,0,13,9,5,27"
            />
          <path fill="none" stroke="#3F4D7B" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M229.331,188.485
            c0,0,9.607,12.558-3.536,27.23"/>
          <path fill="none" stroke="#3F4D7B" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M227.331,229.485
            c0,0,5.549,7.254-2.043,15.729"/>
          <path fill="none" stroke="#3F4D7B" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M224.331,256.485
            c0,0,5.549,7.254-2.043,15.729"/>
        </g>

        <!-- Laughing face -->
        <g *ngIf="emojiType === 'laugh'">
          <circle fill="#27314C" cx="188" cy="188" r="127"/>
          <circle fill="#D4DBDC" cx="172" cy="172" r="127"/>
          <path fill="#3F4D7B" d="M80,172c0,50.81,41.19,92,92,92s92-41.19,92-92H80z"/>
          <path fill="#3F4D7B" d="M110.468,123.065c14.046,0,25.864,9.507,29.39,22.435c0.698-2.56,1.078-5.251,1.078-8.032
            c0-16.827-13.641-30.468-30.468-30.468C93.641,107,80,120.641,80,137.468c0,2.781,0.38,5.472,1.078,8.032
            C84.603,132.572,96.422,123.065,110.468,123.065z"/>
          <path fill="#3F4D7B" d="M233.468,123.065c14.046,0,25.864,9.507,29.39,22.435c0.698-2.56,1.078-5.251,1.078-8.032
            c0-16.827-13.641-30.468-30.468-30.468C216.641,107,203,120.641,203,137.468c0,2.781,0.38,5.472,1.078,8.032
            C207.603,132.572,219.422,123.065,233.468,123.065z"/>
        </g>

        <!-- Light bulb -->
        <g *ngIf="emojiType === 'bulb'">
          <line fill="none" stroke="#27314C" stroke-width="9" stroke-linecap="round" stroke-miterlimit="10" x1="187.503" y1="62.575" x2="187.503" y2="98.786"/>
          <line fill="none" stroke="#27314C" stroke-width="9" stroke-linecap="round" stroke-miterlimit="10" x1="258" y1="81.861" x2="239.35" y2="112.9"/>
          <line fill="none" stroke="#27314C" stroke-width="9" stroke-linecap="round" stroke-miterlimit="10" x1="117.143" y1="82.352" x2="135.793" y2="113.391"/>
        
          <path fill="#27314C" d="M254.671,180.943c0.018-0.623,0.012-0.955,0.012-0.955c0-36.577-29.651-66.228-66.228-66.228
            s-66.228,29.651-66.228,66.228c0,0-0.006,0.331,0.012,0.953c0.115,8.13,1.692,15.905,4.484,23.074
            c4.225,12.559,12.413,28.191,28.29,42.2c0,0,9.18,5.901,9.18,32.786h24.262h24.262c0-26.885,9.18-32.786,9.18-32.786
            c15.877-14.009,24.065-29.642,28.29-42.201C252.98,196.846,254.556,189.072,254.671,180.943z"/>
          <path fill="#27314C" d="M216.354,290.965h-55.798c-1.899,0-3.439-1.54-3.439-3.439s1.54-3.439,3.439-3.439h55.798
            c1.899,0,3.439,1.54,3.439,3.439S218.254,290.965,216.354,290.965z"/>
          <path fill="#27314C" d="M215.996,301.457h-55.081c-1.899,0-3.439-1.54-3.439-3.439s1.54-3.439,3.439-3.439h55.081
            c1.899,0,3.439,1.54,3.439,3.439S217.896,301.457,215.996,301.457z"/>
          <path fill="#27314C" d="M213.373,311.948h-49.835c-1.899,0-3.439-1.54-3.439-3.439s1.54-3.439,3.439-3.439h49.835
            c1.899,0,3.439,1.54,3.439,3.439S215.272,311.948,213.373,311.948z"/>
          <path fill="#D4DBDC" d="M238.671,164.943c0.018-0.623,0.012-0.955,0.012-0.955c0-36.577-29.651-66.228-66.228-66.228
            s-66.228,29.651-66.228,66.228c0,0-0.006,0.331,0.012,0.953c0.115,8.13,1.692,15.905,4.484,23.074
            c4.225,12.559,12.413,28.191,28.29,42.2c0,0,9.18,5.901,9.18,32.786h24.262h24.262c0-26.885,9.18-32.786,9.18-32.786
            c15.877-14.009,24.065-29.642,28.29-42.201C236.98,180.846,238.556,173.072,238.671,164.943z"/>
          
          <line fill="none" stroke="#D4DBDC" stroke-width="6.879" stroke-linecap="round" stroke-miterlimit="10" x1="144.557" y1="271.526" x2="200.354" y2="271.526"/>
          <line fill="none" stroke="#D4DBDC" stroke-width="6.879" stroke-linecap="round" stroke-miterlimit="10" x1="144.915" y1="282.017" x2="199.996" y2="282.017"/>
          <line fill="none" stroke="#D4DBDC" stroke-width="6.879" stroke-linecap="round" stroke-miterlimit="10" x1="147.538" y1="292.509" x2="197.373" y2="292.509"/>
        
          <path fill="none" stroke="#3F4D7B" stroke-width="6.5572" stroke-linecap="round" stroke-miterlimit="10" d="M164.587,113.497
            c0,0-39.343,13.77-37.376,51.802"/>
        
          <line fill="none" stroke="#D4DBDC" stroke-width="8.5763" stroke-linecap="round" stroke-miterlimit="10" x1="171.503" y1="46.575" x2="171.503" y2="82.786"/>
          <line fill="none" stroke="#D4DBDC" stroke-width="8.5763" stroke-linecap="round" stroke-miterlimit="10" x1="242" y1="65.861" x2="223.35" y2="96.9"/>
          <line fill="none" stroke="#D4DBDC" stroke-width="8.5763" stroke-linecap="round" stroke-miterlimit="10" x1="101.143" y1="66.352" x2="119.793" y2="97.391"/>
        </g>

        <!-- Confused face -->
        <g *ngIf="emojiType === 'confused'">
          <path fill="#27314C" d="M323.183,140.25c-2.309-4.459-5.799-8.461-10.375-11.896c-4.96-3.723-10.221-6.007-15.638-6.789
            c-1.668-0.241-3.321-0.363-4.913-0.363h0c-3.597,0-7.027,0.619-10.195,1.84c-4.693,1.809-8.408,4.505-11.041,8.013
            c-1.39,1.852-2.924,5.008-2.339,9.532c0.472,3.649,2.288,6.753,5.252,8.978c2.303,1.729,4.963,2.642,7.693,2.642
            c2.989,0,5.965-1.063,8.846-3.16c2.806-1.93,4.536-2.45,5.32-2.587c0,0,0.001,0,0.001,0c0.055,0,0.821,0.024,2.474,1.265
            c1.45,1.089,1.599,1.905,1.663,2.254c0.133,0.726,0.004,1.049-0.333,1.499c-0.1,0.134-0.152,0.157-0.212,0.183
            c-0.593,0.264-1.197,0.437-1.848,0.53c-0.776,0.111-2.374,0.293-5.577,0.533c-3.408,0.259-6.166,0.587-8.44,1.002
            c-2.802,0.511-5.519,1.525-8.076,3.013c-2.669,1.553-5.13,3.816-7.316,6.728c-2.44,3.25-3.064,6.267-3.024,8.583
            c-1.084,0.21-2.111,0.55-3.087,0.989c-3.747-10.261-9.148-19.724-15.874-28.077c0.807-0.981,1.567-2.15,2.243-3.548
            c1.061-2.022,1.568-2.924,1.807-3.325c0.182-0.305,0.417-0.618,0.7-0.931c0.053-0.058,0.362-0.382,1.185-0.934
            c0.819-0.55,1.945-1.197,3.346-1.922c6.112-3.086,10.28-5.259,12.74-6.644c2.955-1.663,5.721-3.849,8.221-6.498
            c2.82-2.988,4.855-6.758,6.049-11.206c1.491-5.556,1.317-11.182-0.517-16.724c-1.801-5.442-5.119-10.274-9.863-14.362
            c-4.574-3.941-10.28-6.849-16.961-8.641c-4.012-1.077-7.955-1.622-11.719-1.622c-3.011,0-5.974,0.349-8.806,1.037
            c-6.413,1.559-11.839,4.373-16.128,8.364c-4.376,4.073-7.274,8.67-8.614,13.664c-0.668,2.488-0.938,6.393,1.568,10.867
            c2.017,3.602,5.207,6.076,9.226,7.154c1.321,0.355,2.649,0.534,3.945,0.534c3.676,0,8.929-1.412,13.125-8.12
            c2.435-3.668,4.363-5.32,5.555-6.06c0.4-0.248,0.996-0.374,1.771-0.374c0.88,0,1.93,0.162,3.12,0.482
            c2.581,0.693,3.547,1.831,4.049,2.627c0.718,1.138,0.866,2.071,0.529,3.327c-0.121,0.452-0.309,0.791-0.607,1.099
            c-0.686,0.706-1.437,1.291-2.298,1.788c-1.276,0.736-3.416,1.843-6.359,3.289c-3.795,1.869-6.805,3.512-9.207,5.027
            c-2.851,1.797-5.391,4.111-7.548,6.879c-1.038,1.331-1.955,2.822-2.759,4.448C208.219,114.13,189.326,108,169,108
            c-54.676,0-99,44.324-99,99c0,29.555,12.955,56.08,33.491,74.219c-0.669,9.544,1.459,17.087,6.349,22.416
            c4.906,5.347,12.164,8.058,21.573,8.058c9.549,0,20.33-2.866,29.347-6.038c2.718,0.224,5.465,0.344,8.24,0.344
            c54.676,0,99-44.324,99-99c0-2.221-0.081-4.423-0.225-6.608c0.122,0.003,0.242,0.015,0.364,0.015
            c0.424,0,0.856-0.018,1.285-0.054c2.755-0.228,6.719-1.407,9.961-5.727c2.337-3.113,3.287-6.76,2.748-10.545
            c-0.001-0.005-0.001-0.009-0.002-0.014c1.476-0.54,3.085-1.444,4.704-2.913c1.418-1.208,2.013-1.681,2.243-1.857
            c0.149-0.114,0.334-0.225,0.551-0.33c0.001,0,0.243-0.102,0.824-0.226c0.492-0.105,1.389-0.259,2.874-0.402
            c5.445-0.468,9.185-0.84,11.434-1.136c2.818-0.371,5.647-1.206,8.41-2.481c3.186-1.471,5.99-3.799,8.333-6.922
            c2.918-3.888,4.553-8.354,4.86-13.273C326.666,149.712,325.595,144.909,323.183,140.25z"/>
          
          <circle fill="#D4DBDC" cx="153" cy="191" r="99"/>
          
          <path fill="#D4DBDC" d="M115.413,291.796c-8.271,0-14.563-2.287-18.701-6.796c-4.263-4.646-6.039-11.47-5.279-20.28
            c0.053-0.533,1.211-11.938,3.917-23.551c4.137-17.748,9.478-26.014,16.808-26.014c0.184,0,0.365,0.005,0.551,0.017
            c9.573,0.598,13.571,8.153,14.86,14.866c10.228-5.199,31.939-15.53,42.952-15.53c2.107,0,3.832,0.35,5.27,1.068
            c1.667,0.833,6.738,3.369,6.834,8.396c0.098,5.149-4.792,10.02-18.798,18.168c1.614,1.189,3.107,2.734,4.035,4.714
            c0.705,1.506,1.273,3.69,0.732,6.405c2.423,1.821,5.424,4.955,5.638,9.406c0.256,5.306-3.591,10.381-11.432,15.086l-0.105,0.063
            l-0.109,0.057C161.484,278.441,135.414,291.796,115.413,291.796z"/>
          <path fill="#3F4D7B" d="M170.521,218.406c1.425,0,2.621,0.204,3.527,0.657c9.354,4.677,5.457,10.134-18.709,23.386
            c0,0,13.252,3.118,8.575,12.472c0,0,16.37,7.795-3.118,19.488c0,0-26.099,13.489-45.382,13.489
            c-12.159,0-21.606-5.359-20.098-22.843c0,0,4.601-46.002,16.842-46.002c0.103,0,0.204,0.003,0.308,0.01
            c12.472,0.78,11.693,17.15,11.693,17.15S157.226,218.407,170.521,218.406 M170.522,210.611h-0.002
            c-10.482,0.001-28.881,8.131-40.494,13.832c-2.948-7.887-9.116-12.663-17.074-13.16c-0.267-0.017-0.53-0.025-0.794-0.025
            c-12.403,0-17.699,16.566-20.603,29.027c-2.763,11.856-3.947,23.504-3.996,23.994l-0.005,0.053l-0.004,0.053
            c-0.857,9.943,1.26,17.765,6.292,23.25c4.906,5.347,12.164,8.058,21.573,8.058c20.938,0,47.827-13.773,48.961-14.359l0.22-0.113
            l0.212-0.127c9.167-5.5,13.648-11.763,13.319-18.615c-0.231-4.82-2.816-8.409-5.416-10.827c0.097-2.632-0.556-4.823-1.319-6.451
            c-0.376-0.803-0.816-1.548-1.304-2.24c11.21-6.899,16.563-12.288,16.434-19.063c-0.093-4.9-3.117-8.873-8.988-11.808
            C175.542,211.095,173.248,210.611,170.522,210.611L170.522,210.611z"/>
          <path fill="#D4DBDC" stroke="#3F4D7B" stroke-width="7.7953" stroke-linecap="round" stroke-miterlimit="10" d="M156.898,203.472
            c0,0,28.063-16.37,44.433-4.677"/>
            
          <ellipse transform="matrix(0.9094 -0.416 0.416 0.9094 -55.7867 62.0039)" fill="#3F4D7B" cx="114.413" cy="159.039" rx="14.421" ry="24.165"/>
          <ellipse transform="matrix(0.9094 -0.416 0.416 0.9094 -42.7065 84.6277)" fill="#3F4D7B" cx="172.878" cy="140.331" rx="11.115" ry="18.626"/>
      
          <path fill="#D4DBDC" d="M214.476,153.447c-1.122,0-2.264-0.154-3.392-0.457c-3.217-0.863-5.784-2.688-7.631-5.424
            c-1.928-2.854-2.416-6.34-1.411-10.08c0.912-3.401,2.905-5.986,5.925-7.681c1.578-0.887,3.249-1.416,4.979-1.578
            c-1.222-0.852-2.241-1.948-3.051-3.282c-1.543-2.548-1.839-5.65-0.88-9.22c1-3.73,2.495-6.889,4.443-9.388
            c1.906-2.446,4.146-4.489,6.654-6.069c2.266-1.428,5.254-3.058,8.88-4.844c3.018-1.482,5.227-2.626,6.568-3.4
            c1.13-0.651,2.159-1.453,3.061-2.382c0.717-0.738,1.2-1.599,1.478-2.631c0.589-2.198,0.288-4.137-0.949-6.1
            c-1.278-2.027-3.274-3.381-6.103-4.14c-1.486-0.399-2.842-0.601-4.027-0.601c-1.458,0-2.642,0.295-3.617,0.901
            c-2.169,1.347-4.407,3.749-6.653,7.14c-3.352,5.382-7.384,6.514-10.181,6.514c-0.99,0-2.013-0.14-3.038-0.415
            c-3.086-0.828-5.534-2.724-7.079-5.482c-1.549-2.769-1.967-5.545-1.241-8.251c1.168-4.351,3.73-8.391,7.618-12.009
            c3.845-3.578,8.747-6.11,14.57-7.525c2.562-0.623,5.246-0.938,7.979-0.938c3.458,0,7.096,0.506,10.812,1.503
            c6.169,1.656,11.412,4.318,15.583,7.912c4.255,3.667,7.225,7.976,8.824,12.81c1.616,4.883,1.771,9.834,0.46,14.719
            c-1.041,3.88-2.795,7.147-5.213,9.709c-2.259,2.393-4.745,4.361-7.393,5.851c-2.416,1.36-6.548,3.514-12.632,6.585
            c-1.503,0.777-2.755,1.498-3.689,2.125c-1.009,0.678-1.551,1.185-1.827,1.49c-0.438,0.484-0.813,0.986-1.114,1.491
            c-0.383,0.642-1.031,1.833-1.927,3.542c-2.17,4.525-5.315,6.043-7.968,6.409c1.636,1.045,2.974,2.46,3.997,4.234
            c1.708,2.961,2.113,6.179,1.205,9.565c-0.99,3.687-3.132,6.445-6.194,7.979C218.425,152.97,216.464,153.447,214.476,153.447z"/>
          <path fill="#3F4D7B" d="M227.379,59.605c3.166,0,6.468,0.461,9.905,1.383c5.683,1.525,10.418,3.919,14.205,7.183
            c3.788,3.264,6.383,7.017,7.787,11.258c1.404,4.242,1.538,8.479,0.402,12.711c-0.894,3.331-2.353,6.069-4.378,8.215
            c-2.025,2.145-4.213,3.88-6.563,5.202s-6.514,3.493-12.491,6.511c-1.658,0.857-3.013,1.639-4.063,2.344
            c-1.051,0.705-1.874,1.388-2.471,2.047c-0.597,0.659-1.106,1.342-1.526,2.048s-1.104,1.962-2.051,3.767
            c-1.446,3.049-3.536,4.573-6.269,4.573c-0.742,0-1.531-0.112-2.367-0.337c-2.038-0.547-3.573-1.673-4.607-3.379
            c-1.034-1.706-1.198-3.872-0.494-6.497c0.883-3.292,2.158-6.006,3.823-8.143c1.666-2.137,3.585-3.89,5.76-5.26
            c2.174-1.37,5.028-2.926,8.562-4.666c3.097-1.522,5.354-2.691,6.771-3.508c1.417-0.817,2.691-1.809,3.823-2.976
            c1.132-1.167,1.914-2.553,2.345-4.16c0.841-3.135,0.385-6.093-1.368-8.875c-1.754-2.781-4.472-4.666-8.156-5.654
            c-1.791-0.48-3.435-0.721-4.934-0.721c-2.11,0-3.932,0.476-5.464,1.428c-2.622,1.628-5.206,4.369-7.752,8.223
            c-2.024,3.269-4.436,4.903-7.235,4.903c-0.687,0-1.397-0.098-2.131-0.295c-2.195-0.589-3.839-1.86-4.932-3.813
            s-1.398-3.831-0.915-5.633c0.999-3.723,3.206-7.174,6.622-10.354c3.416-3.18,7.753-5.408,13.011-6.686
            C222.528,59.884,224.912,59.605,227.379,59.605 M214.099,131.675c0.849,0,1.718,0.119,2.609,0.358
            c2.469,0.662,4.32,2.062,5.553,4.2c1.233,2.137,1.518,4.441,0.856,6.91c-0.736,2.743-2.196,4.662-4.38,5.757
            c-1.394,0.698-2.814,1.048-4.26,1.048c-0.82,0-1.648-0.112-2.485-0.337c-2.391-0.641-4.27-1.975-5.638-4.002
            c-1.368-2.026-1.678-4.431-0.932-7.214c0.662-2.469,2.082-4.315,4.259-5.537C211.084,132.069,212.557,131.675,214.099,131.675
             M227.379,52.605c-3.011,0-5.974,0.349-8.806,1.037c-6.413,1.559-11.839,4.373-16.128,8.365
            c-4.376,4.073-7.274,8.67-8.614,13.664c-0.667,2.487-0.938,6.392,1.568,10.867c2.017,3.602,5.207,6.075,9.226,7.154
            c1.322,0.355,2.649,0.534,3.945,0.534c3.676,0,8.929-1.412,13.125-8.12c2.435-3.668,4.363-5.32,5.555-6.06
            c0.4-0.248,0.996-0.374,1.771-0.374c0.88,0,1.929,0.162,3.12,0.482c2.581,0.693,3.547,1.831,4.049,2.627
            c0.717,1.138,0.865,2.071,0.529,3.327c-0.122,0.452-0.309,0.791-0.608,1.099c-0.685,0.706-1.437,1.291-2.298,1.787
            c-1.276,0.736-3.416,1.843-6.359,3.289c-3.794,1.869-6.804,3.512-9.208,5.027c-2.851,1.797-5.391,4.111-7.548,6.879
            c-2.237,2.869-3.94,6.447-5.063,10.633c-1.457,5.429-0.245,9.305,1.109,11.669c-0.164,0.085-0.328,0.174-0.49,0.265
            c-3.817,2.144-6.442,5.542-7.592,9.827c-1.609,5.998,0.14,10.349,1.89,12.943c2.3,3.407,5.628,5.774,9.626,6.847
            c1.424,0.382,2.871,0.576,4.299,0.576c2.538,0,5.026-0.602,7.396-1.789c2.777-1.392,6.42-4.291,8.005-10.201
            c1.142-4.254,0.605-8.48-1.553-12.221c-0.257-0.445-0.531-0.873-0.822-1.285c1.78-1.268,3.506-3.179,4.887-6.037
            c1.06-2.02,1.568-2.922,1.807-3.325c0.182-0.305,0.417-0.618,0.7-0.931c0.053-0.059,0.362-0.382,1.185-0.934
            c0.82-0.55,1.945-1.197,3.346-1.922c6.112-3.086,10.279-5.259,12.74-6.644c2.955-1.663,5.721-3.849,8.221-6.498
            c2.82-2.988,4.855-6.758,6.049-11.206c1.491-5.555,1.317-11.182-0.517-16.724c-1.801-5.442-5.119-10.274-9.863-14.362
            c-4.573-3.941-10.28-6.849-16.96-8.641C235.085,53.15,231.143,52.605,227.379,52.605L227.379,52.605z"/>
          <path fill="#D4DBDC" d="M252.14,180.907c-2.422,0-4.639-0.742-6.587-2.206c-2.261-1.697-3.707-3.948-4.297-6.689
            c-0.433-2.014-0.425-5.113,2.072-8.441c1.779-2.368,4.245-3.819,7.134-4.196c0.486-0.063,0.976-0.096,1.455-0.096
            c0.484,0,0.964,0.032,1.435,0.096c-0.298-0.693-0.512-1.426-0.638-2.193c-0.417-2.538,0.329-5.099,2.215-7.613
            c1.905-2.537,4.018-4.49,6.278-5.805c2.204-1.283,4.54-2.156,6.944-2.595c2.121-0.387,4.836-0.708,8.069-0.954
            c2.648-0.199,4.603-0.387,5.813-0.559c0.96-0.137,1.895-0.405,2.776-0.797c0.633-0.282,1.152-0.701,1.588-1.28
            c0.96-1.279,1.27-2.623,0.976-4.229c-0.309-1.691-1.291-3.138-3.005-4.424c-1.737-1.305-3.272-1.966-4.563-1.966
            c-0.21,0-0.418,0.018-0.618,0.053c-1.897,0.333-4.164,1.403-6.739,3.179c-2.282,1.669-4.577,2.515-6.821,2.515
            c-1.995,0-3.876-0.653-5.592-1.941c-2.192-1.646-3.535-3.938-3.883-6.628c-0.344-2.656,0.232-5.07,1.666-6.981
            c2.234-2.975,5.431-5.279,9.501-6.848c2.765-1.066,5.771-1.606,8.937-1.606c1.426,0,2.91,0.11,4.413,0.327
            c4.829,0.697,9.552,2.757,14.037,6.124c4.152,3.117,7.304,6.719,9.368,10.707c2.116,4.085,3.058,8.274,2.798,12.449
            c-0.263,4.226-1.664,8.058-4.166,11.39c-1.99,2.653-4.346,4.62-7.001,5.846c-2.441,1.127-4.932,1.863-7.399,2.188
            c-2.183,0.287-5.988,0.664-11.313,1.122c-1.304,0.125-2.414,0.282-3.268,0.463c-0.876,0.187-1.367,0.375-1.625,0.501
            c-0.428,0.208-0.811,0.441-1.144,0.695c-0.322,0.246-1.004,0.793-2.435,2.013c-2.012,1.84-4.181,2.772-6.45,2.772
            c-0.143,0-0.285-0.004-0.427-0.011c0.512,1.015,0.856,2.116,1.022,3.287c0.406,2.844-0.313,5.594-2.081,7.95
            c-1.95,2.597-4.526,4.098-7.453,4.34C252.799,180.893,252.467,180.907,252.14,180.907z"/>
          <path fill="#3F4D7B" d="M276.256,112.203c1.265,0,2.569,0.097,3.914,0.291c4.262,0.615,8.407,2.435,12.436,5.459
            c3.744,2.811,6.531,5.983,8.361,9.516c1.83,3.533,2.634,7.074,2.413,10.623s-1.378,6.717-3.472,9.506
            c-1.648,2.195-3.537,3.785-5.669,4.769c-2.132,0.984-4.261,1.616-6.389,1.896c-2.128,0.28-5.846,0.648-11.155,1.105
            c-1.479,0.141-2.711,0.317-3.696,0.526s-1.796,0.469-2.432,0.779c-0.636,0.31-1.214,0.663-1.734,1.06
            c-0.52,0.397-1.397,1.122-2.631,2.174c-1.365,1.262-2.741,1.893-4.128,1.893c-1.161,0-2.329-0.441-3.505-1.324
            c-1.343-1.008-2.143-2.295-2.401-3.861c-0.258-1.566,0.263-3.214,1.562-4.945c1.628-2.169,3.374-3.796,5.238-4.88
            s3.801-1.81,5.813-2.177c2.012-0.367,4.581-0.67,7.707-0.908c2.738-0.206,4.752-0.4,6.04-0.584
            c1.289-0.184,2.524-0.538,3.706-1.064c1.182-0.526,2.17-1.318,2.965-2.377c1.551-2.066,2.091-4.386,1.621-6.96
            c-0.47-2.574-1.919-4.772-4.347-6.594c-2.368-1.778-4.59-2.667-6.665-2.667c-0.414,0-0.822,0.035-1.224,0.106
            c-2.419,0.425-5.14,1.684-8.163,3.775c-1.69,1.245-3.288,1.867-4.791,1.867c-1.226,0-2.39-0.413-3.491-1.24
            c-1.446-1.086-2.283-2.511-2.512-4.278s0.103-3.243,0.995-4.431c1.842-2.453,4.495-4.348,7.96-5.683
            C270.951,112.66,273.511,112.203,276.256,112.203 M251.916,162.779c1.586,0,3.063,0.514,4.432,1.541
            c1.627,1.221,2.579,2.804,2.856,4.748c0.277,1.944-0.195,3.729-1.416,5.356c-1.357,1.808-3.004,2.792-4.942,2.953
            c-0.239,0.02-0.474,0.03-0.707,0.03c-1.654,0-3.149-0.501-4.485-1.504c-1.575-1.182-2.568-2.725-2.977-4.627
            c-0.409-1.902,0.074-3.77,1.45-5.603c1.221-1.627,2.817-2.569,4.787-2.827C251.253,162.801,251.587,162.779,251.916,162.779
             M276.257,105.203L276.257,105.203c-3.598,0-7.028,0.619-10.195,1.84c-4.693,1.809-8.408,4.504-11.042,8.013
            c-1.39,1.852-2.924,5.008-2.338,9.532c0.472,3.649,2.288,6.753,5.252,8.978c2.303,1.729,4.963,2.642,7.694,2.642
            c2.989,0,5.965-1.063,8.846-3.16c2.806-1.929,4.536-2.449,5.32-2.587c0,0,0.001,0,0.001,0c0.055,0,0.821,0.024,2.473,1.264
            c1.45,1.089,1.6,1.905,1.663,2.254c0.133,0.726,0.005,1.049-0.333,1.499c-0.1,0.134-0.152,0.157-0.212,0.183
            c-0.593,0.264-1.198,0.437-1.848,0.53c-0.776,0.111-2.374,0.293-5.577,0.533c-3.407,0.259-6.166,0.587-8.439,1.002
            c-2.802,0.512-5.519,1.525-8.076,3.013c-2.669,1.553-5.13,3.817-7.316,6.729c-2.44,3.25-3.064,6.267-3.023,8.583
            c-3.457,0.668-6.408,2.529-8.578,5.42c-3.29,4.383-3.282,8.552-2.696,11.278c0.762,3.54,2.705,6.566,5.619,8.753
            c2.532,1.901,5.536,2.906,8.687,2.906c0.424,0,0.856-0.018,1.286-0.054c2.755-0.228,6.719-1.407,9.961-5.726
            c2.337-3.114,3.287-6.761,2.748-10.546c0-0.004-0.001-0.009-0.002-0.013c1.476-0.54,3.085-1.444,4.704-2.913
            c1.418-1.208,2.013-1.681,2.243-1.857c0.149-0.114,0.334-0.225,0.551-0.33c0,0,0,0,0,0c0.001,0,0.243-0.102,0.825-0.226
            c0.492-0.105,1.389-0.259,2.874-0.402c5.446-0.468,9.186-0.84,11.434-1.136c2.818-0.371,5.647-1.205,8.41-2.481
            c3.186-1.471,5.99-3.8,8.333-6.922c2.918-3.888,4.553-8.354,4.86-13.274c0.3-4.815-0.771-9.618-3.183-14.277
            c-2.308-4.458-5.799-8.461-10.375-11.896c-4.959-3.723-10.221-6.007-15.638-6.789
            C279.502,105.325,277.849,105.203,276.257,105.203L276.257,105.203z"/>
        </g>
      </svg>
    </div>
  `
})
export class SvgReactionEmojiComponent {
  @Input() emojiType: string;

  constructor() {

  }
}
