import {Injectable} from '@angular/core';
import Timeout = NodeJS.Timeout;
import {BehaviorSubject} from "rxjs";
import {GameSessionService} from "./game-session.service";
import {Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  public idleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private _idleLimiterSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private idleTimeout: Timeout;
  private popUpTimeout: Timeout;
  private idleReason: string;
  private totalTimeOut: number; // Seconds
  private popUpTimeOut: number; // Seconds
  private stopOnAction: boolean;

  constructor(private gameSessionService: GameSessionService,
              private router: Router) {

  }

  public init(reason: string, timeOut: number = 180, popUp: number = 60, stopOnAction?: boolean): void {
    if (timeOut < popUp) {
      // No point in initiating if timeout never happens
      return;
    }

    this._idleLimiterSubject
      .pipe(
        filter(value => this.idleSubject.value !== value)
      )
      .subscribe(() => {
        this.resetIdleTimer();
      });

    this.idleReason = reason;
    this.totalTimeOut = timeOut;
    this.popUpTimeOut = popUp;
    this.stopOnAction = stopOnAction;

    this.idleSubject.next('active');

    // DOM Events
    document.onmousemove = this.onEvent.bind(this);
    document.ontouchmove = this.onEvent.bind(this);
    document.onkeypress = this.onEvent.bind(this);
  }

  public clearIdle(restart?: boolean): void {
    this.clearTimeOuts();
    document.onmousemove = null;
    document.ontouchmove = null;
    document.onkeypress = null;

    if (restart) {
      this.init(this.idleReason, this.totalTimeOut);
    }
  }

  public idleReached(): void {
    this.clearIdle();

    if (this.idleReason === 'discussion') {
      this.exitDiscussion();
      return;
    }

    console.warn("Idle reached but with unknown reason", this.idleReason);
  }

  public unsetIdle(): void {
    this.clearIdle();
    this.idleSubject.next("cleared");
  }

  public exitDiscussion(): void {
    // Disconnect from session if connected
    if (this.gameSessionService.isConnected()) {
      this.gameSessionService.leaveDiscussionRoom("button-leave-discussion");
    }

    this.router.navigate(['/landing-page']);
  }

  private onEvent(event: Event): void {
    if (this.stopOnAction) {
      this.unsetIdle();
      return;
    }

    this._idleLimiterSubject.next(event.type);
  }

  private clearTimeOuts(): void {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }

    if (this.popUpTimeout) {
      clearTimeout(this.popUpTimeout);
    }
  }

  private resetIdleTimer(): void {
    this.clearTimeOuts();

    if (this.idleSubject.value !== 'active') {
      this.idleSubject.next('active');
    }

    if (this.totalTimeOut > this.popUpTimeOut) {
      this.popUpTimeout = setTimeout(() => {
        this.idleSubject.next('soon');
      }, (this.totalTimeOut - this.popUpTimeOut) * 1000);
    }

    this.idleTimeout = setTimeout(() => {
      this.idleSubject.next('idle');
      this.idleReached();
    }, this.totalTimeOut * 1000);
  }
}
