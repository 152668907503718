import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import * as StackTrace from 'stacktrace-js';
import {ErrorLoggingService} from "./error-logging.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggingService: ErrorLoggingService,
              private injector: Injector) {

  }

  handleError(error: any): void {
    const locationStrategy = this.injector.get(LocationStrategy);
    const message = error.message ? error.message : error.toString();
    const url = locationStrategy instanceof PathLocationStrategy
      ? locationStrategy.path() : '';

    if (error.originalStack && error.originalStack.indexOf('Error: Loading chunk') >= 0) {
      this.loggingService.logError({message: "Failed loading chunk, refresh page", url, stack: ""});

      // Reload page if chunk was not found
      location.reload();
    }

    if (!error.url && !error.headers) {
      // Get the stack trace
      StackTrace.fromError(error).then(stackframes => {
        const stackString = stackframes
          .splice(0, 20)
          .map(function (sf) {
            return sf.toString();
          }).join('\n');

        // Log on the server
        this.loggingService.logError({message, url, stack: stackString});
      });
    }

    throw error;
  }
}
