import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GeneralFeedbackAnswer, Measure, MeasureAnswer, MeasureQuestion} from "../../data-model/measure-question.interface";
import {User} from "../../data-model/user.type";
import {MeasureService} from "../../core/service/measure.service";

@Component({
  selector: 'discussion-feedback',
  template: `
    <!-- Measure Questions -->
    <div class="feedback" *ngFor="let question of measureQuestions; let i = index">
      <div class="question-prompt">{{question.measureQuestion.prompt}}</div>

      <written-answer
        *ngIf="measureAnswers && measureAnswers[i] && !question.measureQuestion.scale"
        [placeholder]="(question.measureQuestion.hint ? question.measureQuestion.hint : 'user-measure.textarea-placeholder' | translate)"
        [answerString]="measureAnswers[i].measureAnswer"
        (answerEmitter)="measureAnswers[i].measureAnswer = $event"
      ></written-answer>

      <div class="scale-rating" *ngIf="question.measureQuestion.scale">
        <scale-rating [question]="question.measureQuestion" (onRated)="setQuestionRating(i, $event)"></scale-rating>
      </div>
    </div>

    <!-- General Rating -->
    <div class="feedback">
      <div class="question-prompt">{{'game-completed.common-rating-title' | translate}}</div>

      <div class="scale-rating">
        <scale-rating (onRated)="setRating($event)"></scale-rating>
      </div>
    </div>

    <!-- General Feedback -->
    <div class="feedback">
      <div class="question-prompt">{{'game-completed.common-feedback-title' | translate}}</div>

      <written-answer
        [placeholder]="'user-measure.textarea-placeholder' | translate"
        [answerString]="generalFeedback.answer"
        (answerEmitter)="generalFeedback.answer = $event"
      ></written-answer>
    </div>

    <raised-css-button
      [buttonText]="measureQuestions?.length ? (isFrontPage ? 'discussion-feedback.save-and-close' : 'game-completed.submit-with-questions') : 'game-completed.submit'"
      [fontSize]="'3vh'"
      [marginTop]="'3vh'"
      [marginBottom]="'3vh'"
      [animatedAppear]="true"
      (onClick)="submitEvaluation()"
    ></raised-css-button>
  `,
  styles: []
})
export class DiscussionFeedbackComponent implements OnChanges {
  @Input() measureQuestions: Measure[];
  @Input() isFrontPage: boolean;

  @Input() userId: string;
  @Input() orgId: string;
  @Input() sessionId: string;
  @Input() seasonId: string;

  @Output() closeView: EventEmitter<boolean> = new EventEmitter<boolean>();

  public measureAnswers: MeasureAnswer[];

  public generalFeedback: GeneralFeedbackAnswer = {
    answer: "",
    rating: -1
  };

  constructor(
    private measureService: MeasureService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.measureQuestions && this.measureQuestions) {
      this.measureAnswers = this.measureQuestions.map(measure => {
        return {
          _id: null,
          measure: measure._id,
          measureAnswer: "",
          measureScaleAnswer: null,
          postMeasureAnswer: "",
          postMeasureScaleAnswer: null,
          measureCompleted: null,
          sessionId: this.sessionId,
          org: this.orgId,
          user: this.userId,
          season: this.seasonId,
          createdAt: undefined,
          updatedAt: undefined
        };
      });
    }

  }

  public setRating(value: number): void {
    this.generalFeedback.rating = value;
  }

  public setQuestionRating(index: number, rating: number) {
    this.measureAnswers[index].measureScaleAnswer = rating;
  }

  public submitEvaluation(): void {
    if (this.generalFeedback.answer || this.generalFeedback.rating >= 1) {
      const newGeneralFeedbackAnswer: GeneralFeedbackAnswer = {
        answer: this.generalFeedback.answer,
        rating: this.generalFeedback.rating,

        sessionId: this.sessionId,
        org: this.orgId,
        user: this.userId,
        season: this.seasonId
      };

      this.measureService.submitGeneralFeedback(newGeneralFeedbackAnswer)
        .subscribe(() => {
          // Done
        });
    }

    if (!this.measureAnswers) {
      this.closeView.emit(true);
      return;
    }

    const validAnswers: MeasureAnswer[] = this.measureAnswers.filter((value) => {
      return value && (value.measureAnswer || value.measureScaleAnswer);
    });

    if (!validAnswers || !validAnswers.length) {
      // No valid answers, close
      this.closeView.emit(true);
      return;
    }

    this.measureService.submitMeasureAnswers(validAnswers)
      .subscribe(() => {
        this.closeView.emit(true);
      });
  }
}
