/**
 * Created by janne on 27.9.2016.
 */
import {IntroAssignmentType} from "./introduction-tutorial.type";
import {Moment} from "moment";
import {HardwareInfo, PlayerData} from "./game-state.interface";
import { AudioDeviceInfo } from "../core/service/audio-device.service";
import { OrganizationNameIDPair } from "./organization.type";

export class ExtraUserData {
  public user: User;
  public sessions: {
    sessionId: string,
    season: string,
    theme: string,
    time: Date
  }[];
  public upcomingSessions: {
    sessionId: string,
    season: string,
    time: Date
  }[];
  public authInfo: AuthInfo;
}

export interface CookieConsent {
  youtube: Date;
}

export interface AuthInfo {
  status?: string;
  activated?: boolean;
  local?: boolean;
  azure?: boolean;
}

export class User {
  public _id: string;

  // General
  public email: string;
  public orgId: string;
  public auth: AuthSettings;

  // Profile
  public name: string;
  public department: string;
  public language: string;
  public location: string;
  public additionalInfo: string;
  public imagePath: string;

  // Discussion
  public score: number;
  public chairman: boolean; // Can be selected as chairman
  public showInData: boolean; // Show in discussion data
  public contribution: Contribution;
  public claimedPrizes: ClaimedPrize[];
  public completedIntroTutorials: IntroAssignmentType[];
  public hideIntroTutorial: boolean;

  // Misc
  public loggedInDates: Date[];
  public expiryDate: Date;
  public userType: number; // 1 for testUser, anything else for normal user
  public contactAccepted: boolean; // For test organization contact form
  public privacyPolicyAccepted: Date;
  public welcomePopUpShown: Moment;
  public failedEmailAttempt: Moment;
  public timeZone: string;
  public hardwareInfo: HardwareInfo;
  public networkInfo: NetworkInformation;
  public websocketInfo: WebsocketInformation;
  public audioSettings: AudioDeviceInfo;
  public cookieConsent: CookieConsent;

  public playerData?: PlayerData; // Actually a part of gameSessionLog, but combined to user in statistics-table

  constructor() {

  }

  static NewDefaultUser(email: string) {
    return {
      email: email
    };
  }

  static getInitials(name: string): string {
    if (!name) {
      return "";
    }

    return name
      .split(" ")
      .map(namePart => {
        return namePart ? namePart[0] : "";
      })
      .join("")
      .substr(0, 3)
      .toUpperCase();
  }
}

export class AuthSettings {
  public local: {
    activated: boolean;
  };
  public azure: {
    displayName: string;
    id: string;
    upn: string;
  };
}

export class NetworkUser {
  public _id: string;
  public name: string;
  public imagePath: string;
  public department: string;
}

export class Contribution {
  public thumb: number;
  public laugh: number;
  public bulb: number;
  public confused: number;
}

export class UpcomingSessionNote {
  public theme: string;
  public notes: string;
}

export class ClaimedPrize {
  public listIndex: number; // Old
  public prize: string; // Old
  public prizeSetId: string;
  public prizeId: string;
  public selectedDate: Date;
}

export class CompletedSession {
  public theme: string;
  public seasonNames: string[];
  public bestScore: number;
  public bestDate: Date;
  public lastDate: Date;
  public lastSeasonName: string;
  public tries: number;
}

export class SystemAdmin {
  public email: string;
}

export class PlayerPrizeSelectionExport {
  constructor(public name: string,
              public email: string,
              public prize: string,
              public listIndex: number) {
  }
}

export class UserCsvExport {
  constructor(public Email: string,
              public Name: string,
              public Department: string,
              public Score: number,
              public Is_Activated: boolean,
              public Amount_Of_Logins: number,
              public Completed_Discussions: number,
              public Upcoming_Discussions: number) {
  }
}

export interface UserPermissions {
  admin: boolean;
  orgManager: boolean;
  gameManager: boolean;

  managedOrganizations: OrganizationNameIDPair[];
  gameManagedOrganizations: OrganizationNameIDPair[];
}

// NetworkInformation only works in Chrome (as of 24.02.2020)
export interface NetworkInformation {
  effectiveType: string; // "slow-2g", "2g", "3g", "4g"
  rtt: number; // Estimated effective round-trip time of the current connection, rounded to the nearest multiple of 25 milliseconds.
  downlink: number; // Effective bandwidth estimate in megabits per second, rounded to the nearest multiple of 25 kilobits per seconds.
  saveData: boolean; // True if the user has set a reduced data usage option on the user agent.

  // Not present in Chrome
  type?: string; // "bluetooth", "cellular", "ethernet", "none", "wifi", "wimax", "other", "unknown"
  downlinkMax?: number;

  onchange?: any; // Not needed
}

export interface WebsocketInformation {
  success: boolean;
  failEmailSent?: Date;
  successEmailSent?: Date;
}
