/**
 * Created by NordicEdu on 17/02/2017.
 */

import { Pipe, PipeTransform } from '@angular/core';
import {Moment} from "moment";
const moment = require("moment");

@Pipe({name: 'logeDate'})
export class LogeDate implements PipeTransform {
  /**
   * Format date
   * @param {Date} value
   * @param {string} format
   * @param {string} locale
   * @param {boolean} fromNow
   * @returns {string}
   */
  transform(value: Date | Moment | string, format: string = null, locale: string = null, fromNow: boolean = false): string {
    const actualDate: Moment = locale ? moment(value).locale(locale) : moment(value);

    if (fromNow) {
      const isToday: boolean = actualDate.isBefore(moment().add(1, 'd').set({hour: 0, minute: 0, second: 0, millisecond: 0}));

      if (isToday) {
        const isInAnHour: boolean = actualDate.isBefore(moment().add(1, 'h'));
        return isInAnHour ? actualDate.fromNow() : actualDate.fromNow() + " (" + actualDate.format("H:mm") + ")";
      }
    }

    if (!format) {
      format = "D.M.YYYY H:mm";
    }

    // Add [na] to the end of format with complete weekday, because Finnish is hard
    if (format.indexOf('dddd') !== -1 && locale === 'fi') {
      format = this.insert(format, format.indexOf('dddd') + 4, '[na]');
    }

    return actualDate.format(format);
  }

  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }
}
