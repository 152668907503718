/**
 * Created by tkok on 28.9.2016.
 */
import {throwError, Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Organization, OrganizationNameIDPair, OrganizationUpdate} from '../../data-model/organization.type';
import {Content, ContentUpdate, ProgressPathSpot, ProgressPathTitle} from '../../data-model/content.type';
import {UserService} from "./user.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, filter, first, map} from "rxjs/operators";
import { GameTimeslot } from "../../data-model/timeslot.type";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  // Current users organzation
  public currentOrganization$: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(undefined);
  public activeOrganizationUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public currentOrgContent$: BehaviorSubject<Content> = new BehaviorSubject<Content>(undefined);

  public allOrgs$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>(undefined);
  public currentManagedOrgs$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>(undefined);
  public organizationNameList$: BehaviorSubject<OrganizationNameIDPair[]> = new BehaviorSubject<OrganizationNameIDPair[]>([]);
  private playerProgressPath: ProgressPathSpot[];

  private organizationNameListUrl = 'api/org/namelistall/';  // URL to web API
  private organizationUrl = 'api/org/';  // URL to web API
  private organizationGuestsUrl = 'api/guests/org/'; // URL to web API
  private organizationUpdateUrl = 'api/org/update/';  // URL to web API
  private organizationAddUrl = 'api/org/add-empty/';  // URL to web API
  private subOrganizationAddUrl = 'api/org/add-sub-org/';  // URL to web API
  private getParentSubOrgsUrl = 'api/org/parent-sub-orgs/';  // URL to web API
  private fetchAllUrl = "api/org/list-all/";  // URL to web API
  private managedUrl = "api/org/current-managed/";  // URL to web API
  private orgCheckPasswordUrl = 'api/org/check-password/';  // URL to web API

  private contentUrl = 'api/content/'; // URL to web API
  private contentUpdateUrl = 'api/content/update/'; // URL to web API
  private contentWithPasswordUrl = 'api/guests/content/'; // URL to web API

  constructor(private http: HttpClient,
              private userService: UserService) {
    this.userService.currentUser$
      .pipe(
        filter(user => !user)
      )
      .subscribe(
        () => {
          this.currentOrganization$.next(undefined);
          this.currentOrgContent$.next(undefined);
          this.currentManagedOrgs$.next(undefined);
          this.allOrgs$.next(undefined);
        }
      );

    this.userService.currentUser$
      .pipe(
        filter(user => !!user),
        first()
      )
      .subscribe(
        () => {
          this.fetchCurrentOrganization();
        }
      );
  }

  /**
   * Get current organization
   */
  /*public getCurrentOrganization(): void {
    this.userService.currentUser$
      .pipe(
        first()
      )
      .subscribe(user => {
        if (!user) {
          return;
        }

        this.http.get(this.organizationUrl + user.orgId)
          .subscribe(
            res => {
              const org: Organization = res as Organization;
              if (!org.managers) {
                org.managers = [];
              }
              if (!org.gameManagers) {
                org.gameManagers = [];
              }

              this.currentOrganization$.next(org);
              this.fetchCurrentContent(org._id);
              this.fetchManagedOrganizations();
            },
            err => {
              console.error("Fetching organization failed. Error:", err);
              this.currentOrganization$.next(null);
            }
          );
      });
  }*/

  // Fetch organization and content by logged in user
  public fetchCurrentOrganization(): void {
    this.http.get<{org: Organization, content: Content}>("/api/org/current-user-org/")
      .subscribe(
        res => {
          const org: Organization = res.org;
          const content: Content = res.content;

          if (org) {
            if (!org.managers) {
              org.managers = [];
            }

            if (!org.gameManagers) {
              org.gameManagers = [];
            }
            
            if (!org.biViewers) {
              org.gameManagers = [];
            }
          }

          /*
          if (!content?.progress?.prizeSets) {
            content.progress.prizeSets = [];
          }
          */

          if (!!content?.pbiIframe) {
            content.progress.prizeSets = null;
          }

          this.currentOrganization$.next(org);
          this.currentOrgContent$.next(content);
        },
        err => {
          console.error("Fetching organization and content failed. Error:", err);
          this.currentOrganization$.next(null);
          this.currentOrgContent$.next(null);
        }
      );
  }

  /**
   * Get organization content
   */
  public getOrganizationContent(orgId: string): Observable<Content> {
    return this.http.get(this.contentUrl + orgId)
      .pipe(
        map(res => {
          return res as Content;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get list of organization names
   */
  fetchOrganizationNameList(): void {
    this.http.get(this.organizationNameListUrl)
      .subscribe(
        res => {
          this.organizationNameList$.next(res as OrganizationNameIDPair[]);
        },
        err => {
          console.error("Fetching organizationNameList failed. Error:", err);
          this.organizationNameList$.next([]);
        }
      );
  }

  /**
   * Get player progress path
   * @returns {ProgressPathSpot[]}
   */
  public getPlayerProgressPath(): ProgressPathSpot[] {
    return this.playerProgressPath ? this.playerProgressPath : [];
  }

  /**
   * Get interval between player progress paths
   * @returns {number}
   */
  public getPlayerProgressPathInterval(): number {
    if (this.currentOrgContent$.value) {
      return this.currentOrgContent$.value.progress.stepInterval;
    }
    return 0;
  }

  /**
   * Get titles for player progress
   * @returns {ProgressPathTitle[]}
   */
  public getOrgProgressTitles(): ProgressPathTitle[] {
    if (!this.currentOrgContent$.value) {
      return [];
    }

    return this.currentOrgContent$.value.progress.progressTitles;
  }

  getProgressReportOrganization(orgID: string): Observable<{
    organization: Organization,
    content: Content
  }> {
    return this.http.put(this.organizationUrl + "progress-report/", {
      _id: orgID,
    })
      .pipe(
        map(res => {
          return res as {
            organization: Organization,
            content: Content
          };
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get organization by ID
   * @param {string} orgID
   * @returns {Observable<Organization>}
   */
  getOrganization(orgID: string): Observable<Organization> {
    return this.http.get(this.organizationUrl + orgID)
      .pipe(
        map(res => {
          return res as Organization;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getOrgWithPassword(userInput: Object): Observable<Organization> {
    return this.http.put(this.organizationGuestsUrl, userInput)
      .pipe(
        map(res => {
          return res as Organization;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get organization content by ID
   * @param {string} orgID
   * @returns {Observable<Content>}
   */
  getOrgContent(orgID: string): Observable<Content> {
    return this.http.get(this.contentUrl + orgID)
      .pipe(
        map(res => {
          return res as Content;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get organization content by ID and password
   * @param {Object} userInput {orgId: string, password: string}
   * @returns {<Content>}
   */
  public getOrgContentWithPassword(userInput: object): Observable<Content> {
    return this.http.put(this.contentWithPasswordUrl, userInput)
      .pipe(
        map(res => {
          return res as Content;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public orgCheckPassword(orgId: string, pw: string): Observable<boolean> {
    return this.http.get(this.orgCheckPasswordUrl + orgId + '/' + pw)
      .pipe(
        map(res => {
          return res as boolean;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Update changes to organization
   * @param {any} changes
   * @param {string} orgId
   * @returns {Observable<Organization>}
   */
  updateOrganization(changes: Organization | OrganizationUpdate, orgId?: string): Observable<Organization> {
    if (!orgId) {
      orgId = changes._id;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.put(this.organizationUpdateUrl + orgId, changes, options)
      .pipe(
        map(res => {
          this.fetchManagedOrganizations();
          this.activeOrganizationUpdate$.next((res as Organization)._id);
          return res as Organization;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateTestOrgLanguage(org: Organization): Observable<Organization> {
    return this.http.get('api/org/update-test-language/' + org._id + '/' + org.defaultLanguage)
      .pipe(
        map(res => {
          return res as Organization;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public fetchAllOrganizations(): void {
    this.http.get(this.fetchAllUrl)
      .subscribe(
        res => {
          this.allOrgs$.next(res as Organization[]);
        },
        err => {
          console.error("Failed fetching all organizations", err);
          this.allOrgs$.next(null);
        }
      );
  }

  public fetchManagedOrganizations(): void {
    this.http.get(this.managedUrl)
      .subscribe(
        res => {
          this.currentManagedOrgs$.next(res as Organization[]);

        },
        err => {
          console.error("Failed fetching managed organizations", err);
          this.currentManagedOrgs$.next(null);
        }
      );
  }

  /**
   * Update changes to organization content
   * @param {Content} content
   * @param {string} orgId
   * @returns {Observable<Content>}
   */
  updateOrgContent(content: Content | ContentUpdate, orgId?: string): Observable<Content> {
    if (!orgId) {
      orgId = content.orgId;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.put(this.contentUpdateUrl + orgId, content, options)
      .pipe(
        map(res => {
          this.activeOrganizationUpdate$.next((res as Content).orgId);
          return res as Content;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Add new organization
   * @returns {*}
   */
  addOrganization(): Observable<{ org: Organization, content: Content }> {
    return this.http.get(this.organizationAddUrl)
      .pipe(
        map(res => {
          return res as { org: Organization, content: Content };
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Add new sub-organization
   * @returns {*}
   */
  addSubOrganization(parentId: string): Observable<{ org: Organization, content: Content }> {
    return this.http.get(this.subOrganizationAddUrl + parentId)
      .pipe(
        map(res => {
          return res as { org: Organization, content: Content };
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getAllowedOrganizations(subOrgId: string, userEmail?: string): Observable<Organization[]> {
    let userDomain;
    if (userEmail) {
      const domainBegin = userEmail.indexOf('@');
      userDomain = userEmail.slice(domainBegin + 1);
    }

    const params = userDomain ? subOrgId + "/" + userDomain : subOrgId;
    return this.http.get(this.getParentSubOrgsUrl + params)
      .pipe(
        map(res => {
          return res as Organization[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
