import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {YoutubeInfoInterface} from "../../data-model/youtube-info.interface";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";

export class YoutubeUtility {
  public static isValidYoutubeId(id: string) {
    if (!id || id.length !== 11) {
      return false;
    }
    return /[a-zA-Z0-9_-]{11}/.test(id);
  }

  public static parseYoutubeId(link: string) {
    if (!link) {
      return;
    }

    if (YoutubeUtility.isValidYoutubeId(link)) {
      // Already valid
      return link;
    }

    const normalLinkIndex: number = link.indexOf("?v=");
    const shortenedLinkIndex: number = link.indexOf(".be/");

    if (normalLinkIndex === -1 && shortenedLinkIndex === -1) {
      return;
    }

    const idStartIndex: number = normalLinkIndex !== -1 ? normalLinkIndex + 3 : shortenedLinkIndex + 4;

    const parsedId: string = link.substr(idStartIndex, 11);

    if (YoutubeUtility.isValidYoutubeId(parsedId)) {
      return parsedId;
    }

    return;
  }

  public static getFullYouTubeLink(value: string): string {
    if (!value) {
      return;
    }

    if (value.indexOf("youtube.com") !== -1 || value.indexOf("youtu.be") !== -1) {
      // Should be valid already
      const linkId: string = YoutubeUtility.parseYoutubeId(value);

      if (!linkId) {
        return;
      }

      return "https://www.youtube.com/embed/" + linkId + "?autoplay=0&rel=0&ps=docs&showInfo=0";
    }

    if (YoutubeUtility.isValidYoutubeId(value)) {
      // Add full link path
      return "https://www.youtube.com/embed/" + value + "?autoplay=0&rel=0&ps=docs&showInfo=0";
    }

    // Invalid
    return;
  }
}

@Injectable({
  providedIn: 'root'
})
export class YoutubeInfoService {

  public videoInfo$: BehaviorSubject<YoutubeInfoInterface> = new BehaviorSubject<YoutubeInfoInterface>(undefined);

  constructor(private http: HttpClient) {

  }

  public resetVideoInfo() {
    this.videoInfo$.next(null);
  }

  /**
   * Get video info from YouTube API
   * @param {string} videoId
   */
  public getVideoInfo(videoId: string): void {
    const apiUrl = 'https://www.googleapis.com/youtube/v3/videos?id=';
    const apiKey = '&key=AIzaSyBXuQPlhSo7Z7s2DS-9Ss-QbkFjagEKE6U';
    const part = '&part=snippet,contentDetails';
    const combinedUrl = apiUrl + videoId + apiKey + part;

    this.http.get(combinedUrl)
      .subscribe(
        res => {
          this.videoInfo$.next(res['items'][0]);
        },
        err => {
          console.error("Error getting info for YouTube videoId " + videoId, err);
          this.videoInfo$.next(null);
        }
      );
  }

  public fetchVideoInfo(videoId: string): Observable<YoutubeInfoInterface> {
    const apiUrl = 'https://www.googleapis.com/youtube/v3/videos?id=';
    const apiKey = '&key=AIzaSyBXuQPlhSo7Z7s2DS-9Ss-QbkFjagEKE6U';
    const part = '&part=snippet,contentDetails';
    const combinedUrl = apiUrl + videoId + apiKey + part;

    return this.http.get(combinedUrl)
      .pipe(
        map(res => {
          return res['items'][0];
        }),
        catchError(err => {
          console.error("Failed getting video info from YouTube API", err);
          return null;
        })
      );
  }
}
