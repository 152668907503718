import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import MediasoupRoom from "../../../../lib/mediasoup/mediasoup-room";
import {User} from "../../../data-model/user.type";

import {getDeviceInfo, isDeviceSupported} from 'mediasoup-client';
import {filter} from "rxjs/operators";
import { AudioDeviceInfo, AudioDeviceService } from "../../../core/service/audio-device.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";

const hark = require('hark');

@Component({
  selector: 'audio-circle',
  template: `
    <div class="audio-circle-wrapper">
      <div class="audio-circle-container">
        <svg [attr.height]="(radius * 2) + format" [attr.width]="(radius * 2) + format"
        >
          <circle
            #circle

            [attr.r]="normalizedRadius + format"
            [attr.cx]="radius + format"
            [attr.cy]="radius + format"
            [attr.stroke-width]="stroke + format"
            [attr.stroke-dasharray]="circumference + format + ' ' + circumference + format"

            [ngStyle]="{'stroke-dashoffset': dashOffset, 'visibility': (chatRoom ? 'unset' : 'hidden')}"
          />
        </svg>

        <span
          *ngIf="(!chatRoom || chatRoom?.roomState !== 'connected') && preConnectMessage"
          class="glyphicon"
          [ngStyle]="{'font-size': (radius / 1.5) + format}"
          [ngClass]="{'glyphicon-exclamation-sign': preConnectMessage !== 'chat-connecting', 'glyphicon-question-sign': preConnectMessage === 'chat-connecting'}"
        >
        <span
          [ngStyle]="{'font-size': (radius / 5) + format}"
          [ngClass]="{'voice-error': preConnectMessage !== 'chat-connecting', 'voice-notification': preConnectMessage === 'chat-connecting'}"
        >
          {{'audio-chat-info.' + preConnectMessage | translate}}
        </span>
      </span>

        <ng-container *ngIf="chatRoom?.roomState === 'connected'">
          <!--<span
            class="icon-faded"
            [ngClass]="{'icon-mic-on': micDetected, 'icon-mic-off': !micDetected}"
            [ngStyle]="{'font-size': radius + format}"
          ></span>-->

          <span
            *ngIf="micDetected && !voiceDetected"
            class="glyphicon glyphicon-question-sign"
            [ngStyle]="{'font-size': (radius / 1.5) + format}"
          >
          <span
            class="voice-notification"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{'audio-chat-info.mic-not-heard-short' | translate}}
          </span>
        </span>

          <span
            *ngIf="voiceDetected"
            class="glyphicon glyphicon-ok-circle"
            [ngStyle]="{'font-size': radius + format}"
          ></span>

          <span
            *ngIf="voiceError"
            class="glyphicon glyphicon-exclamation-sign"
            [ngStyle]="{'font-size': (radius / 1.5) + format}"
          >
          <span
            *ngIf="voiceError === 'PermissionDismissedError' || voiceError === 'NotAllowedError'"
            class="voice-error"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{'audio-chat-info.mic-blocked-short' | translate}}
          </span>

          <span
            *ngIf="voiceError === 'NotFoundError'"
            class="voice-error"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{'audio-chat-info.mic-not-found-short' | translate}}
          </span>

          <span
            *ngIf="voiceError === 'NotReadableError'"
            class="voice-error"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{'audio-chat-info.mic-not-found-short' | translate}}
          </span>

          <span
            *ngIf="voiceError !== 'PermissionDismissedError' && voiceError !== 'NotAllowedError' && voiceError !== 'NotFoundError' && voiceError !== 'NotReadableError'"
            class="voice-error"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{('audio-chat-info.mic-unknown-error' | translate) + voiceError}}
          </span>

          <span
            *ngIf="!voiceError && deviceError"
            class="voice-error"
            [ngStyle]="{'font-size': (radius / 5) + format}"
          >
            {{(deviceError | translate)}}
          </span>
        </span>

          <span
            class="glyphicon glyphicon-remove"
            [ngStyle]="{'font-size': (radius / 5) + format, 'top': (radius / 5) + format, 'right': (radius / 5) + format}"
            (click)="closePersonalConnection()"
          ></span>
        </ng-container>


        <div
          *ngIf="currentUser && (!chatRoom || chatRoom?.roomState !== 'connected') && !preConnectMessage"
          class="start-mic-test"
          (click)="startPersonalChatRoom()"
        >
          <span class="glyphicon glyphicon-play-circle" [ngStyle]="{'font-size': radius + format}"></span>
          <h2>{{"new-tutorial.mic-test-start" | translate}}</h2>
        </div>
      </div>

      <audio-device-selector></audio-device-selector>
    </div>
  `,
  styles: []
})
export class AudioCircleComponent extends OnDestroyMixin implements OnInit, OnChanges, OnDestroy {
  @ViewChild('circle', {static: true}) circleElement: ElementRef;

  @Input() currentUser: User;

  @Input() radius: number = 10;
  @Input() stroke: number = 1.5;
  @Input() format: string = "vmin";

  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public normalizedRadius: number = this.radius - this.stroke * 2;
  public circumference: number = this.normalizedRadius * 2 * Math.PI;
  public dashOffset: string = this.circumference + this.format;
  public percentageText: string = "0";

  public chatRoom: MediasoupRoom;
  public localSpeech: any;

  public voiceDetected: boolean;
  public micDetected: boolean;
  public preConnectMessage: string;

  public voiceError: string;
  public deviceError: string;

  constructor(
    private audioDeviceService: AudioDeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.audioDeviceService.selectedDevices$
      .pipe(
        filter(selectedDevices => !!selectedDevices || (!selectedDevices.input && !selectedDevices.output))
      )
      .subscribe(() => {
        if (!this.chatRoom) {
          // Test not started
          return;
        }

        // Reset success state
        this.voiceDetected = false;
        this.successEmitter.emit(null);

        setTimeout(() => {
          this.setLocalVoiceListener();
        }, 1000);
      });

    this.audioDeviceService.deviceError$
      .pipe(
        filter(errorMsg => !!errorMsg)
      )
      .subscribe((errorMsg: string) => {
        this.deviceError = errorMsg;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.radius || changes.stroke) && this.radius && this.stroke) {
      this.normalizedRadius = this.radius - this.stroke * 2;
      this.circumference = this.normalizedRadius * 2 * Math.PI;
    }
  }

  ngOnDestroy(): void {
    this.closePersonalConnection();
  }

  public setPercentage(percentage: number, max: number) {
    this.percentageText = "" + percentage;
    this.dashOffset = this.circumference - ((percentage ? percentage : 0) / max * this.circumference) + this.format;
  }

  public closePersonalConnection() {
    if (this.chatRoom) {
      this.chatRoom.closeConnection();
      this.chatRoom = null;
    }

    if (this.localSpeech) {
      this.localSpeech.stop();
      this.localSpeech = null;
    }

    this.voiceError = null;
    this.preConnectMessage = null;
    this.voiceDetected = null;
    this.micDetected = null;
  }

  public checkConnectionError(): void {
    if (this.chatRoom && this.chatRoom.roomState === "connected") {
      // Connection successful
      return;
    }

    if (!this.preConnectMessage || this.preConnectMessage !== "chat-connecting") {
      // Message has changed
      return;
    }

    this.preConnectMessage = "room-start-failed";
  }

  private startPersonalChatRoom() {
    if (!this.currentUser) {
      return;
    }

    this.preConnectMessage = "chat-connecting";

    setTimeout(() => {
      this.checkConnectionError();
    }, 5000);

    const device: any = getDeviceInfo();

    if (!isDeviceSupported()) {
      console.error("Device is not supported by WebRTC!", device);
      this.preConnectMessage = "not-supported";

      return;
    }

    try {
      this.chatRoom = new MediasoupRoom(
        this.currentUser._id,
        this.currentUser._id,
        device,
        this.audioDeviceService,
        true
      );
    } catch (err) {
      console.error("Failed starting test chatRoom", err);

      this.preConnectMessage = "room-start-failed";

      return;
    }

    this.chatRoom.notificationsSubject$
      .pipe(
        untilComponentDestroyed(this),
        filter(notification => !!notification && notification.type === "micProducerCreated"),
      )
      .subscribe(() => {
        this.micDetected = true;

        setTimeout(() => {
          this.setLocalVoiceListener();
        }, 500);
      });

    this.chatRoom.errorsSubject$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(error => {
        if (!error) {
          return;
        }

        this.voiceError = error.name;
        console.error("Voice chat room error", error);
      });
  }

  private setLocalVoiceListener() {
    if (!this.chatRoom || !this.chatRoom.micProducer || !this.chatRoom.micProducer.track) {
      console.error("No voice chat room found or microphone hasn't connected");
      return;
    }

    const localTrack: MediaStreamTrack = this.chatRoom.micProducer.track;
    const localStream = new MediaStream;
    localStream.addTrack(localTrack);

    if (this.localSpeech) {
      this.localSpeech.stop();
    }

    // Create a hark instance to listen to the local audio stream volume
    this.localSpeech = hark(localStream, {play: true, interval: 200});

    // Use 80 as min, since some devices emit white noise
    const mindBs: number = 80;

    this.localSpeech.on('volume_change', dBs => {
      if (!this.voiceDetected && dBs > -mindBs) {
        this.voiceDetected = true;
        this.successEmitter.emit(true);

        // Save successful selection
        this.audioDeviceService.saveUserAudioDeviceSelection("input");
      }

      if (dBs < -mindBs) {
        dBs = -mindBs;
      }

      if (dBs > 0) {
        dBs = 0;
      }

      this.setPercentage(dBs + mindBs, mindBs);
    });
  }
}
