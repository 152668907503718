import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  transform(htmlString: string): SafeResourceUrl {
    if (!htmlString) {
      return;
    }

    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
}
