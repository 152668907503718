/**
 * Created by TopSal on 12.10.2018
 */

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MaterialInterface} from "../../data-model/material.interface";
import {MediaUtils} from "../../../lib/media-utils";
import {GeneralUtils} from "../../../lib/general-utils";
import {User} from "../../data-model/user.type";
import {Measure} from "../../data-model/measure-question.interface";

@Component({
  selector: 'centered-pop-up',
  template: `
    <div id="pop-up-container"
         class="pop-up-container"
         *ngIf="popUpVisible"
         (elementClicked)="clickedOutside($event)"
         [ngClass]="containerClass"
         [ngStyle]="customWidth && {width: customWidth}"
    >
      <button (click)="closePopUp()" class="pop-up-close-button">
        <span class="glyphicon glyphicon-remove"></span>
      </button>

      <h2 class="pop-up-header" [innerHtml]="(headerText | translate) | newLine"></h2>

      <div *ngIf="!materialContent" class="gradient-line"></div>

      <div *ngIf="bodyText" class="pop-up-text" [innerHtml]="((bodyText | translate) | newLine) | safeHtml"></div>

      <div *ngIf="materialContent" class="pop-up-material">
        <div *ngIf="materialContent.type === 'link'"
             [ngStyle]="{'display': 'flex', 'flex-direction': 'column'}">
          <h4>{{'player-progress-view.link-new-tab' | translate}}</h4>
          <a target="_blank" [href]="materialContent.path"
             title="{{'player-progress-view.in-new-tab' | translate}}">
            <!--<button class="material-selection-button">{{materialContent.path}}</button>-->

            <raised-css-button
              [buttonText]="materialContent.path"
              [fontSize]="'3vmin'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>
          </a>
        </div>

        <div *ngIf="materialContent.type === 'videoId'"
             [ngStyle]="!materialContent && {'display': 'none'}">
          <youtube-player
            [youTubeId]="materialContent.path"
            [identifier]="materialContent._id"
          ></youtube-player>
        </div>

        <ng-container *ngIf="materialType === 'pdf'">
          <!--<custom-pdf-viewer
            [pdfUrl]="getActualFilePath(materialContent)"
            [pdfWidth]="'45vw'"
          ></custom-pdf-viewer>-->

          <h4>{{'player-progress-view.link-new-tab' | translate}}</h4>
          <a target="_blank" [href]="getActualFilePath(materialContent)"
             title="{{'player-progress-view.in-new-tab' | translate}}">
            <!--<button class="material-selection-button">{{materialContent.path}}</button>-->

            <raised-css-button
              [buttonText]="getActualFilePath(materialContent)"
              [fontSize]="'3vmin'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>
          </a>
        </ng-container>

        <ng-container *ngIf="materialType === 'image'">
          <img [src]="getActualFilePath(materialContent)" [alt]="materialContent?.path">
        </ng-container>

        <ng-container *ngIf="materialType === 'video'">
          <video [src]="getActualFilePath(materialContent)" [controls]="1"></video>
        </ng-container>
      </div>

      <email-contact-form
        *ngIf="contactFormUser"
        [currentUser]="contactFormUser"
        [location]="contactFormLocation"
        [marginTop]="'2vmin'"
      ></email-contact-form>

      <discussion-feedback
        *ngIf="measureQuestions"
        [measureQuestions]="measureQuestions"
        [userId]="userId"
        [orgId]="orgId"
        [seasonId]="seasonId"
        [sessionId]="sessionId"
        [isFrontPage]="true"
        (closeView)="closePopUp()"
      ></discussion-feedback>
    </div>
  `
})
export class CenteredPopUpComponent {
  @Input() popUpVisible: boolean;
  @Input() allowOutsideClick: boolean;
  @Input() customWidth: string;
  @Input() headerText: string;
  @Input() bodyText: string;
  @Input() materialContent: MaterialInterface;
  @Input() materialType: string;
  @Input() allowedElementIds: string[] = [];

  // Discussion feedback
  @Input() measureQuestions: Measure[];
  @Input() userId: string;
  @Input() orgId: string;
  @Input() seasonId: string;
  @Input() sessionId: string;

  @Input() containerClass: Object;

  @Input() contactFormUser: User;
  @Input() contactFormLocation: string;

  @Output() popUpEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {

  }

  public clickedOutside(elementId: string): void {
    if (this.allowOutsideClick) {
      return;
    }

    if (GeneralUtils.clickedOutside(elementId, this.allowedElementIds)) {
      this.popUpEmitter.emit(false);
    }
  }

  public closePopUp(): void {
    this.popUpEmitter.emit(false);
  }

  public checkForFiletype(path: string, type: string): boolean {
    const pathType = path.substr(path.lastIndexOf('.') + 1);

    return pathType.toLowerCase() === type.toLowerCase();
  }

  public getActualFilePath(material: MaterialInterface): string {
    if (material.media) {
      return material.media.path;
    }

    return MediaUtils.getOrgFile(material.path, material.orgId);
  }
}
