<div class="org-removed-message" *ngIf="showInvalidOrganizationMessage">
    <h1>Unable to find your LOGE organization! Either your organization has been removed or your connection to the LOGE server was interrupted.</h1>

    <raised-css-button
      [buttonText]="'user-button-bar.logout'"
      (onClick)="invalidOrgLogout()"
      [fontSize]="'5vmin'"
      [marginTop]="'1vmin'"
      [marginBottom]="'0'"
    ></raised-css-button>
  </div>

<!--    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">-->

  <div class="landing-page-container" [ngStyle]="pageStyle">
    <!-- Contact message to test users -->
    <landing-checkbox
      *ngIf="currentUser?.userType === 1 && !currentUser?.contactAccepted"
      [currentUser]="currentUser"
    ></landing-checkbox>

    <next-discussion-info
      [currentUser]="currentUser"
      [upcomingDiscussions]="userUpcomingSessions"
      [activeDiscussion]="activeDiscussion"
    ></next-discussion-info>

    <logo-badge [floating]="true"></logo-badge>

    <div class="center-container" [ngClass]="{lowered: activeDiscussion || userUpcomingSessions?.length}">
      <div class="organization-info">
        <h1 *ngIf="currentOrganization?.organizationType !== 2"
            [innerHtml]="('player-progress-view.title' | translate) | newLine"></h1>
        <h1 *ngIf="currentOrganization?.organizationType === 2"
            [innerHtml]="('test-organization.project-title' | translate) | newLine"></h1>

        <p [innerHtml]="('player-progress-view.instructions' | translate) | newLine"></p>
      </div>

      <user-statistics
        [currentUser]="currentUser"
        [currentOrganization]="currentOrganization"
        [currentOrganizationContent]="organizationContent"
        [userSessions]="userSessions"
        [showingIntroTutorial]="!currentUser?.hideIntroTutorial"
        (tutorialSelectionEmitter)="setTutorial($event)"
        (openSignUpEmitter)="openTab('sign-up')"
      ></user-statistics>
    </div>

    <div>
      <large-drawer
        [contentType]="'introduction-tutorial'"
        [drawerTitle]="'introduction-tutorial.helper-title' | translate"
        [iconTitle]="'introduction-tutorial.helper-icon-title' | translate"
        [position]="'left'"
        [currentUser]="currentUser"
        [loweredVertical]="1"
        [autoOpen]="!showIntroPopUp && showHelperAutomatically"
        [organizationContent]="organizationContent"
        (tutorialSelectionEmitter)="setTutorial($event)"
        (drawerOpen)="openTab($event)"
      ></large-drawer>

      <large-drawer
        [contentType]="'player-progress'"
        [drawerTitle]="'player-progress-view.title' | translate"
        [iconTitle]="'player-progress-view.icon-title' | translate"
        [position]="'right'"
        [currentUser]="currentUser"
        [userSessions]="userSessions"
        [userUpcomingSessions]="userUpcomingSessions"
        [organizationContent]="organizationContent"
        [currentOrganization]="currentOrganization"
        (drawerOpen)="openTab($event)"
      ></large-drawer>

      <large-drawer
        [contentType]="'upcoming-discussions'"
        [drawerTitle]="'player-progress-view.enrollment-title' | translate"
        [iconTitle]="'player-progress-view.enrollment-icon-title' | translate"
        [position]="'left'"
        [currentUser]="currentUser"
        [userSessions]="userSessions"
        [currentIntroTutorial]="currentIntroTutorial"
        [organizationContent]="organizationContent"
        [currentOrganization]="currentOrganization"
        [currentTab]="currentTab"
        [tabIdentifier]="'sign-up'"
        (onJoinedDiscussionsChanged)="updateNextDiscussion()"
        (drawerOpen)="openTab($event)"
      ></large-drawer>
    </div>
  </div>

  <div class="floating-user-button-bar" [ngStyle]="{'position': 'fixed'}">
    <user-button-bar
      [currentUser]="currentUser"
      [currentOrganization]="currentOrganization"
      [showClose]="false"
      [currentTab]="currentTab"
      [hideSettings]="showInvalidOrganizationMessage"
      (toggleMiniAdminEmitter)="showMiniAdmin = !showMiniAdmin"
    ></user-button-bar>
  </div>

  <mini-admin
    *ngIf="showMiniAdmin && currentOrganization?.organizationType === 2 && currentUser?.userType === 1"
    [currentUser]="currentUser"
    [currentOrganization]="currentOrganization"
    [allowedElementIds]="['test-admin-button']"
    (onClose)="showMiniAdmin = false"
  ></mini-admin>

  <centered-pop-up
    [popUpVisible]="showIntroPopUp"
    (popUpEmitter)="hidePopUp()"
    [headerText]="'welcome-pop-up.pop-up-header'"
    [bodyText]="organizationContent?.welcomeContent?.text ? organizationContent.welcomeContent.text : 'welcome-pop-up.pop-up-text'"
  ></centered-pop-up>

  <tutorial-selector
    [tutorialVisible]="showTutorialPopUp"
    [outsideSelection]="selectedTutorial"
    (tutorialCloseEmitter)="closeTutorialPopUp()"
  ></tutorial-selector>

  <confirm-modal
    *ngIf="showIdleInfo"
    [ngClass]="'absolute-backdrop'"
    [confirmMessage]="'situation.auto-disconnect-notification'"
    [customWidth]="40"
    [customWidthFormat]="'vw'"
    [hideSecondButton]="true"
    (sendConfirmation)="showIdleInfo = false"
  ></confirm-modal>

  <loading-indicator
    [requirements]="{
      user: !!currentUser,
      sessions: !!userSessions,
      organization: !!currentOrganization,
      content: !!organizationContent
    }"
  ></loading-indicator>