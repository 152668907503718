/**
 * Created by Topi on 29.08.2018
 */
import {
  Output, EventEmitter, Component, Input, ViewChild,
  ElementRef, SimpleChanges, OnChanges
} from '@angular/core';
import {User} from "../../data-model/user.type";
import {GameSessionLog} from "../../data-model/game-session-log.type";
import {Content} from "../../data-model/content.type";
import {GameTimeslot} from "../../data-model/timeslot.type";
import {GameSeason} from "../../data-model/game-season.type";
import {Moment} from "moment";
const moment = require("moment");

export interface Point {
  x: number;
  y: number;
  size: number;
}

@Component({
  selector: 'sine-wave-graph',
  template: `
    <div *ngIf="nothingToShow">
      <h2 class="no-progress">
        {{'player-progress-view.no-progress-to-show' | translate}}
      </h2>
    </div>

    <div *ngIf="!nothingToShow && !showProgressPath" class="loading-indicator"></div>

    <div class="sine-container" [ngStyle]="(nothingToShow || !showProgressPath) && {'visibility': 'hidden'}">
      <!-- Prize selection -->
      <!--<prize-selection
        *ngIf="showPopUp"
        [user]="currentUser"
        [prizeSet]="selectedPrizeSet"
        [prizeIndex]="selectedPrizeIndex"
        (close)="closePopup()"
        [allowedElementIds]="['prize-selection-chest-']"
        [customWidth]="30"
        [customWidthFormat]="'vw'"
      ></prize-selection>-->

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="svg-container">
        <defs>
          <linearGradient id="opacity_stops" x1="0%" y1="0%" x2="0%" y2="100%" *ngIf="currentUserPercentage >= 0">
            <stop [attr.offset]="currentUserPercentage - 15 + '%'" style="stop-color: black; stop-opacity: 0"/>
            <stop [attr.offset]="currentUserPercentage - 12 + '%'" style="stop-color: black; stop-opacity: 0"/>
            <stop [attr.offset]="currentUserPercentage - 10 + '%'" style="stop-color: white; stop-opacity: 1"/>
            <stop [attr.offset]="currentUserPercentage + 10 + '%'" style="stop-color: white; stop-opacity: 1"/>
            <stop [attr.offset]="currentUserPercentage + 12 + '%'" style="stop-color: white; stop-opacity: 1"/>
            <stop [attr.offset]="currentUserPercentage + 15 + '%'" style="stop-color: white; stop-opacity: 1"/>
          </linearGradient>
.
          <linearGradient id="completion-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" class="line-empty" [ngStyle]="{'stop-color': lineColor}"/>
            <stop [attr.offset]="(currentUserPercentage >= 0 ? currentUserPercentage : 99.99) + '%'"
                  class="line-empty"
                  [ngStyle]="{'stop-color': lineColor}"/>
            <stop [attr.offset]="(currentUserPercentage >= 0 ? currentUserPercentage + 0.01 : 100) + '%'"
                  class="line-filled"
                  [ngStyle]="{'stop-color': completedLineColor}"/>
            <stop offset="100%" class="line-filled" [ngStyle]="{'stop-color': completedLineColor}"/>
          </linearGradient>
        </defs>

        <!-- Use opacity mask if line created be session amount -->
        <g>
          <path fill="none" [attr.stroke-width]="lineWeight" [attr.d]="sinePath" stroke="url(#completion-gradient)"
                #SinePath
                id="sine-path"></path>

          <ng-container *ngFor="let point of linePoints; let i = index" >
            <circle [attr.cx]="point.x" [attr.cy]="point.y"
                    [attr.r]="i > lastCircleIndex ? 10 : point.size" id="line_point_{{i}}" class="circle-empty"
                    [ngClass]="{'circle-filled': currentUserLinePoint >= i, 'large': largeDotIndexes.indexOf(i) !== -1, 'filler-circle': i > lastCircleIndex}"
                    [attr.stroke-width]="point.size / 2"
            />
          </ng-container>

          <!-- Render last circle on top so filler-circles go below -->
          <use *ngIf="lastCircleIndex >= 0" id="use" [attr.xlink:href]="'#line_point_' + lastCircleIndex" />

          <rect
            *ngIf="linePoints?.length"
            id="start-box"
            class="start-square"
            [ngClass]="{'reached': currentUserLinePoint >= lastCircleIndex}"
            [attr.x]="linePoints[0].x - (userDotSize / 2)"
            [attr.y]="linePoints[0].y - (userDotSize / 2)"
            [attr.transform-origin]="linePoints[0].x + ' ' + linePoints[0].y"
            [attr.transform]="'rotate(45)'"
            [attr.width]="userDotSize"
            [attr.height]="userDotSize"
          />

          <polygon
            id="goal-star"
            class="ending-star"
            points="16.86 1.5 21.61 11.12 32.23 12.66 24.55 20.15 26.36 30.73 16.86 25.73 7.37 30.73 9.18 20.15 1.5 12.66 12.12 11.12 16.86 1.5"
          />
        </g>

        <g *ngIf="linePoints?.length && currentUserLinePoint >= 0 && linePoints[currentUserLinePoint]">
          <!--User circle-->
          <rect
            [ngStyle]="!showScore && {visibility: 'hidden'}"
            class="user-score-box"
            id="user-score-rect"
            rx="2"
            ry="2"
          />

          <text
            [ngStyle]="!showScore && {visibility: 'hidden'}"
            class="user-score" id="svg-user-score-text"
            [attr.x]="linePoints[currentUserLinePoint].x"
            [attr.y]="linePoints[currentUserLinePoint].y - (userDotSize * 3.25)"
            alignment-baseline="middle"
            text-anchor="middle"
          >
            {{currentUser?.score || 0}}
          </text>

          <rect
            class="profile-arrow"
            id="user-score-arrow"
            [attr.x]="linePoints[currentUserLinePoint].x - userDotSize"
            [attr.y]="linePoints[currentUserLinePoint].y - userDotSize"
            [attr.transform-origin]="linePoints[currentUserLinePoint].x + ' ' + linePoints[currentUserLinePoint].y"
            [attr.width]="userDotSize"
            [attr.height]="userDotSize"
          />

          <circle
            [attr.cx]="linePoints[currentUserLinePoint].x"
            [attr.cy]="linePoints[currentUserLinePoint].y - (userDotSize * 1.5)"
            [attr.r]="userDotSize" class="circle-filled"
            [attr.stroke-width]="linePoints[currentUserLinePoint].size / 2"
          />

          <circle
            class="profile-circle"
            [attr.cx]="linePoints[currentUserLinePoint].x"
            [attr.cy]="linePoints[currentUserLinePoint].y - (userDotSize * 1.5)"
            [attr.r]="userDotSize * 0.8"
            [attr.stroke-width]="linePoints[currentUserLinePoint].size / 2"
          />

          <clipPath id="circleView">
            <circle
              [attr.cx]="linePoints[currentUserLinePoint].x"
              [attr.cy]="linePoints[currentUserLinePoint].y - (userDotSize * 1.5)"
              [attr.r]="userDotSize * 0.8"
            />
          </clipPath>

          <text
            class="user-initials"
            [attr.x]="linePoints[currentUserLinePoint].x"
            [attr.y]="linePoints[currentUserLinePoint].y - (userDotSize * 1.5)"
            [attr.dy]="userDotSize / 4"
            clip-path="url(#circleView)"
          >{{currentUserInitials}}
          </text>

          <image
            *ngIf="currentUser?.imagePath"
            class="profile-image"
            [attr.x]="linePoints[currentUserLinePoint].x - (userDotSize * 0.8)"
            [attr.y]="linePoints[currentUserLinePoint].y - (userDotSize * (1.5 + 0.8))"
            [attr.width]="userDotSize * 1.6"
            [attr.height]="userDotSize * 1.6"
            [attr.href]="currentUser?.imagePath"
            [attr.fill]="'white'"
            clip-path="url(#circleView)"
          />
        </g>

        <!--<g>
          &lt;!&ndash; Prize chests &ndash;&gt;
          <ng-container *ngIf="linePoints?.length && prizeIndexes?.length">
            <ng-container *ngFor="let prizePoint of prizeIndexes; let prizeIndex = index">
              <image
                *ngIf="prizePoint <= currentUserLinePoint"
                id="prize-selection-chest-{{prizeIndex}}"
                class="svg-chest"
                [attr.width]="userDotSize"
                [attr.height]="userDotSize"
                [attr.x]="linePoints[prizePoint].x - (userDotSize * 2)"
                [attr.y]="linePoints[prizePoint].y - (userDotSize / 2)"
                href="/assets/image/treasure.svg"
                (click)="selectPrize(prizeIndex)"
              />
              <image
                *ngIf="prizePoint > currentUserLinePoint"
                id="prize-selection-chest-{{prizeIndex}}"
                class="svg-chest"
                [attr.width]="userDotSize"
                [attr.height]="userDotSize"
                [attr.x]="linePoints[prizePoint].x - (userDotSize * 2)"
                [attr.y]="linePoints[prizePoint].y - (userDotSize / 2)"
                href="/assets/image/treasure-empty.svg"
                (click)="selectPrize(prizeIndex)"
              />
            </ng-container>
          </ng-container>
        </g>-->

        <g *ngIf="linePoints?.length && lineTitles?.length">
          <ng-container *ngFor="let lineTitle of lineTitles; let titleIndex = index">
            <foreignObject class="foreign-object" id="svg-title-object-{{titleIndex}}">
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml"
                         id="svg-title-div-{{titleIndex}}"
                         class="title-div show-arrow"
                         [ngClass]="{'hover-fade': hoveredTitleIndex !== -1 && hoveredTitleIndex !== titleIndex}"
              >
                <xhtml:div>
                  {{lineTitle.name}}
                </xhtml:div>
              </xhtml:div>
            </foreignObject>
          </ng-container>

          <ng-container *ngFor="let lineTitle of lineTitles; let titleIndex = index">
            <foreignObject class="foreign-object" id="svg-title-object-hover-{{titleIndex}}">
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml"
                         id="svg-title-div-hover-{{titleIndex}}"
                         class="title-div"
                         [ngStyle]="hoveredTitleIndex !== titleIndex && {'visibility': 'hidden'}"
              >
                <xhtml:div>
                  {{lineTitle.name}}
                </xhtml:div>

                <xhtml:div *ngIf="lineTitle.date">
                  {{(lineTitle.date) | logeDate}}
                </xhtml:div>

                <xhtml:div *ngIf="!lineTitle.date" class="text-small">
                  {{'player-progress-view.not-signed-up' | translate}}
                </xhtml:div>

                <xhtml:div *ngIf="lineTitle.score">
                  {{'score-view.total-score' | translate :{score: lineTitle.score} }}
                </xhtml:div>
              </xhtml:div>
            </foreignObject>
          </ng-container>
        </g>
      </svg>
    </div>
  `
})
export class SineWaveSvgComponent implements OnChanges {
  @ViewChild('SinePath') sinePathElement: ElementRef;

  @Input() visible: boolean = true;
  @Input() currentUser: User;
  @Input() userSessions: GameSessionLog[];
  @Input() userUpcomingSessions: GameTimeslot[];
  @Input() showScore: boolean;

  @Input() repeatAmount: number; // How lang the path is
  @Input() dotDistance: number = 3; // How many dots fits on one line

  @Input() normalDotSize: number = 8;
  @Input() largeDotSize: number = 14;
  @Input() userDotSize: number = 30;
  @Input() lineWeight: number = 5;

  @Input() prizeIndexes: number[];
  @Input() titleIndexes: number[];
  @Input() largeDotIndexes: number[];
  @Input() largeDotEvery: number = 5;

  @Input() lineColor: string;
  @Input() completedLineColor: string;

  @Input() organizationContent: Content;
  @Input() requiredSeasonList: GameSeason[];

  public sinePath;
  public linePoints: Point[] = [];
  public viewBox: string;

  public currentUserLinePoint: number = 0;
  public currentUserPercentage: number;
  public currentUserInitials: string;

  public lineTotalLength: number = 0;
  public circleDistanceLength: number = 0;

  public showProgressPath: boolean;
  public showPopUp: boolean;
  /*public selectedPrizeSet: PrizeSet;
  public selectedPrizeIndex: number;*/

  public hoveredTitleIndex: number = -1;
  public lastCircleIndex: number = -1;

  public nothingToShow: boolean;

  public lineTitles: {
    name: string,
    date: Moment,
    score: number,
    index: number
  }[];

  @Output() modalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  /*// // Squiggly
  /!*private linePathControls = [
    [0, 0.5],
    [0.8, 0.1],
    [1, 1],
    [Math.PI / 2, 1]
  ];*!/

  // // Also squiggly
  /!*private linePathControls = [
    [0, -0.5],
    [0.5, 0.8],
    [1, 1],
    [Math.PI / 2, 1]
  ];*!/

  // // Aggressive left-to-right
  /!*private linePathControls = [
    [0, 0],
    [0.5, -3],
    [1, 1],
    [Math.PI / 2, 1]
  ];*!/

  // // Close-to-sine wave
  /!*private linePathControls = [
    [0, 0],
    [0.5, 0.5],
    [1, 1],
    [Math.PI / 2, 1]
  ];*!/*/

  // Optimized sine wave
  private linePathControls = [
    [0, 0], // Start
    [0.512286623256592433, 0.512286623256592433],
    [1.002313685767898599, 1],
    [Math.PI / 2, 1] // End
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible && this.visible) {
      this.scrollToTarget();
    }

    if (changes.currentUser && this.currentUser) {
      this.currentUserInitials = User.getInitials(this.currentUser.name);
    }

    if (changes.organizationContent && this.organizationContent && this.organizationContent.progress) {
      /*const prizes = [];
      this.organizationContent.progress.prizeSets.forEach(prizeSet => {
        if (prizeSet.score >= 0) {
          prizes.push(prizeSet.score / this.organizationContent.progress.stepInterval);
        }
      });*/

      const titles = [];
      this.organizationContent.progress.progressTitles.forEach(progressTitle => {
        if (progressTitle.points >= 0) {
          titles.push(progressTitle.points / this.organizationContent.progress.stepInterval);
        }
      });

      // this.prizeIndexes = prizes;
      // this.titleIndexes = titles;
    }

    // Wait for everything needed to load
    if (
      (changes.currentUser || changes.organizationContent || changes.userSessions || changes.userUpcomingSessions || changes.requiredSeasonList) &&
      this.currentUser && this.organizationContent && this.userSessions && this.requiredSeasonList
    ) {
      let totalQuestions = 0;
      const largeDots = [];
      const titles: {
        name: string,
        date: Moment,
        score: number,
        index: number
      }[] = [];

      let total2: number = 0;

      const reverseSessions: GameSessionLog[] = this.userSessions.slice().reverse();

      this.currentUserLinePoint = reverseSessions.map((session, index) => {
        totalQuestions += session.session.situations.length + 1;
        largeDots.push(totalQuestions);
        total2++;

        titles.push({
          index: totalQuestions,
          name: session.session.gameSeasonId ? session.session.gameSeasonId.name : "Unknown Season",
          date: moment(session.session.beginTime),
          score: session.derived.groupScore + session.derived.suggestionScore + session.derived.scalingScore + session.derived.pollScore
        });

        return session.session.situations.length + 1;
      }).reduce((a, b) => {
        return a + b;
      }, 0);

      if (
        this.currentUserLinePoint === 0 &&
        (!this.userUpcomingSessions || !this.userUpcomingSessions.length) &&
        (!this.requiredSeasonList || !this.requiredSeasonList.length)
      ) {
        this.nothingToShow = true;
        return;
      }

      if (this.userUpcomingSessions) {
        this.userUpcomingSessions.forEach(timeSlot => {
          totalQuestions += (timeSlot.gameSeasonId.setId && timeSlot.gameSeasonId.setId['situations']
            ? timeSlot.gameSeasonId.setId['situations'].length
            : timeSlot.gameSeasonId.situationCount) + 1;

          largeDots.push(totalQuestions);

          titles.push({
            index: totalQuestions,
            name: timeSlot.gameSeasonId.name,
            date: moment(timeSlot.beginTime),
            score: null
          });
        });
      }

      if (this.requiredSeasonList) {
        this.requiredSeasonList.forEach(gameSeason => {
          const hasBeenCompleted: boolean = titles.findIndex(title => {
            return title.name === gameSeason.name;
          }) !== -1;

          if (!hasBeenCompleted) {
            if (gameSeason.setId && gameSeason.setId['situations']) {
              totalQuestions += gameSeason.setId['situations'].length + 1;
            }
            else if (gameSeason.situationCount) {
              totalQuestions += gameSeason.situationCount + 1;
            }
            else {
              // Just show 3 circles
              totalQuestions += 3 + 1;
            }

            largeDots.push(totalQuestions);

            titles.push({
              index: totalQuestions,
              name: gameSeason.name,
              date: null,
              score: null
            });
          }
        });
      }

      const totalLinePoint: number = totalQuestions;

      this.lastCircleIndex = totalLinePoint;
      this.largeDotIndexes = largeDots;
      this.repeatAmount = Math.ceil(totalLinePoint / this.dotDistance) + 1;
      this.lineTitles = titles.reverse();

      this.approximateCubicBezier(this.linePathControls, this.repeatAmount, true);
    }
  }

  public scrollToTarget(targetIndex?: number) {
    if (targetIndex === undefined) {
      // Use current user point
      targetIndex = this.currentUserLinePoint;
    }

    if (targetIndex < 0) {
      targetIndex = 0;
    }

    const elementId: string = 'line_point_' + (targetIndex);
    const targetElement: HTMLElement = document.getElementById(elementId);

    if (!targetElement) {
      // Element doesn't exist (yet)
      return;
    }

    targetElement.scrollIntoView({behavior: "smooth", block: "center"});
  }

  public approximateCubicBezier(controls, repeats, flipped: boolean = false) {
    const controlStart = controls[0];
    const controlEnd = controls[3];
    const quarterX = controlEnd[0];
    const startX = 0;

    const control1 = controls[1];
    const control2 = controls[2];

    let x, y, x1, y1, x2, y2;
    let negateY = false;
    let data = "";

    function negateYs() {
      if (negateY) {
        y = -y;
        y1 = -y1;
        y2 = -y2;
      }
    }

    for (x = startX; x < repeats;) {
      if (x === startX) {
        y = controlStart[1];
        x1 = x + control1[0];
        y1 = control1[1];

        negateYs();
        data = flipped ? ('M' + [y * 100, x * 100] + ' C' + [y1 * 100, x1 * 100] + ' ') : ('M' + [x * 100, y * 100] + ' C' + [x1 * 100, y1 * 100] + ' ');
      } else {
        // Mirror instead of creating new C
        data += ' S';
      }

      // Create line leaving from center
      x2 = x + control2[0];
      y2 = control2[1];
      x += quarterX;
      y = controlEnd[1];
      negateYs();
      data += flipped ? ([y2 * 100, x2 * 100] + ' ' + [y * 100, x * 100]) : ([x2 * 100, y2 * 100] + ' ' + [x * 100, y * 100]);

      // Create line back to center
      x2 = (x + quarterX) - control1[0];
      y2 = control1[1];
      x += quarterX;
      y = controlStart[1];
      negateYs();
      data += flipped ? (' S' + [y2 * 100, x2 * 100] + ' ' + [y * 100, x * 100]) : (' S' + [x2 * 100, y2 * 100] + ' ' + [x * 100, y * 100]);

      // Go the other way next time
      negateY = !negateY;

      if (x >= repeats) {
        // Update SVG viewBox to have the height of the created line
        this.viewBox = "-150 -100 550 " + ((x * 100) + 200);

        const svgContainer: SVGElement = document.getElementById('svg-container') as unknown as SVGElement;

        if (svgContainer) {
          svgContainer.setAttribute('viewBox', this.viewBox);
          svgContainer.setAttribute('width', '100%');
        }
        else {
          console.error("No svg container yet!");
        }
      }
    }

    this.sinePath = data;

    setTimeout(() => {
      this.createCirclesOnPath(this.sinePathElement);
    }, 200);
  }

  public createCirclesOnPath(svgPath: ElementRef) {
    if (!svgPath) {
      return;
    }

    const linePoints: Point[] = [];
    const totalLength = svgPath.nativeElement.getTotalLength();

    if (!totalLength) {
      return;
    }

    const circleDistance = totalLength / this.repeatAmount / this.dotDistance;
    let pointLength: number = 0;
    let totalPoints: number = 0;

    this.circleDistanceLength = circleDistance;
    this.lineTotalLength = totalLength;

    while (pointLength <= totalLength) {
      const pointAtLength = svgPath.nativeElement.getPointAtLength(pointLength);
      linePoints.push({
        x: pointAtLength.x,
        y: pointAtLength.y,
        size: this.normalDotSize
      });

      pointLength += circleDistance;
      totalPoints += 1;

      // Add last point to end
      if (pointLength > totalLength) {
        const lastPointAtLength = svgPath.nativeElement.getPointAtLength(pointLength);
        linePoints.push({
          x: lastPointAtLength.x,
          y: lastPointAtLength.y,
          size: this.normalDotSize
        });
      }
    }

    // Reverse so that we start from the bottom
    const reversedPoints: Point[] = linePoints.reverse();

    // Add large dots
    reversedPoints.forEach((point, pointIndex) => {
      if (this.largeDotIndexes) {
        if (this.largeDotIndexes.indexOf(pointIndex) !== -1) {
          point.size = this.largeDotSize;
        }
      } else if (this.largeDotEvery && pointIndex !== 0 && pointIndex % this.largeDotEvery === 0) {
        point.size = this.largeDotSize;
      }
    });

    if (this.currentUserLinePoint) {
      if (this.currentUserLinePoint > reversedPoints.length - 1) {
        this.currentUserLinePoint = reversedPoints.length - 1;
        this.currentUserPercentage = 0;
      } else {
        this.currentUserPercentage = 100 - ((100 / totalPoints) * this.currentUserLinePoint);
      }
    }

    this.linePoints = reversedPoints;

    setTimeout(() => {
      this.setTitlePositions();
      this.setUserPosition();
      this.setGoalPosition();
    }, 200);
  }

  /*public selectPrize(prizeIndex: number) {
    this.selectedPrizeIndex = prizeIndex;
    this.selectedPrizeSet = this.organizationContent.progress.prizeSets[prizeIndex];
    this.showPopUp = true;

    this.modalOpened.emit(true);
  }*/

  closePopup() {
    this.showPopUp = false;

    this.modalOpened.emit(false);
  }

  public onTitleHover(index: number): void {
    this.hoveredTitleIndex = index;
  }

  private setTitlePositions(): void {
    if (!this.lineTitles || !this.linePoints) {
      return;
    }

    this.lineTitles.forEach((title, index) => {
      const titleObject: SVGForeignObjectElement = document.getElementById('svg-title-object-' + index) as unknown as SVGForeignObjectElement;
      const titleDiv: HTMLDivElement = document.getElementById('svg-title-div-' + index) as unknown as HTMLDivElement;
      const hoverTitleObject: SVGForeignObjectElement = document.getElementById('svg-title-object-hover-' + index) as unknown as SVGForeignObjectElement;
      const hoverTitleDiv: HTMLDivElement = document.getElementById('svg-title-div-hover-' + index) as unknown as HTMLDivElement;

      if (this.linePoints[title.index]) {
        if (titleDiv && titleObject) {
          const titleDivRect: ClientRect = titleDiv.getBoundingClientRect();
          titleObject.setAttribute("width", titleDivRect.width.toString());
          titleObject.setAttribute("height", titleDivRect.height.toString());
          titleObject.setAttribute("x", (this.linePoints[title.index].x).toString());
          titleObject.setAttribute("y", (this.linePoints[title.index].y).toString());
          titleObject.setAttribute("transform", 'translate(45 ' + -(titleDivRect.height / 2).toFixed(3) + ')');
        }

        if (hoverTitleDiv && hoverTitleObject) {
          const hoverTitleDivRect: ClientRect = hoverTitleDiv.getBoundingClientRect();
          hoverTitleObject.setAttribute("width", hoverTitleDivRect.width.toString());
          hoverTitleObject.setAttribute("height", hoverTitleDivRect.height.toString());
          hoverTitleObject.setAttribute("x", (this.linePoints[title.index].x).toString());
          hoverTitleObject.setAttribute("y", (this.linePoints[title.index].y).toString());
          hoverTitleObject.setAttribute("transform", 'translate(45 ' + -(hoverTitleDivRect.height / 2).toFixed(3) + ')');
        }
      }

      // Add hover listener
      const itemElement = document.getElementById('svg-title-div-' + (index));
      const hoverItemElement = document.getElementById('svg-title-div-hover-' + (index));

      if (itemElement && hoverItemElement) {
        itemElement.onmouseenter = () => {
          this.onTitleHover(index);
        };

        hoverItemElement.onmouseleave = () => {
          this.onTitleHover(-1);
        };
      }
    });
  }

  private setUserPosition(): void {
    if (!this.linePoints || this.currentUserLinePoint < 0) {
      return;
    }

    const userScoreRect: SVGRectElement = document.getElementById('user-score-rect') as unknown as SVGRectElement;
    const userScoreText: SVGTextElement = document.getElementById('svg-user-score-text') as unknown as SVGTextElement;

    if (this.linePoints[this.currentUserLinePoint] && userScoreRect && userScoreRect) {
      const userScoreTextRect: ClientRect = userScoreText.getBoundingClientRect();
      userScoreRect.setAttribute("x",
        (this.linePoints[this.currentUserLinePoint].x - (userScoreTextRect.width / 2) - (this.userDotSize / 2)).toString());
      userScoreRect.setAttribute("y",
        (this.linePoints[this.currentUserLinePoint].y - (this.userDotSize * 3.8)).toString());
      userScoreRect.setAttribute("width", (userScoreTextRect.width + this.userDotSize).toString());
      userScoreRect.setAttribute("height", (this.userDotSize).toString());
    }

    this.scrollToTarget();
    this.showProgressPath = true;
  }

  private setGoalPosition(): void {
    if (!this.linePoints || !this.lastCircleIndex) {
      return;
    }

    const goalStarPolygon: SVGPolygonElement = document.getElementById('goal-star') as unknown as SVGPolygonElement;

    if (this.linePoints[this.lastCircleIndex] && goalStarPolygon) {
      const goalRect: ClientRect = goalStarPolygon.getBoundingClientRect();
      goalStarPolygon.setAttribute("transform",
        'translate(' + +(this.linePoints[this.lastCircleIndex].x - (goalRect.width * 1.15)).toFixed(1) + ' ' +
        +(this.linePoints[this.lastCircleIndex].y - (goalRect.height * 1.45)).toFixed(1) + ') scale(2.2) rotate(8 ' + (goalRect.width / 2) + ' ' + (goalRect.height / 2) + ')');
    }
  }
}
