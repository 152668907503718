import {
  throwError,
  Observable,
  BehaviorSubject,
  ReplaySubject,
  Subscription
} from 'rxjs';
import {Injectable} from "@angular/core";
import {UserService} from "./user.service";
import {ChatMessage} from "../../data-model/chat-message.type";
import {Player, PlayerStatus, Reaction} from "../../data-model/player.type";
import {GameSession} from "../../data-model/game-session.interface";
import {GameState, GameStateSnapshot, HardwareInfo, PollGroupVote, PollVote, ScaleVote, TimerState} from "../../data-model/game-state.interface";
import {GroupScore} from "../../data-model/group-score.interface";
import {DeviceDetectorService} from 'ngx-device-detector';
import {OrganizationService} from "./organization.service";
import {GroupScoreTheme} from "app/data-model/GroupScoreTheme.interface";
import {SurveyAnswer} from "app/data-model/survey-answer.type";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {User} from "../../data-model/user.type";
import {catchError, filter, first, map} from "rxjs/operators";
import Timer = NodeJS.Timer;
import {UserAudioStatus} from "../../data-model/game-session-log.type";
import {ScaleItem} from "../../data-model/scale-vote.interface";
import {GroupNameSuggestion} from "../../session/component/group-name-vote.component";
import {DomSanitizer} from "@angular/platform-browser";
import {NewLinePipe} from "../../shared/pipe/newline.pipe";
import Socket = SocketIOClient.Socket;
import {DiscussionUpdate, SocketService} from "./socket.service";
import { DataUpdate } from "./data-update.service";

@Injectable({
  providedIn: 'root'
})
export class GameSessionService {
  public gameState$ = new BehaviorSubject<GameState>(null);
  public timerState$ = new BehaviorSubject<TimerState>(null);
  public gameStateSnapshots$ = new BehaviorSubject<GameStateSnapshot[]>(null);
  public sessionDoc$ = new BehaviorSubject<GameSession>(undefined);
  public currentSessionId: string;

  public chatMessages$: BehaviorSubject<ChatMessage[]> = new BehaviorSubject<ChatMessage[]>(undefined);
  public newChatMessage$: ReplaySubject<ChatMessage> = new ReplaySubject<ChatMessage>();
  public sessionJoinError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public connectionStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private hasConnected: boolean; // Show different message if never connected

  // private webSocketSubject: WebSocketSubject<WSMessage>;
  // private IOSocket: Socket;
  private currentPlayers: Map<string, Player> = new Map<string, Player>();
  private currentSpectators: Map<string, Player> = new Map<string, Player>();
  private messageQueue: (() => void)[] = [];
  private queueInterval: any;
  private currentUser: User;

  private retryAmount: number = 0;
  private creatingNewConnection: boolean;

  private lastDisconnectedReason: string = "";
  private disconnectReasonTimeOut: Timer;
  private socket: Socket;

  private dataSub: Subscription;

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected org: OrganizationService,
              protected device: DeviceDetectorService,
              protected router: Router,
              private domSanitizer: DomSanitizer,
              private newLinePipe: NewLinePipe,
              private socketService: SocketService
  ) {
    this.userService.currentUser$
      .pipe(
        filter(user => !!user),
        first()
      )
      .subscribe(user => {
        this.currentUser = user;
      });

    this.userService.userUpdated$
      .subscribe(
        (userId: string) => {
          this.sendUserProfileUpdated(userId);
        }
      );

    this.socketService.gameSessionStarted$
      .subscribe((update: DataUpdate) => {
        // Re-join discussion
        if (window.location.href.indexOf('game-session') !== -1) {
          console.log("Re-join discussion that has restarted", update);
          this.joinSession(update.sessionId);
        }
      });
  }

  public joinSession(sessionId?: string) {
    if (!this.socketService.connected()) {
      console.error("Socket not connected");

      setTimeout(() => {
        console.log("Retry connect", this.joinSession());
      }, 500);

      return;
    }

    // Get discussion
    this.sessionDoc$
      .pipe(
        filter(sessionDoc => !!sessionDoc),
        first()
      )
      .subscribe(
        (sessionDoc: GameSession) => {
          if (sessionId && sessionDoc._id.toString() !== sessionId.toString()) {
            // Not the session we wanted
            return;
          }

          this.currentSessionId = sessionDoc._id;
          this.socketService.joinRoom(this.currentSessionId, true);
          this.router.navigate(['/game-session']);

          if (this.dataSub) {
            this.dataSub.unsubscribe();
          }

          this.dataSub = this.socketService.discussionUpdate$
            .subscribe((update: DiscussionUpdate) => {
              this.sessionJoinError$.next(null);
              this.messageListener(update);
            });
        }
      );
  }

  /**
   * Get current game session(s)
   */
  public getCurrentSession(autoJoin?: boolean): void {
    this.http.get('/api/game-session/current')
      .subscribe(
        (doc: GameSession) => {
          if (!doc) {
            this.sessionDoc$.next(null);
            return;
          }

          this.sessionJoinError$.next(null);

          for (const userId of doc.players) {
            const old = this.currentPlayers.get(userId);
            if (old) {
              old.user = this.userService.getUser(userId);
              continue;
            }
            const player = new Player(userId, this.userService.getUser(userId));
            this.currentPlayers.set(userId, player);
          }

          for (const userId of doc.spectators) {
            const old = this.currentSpectators.get(userId);
            if (old) {
              old.user = this.userService.getUser(userId);
              continue;
            }
            const spectator = new Player(userId, this.userService.getUser(userId), true);
            this.currentSpectators.set(userId, spectator);
          }

          this.sessionDoc$.next(doc);

          if (autoJoin) {
            this.joinSession();
          }
        },
        err => {
          if (err.status === 401) {
            console.log("Logged out, skip retrying connection", err);
            return;
          }

          const retryAllowed: boolean = this.retryAmount < 10;
          console.log("Error connecting to current session.", err);

          if (!retryAllowed) {
            console.log("Retried connecting 10 times, stop trying.");
            this.connectionStatus$.next("failed-reconnecting");
            return;
          }

          setTimeout(() => {
            this.retryAmount++;
            console.log("Retrying connection, try no. " + this.retryAmount + "/10.");
            this.getCurrentSession(autoJoin);
          }, 3000);
        }
      );
  }

  /**
   * Get current game session states
   */
  public getGameState(query: any): Observable<GameStateSnapshot> {
    if (!query || !query.orgId) {
      return;
    }

    return this.http.put('/api/game-session/states/', query)
      .pipe(
        map(res => {
          const snapshots = res as GameStateSnapshot[];
          return snapshots[0];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get current game session states
   */
  public updateStates(orgId: string) {
    if (!orgId) {
      return;
    }

    this.http.put('/api/game-session/states/', {orgId: orgId})
      .subscribe(
        res => {
          this.gameStateSnapshots$.next(res as GameStateSnapshot[]);
        },
        err => {
          console.error("Fetching current GameStates listing failed", err);
          this.gameStateSnapshots$.next(null);
        }
      );
  }

  /**
   * Send User hardware info to server
   */
  public sendHardwareInfo() {
    this.sendSocketMessage('hardware-info', {
      hardware: this.userService.hardwareInfo,
      lastRefresh: this.userService.lastRefresh
    });
  }

  /**
   * Send evaluation about previous session
   * @param {string} userId
   * @param {Array<SurveyAnswer>} answers
   * @param {MeasureAnswer} actions
   */
  /*public sendLastSessionEvaluation(userId: string, answers: Array<SurveyAnswer>, actions: MeasureAnswer) {
    this.send(new WSMessage(null, 'last-session-evaluation', {userId: userId, answers: answers, actions: actions}));
  }*/

  /**
   * Send evaluation about completed session
   * @param {string} userId
   * @param {Array<SurveyAnswer>} answers
   * @param {number} rating
   * @param {*} audioRating
   */
  public sendGameCompletedEvaluation(userId: string, answers: Array<SurveyAnswer>, rating: number, audioRating: { feedback: string, rating: number }) {
    this.sendSocketMessage('game-completed-evaluation', {
      userId: userId,
      answers: answers,
      rating: rating,
      audioRating: audioRating
    });
  }

  /**
   * Send reaction via WebSocket
   */
  public sendReactionMessage(targetUserId: string, fromUserId: string, reactionType: string): void {
    this.sendSocketMessage('reaction', {
      reactionType: reactionType,
      targetUserId: targetUserId,
      fromUserId: fromUserId
    });
  }

  /**
   * Send chat message via WebSocket
   * @param {string} text
   */
  public sendChatMessage(text: string): void {
    this.sendSocketMessage('chat', {text: text});
  }

  public sendPlayerStatus(statusChange: PlayerStatus): void {
    this.sendSocketMessage('player-status', statusChange);
  }

  /**
   * Send User ready via WebSocket
   * @param {boolean} ready
   * @param {string} message
   */
  public sendReady(ready: boolean, message?: string): void {
    this.sendSocketMessage('ready', {ready: ready, message: message});
  }

  /**
   * Send personal multi-select vote via WebSocket
   * @param {string} situationId
   * @param {number} choice
   * @param {string} reason
   */
  public sendMultiSelectVote(situationId: string, choice: number, reason: string) {
    this.sendSocketMessage('multi-select-personal', {situationId: situationId, choice: choice, reason: reason});
  }

  /**
   * Send personal suggestion vote via WebSocket
   * @param {string} situationId
   * @param {string} reason
   */
  public sendSuggestionVote(situationId: string, reason: string) {
    this.sendSocketMessage('suggestion-personal', {situationId: situationId, reason: reason});
  }

  /**
   * Send group choice via WebSocket
   * @param {number} choice
   * @param {string} reason
   * @param {boolean} groupReady
   */
  public sendGroupMultiSelectChoice(choice: number, reason: string, groupReady: boolean): void {
    this.sendSocketMessage('group-multi-select-choice', {choice: choice, reason: reason, ready: groupReady});
  }

  /**
   * Send commit multiselect group choice
   * @param {number} choice
   * @param {string} reason
   */
  /*public sendMultiselectCommit(choice: number, reason: string) {
    this.send(new WSMessage(null, 'commit-multiselect', {choice: choice, reason: reason}));
  }*/

  /**
   * Send group suggestion to other players via WebSocket
   * @param {string} suggestion
   * @param {boolean} groupReady
   */
  public sendGroupSuggestion(suggestion: string, groupReady: boolean) {
    this.sendSocketMessage('group-suggestion', {suggestion: suggestion, ready: groupReady});
  }

  /**
   * Commit group suggestion
   * @param {string} suggestion
   */
  /*public sendSuggestionCommit(suggestion: string) {
    this.send(new WSMessage(null, 'commit-suggestion', {suggestion: suggestion}));
  }*/

  /**
   * Send personal vote via WebSocket
   * @param {string} situationId
   * @param {ScaleVote} vote
   */
  public sendScaleVote(situationId: string, vote: ScaleVote) {
    this.sendSocketMessage('scale-vote', {situationId: situationId, vote: vote});
  }

  public sendScaleSuggestions(situationId: string, suggestions: string[]) {
    this.sendSocketMessage('scale-item-suggestions', {situationId: situationId, suggestions: suggestions});
  }

  public removeScaleSuggestion(situationId: string, item: ScaleItem) {
    this.sendSocketMessage('remove-scale-item-suggestion', {situationId: situationId, item: item});
  }

  public sendGroupScaleVote(situationId: string, vote: ScaleVote, groupReady: boolean): void {
    this.sendSocketMessage('scale-group-vote', {situationId: situationId, vote: vote, ready: groupReady});
  }

  public sendPollVote(situationId: string, vote: PollVote) {
    this.sendSocketMessage('poll-vote', {situationId: situationId, pollVote: vote});
  }

  public sendGroupPollVote(situationId: string, vote: PollGroupVote, groupReady: boolean): void {
    this.sendSocketMessage('poll-group-vote', {situationId: situationId, groupVote: vote, ready: groupReady});
  }

  /**
   * Send group ready via WebSocket
   * @param {boolean} status
   */
  /*public sendGroupReady(status: boolean): void {
    this.send(new WSMessage(null, 'group-ready', {ready: status}));
  }*/

  /**
   * Send updated User profile to other players
   * @param {string} id
   */
  public sendUserProfileUpdated(id: string): void {
    this.sendSocketMessage('user-updated', {userId: id});
  }

  /**
   * Send summary feedback to server via WebSocket and attach to session log
   * @param {string} feedback
   */
  public sendSummaryFeedback(feedback: string): void {
    this.sendSocketMessage('summary-feedback', {feedback: feedback});
  }

  public returnToGroupPhase(): void {
    this.sendSocketMessage('return-to-group-phase', {});
  }

  public setChatMessages(chatLog: ChatMessage[]) {
    if (!chatLog || !chatLog.length) {
      return;
    }

    const previousMessages: ChatMessage[] = [];
    for (const message of chatLog) {
      if (message.sender) {
        message.sender = this.userService.getUser(message.sender as unknown as string);
      }

      previousMessages.push(message);
    }

    this.chatMessages$.next(previousMessages);
  }

  /**
   * Get top scores from sessions with same theme and equal amount of situations
   * @param {string} theme
   * @param {string} setId
   * @param {number} numOfSituations
   * @param {string} sessionId
   * @returns {Observable<Array<GroupScoreTheme>>}
   */
  public getTopScoresTheme(theme: string, setId: string, numOfSituations: number, sessionId: string): Observable<GroupScoreTheme[]> {
    const body: any = {
      theme: theme,
      set: setId,
      numOfQuestions: numOfSituations,
      sessionId: sessionId
    };

    return this.http.put('/api/game-session/list-top-scores-themes/', body)
      .pipe(
        map(res => {
          return res as GroupScoreTheme[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get current session
   * @returns {Observable<Array<GroupScore>>}
   */
  public getCurrent(): Observable<GroupScore[]> {
    return this.http.get('/api/game-session/current')
      .pipe(
        map(res => {
          return res as GroupScore[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * @param userId - If null, an attempt is made to use the ID of the current User
   * @returns {Player} - The requested Player or null if not found
   */
  public getPlayer(userId?: string): Player {
    if (!userId) {
      if (!this.currentUser) {
        return null;
      }

      userId = this.currentUser._id;
    }

    if (!this.currentPlayers.has(userId)) {
      if (this.currentSpectators.has(userId)) {
        return this.currentSpectators.get(userId);
      } else {
        return null;
      }
    }

    return this.currentPlayers.get(userId);
  }

  public getSessionPlayers(): Player[] {
    const playerArray: Player[] = [];
    for (const player of this.currentPlayers) {
      playerArray.push(player[1]);
    }

    return playerArray;
  }

  /**
   * Check if the User if the given ID is currently the chairman
   * @param [userId] - If omitted, the ID of the current User is used
   * @returns {boolean} True when the User is a chairman; False otherwise
   */
  public isChairman(userId?: string): boolean {
    if (!userId) {
      if (!this.currentUser) {
        return false;
      }

      userId = this.currentUser._id;
    }

    const gameState: GameState = this.gameState$.getValue();

    if (!gameState) {
      return false;
    }

    return gameState.chairmanId === userId;

  }

  public isConnected(): boolean {
    return this.socketService.isInRoom("test");
  }

  /**
   * Disconnect from socket
   */
  public leaveDiscussionRoom(reason?: string): void {
    // Send reason before disconnecting
    this.sendSocketMessage('user-disconnection', {
      message: reason
    });

    // Leave room
    this.socketService.leaveRoom(this.currentSessionId, true);

    // Reset doc if intended leave
    if (reason && (reason.indexOf("leave-discussion") !== -1 || reason === "deactivate-guard")) {
      this.clearGameState();
    }
  }

  public clearGameState(): void {
    this.sessionDoc$.next(null);
    this.gameState$.next(null);
    this.currentSessionId = null;

    this.currentPlayers.clear();
    this.currentSpectators.clear();
  }

  /**
   * Delete incomplete session
   * @param {string} sessionId
   */
  public removeGameSession(sessionId: string): void {
    this.http.delete('/api/game-session/delete/' + sessionId)
      .subscribe(
        res => {
          const currentSnapShots: GameStateSnapshot[] = this.gameStateSnapshots$.getValue();

          if (!currentSnapShots || !currentSnapShots.length) {
            this.gameStateSnapshots$.next([]);
            return;
          }

          const removedSessionIndex: number = currentSnapShots.findIndex(snapShot => {
            return snapShot.id.toString() === sessionId.toString();
          });

          if (removedSessionIndex !== -1) {
            currentSnapShots.splice(removedSessionIndex, 1);
            this.gameStateSnapshots$.next(currentSnapShots);
          }
        },
        err => {
          console.error("Failed removing gameSession", err);
        }
      );
  }

  public adminRestartDiscussion() {
    if (!this.userService.checkCurrentUserEditPrivileges()) {
      return;
    }

    this.sendSocketMessage('admin-restart-discussion', {status: true});
  }

  public adminForceNextPhase(next: boolean) {
    if (!this.userService.checkCurrentUserEditPrivileges()) {
      return;
    }

    this.sendSocketMessage('admin-next-phase', {status: next});
  }

  public addTimeToPhase(seconds: number) {
    if (!this.userService.checkCurrentUserEditPrivileges()) {
      return;
    }

    this.sendSocketMessage('add-time', {seconds: seconds});
  }

  public adminForceNextChairman(playerId: string) {
    if (!this.userService.checkCurrentUserEditPrivileges()) {
      return;
    }

    this.sendSocketMessage('admin-next-chairman', {chairmanId: playerId});
  }

  public sendUserAudioStatus(userId: string, status: UserAudioStatus) {
    this.sendSocketMessage('audio-status', status);
    this.sendPlayerStatus({micEnabled: status.voiceDetected});
  }

  public sendGroupNameSuggestion(suggestion: string, playerId: string, complete: boolean): void {
    this.sendSocketMessage('group-name-suggestion', {suggestion: suggestion, playerId: playerId, complete: complete});
  }

  public sendGroupNameVote(suggestionPlayer: string, suggestion: string, playerId: string): void {
    this.sendSocketMessage('group-name-vote', {suggestion: suggestion, suggestionPlayer: suggestionPlayer, playerId: playerId});
  }

  public removeGroupNameSuggestion(suggestion: GroupNameSuggestion): void {
    this.sendSocketMessage('remove-group-name-suggestion', {suggestion: suggestion});
  }

  public joinToExistingDiscussion(sessionId: string, userId: string, spectate: boolean): Observable<any> {
    return this.http.put('/api/game-session/join-existing-discussion/', {sessionId: sessionId, userId: userId, spectate: spectate})
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Send message via Socket
   * @param {string} topic
   * @param {} data
   */
  protected sendSocketMessage(topic: string, data: any): void {
    this.socketService.sendMessage(
      "discussion-data",
      {
        topic: topic,
        data: data,
        to: this.currentSessionId
      }
    );
  }

  /**
   * Wait for connection before sending WebSocket message
   * @param {() => void} callback
   */
  protected waitForConnection(callback: () => void) {
    if (this.isConnected()) {
      callback();
    }
    else {
      this.messageQueue.push(callback);

      if (!this.queueInterval) {
        this.queueInterval = setInterval(this.drainMessageQueue.bind(this), 200);
      }
    }
  }

  /**
   * Get items from WS message queue
   */
  protected drainMessageQueue(): void {
    if (this.isConnected()) {
      return;
    }

    clearInterval(this.queueInterval);
    this.queueInterval = null;

    const queue = this.messageQueue;
    this.messageQueue = [];

    for (const cb of queue) {
      cb();
    }
  }

  /**
   * Connect to WebSocket
   */
  // private connectToWebSocket(sessionDoc: GameSession): void {
  //   this.http.get('/api/game-session/' + sessionDoc._id + '/join')
  //     .pipe(
  //       map(res => {
  //         return res as {token: string};
  //       }),
  //       catchError(err => {
  //         console.log("Failed connecting to socket", err);
  //
  //         return throwError(err);
  //       })
  //     )
  //     .subscribe(
  //       doc => {
  //         this.sessionJoinError$.next(null);
  //         this.createWebSocketMessageListener(doc.token);
  //       },
  //       err => {
  //         console.error("Failed to update token", err);
  //         this.sessionJoinError$.next("Failed to update token");
  //       }
  //     )
  //   ;
  // }

  /**
   * Add WebSocket listener
   */
  // public createWebSocketMessageListener(token: string): void {
  //   this.hasConnected = false;
  //
  //   if (this.isConnected()) {
  //     // Close previous connection
  //     this.creatingNewConnection = true;
  //     this.leaveDiscussionRoom("create-new");
  //   }
  //
  //   const openObserver: NextObserver<Event> = {
  //     next: () => {
  //       if (this.disconnectReasonTimeOut) {
  //         // Don't show disconnection message if reconnection takes less than a second
  //         clearTimeout(this.disconnectReasonTimeOut);
  //       }
  //
  //       this.retryAmount = 0;
  //       this.hasConnected = true;
  //       this.connectionStatus$.next("open");
  //       this.creatingNewConnection = false;
  //
  //       if (this.lastDisconnectedReason !== "") {
  //         // send error to the server
  //         this.sendSocketMessage('client-error', {
  //           source: 'WebSocket-disconnected-reason',
  //           message: this.lastDisconnectedReason
  //         });
  //
  //         this.lastDisconnectedReason = "";
  //       }
  //     }
  //   };
  //
  //   const closeObserver: NextObserver<CloseEvent> = {
  //     next: (closeEvent) => {
  //       this.disconnectReasonTimeOut = setTimeout(() => {
  //         // Show disconnected after 0.5 second
  //         this.connectionStatus$.next(this.hasConnected ? "closed" : "never-connected");
  //       }, 500);
  //
  //       if (this.creatingNewConnection) {
  //         console.log("Creating new connection, skip reconnect", closeEvent);
  //         return;
  //       }
  //
  //       if (this.lastDisconnectedReason === "" && closeEvent) {
  //         this.lastDisconnectedReason = "Disconnect reason was: " + (closeEvent.code ? closeEvent.code : 0) + ": "
  //           + (closeEvent.reason ? closeEvent.reason : "No reason");
  //       }
  //
  //       if (closeEvent.code === 1000) {
  //         // Normal disconnection
  //         return;
  //       }
  //
  //       if (location.href.indexOf('game-session') === -1) {
  //         // User has changed page, don't re-connect
  //         return;
  //       }
  //
  //       if (!this.gameState$.getValue()) {
  //         // GameState doesn't exist anymore, don't reconnect
  //         return;
  //       }
  //
  //       // Try reconnecting
  //       console.log("Try reconnecting to live session");
  //       this.leaveDiscussionRoom("reconnect");
  //       this.getCurrentSession(true);
  //     }
  //   };
  //
  //   const protocol: string = location.protocol === 'https:' ? 'wss:' : 'ws:';
  //   let hostname: string;
  //   if (window.location.port === "80" || window.location.port === "443") {
  //     hostname = window.location.hostname;
  //   } else {
  //     hostname = window.location.hostname + ":" + window.location.port;
  //   }
  //
  //   this.IOSocket = io(
  //     `${protocol}//${hostname}/?type=discussion&token=${token}`
  //   );
  //
  //   /*this.webSocketSubject = new WebSocketSubject<WSMessage>({
  //     url: `${protocol}//${hostname}/?type=discussion&token=${token}`,
  //     openObserver: openObserver,
  //     closeObserver: closeObserver
  //   });*/
  //
  //   this.IOSocket.on("message", (message) => {
  //     console.log(message);
  //     this.sessionJoinError$.next(null);
  //     this.messageListener(message.topic, message.data);
  //   });
  //
  //   this.IOSocket.on("error", (error) => {
  //     console.error("Socket error occurred:", error);
  //   });
  //
  //   /*this.webSocketSubject
  //     .subscribe(
  //       message => {
  //         this.sessionJoinError$.next(null);
  //         this.messageListener(message);
  //       },
  //       error => {
  //         console.error("WebSocket error occurred:", error);
  //       },
  //       () => {
  //         // Socket closed normally
  //       }
  //     );*/
  // }

  /**
   * Listen to WebSocket messages and act accordingly
   * @param {DiscussionUpdate} message
   */
  protected messageListener(message: DiscussionUpdate): void {
    if (!message || !message.data) {
      // Invalid message
      return;
    }

    let senderPlayer: Player;

    if (message.data.sender) {
      senderPlayer = this.getPlayer(message.data.sender);
    }

    switch (message.topic) {
      case 'join-session':
        // Self successfully joined
        this.sendHardwareInfo();

        if (message.data?.chatLog) {
          this.setChatMessages(message.data.chatLog);
        }

        break;

      case 'chat':
        if (!senderPlayer) {
          this.newChatMessage$.next(message.data);
          break;
        }

        const chatMessage = new ChatMessage(
          senderPlayer.user,
          message.data['text']
        );

        senderPlayer.status.writing = false;
        this.newChatMessage$.next(chatMessage);

        break;

      case 'player-status': {
        if (!senderPlayer) {
          break;
        }

        senderPlayer.status = message.data.status;

        break;
      }

      case 'timer-state':
        this.timerState$.next(<TimerState>message.data);
        break;

      case 'game-state':
        this.gameState$.next(<GameState>message.data);

        break;

      case 'session-end':
        this.leaveDiscussionRoom("session-end-leave-discussion");
        break;

      case 'user-updated':
        const userId: string = message.data['userId'];
        this.userService.getUser(userId, true);
        break;

      case 'new-player':
        // Update sessionDoc
        this.getCurrentSession();
        break;

      case 'discussion-ended':
        // Discussion has ended
        // Everyone will be disconnected in game-session.view and moved to self-evaluation
        break;

      default:
        console.warn("Unknown message topic. Message: ", message.topic, message.data);
    }
  }
}
