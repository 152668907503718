/**
 * Created by Topi on 27.7.2018
 */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from "../../data-model/user.type";
import {GameSessionLog} from "../../data-model/game-session-log.type";
import {Content} from "../../data-model/content.type";
import {Organization} from "../../data-model/organization.type";
import {UserService} from "../../core/service/user.service";
import {MaterialInterface} from "../../data-model/material.interface";
import {GameTimeslot} from "../../data-model/timeslot.type";
import {TutorialItem} from "../../core/service/game-tutorial.service";
import Timeout = NodeJS.Timeout;
import {MediaUtils} from "../../../lib/media-utils";

@Component({
  selector: 'large-drawer',
  template: `
    <ng-container *ngIf="componentInitiated">
      <div class="drawer-background" [ngClass]="{'background-fade': modalOpened}" (click)="toggleDrawer()"></div>

      <div class="drawer drawer-header unselectable animated"
           [ngClass]="{'lowered-drawer': loweredVertical === 1, 'narrow': contentType === 'player-progress',
             'left slideInLeft': position === 'left', 'right slideInRight': position === 'right', 'opened': modalOpened, 'drawer-transition': showDrawerTransition}"
           (mouseenter)="setDrawerTransitionTimeout(true)"
           (mouseleave)="setDrawerTransitionTimeout(false)"
           (click)="toggleDrawer()">

        <div class="icon-container" *ngIf="position === 'right'">
          <span class="icon-map"></span>
          <span class="small-title-text">{{iconTitle ? iconTitle : drawerTitle}}</span>
        </div>

        <span class="drawer-title" [ngStyle]="!modalOpened && {visibility: 'hidden'}">{{drawerTitle}}</span>

        <div class="icon-container" *ngIf="position === 'left'">
          <span *ngIf="contentType !== 'introduction-tutorial'" class="glyphicon glyphicon-calendar"></span>
          <span *ngIf="contentType === 'introduction-tutorial'" class="glyphicon glyphicon-question-sign"></span>
          <span class="small-title-text">{{iconTitle ? iconTitle : drawerTitle}}</span>
        </div>
      </div>

      <div class="drawer drawer-container"
           [ngClass]="{'lowered-drawer': loweredVertical === 1, 'narrow': contentType === 'player-progress', 'opened': modalOpened,
             'left': position === 'left', 'right': position === 'right', 'drawer-transition': showDrawerTransition}"
      >
        <div class="drawer-line"></div>

        <div *ngIf="contentType === 'upcoming-discussions'">
          <upcoming-sessions
            [currentUser]="currentUser"
            [userSessions]="userSessions"
            [currentIntroTutorial]="currentIntroTutorial"
            (modalOpened)="modalOpened = $event"
            [seasonNameOrder]="organizationContent?.seasonNameOrder"
            [currentOrganization]="currentOrganization"
            [selectedMaterial]="selectedMaterial"
            (materialSelected)="selectedMaterial = $event"
            (onJoinedDiscussionsChanged)="onJoinedDiscussionsChanged.emit()"
          ></upcoming-sessions>

          <!--<test-discussions
            *ngIf="currentOrganization?.organizationType === 2"
            [currentUser]="currentUser"
            [currentIntroTutorial]="currentIntroTutorial"
            [currentOrganization]="currentOrganization"
            [selectedMaterial]="selectedMaterial"
            (materialSelected)="selectedMaterial = $event"
          ></test-discussions>-->
        </div>

        <div *ngIf="contentType === 'player-progress'" class="unselectable">
          <player-progress-v2
            [currentUser]="currentUser"
            [currentOrganization]="currentOrganization"
            [userSessions]="userSessions"
            [userUpcomingSessions]="userUpcomingSessions"
            [visible]="modalOpened"
            [organizationContent]="organizationContent"
            (modalOpened)="modalOpened = $event"
          ></player-progress-v2>
        </div>

        <div *ngIf="contentType === 'introduction-tutorial'">
          <intro-tutorial-component
            *ngIf="modalOpened"
            [visible]="modalOpened"
            [currentUser]="currentUser"
            (exitTutorial)="hideIntroTutorial()"
            [ngClass]="{'disappear-animation': hideIntroTutorial}"
            [isNewLandingPage]="true"
            (onContactUs)="showContactUs = !showContactUs"
            (tutorialSelectionEmitter)="tutorialSelectionEmitter.emit($event)"
            (openTabEmitter)="drawerOpen.emit($event)"
          ></intro-tutorial-component>
        </div>
      </div>
    </ng-container>

    <centered-pop-up
      *ngIf="selectedMaterial"
      [popUpVisible]="true"
      (popUpEmitter)="selectedMaterial = null"
      [headerText]="selectedMaterial.title"
      [orgId]="currentUser.orgId"
      [materialContent]="selectedMaterial"
      [materialType]="getFileType(selectedMaterial?.path)"
      [containerClass]="{
        'has-youtube': selectedMaterial?.type === 'videoId',
        'has-pdf': checkFileType(selectedMaterial?.path, 'file'),
        'has-image': checkFileType(selectedMaterial?.path, 'image'),
        'has-video': checkFileType(selectedMaterial?.path, 'video')
      }"
      [allowedElementIds]="['material-button-']"
    ></centered-pop-up>

    <centered-pop-up
      *ngIf="showContactUs"
      [popUpVisible]="true"
      [containerClass]="{'contact-form': true}"
      [headerText]="'contact-form.contact-form-header'"
      [contactFormUser]="currentUser"
      (popUpEmitter)="showContactUs = false"
    ></centered-pop-up>
  `
})
export class LargeDrawerComponent implements OnChanges, OnInit {
  @Input() position: string = 'left';
  @Input() drawerTitle: string;
  @Input() iconTitle: string;
  @Input() contentType: string;
  @Input() loweredVertical: number;
  @Input() autoOpen: boolean;
  @Input() tabIdentifier: string;
  @Input() currentIntroTutorial: string;

  @Output() drawerOpen: EventEmitter<string> = new EventEmitter<string>();
  @Output() onJoinedDiscussionsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tutorialSelectionEmitter: EventEmitter<TutorialItem> = new EventEmitter<TutorialItem>();

  @Input() currentUser: User;
  @Input() userSessions: GameSessionLog[];
  @Input() userUpcomingSessions: GameTimeslot[];
  @Input() organizationContent: Content;
  @Input() currentOrganization: Organization;
  @Input() currentTab: string;

  public componentInitiated: boolean;
  public modalOpened: boolean;
  public selectedMaterial: MaterialInterface;
  public showContactUs: boolean;

  public drawerTransitionTimeout: Timeout;
  public showDrawerTransition: boolean; // Disable transition during resize

  constructor(private userService: UserService) {

  }

  ngOnInit(): void {
    this.componentInitiated = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.autoOpen && this.autoOpen) {
      this.toggleDrawer(true);
    }

    if (changes.currentTab && this.tabIdentifier && this.currentTab === this.tabIdentifier) {
      this.setDrawerTransitionTimeout(true);
      this.toggleDrawer(true, true);
    }
  }

  public toggleDrawer(forceOpen?: boolean, noEmit?: boolean) {
    this.modalOpened = forceOpen ? true : !this.modalOpened;

    if (!noEmit) {
      this.drawerOpen.emit(this.modalOpened ? this.tabIdentifier : null);
    }
  }

  public hideIntroTutorial() {
    this.userService.saveUser(
      this.currentUser._id,
      {
        hideIntroTutorial: true
      }
    );
  }

  public setDrawerTransitionTimeout(status: boolean): void {
    if (this.drawerTransitionTimeout) {
      clearTimeout(this.drawerTransitionTimeout);
    }

    if (status) {
      this.showDrawerTransition = true;
      return;
    }

    this.drawerTransitionTimeout = setTimeout(() => {
      this.showDrawerTransition = false;
    }, 200);
  }

  public getFileType(path: string): string {
    if (!path) {
      return null;
    }

    return MediaUtils.getMediaType(path);
  }

  public checkFileType(path: string, wanted: string): boolean {
    if (!path || !wanted) {
      return false;
    }

    return MediaUtils.getMediaType(path) === wanted;
  }
}
