
import {throwError, Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from "@angular/core";
import {SurveyAnswer} from "../../data-model/survey-answer.type";
import {UserService} from "./user.service";
import {HttpClient} from "@angular/common/http";
import {GameCompleteEvaluation} from "../../data-model/game-state.interface";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  public orgSurveys$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public userSurveys$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public allThumbSurveys$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public userThumbSurveys$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  private submitEvaluationUrl: string = "/api/survey/last-session-evaluation";
  private submitGameCompletedUrl: string = "/api/survey/game-completed";
  private getLatestGameCompletedUrl: string = "/api/survey/game-completed/latest-user-survey/";
  private getOrgSurvlistUrl: string = '/api/survey/surveys-by-organization/';
  private getGuestOrgSurvlistUrl: string = '/api/guests/surveys-by-organization/';
  private getUserSurvlistUrl: string = '/api/survey/user-surveys/';
  private getEngagementDataURL: string = '/api/survey/engagement-data/';
  private getGuestEngagementDataURL: string = '/api/guests/engagement-data/';
  private getUserEngagementDataURL: string = '/api/survey/user-engagement-data/';
  private getGeneralFeedbackUrl: string = '/api/survey/general-feedback/';

  constructor(private http: HttpClient,
              private userService: UserService) {
  }

  /**
   * Fetch surveys by organization
   * @param {string} orgId
   */
  public fetchSurveysByOrganization(orgId: string): void {
    this.http.get(this.getOrgSurvlistUrl + orgId)
      .subscribe(
        res => {
          this.orgSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching surveys failed", err);
          this.orgSurveys$.next(null);
        }
      );
  }

  public fetchGuestSurveysByOrganization(orgId: string, pw: string): void {
    this.http.get(this.getGuestOrgSurvlistUrl + orgId + '/' + pw)
      .subscribe(
        res => {
          this.orgSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching surveys failed", err);
          this.orgSurveys$.next(null);
        }
      );
  }

  /**
   * Fetch surveys by user
   */
  public fetchCurrentUserSurveys(): void {
    this.http.get(this.getUserSurvlistUrl)
      .subscribe(
        res => {
          this.userSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching surveys failed", err);
          this.userSurveys$.next(null);
        }
      );
  }

  /**
   * Save last session evaluation
   * @param {string} sessionId
   * @param {string} seasonName
   * @param {Array<SurveyAnswer>} answers
   * @returns {Subscription}
   */
  /*submitLastSessionEvaluation(sessionId: string, seasonName: string, answers: Array<SurveyAnswer>, measure: MeasureAnswer) {
    const body = {
      sessionId: sessionId,
      seasonName: seasonName,
      answers: answers,
      measure: measure
    };

    return this.http.post(this.submitEvaluationUrl, body)
      .subscribe(
        res => {

        },
        err => {
          console.error("Submitting Last Session Evaluation failed", err);
        }
      );
  }*/

  /**
   * Save game completed survey
   * @param {string} sessionId
   * @param {string} gameSeasonId
   * @param {string} gameSeasonName
   * @param {Array<SurveyAnswer>} answers
   * @param {Array<SurveyAnswer>} thumbAnswers
   * @param {number} rating
   * @param {*} audioRating
   * @returns {Subscription}
   */
  submitGameCompletedSurvey(sessionId: string, gameSeasonId: string, gameSeasonName: string,
                            answers: Array<SurveyAnswer>, thumbAnswers: Array<SurveyAnswer>, rating: number,
                            audioRating: { feedback: string, rating: number }) {
    const body = {
      sessionId: sessionId,
      gameSeasonId: gameSeasonId,
      seasonName: gameSeasonName,
      rating: rating,
      answers: answers,
      thumbAnswers: thumbAnswers,
      audioRating: audioRating
    };

    return this.http.post(this.submitGameCompletedUrl, body)
      .subscribe(
        res => {
          this.userService.update();
        },
        err => {
          console.error("Submitting Game Completed Survey failed", err);
        }
      );
  }

  /**
   * Get the previous game completed survey to display during last session evaluation phase
   * @returns {Observable<any | any>}
   */
  public getLatestGameCompletedSurvey(): Observable<GameCompleteEvaluation> {
    return this.http.get(this.getLatestGameCompletedUrl)
      .pipe(
        map(res => {
          return res as GameCompleteEvaluation;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Check if user has submitted game completed survey
   * @returns {Observable<boolean>}
   */
  userHasSubmitedSurvey(): Observable<boolean> {
    return this.http.get(`/api/survey/user-has-submited-game-completed-survey`)
      .pipe(
        map(res => {
          return res['submitted'] as boolean;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get all the thumb ratings related to the engagement data
   */
  public getEngagementData(orgId: string): void {
    this.http.get(this.getEngagementDataURL + orgId)
      .subscribe(res => {
          this.allThumbSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching engagement data failed", err);
          this.allThumbSurveys$.next(null);
        }
      );
  }

  /**
   * @param {string} orgId
   * @param {string} pw
   */
  public getGuestEngagementData(orgId: string, pw: string): void {
    this.http.get(this.getGuestEngagementDataURL + orgId + '/' + pw)
      .subscribe(res => {
          this.allThumbSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching engagement data failed", err);
          this.allThumbSurveys$.next(null);
        }
      );
  }

  public getUserEngagementData(query: Object): void {
    this.http.put(this.getUserEngagementDataURL, query)
      .subscribe(res => {
          this.userThumbSurveys$.next(res as any[]);
        },
        err => {
          console.error("Fetching engagement data failed", err);
          this.userThumbSurveys$.next(null);
        }
      );
  }

  /*public getGeneralFeedback(startDate?: Date): Observable<GeneralFeedback[]> {
    return this.http.put(this.getGeneralFeedbackUrl, {time: startDate})
      .pipe(
        map(res => {
          return res as GeneralFeedback[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }*/
}
