import {Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TutorialContent} from "../../../core/service/game-tutorial.service";

@Component({
  selector: 'tutorial-slide-show',
  template: `
    <div class="slide-show-container">
      <div *ngIf="showHelpButtons" class="help-buttons-container">
        <raised-css-button
          [disabled]="!isSituationPhase"
          [buttonText]="'session-helper.chairman-info'"
          (onClick)="showChairmanInfo()"
          [marginTop]="'0.1vmin'"
          [marginBottom]="'0.1vmin'"
          [buttonId]="'chairman-info-force'"
        ></raised-css-button>
        
        <raised-css-button
          [buttonText]="'contact-form.contact-form-open-button'"
          (onClick)="showContactInfo()"
          [marginTop]="'0.1vmin'"
          [marginBottom]="'0.1vmin'"
        ></raised-css-button>
      </div>

      <ng-container *ngIf="slideShowContent?.content?.length">
        <div class="slide-show"
             [ngStyle]="{
             'width': (slideShowContent.content.length * slideShowWidth) + slideShowWidthFormat,
             'transform': 'translateX(-' + (currentIndex * slideShowWidth) + slideShowWidthFormat + ')'
           }">
          <div class="slide-show-content" *ngFor="let content of slideShowContent.content; let contentIndex = index" [ngClass]="{'content-visible': currentIndex === contentIndex}">
            <div>
              <h3 class="tutorial-phase-title">{{'new-tutorial' + '.' + slideShowContent.phase + '.' + content + '.' + 'title' | translate}}</h3>

              <div class="tutorial-phase-text" [innerHtml]="('new-tutorial' + '.' + slideShowContent.phase + '.' + content + '.' + 'description' | translate) | newLine"></div>
            </div>

            <div class="final-answer-title">
              <h3>{{'new-tutorial.example-question' | translate}}</h3>
            </div>

            <div class="animation-container">
              <tutorial-animation
                [tutorialTitle]="slideShowContent.phase"
                [tutorialIdentifier]="content"
                [currentIdentifier]="slideShowContent.content[currentIndex]"
              ></tutorial-animation>
            </div>
          </div>
        </div>

        <div class="slide-show-buttons" [ngClass]="{'relative-button': relativeButton}">
          <button (click)="changeCurrentIndex(-1)" [disabled]="currentIndex === 0">
            <span class="next-page-arrow arrow-left" [ngClass]="{'disabled': currentIndex === 0}"></span>
          </button>

          <div class="page-number">{{(currentIndex + 1) + "/" + slideShowContent.content.length}}</div>

          <button (click)="changeCurrentIndex(1)" [disabled]="currentIndex === slideShowContent.content.length - 1">
          <span class="next-page-arrow arrow-right"
                [ngClass]="{'disabled': currentIndex === slideShowContent.content.length - 1}"></span>
          </button>
        </div>
      </ng-container>
    </div>
  `,
  styles: []
})
export class TutorialSlideShowComponent implements OnChanges {
  @Input() slideShowContent: TutorialContent;
  @Input() slideShowPage: number;
  @Input() relativeButton: boolean;
  @Input() isSituationPhase: boolean;

  @Input() slideShowWidth: number = 72;
  @Input() slideShowWidthFormat: string = "vmin";
  @Input() showHelpButtons: boolean;

  @Output() chairmanInfoEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contactFormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public currentIndex: number = 0;

  constructor() {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === "ArrowLeft") {
      this.changeCurrentIndex(-1);
    } else if (event.key === "ArrowRight") {
      this.changeCurrentIndex(1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slideShowPage) {
      this.currentIndex = this.slideShowPage || 0;
    }
  }

  public changeCurrentIndex(change: number): void {
    if (!this.slideShowContent || !this.slideShowContent.content) {
      return;
    }

    const newIndex: number = this.currentIndex + change;

    if (newIndex < 0 || newIndex > this.slideShowContent.content.length - 1) {
      return;
    }

    this.currentIndex = newIndex;
  }

  public showChairmanInfo(): void {
    this.chairmanInfoEmitter.emit();
  }

  public showContactInfo(): void {
    this.contactFormEmitter.emit();
  }
}
