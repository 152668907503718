/**
 * Created by janne on 14.10.2016.
 */

import {User} from "./user.type";
import {Observable} from "rxjs";

export class ChatMessage {
  constructor(public sender: Observable<User>,
              public text: string,
              public systemMessage: boolean = false,
              public translateParams?: { [key in string]: string }) {
  }
}
