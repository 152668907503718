/**
 * Created by Topi on 3.11.2017.
 */

import {Component, Input, EventEmitter, Output, SimpleChanges, OnChanges} from '@angular/core';
import {environment} from "../../../environments/environment.prod";
import {User} from "../../data-model/user.type";
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'image-crop-modal',
  template: `
    <div class="modal in">
      <div class="modal-dialog animated slideInDown">
        <div class="modal-content">
          <div class="modal-header">
            <button (click)="hideModal()" class="pull-right modal-close-button">
              <span class="glyphicon glyphicon-remove"></span>
            </button>
            <h2>{{'participant-info-card.crop-image' | translate}}</h2>
          </div>

          <div class="modal-body">
            <div class="crop-area">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                format="png"
                [resizeToWidth]="300"
                [cropperMinWidth]="300"
                [onlyScaleDown]="true"
                (imageCropped)="imageCropped($event)"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
            </div>

            <div class="card-root button-area">
              <!-- TODO Fix circular dependency -->
              <!--<div class="image-preview">
                <h3>{{'participant-info-card.preview-image' | translate}}</h3>

                <user-profile-card
                  [userDoc]="userDoc"
                  [customImage]="croppedImage"
                  [disableHover]="true"
                  [cardColor]="'light'"
                ></user-profile-card>
              </div>-->

              <raised-css-button
                [buttonText]="'participant-info-card.save-image'"
                (onClick)="emitCroppedPicture()"
                [fontSize]="'2vh'"
                [marginTop]="'0'"
                [marginBottom]="'0'"
                [disabled]="waitForSave"
              ></raised-css-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ImageCropModalComponent implements OnChanges {
  @Input() userDoc: User;

  @Input() imageChangedEvent: Event;
  @Input() waitForSave: boolean;

  @Output() onNewImage: EventEmitter<File> = new EventEmitter<File>();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public croppedImage: string; // base64 png

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageChangedEvent'] && this.imageChangedEvent) {
      this.croppedImage = "";
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    console.error("Image load failed. Wrong file type.");
  }

  public emitCroppedPicture() {
    if (!this.croppedImage || !this.userDoc) {
      return;
    }

    this.onNewImage.emit(this.dataURLtoFile(this.croppedImage, "avatar.png"));
  }

  public dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  public hideModal() {
    this.closeModal.emit(false);
  }
}
