/**
 * Created by Topi on 14.03.2019
 */

import {Component, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, ElementRef, OnInit, OnDestroy} from '@angular/core';
import {User} from "../../data-model/user.type";
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'written-answer',
  template: `
    <div class="answer-header"
         [ngClass]="{'group-phase': dark || phase === 'group'}"
         [ngStyle]="customWidth && {'width': customWidth}"
    >
      <ng-container *ngIf="phase === 'personal' && !titleText">
        {{"situation.personal-answer" | translate}}
      </ng-container>

      <ng-container *ngIf="phase && phase !== 'personal'">
        <user-profile-card
          [cardSize]="'small'"
          [cardColor]="'extra-dark'"
          [userDoc]="currentChairman"
          [zIndex]="1"
          [showStatus]="false"
          [disableHover]="true"
          [hideName]="true"
        ></user-profile-card>

        <ng-container *ngIf="!titleText && phase === 'group'">
          {{"situation.chairman-group-answer" | translate}}
        </ng-container>

        <ng-container *ngIf="!titleText && phase === 'review' ">
          {{"situation.chairman-review-answer" | translate}}
        </ng-container>
      </ng-container>

      <ng-container *ngIf="titleText">
        {{titleText | translate}}
      </ng-container>
    </div>

    <div
      *ngIf="phase && ((titleText || phase !== 'personal') && !userIsChairman)"
      class="answer-div no-write"
      [ngClass]="{'group-phase': dark || phase === 'group'}"
      [innerHtml]="(answerString ? answerString : (placeholder | translate)) | newLine"
      [ngStyle]="customWidth && {'width': customWidth}"
    ></div>

    <div
      *ngIf="userIsChairman || phase === 'personal' || !phase"
      class="answer-div"
      [ngClass]="{'group-phase': dark || phase === 'group'}"
      [ngStyle]="{width: customWidth, height: areaHeight === 'scroll' && customHeight, 'min-height': customHeight}"
    >
      <ng-container *ngIf="!areaHeight || areaHeight === 'stretch'">
        <textarea
          *ngIf="!oneLineText"
          class="answer-textarea"
          spellcheck="false"
          [id]="inputId"
          [auto-focus]="focusToId && inputId === focusToId"
          [auto-resize]="currentAnswerString"
          [(ngModel)]="currentAnswerString"
          [placeholder]="placeholder ? (placeholder | translate) : ''"
          (ngModelChange)="updateAnswer($event)"
          [ngClass]="{'placeholder-flash': placeholder && !disabled && !currentAnswerString,
          'group-phase': dark || phase === 'group', 'remove-visible': showRemoveButton}"
          [disabled]="disabled"
        ></textarea>

        <input
          *ngIf="oneLineText"
          class="answer-textarea"
          spellcheck="false"
          [id]="inputId"
          [auto-focus]="focusToId && inputId === focusToId"
          [(ngModel)]="currentAnswerString"
          [placeholder]="placeholder ? (placeholder | translate) : ''"
          (keyup.enter)="enterPressed()"
          (ngModelChange)="updateAnswer($event)"
          [ngClass]="{'placeholder-flash': placeholder && !disabled && !currentAnswerString,
        'group-phase': dark || phase === 'group', 'remove-visible': showRemoveButton}"
          [disabled]="disabled"
        />
      </ng-container>

      <ng-container *ngIf="areaHeight === 'scroll'">
        <div
          class="scrollbar-wrapper custom-loge-scrollbar"
        >
          <textarea
            *ngIf="!oneLineText"
            [ngStyle]="{height: '100%'}"
            class="answer-textarea"
            spellcheck="false"
            [id]="inputId"
            [auto-focus]="focusToId && inputId === focusToId"
            [(ngModel)]="currentAnswerString"
            [placeholder]="placeholder ? (placeholder | translate) : ''"
            (ngModelChange)="updateAnswer($event)"
            [ngClass]="{'placeholder-flash': placeholder && !disabled && !currentAnswerString,
                    'group-phase': dark || phase === 'group', 'remove-visible': showRemoveButton}"
            [disabled]="disabled"
          ></textarea>

          <input
            *ngIf="oneLineText"
            [ngStyle]="{height: '100%'}"
            class="answer-textarea"
            spellcheck="false"
            [id]="inputId"
            [auto-focus]="focusToId && inputId === focusToId"
            [(ngModel)]="currentAnswerString"
            [placeholder]="placeholder ? (placeholder | translate) : ''"
            (keyup.enter)="enterPressed()"
            (ngModelChange)="updateAnswer($event)"
            [ngClass]="{'placeholder-flash': placeholder && !disabled && !currentAnswerString,
                          'group-phase': dark || phase === 'group', 'remove-visible': showRemoveButton}"
            [disabled]="disabled"
          />
        </div>
      </ng-container>

      <span
        *ngIf="showRemoveButton" class="remove-button glyphicon glyphicon-remove"
        [ngStyle]="{'group-phase': dark || phase === 'group'}"
        (click)="onRemoveButton.emit()"
      ></span>
    </div>
  `
})
export class WrittenAnswerComponent implements OnChanges, OnDestroy, OnInit {
  @Input() phase: string;
  @Input() answerString: string = "";
  @Input() currentChairman: User;
  @Input() userIsChairman: boolean;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() forceChange: boolean;
  @Input() titleText: string;
  @Input() dark: boolean;
  @Input() inputId: string;
  @Input() oneLineText: boolean;
  @Input() customWidth: string;
  @Input() customHeight: string;
  @Input() areaHeight: string = "stretch"; // "stretch" or "scroll"
  @Input() showRemoveButton: boolean;
  @Input() focusToId: string;

  @Output() answerEmitter: EventEmitter<string> = new EventEmitter();
  @Output() onPressEnter: EventEmitter<string> = new EventEmitter();
  @Output() onRemoveButton: EventEmitter<string> = new EventEmitter();

  public currentAnswerString: string = "";


  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.answerString || changes.forceChange) && this.forceChange) {
      this.currentAnswerString = this.answerString ? this.answerString : "";
      return;
    }

    if ((changes.currentChairman || changes.answerString) && (!this.currentChairman || !this.currentAnswerString)) {
      this.currentAnswerString = this.answerString ? this.answerString : "";
    }
  }

  ngOnDestroy(): void {

  }

  public updateAnswer(answer: string) {
    this.answerEmitter.emit(answer);
  }

  public enterPressed(): void {
    if (!this.currentAnswerString || !this.currentAnswerString.trim().length) {
      return;
    }

    this.onPressEnter.emit();
  }
}
