import {
  Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import {UserReactions} from "../../data-model/game-session-log.type";

@Component({
  selector: 'reaction-statistics',
  template: `
    <div class="sub-header-title">
      {{'user-statistics.reactions' | translate}}
    </div>

    <div class="sub-header-line"></div>
    
    <div class="reactions-sub-titles">
      <div class="sub-sub-header-title">
        {{'user-statistics.reactions-received' | translate}}
      </div>
      <div class="sub-sub-header-title">
        {{'user-statistics.reactions-given' | translate}}
      </div>
    </div>
    
    <div class="reaction-container" *ngFor="let userReaction of userReactions">
      <div class="reaction-bar left-side"
           [ngStyle]="{'width': getWidthPercentage(userReaction.receivedAmount)}"
           [attr.reactions-amount]="userReaction.receivedAmount ? userReaction.receivedAmount : undefined"
      ></div>

      <svg-reaction-emoji
        [emojiType]="userReaction.type"
        class="reaction"
      ></svg-reaction-emoji>

      <div class="reaction-bar right-side"
           [ngStyle]="{'width': getWidthPercentage(userReaction.givenAmount)}"
           [attr.reactions-amount]="userReaction.givenAmount ? userReaction.givenAmount : undefined"
      ></div>
    </div>
  `,
})
export class ReactionStatisticsComponent implements OnChanges, OnInit {
  @Input() userReactions: UserReactions[];
  @Input() statisticHeight: string = "5vh";

  public mostReactionsAmount: number = 3;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userReactions && this.userReactions && this.userReactions.length) {
      // Get largest value between receivedAmounts and givenAmounts to use as maximum
      this.mostReactionsAmount = this.userReactions.reduce((maxAmount, reaction) => {
        if (reaction.receivedAmount > maxAmount || reaction.givenAmount > maxAmount) {
          return reaction.receivedAmount > reaction.givenAmount ? reaction.receivedAmount : reaction.givenAmount;
        }

        return maxAmount;
      }, 1);

      if (this.mostReactionsAmount < 3) {
        this.mostReactionsAmount = 3;
      }
    }
  }

  public getWidthPercentage(amount: number): string {
    if (!amount) {
      return '0%';
    }

    return (amount / this.mostReactionsAmount) * 40 + '%';
  }
}
