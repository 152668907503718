/**
 * Created by janne on 28.11.2016.
 */

import {Injectable} from "@angular/core";
import {GameSessionService} from "./game-session.service";
import {GameTimeslotService} from "./timeslot.service";
import {Router} from "@angular/router";
import {UserService} from "./user.service";
import {User} from "app/data-model/user.type";
import {GameTimeslot} from "app/data-model/timeslot.type";
import {GameSession} from "app/data-model/game-session.interface";
import {combineLatest} from "rxjs";
import {Moment} from "moment";
import {filter, first} from "rxjs/operators";
import Timer = NodeJS.Timer;
import {NewLinePipe} from "../../shared/pipe/newline.pipe";
import {SocketService} from "./socket.service";
const moment = require("moment");

class TimeSlotState {
  constructor(public user: User,
              public timeslots: GameTimeslot[]) {
  }
}

class SessionState {
  constructor(public user: User,
              public session: GameSession) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class GameSessionAutoJoinerService {
  private nextDiscussionTimer: any;
  private previousNextTimeSlot: GameTimeslot;
  private nextJoinAttempt: Moment;

  constructor(protected router: Router,
              protected userService: UserService,
              protected gameTimeslotService: GameTimeslotService,
              protected gameSessionService: GameSessionService,
              private socketService: SocketService) {
  }

  public init() {
    // Update lists
    this.gameSessionService.getCurrentSession();
    this.gameTimeslotService.updateCurrentUserJoinedList();

    // Schedule auto-join to next TimeSlot
    this.createTimeSlotStates();

    // Check for current discussion
    this.gameSessionService.sessionDoc$
      .pipe(
        filter(doc => doc !== undefined),
        first()
      )
      .subscribe((sessionDoc: GameSession) => {
        // No discussion to join
        if (!sessionDoc) {
          return;
        }

        // Already in discussion, no need to try
        if (location.href.indexOf('game-session') !== -1) {
          return;
        }

        // Join discussion if past pre-lobby
        if (moment(sessionDoc.beginTime).isBefore(moment())) {
          this.gameSessionService.joinSession();
          return;
        }

        // Schedule join
        this.scheduleJoinAttempt(moment(sessionDoc.beginTime));
      });
  }

  /**
   * Create timeSlot states
   */
  public createTimeSlotStates() {
    this.gameTimeslotService.currentUserJoinedGameTimeslots$
      .pipe(
        filter(docs => !!docs)
      )
      .subscribe(userTimeSlots => {
        this.timeSlotStateUpdated(userTimeSlots);
      });
  }

  public clearTimer(): void {
    if (!this.nextDiscussionTimer) {
      return;
    }

    clearTimeout(this.nextDiscussionTimer);
  }

  protected timeSlotStateUpdated(gameTimeSlots: GameTimeslot[]): void {
    if (!gameTimeSlots.length) {
      return;
    }

    // Find timeSlot that is the next one to begin
    const nextTimeSlot: GameTimeslot = gameTimeSlots.sort((a, b) => {
      return moment(a.beginTime).isAfter(b.beginTime);
    })[0];

    if (!nextTimeSlot || !nextTimeSlot.beginTime) {
      // No valid timeSlot or beginTime not set, don't try scheduling
      return;
    }

    if (this.previousNextTimeSlot && nextTimeSlot._id === this.previousNextTimeSlot._id) {
      // Already scheduled
      return;
    }

    this.previousNextTimeSlot = nextTimeSlot;
    this.scheduleJoinAttempt(moment(nextTimeSlot.beginTime).subtract(1, 'minute'));
  }

  /**
   * Schedule join attempt if timeSlot has not yet started
   * @param {Moment} attemptTime
   */
  protected scheduleJoinAttempt(attemptTime: Moment): void {
    if (this.nextJoinAttempt && attemptTime.isAfter(this.nextJoinAttempt)) {
      return;
    }

    this.clearTimer();
    this.nextJoinAttempt = attemptTime;

    const now: number = Date.now().valueOf();
    const then: number = attemptTime.valueOf();

    // Schedule auto-join to 15min after timeSlot starts (15min optional lobby)
    const timeoutDelta: number = Math.min(15 * 60 * 1000, Math.max(0, then - now + 10000));

    this.nextDiscussionTimer = window.setTimeout(
      () => {
        if (location.href.indexOf('game-session') !== -1) {
          // Don't try joining if already joined
          console.log("Skipping scheduled join attempt, because already in discussion");
          return;
        }

        // Find discussion and join instantly
        this.gameSessionService.getCurrentSession(true);

        // Update timeSlots list
        this.gameTimeslotService.updateCurrentUserJoinedList();
      },
      timeoutDelta
    );
  }
}
