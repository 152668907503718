import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from "@angular/router";
import {UserService} from "../core/service/user.service";
import {filter, first, takeUntil} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: 'recover-view',
  template: `
    <div class="login-area">
      <div class="login-flex">
        <ng-container *ngIf="!sent">
          <h1>{{'recover-view.title' | translate}}</h1>

          <p *ngIf="error && error=='mismatch'" class="text-danger">{{'recover-view.error-mismatch' | translate}}</p>
          <p *ngIf="error && error=='empty'" class="text-danger">{{'recover-view.error-empty' | translate}}</p>
          <div class="form">
            <div class="form-group" [ngStyle]="{'margin-bottom': '2vh'}">
              <label class="form-label" for="pwdInput">{{'recover-view.password-label' | translate}}</label>
              <input class="text-input" name="password" id="pwdInput" type="password" (keydown)="enterActivate($event, 'submit')"
                     [(ngModel)]="password">
            </div>
            <div class="form-group" [ngStyle]="{'margin-bottom': '2vh'}">
              <label class="form-label" for="pwdConfirm">{{'recover-view.confirm-label' | translate}}</label>
              <input class="text-input" name="password" id="pwdConfirm" type="password" (keydown)="enterActivate($event, 'submit')"
                     [(ngModel)]="passwordConfirmation">
            </div>
          </div>

          <raised-css-button
            [buttonText]="'recover-view.submit'"
            (onClick)="submit()"
            [marginTop]="'1vmin'"
            [fontSize]="'3vmin'"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
            [marginBottom]="'0.5vmin'"
            [animatedAppear]="true"
          ></raised-css-button>
        </ng-container>

        <ng-container class="sent" *ngIf="sent">
          <h1>{{'recover-view.sent-title' | translate}}</h1>
          <div class="instructions">{{'recover-view.sent-instructions' | translate}}</div>

          <raised-css-button
            [buttonText]="'recover-view.sent-return'"
            (onClick)="returnToFrontPage()"
            [marginTop]="'1vmin'"
            [fontSize]="'3vmin'"
            [ngStyle]="{width: '100%'}"
            [buttonClasses]="'full-width'"
            [marginBottom]="'0.5vmin'"
            [animatedAppear]="true"
          ></raised-css-button>
        </ng-container>
      </div>
    </div>
  `
})
export class RecoverView extends OnDestroyMixin implements OnInit {

  public sent: boolean = false;
  protected password: string;
  protected passwordConfirmation: string;
  protected error: string = null;

  protected userId: string;
  protected recoveryKey: string;

  protected loggedIn: boolean = false;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected userService: UserService) {
    super();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        first(),
        untilComponentDestroyed(this)
      )
      .subscribe((params: Params) => {
        this.userId = params['uid'];
        this.recoveryKey = params['key'];

        this.userService.checkRecoveryInfo(this.userId, this.recoveryKey)
          .subscribe((urlValid: boolean) => {
            this.userService.currentUser$
              .pipe(
                filter(user => user !== undefined),
                first()
              )
              .subscribe(
                user => {
                  if (user) {
                    this.loggedIn = true;
                  }

                  if (!urlValid) {
                    this.router.navigate(['/welcome']);
                  }
                }
              );
          });
      });
  }

  enterActivate(event, fn: string) {
    if (event.key === "Enter" && this[fn]) {
      this[fn]();
    }
  }

  /**
   * Check that password is valid before completing recovery
   */
  submit() {
    this.error = null;
    if (!this.password || !this.passwordConfirmation) {
      this.error = 'empty';
      return;
    }
    if (this.password !== this.passwordConfirmation) {
      this.error = 'mismatch';
      return;
    }

    this.userService.completePasswordRecovery(this.userId, this.recoveryKey, this.password, (err) => {
      if (err) {
        this.error = "server";
      } else {
        this.sent = true;
      }
    });
  }

  public returnToFrontPage(): void {
    if (this.loggedIn) {
      this.router.navigate(['/landing-page']);
      return;
    }

    this.router.navigate(['/welcome']);
  }
}
