import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ClockRoundComponent} from "../clock-round.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'tutorial-animation',
  template: `
    <clock-round
      #timeSlotTimer
      [hidden]="tutorialIdentifier !== 'clock-info'"
    ></clock-round>

    <ng-container *ngIf="tutorialTitle === 'situation-phases' && situationPhaseIndex >= 0 && situationPhaseIndex <= 2">
      <discussion-progress
        class="animated slideInDown"
        [amountOfSituations]="2"
        [currentSituationIndex]="situationIndex"
        [currentSituationPhaseIndex]="situationPhaseIndex"
        [situationType]="1"
      ></discussion-progress>

      <written-answer
        *ngIf="tutorialIdentifier === 'personal-phase'"
        [phase]="'personal'"
        [answerString]="currentPersonalAnswer"
        [placeholder]="answerLocked ? '' : 'situation.suggestion-answer-placeholder'"
        [forceChange]="true"
        [disabled]="answerLocked"
        [dark]="true"
      ></written-answer>

      <written-answer
        *ngIf="tutorialIdentifier === 'group-phase'"
        [phase]="'review'"
        [userIsChairman]="false"
        [currentChairman]="{name: 'Chairman'}"
        [answerString]="currentGroupAnswer"
        [dark]="true"
      ></written-answer>

      <question-feedback
        *ngIf="tutorialIdentifier === 'review-phase'"
        [tutorialExplanation]="'new-tutorial.review-explanation'"
      ></question-feedback>

      <div class="lock-button-container" *ngIf="tutorialIdentifier === 'personal-phase'">
        <raised-css-button
          [buttonText]="answerLocked ? 'session-button.cancel-lock-answer' : 'session-button.lock-answer'"
          (onClick)="toggleLocked()"
          [fontSize]="'2vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'1vmin'"
        ></raised-css-button>
      </div>
    </ng-container>

    <ng-container *ngIf="tutorialTitle === 'multi-select-phases'">
      <div class="main-prompt" *ngIf="tutorialIdentifier !== 'multi-select-review'">{{'tutorial-situations.multi-select-prompt' | translate}}</div>

      <tutorial-multi-select
        [answerLocked]="answerLocked"
        [phase]="tutorialIdentifier === 'multi-select-general' ? 'personal' : (tutorialIdentifier === 'multi-select-group' ? 'group' : 'review')"
      ></tutorial-multi-select>

      <div class="lock-button-container" *ngIf="tutorialIdentifier === 'multi-select-general'">
        <raised-css-button
          [buttonText]="answerLocked ? 'session-button.cancel-lock-answer' : 'session-button.lock-answer'"
          (onClick)="toggleLocked()"
          [fontSize]="'2vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'1vmin'"
        ></raised-css-button>
      </div>
    </ng-container>

    <ng-container *ngIf="tutorialTitle === 'scaling-phases'">
      <div class="main-prompt" [ngStyle]="{margin: '0 auto'}">
        {{('tutorial-situations.' + (tutorialIdentifier !== 'scaling-suggestions' ? 'scaling-prompt' : 'scaling-suggestion-prompt')) | translate}}
      </div>

      <tutorial-scaling
        *ngIf="currentIdentifier === tutorialIdentifier"
        [phase]="tutorialIdentifier === 'scaling-general' ? 'personal' : 'group'"
        [tutorialIdentifier]="tutorialIdentifier"
      ></tutorial-scaling>
    </ng-container>

    <ng-container *ngIf="tutorialTitle === 'poll-phases'">
      <div class="main-prompt" [ngStyle]="{margin: '0 auto'}">{{'tutorial-situations.poll-prompt' | translate}}</div>

      <tutorial-poll
        [phase]="tutorialIdentifier === 'poll-personal-phase' ? 'personal' : 'group'"
      ></tutorial-poll>
    </ng-container>
  `,
  styles: []
})
export class TutorialAnimationComponent implements OnInit, OnChanges {
  @ViewChild('timeSlotTimer') timeSlotTimer: ClockRoundComponent;

  @Input() tutorialTitle: string;
  @Input() tutorialIdentifier: string;
  @Input() currentIdentifier: string;

  public situationIndex: number = 0;
  public situationPhaseIndex: number;

  public currentPersonalAnswer: string = "";
  public currentGroupAnswer: string = "";

  public timeOutSeconds: number;

  public answerLocked: boolean;

  private answerTimeout: any;
  private phaseInterval: any;
  private clockRestart: any;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.currentIndex || changes.currentIdentifier) && this.tutorialIdentifier) {
      this.timeOutSeconds = this.tutorialIdentifier ? 5 : 0;

      if (this.phaseInterval) {
        clearInterval(this.phaseInterval);
        this.phaseInterval = null;
      }

      if (this.clockRestart) {
        clearInterval(this.clockRestart);
        this.clockRestart = null;
      }

      if (this.tutorialIdentifier !== this.currentIdentifier) {
        // Only reset animation for selected identifier
        return;
      }

      switch (this.tutorialIdentifier) {
        case "question-general": {
          this.situationPhaseIndex = 0;

          this.phaseInterval = setInterval(() => {
            this.situationPhaseIndex = this.situationPhaseIndex === 2 ? 0 : this.situationPhaseIndex + 1;

            if (this.situationPhaseIndex === 0) {
              this.situationIndex = this.situationIndex === 0 ? 1 : 0;
            }
          }, this.timeOutSeconds * 1000);
          break;
        }
        case "personal-phase": {
          this.situationPhaseIndex = 0;
          break;
        }
        case "group-phase": {
          this.situationPhaseIndex = 1;
          this.textScroll(this.translate.instant('new-tutorial.animations.multi-select-group-answer'), 0, 100, true);
          break;
        }
        case "review-phase": {
          this.situationPhaseIndex = 2;
          break;
        }
        case "clock-info": {
          this.timeSlotTimer.setTime(60);

          this.clockRestart = setInterval(() => {
            this.timeSlotTimer.setTime(60);
          }, 60000);

          break;
        }
        default: {
          this.situationPhaseIndex = null;
        }
      }
    }
  }

  public textScroll(message, index, interval, reset?: boolean) {
    if (reset && this.answerTimeout) {
      clearInterval(this.answerTimeout);
    }

    if (!message) {
      return;
    }

    if (index === 0) {
      this.currentGroupAnswer = "";
    }

    if (index < message.length) {
      this.currentGroupAnswer += message[index];

      this.answerTimeout = setTimeout(() => {
        this.textScroll(message, ++index, interval);
      }, interval);
    }
  }

  public toggleLocked(): void {
    this.answerLocked = !this.answerLocked;
  }
}
