/**
 * Created by Topi on 3.10.2018.
 */

import {Component, Input, SimpleChanges, OnChanges} from '@angular/core';
import {NetworkInformation, User} from "../../data-model/user.type";
import {UserService} from "../../core/service/user.service";
import {GameState, GameStateSnapshot, HardwareInfo} from "../../data-model/game-state.interface";

@Component({
  selector: 'email-contact-form',
  template: `
    <div class="email-contact-container" [ngStyle]="{'margin-top': marginTop}">
      <div class="input-container">
        <h3>
          {{'contact-form.contact-email' | translate}}
        </h3>

        <input
          type="email"
          class="text-input"
          [(ngModel)]="emailSender"
          [placeholder]="'contact-form.message-email-placeholder' | translate"
        >

        <h3>
          {{'contact-form.message-content' | translate}}
        </h3>

        <textarea
          id="emailContent"
          class="text-input"
          [(ngModel)]="emailContent"
          [placeholder]="'contact-form.message-content-placeholder' | translate"
          [auto-resize]="emailContent"
          [rows]="3"
          [maxHeight]="getWindowPixels('innerHeight', 0.5)"
        ></textarea>
      </div>

      <div class="send-button-container">
        <raised-css-button
          [ngStyle]="{marginLeft: '1vh'}"
          [buttonText]="'contact-form.email-status' + sendingStatus"
          [iconClass]="'glyphicon glyphicon-envelope icon-margin'"
          [disabled]="!emailContent || sendingStatus"
          [fontSize]="'2vh'"
          [marginTop]="'0'"
          [marginBottom]="'0'"
          (onClick)="sendContactEmail()"
        ></raised-css-button>
      </div>
    </div>
  `
})
export class EmailContactFormComponent implements OnChanges {
  @Input() currentUser: User;
  @Input() location: string;

  @Input() marginTop: string;

  public emailSender: string = "";
  public emailContent: string = "";
  public sendingStatus: string = "";

  constructor(private userService: UserService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser && this.currentUser) {
      this.emailSender = this.currentUser.email;
    }
  }

  public sendContactEmail() {
    this.sendingStatus = '-sending';

    const location: string = window.location.hash.substring(2);
    const hardware: HardwareInfo = UserService.getHardwareInfo();
    const network: NetworkInformation = UserService.getNetworkInfo();

    this.userService.sendContactEmail({
      emailSender: this.emailSender,
      emailContent: this.emailContent,
      siteLocation: this.location || location,
      browser: hardware ? hardware.browser : "Unknown",
      network: network ? ("Network estimate: " + network.effectiveType + ", " + network.downlink + "MB/s") : "Unknown"
    }).subscribe(() => {
      this.sendingStatus = '-sent';
    });
  }

  public getWindowPixels(value: string, amount: number): number {
    if (!window[value]) {
      return 0;
    }

    return window[value] * amount;
  }
}
