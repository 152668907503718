/**
 * Created by Topi on 7.11.2018
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {GameTutorialText} from "../../data-model/tutorial.interface";

@Component({
  selector: 'raised-css-button',
  template: `
    <div [ngStyle]="{'height': marginTop}">
      <!-- Margins will break the button press animation -->
    </div>

    <div class="main-button"
         [id]="buttonId ? buttonId : 'raised-button-main'"
         [ngStyle]="{'font-size': fontSize}"
         [ngClass]="buttonClass"
         (click)="onButtonClick()"
    >
      <div
        class="button-gradient"
        [ngClass]="roundedCorners ? 'rounded-corners' : ''"
      ></div>

      <span class="button-icon" *ngIf="iconClass && iconPosition === 'left'" [ngClass]="iconClass"></span>

      <div class="button-text" *ngIf="buttonText">
        {{buttonText | translate: translateOptions }}
      </div>

      <span class="button-icon" *ngIf="iconClass && iconPosition === 'right'" [ngClass]="iconClass"></span>

      <ng-container *ngIf="notificationText">
        <div class="button-notification">
          {{notificationText | translate}}
        </div>

        <!--<div [ngStyle]="{'height': '0.2vh'}"></div>-->
      </ng-container>
    </div>

    <div [ngStyle]="{'height': marginBottom}">
      <!-- Margins will break the button press animation -->
    </div>
  `
})

export class RaisedCssButtonComponent implements OnChanges {
  @Input() buttonText: string;
  @Input() translateOptions: any = {};
  @Input() buttonClasses: string;
  @Input() fullSize: boolean;
  @Input() fontSize: string;
  @Input() disabled: boolean;
  @Input() buttonDown: boolean;
  @Input() iconClass: string;
  @Input() iconPosition: string = "left";
  @Input() notificationText: string;
  @Input() buttonId: string;
  @Input() animatedAppear: boolean;
  @Input() animatedFade: boolean;
  @Input() roundedCorners: boolean;

  @Input() marginTop: string | number = "0vh";
  @Input() marginBottom: string | number = "0vh";

  @Output() onClick: EventEmitter<boolean> = new EventEmitter();
  @Output() onDisabledClick: EventEmitter<boolean> = new EventEmitter();

  public buttonClass: Object;

  // Show discussion tutorial spotlight here so the margins aren't highlighted
  @Input() currentTutorial: GameTutorialText;
  @Output() spotlightOpened: EventEmitter<string> = new EventEmitter();
  @Output() spotlightClosed: EventEmitter<string> = new EventEmitter();

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setButtonClass();
  }

  public onButtonClick(): void {
    if (this.disabled) {
      this.onDisabledClick.emit();
      return;
    }

    this.onClick.emit();
  }

  public setButtonClass(): void {
    this.buttonClass = {
      'active': this.buttonDown,
      'notification-visible': !!this.notificationText,
      [this.buttonClasses]: !!this.buttonClasses,
      'animated fadeInUpSmall': this.animatedAppear && !this.animatedFade,
      'animated fadeOutDown': this.animatedFade,
      'disabled': this.disabled,
      'rounded-corners': this.roundedCorners,
    };
  }
}
