import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AudioDeviceInfo, AudioDeviceService, AvailableDevices, DeviceInfo } from "../../../core/service/audio-device.service";
import { UserService } from "../../../core/service/user.service";
import { User } from "../../../data-model/user.type";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { filter, first } from "rxjs/operators";

@Component({
  selector: "audio-device-selector",
  template: `
    <div class="flex-column-centered" *ngIf="availableDevices" [ngStyle]="{position: 'relative'}">
      <raised-css-button
        [buttonText]="showButtonText ? 'audio-device-selector.sound-settings' : null"
        [iconClass]="'glyphicon glyphicon-cog'"
        [marginTop]="'0'"
        [marginBottom]="settingPopUpType === 'landing-page-overlay' ? '2vmin' : 0"
        (onClick)="setShowSettings(true)"
      ></raised-css-button>

      <div
        class="audio-settings-container animated short"
        [ngClass]="{'landing-page-overlay': settingPopUpType === 'landing-page-overlay',
        'discussion-chat': settingPopUpType === 'discussion-chat',
        fadeIn: settingPopUpType === 'landing-page-overlay' && showSettings,
        fadeOut: settingPopUpType === 'landing-page-overlay' && !showSettings,
        'settings-visible': showSettings}"
        [ngStyle]="!showSettings && {pointerEvents: 'none'}"
      >
        <div *ngIf="!availableDevices || !availableDevices.input.length || !availableDevices.output.length">
          {{'audio-device-selector.no-devices' | translate}}
        </div>

        <label class="audio-settings-title">
          {{'audio-device-selector.sound-settings' | translate}}
        </label>

        <button
          class="pop-up-close-button"
          (click)="setShowSettings(false)"
        >
          <span class="glyphicon glyphicon-remove"></span>
        </button>

        <div class="select-option-row">
          <label>
            {{'audio-device-selector.microphone-label' | translate}}
          </label>

          <select class="bordered-input" *ngIf="showInputSelect" [(ngModel)]="selectedInput" (ngModelChange)="selectInput($event)">
            <option *ngIf="selectedInput" selected hidden [ngValue]="selectedInput">
              {{selectedInput.label}}
            </option>

            <option
              *ngFor="let device of availableDevices.input"
              [ngValue]="device"
            >
              {{device.label}}
            </option>
          </select>
        </div>

        <div class="select-option-row">
          <label>
            {{'audio-device-selector.speaker-label' | translate}}
          </label>

          <select class="bordered-input" *ngIf="showOutputSelect" [(ngModel)]="selectedOutput" (ngModelChange)="selectOutput($event)">
            <option *ngIf="selectedOutput" selected hidden [ngValue]="selectedOutput">
              {{selectedOutput.label}}
            </option>

            <option
              *ngFor="let device of availableDevices.output"
              [ngValue]="device"
            >
              {{device.label}}
            </option>
          </select>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class AudioDeviceSelectorComponent extends OnDestroyMixin implements OnInit {
  @Input() showInputSelect: boolean = true;
  @Input() showOutputSelect: boolean = true;
  @Input() showButtonText: boolean = true;
  @Input() settingPopUpType: "discussion-chat" | "landing-page-overlay" = "landing-page-overlay";

  public availableDevices: AvailableDevices;

  public selectedInput: DeviceInfo;
  public selectedOutput: DeviceInfo;

  public showSettings: boolean;

  @Output() onSettingsVisibilitySet: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private audioDeviceService: AudioDeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDevices();
  }

  public getDevices(): void {
    this.audioDeviceService.availableDevices$
      .pipe(
        untilComponentDestroyed(this),
        filter(availableDevices => !!availableDevices),
        first()
      )
      .subscribe((availableDevices: AvailableDevices) => {
        if (!availableDevices?.input?.length && !availableDevices?.output?.length) {
          console.error("No devices detected", availableDevices);
          return;
        }

        this.availableDevices = availableDevices;
      });

    this.audioDeviceService.selectedDevices$
      .pipe(
        untilComponentDestroyed(this),
        filter(selectedDevices => !!selectedDevices)
      )
      .subscribe((selectedDevices: AudioDeviceInfo) => {
        const selectedInputExists: boolean = selectedDevices?.input && !!this.availableDevices.input.find((input: DeviceInfo) => {
          return input.deviceId === selectedDevices.input.deviceId;
        });

        this.selectedInput = selectedInputExists ? selectedDevices.input : this.availableDevices.input[0];

        const selectedOutputExists: boolean = selectedDevices?.output && !!this.availableDevices.output.find((output: DeviceInfo) => {
          return output.deviceId === selectedDevices.output.deviceId;
        });

        this.selectedOutput = selectedOutputExists ? selectedDevices.output : this.availableDevices.output[0];
      });
  }

  public selectInput(inputDevice: DeviceInfo): void {
    this.audioDeviceService.switchVoiceDevice("input", inputDevice);
  }

  public selectOutput(outputDevice: DeviceInfo): void {
    this.audioDeviceService.switchVoiceDevice("output", outputDevice);
  }

  public setShowSettings(value: boolean): void {
    this.showSettings = value;
    this.onSettingsVisibilitySet.emit(value);
  }
}
