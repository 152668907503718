import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ClockRoundComponent } from "./clock-round.component";
import { LogeNotification, NotificationService } from "../../core/service/notification.service";
import { User } from "../../data-model/user.type";
import { UserService } from "../../core/service/user.service";
import { AudioDeviceService } from "../../core/service/audio-device.service";
import { Organization } from "../../data-model/organization.type";
import * as _ from 'lodash';
const moment = require("moment");

@Component({
  selector: "notification-pop-up",
  template: `
    <div class="notification-container" *ngFor="let notification of notificationQueue; let i = index">
      <div
        class="notification-border animated short"
        *ngIf="notification.visible !== null"
        [ngClass]="{fadeInUpSmall: notification.visible, fadeOutDown: !notification.visible}"
      >
        <button *ngIf="!notification.timer" (click)="closeNotification(i)" class="notification-close-button">
          <span class="glyphicon glyphicon-remove"></span>
        </button>

        <span *ngIf="previewNotification" class="notification-preview">Preview</span>

        <div class="flex-row-centered">
          <span class="notification-icon custom-icon" *ngIf="notification.icon" [ngClass]="notification.icon"></span>

          <div>
            <div class="notification-title" *ngIf="notification.title">
              {{notification.title | translate}}
            </div>

            <div class="notification-text" [ngClass]="{'has-title': notification.title}">
              <div class="notification-timer" *ngIf="notification.timer">
                <clock-round
                  #notificationCountdown
                  [hideCircle]="true"
                ></clock-round>
              </div>

              <span
                *ngIf="notification.message_html"
                [innerHTML]="notification.message_html | translate : (notification.customParams?.translateParams || {})"
              ></span>

              <span *ngIf="notification.message">
                {{notification?.message | translate : (notification.customParams?.translateParams || {})}}
              </span>

              <span *ngIf="notification?.customParams?.errorMsg">
                {{notification?.customParams?.errorMsg}}
              </span>
            </div>

            <div class="flex-row-centered" *ngIf="notification.confirm">
              <raised-css-button
                [ngStyle]="{marginRight: '1vmin'}"
                [buttonText]="'confirmation.default-yes'"
                [marginTop]="'0.5vmin'"
                [fontSize]="'1.5vmin'"
                (onClick)="closeNotification(i, true)"
              ></raised-css-button>

              <raised-css-button
                [buttonText]="'confirmation.default-no'"
                [marginTop]="'0.5vmin'"
                [fontSize]="'1.5vmin'"
                (onClick)="closeNotification(i)"
              ></raised-css-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class NotificationPopUpComponent extends OnDestroyMixin implements OnChanges {
  @ViewChild("notificationCountdown") notificationCountdown: ClockRoundComponent;

  @Input() currentUser: User;
  @Input() currentOrg: Organization;

  @Input() previewNotification: LogeNotification; // For testing in super-admin

  @Output() closeEmitter: EventEmitter<string> = new EventEmitter<string>();

  public currentNotification: LogeNotification;
  public notificationQueue: LogeNotification[] = [];
  public showNotification: boolean;

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private audioDeviceService: AudioDeviceService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.previewNotification && this.previewNotification) {
      // Show preview if we have at least a message or a title
      const tempNotification: LogeNotification = _.clone(this.previewNotification);
      tempNotification.visible = true;
      this.notificationQueue = (this.previewNotification.message || this.previewNotification.title) ? [tempNotification] : [];
    }
  }

  ngOnInit() {
    if (this.previewNotification) {
      // Don't listen to real notifications during preview as that is handled in admin-view
      return;
    }

    this.notificationService.notification$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe((notification: LogeNotification) => {
        if (!notification) {
          // Closed by service
          this.showNotification = false;

          return;
        }

        // No need to show new device notification if not using LOGE voice chat
        // Since notification$ is a Subject this won't catch the first audio device error, as getting currentOrg takes longer
        if (
          this.currentOrg?.voiceChat === "none" && (
            notification.title.indexOf("audio-device-test") === 0 ||
            notification.title.indexOf("media-devices") === 0 ||
            notification.title.indexOf("audio-chat-info") === 0
          )
        ) {
          console.log("Skip showing voice device notification with voiceChat disabled", notification);
          return;
        }

        this.setNotification(notification);
      });
  }

  public closeNotification(i: number, confirm?: boolean) {
    if (!this.notificationQueue[i]?.visible) {
      // Already hidden
      return;
    }

    // Toggle fade animation notification
    this.notificationQueue[i].visible = false;

    // Set chrome+mac combo possible error as confirmed
    if (this.notificationQueue[i].id === "chrome-mac-info") {
      if (!this.currentUser?.hardwareInfo) {
        // Invalid hardwareInfo
        return;
      }

      this.currentUser.hardwareInfo.chromeMacConfirmed = true;

      this.userService.saveUser(
        this.currentUser._id,
        {
          hardwareInfo: this.currentUser.hardwareInfo
        }
      );
    }

    // Do something if notification has a confirm option
    if (this.notificationQueue[i].confirm && confirm) {
      if (this.notificationQueue[i].customParams?.device) {
        // Set device changes

        if (this.notificationQueue[i].customParams.device.kind === "audiooutput") {
          // Set output
          this.audioDeviceService.switchVoiceDevice("output", this.notificationQueue[i].customParams.device);
        }

        else if (this.notificationQueue[i].customParams.device.kind === "audioinput") {
          // Set input
          this.audioDeviceService.switchVoiceDevice("input", this.notificationQueue[i].customParams.device);
        }
      }
    }

    // Emit close event (if needed)
    this.closeEmitter.emit(this.notificationQueue[i].id);

    // Remove from list
    setTimeout(() => {
      this.notificationQueue[i].visible = null;
    }, 500);
  }

  private setNotification(notification: LogeNotification) {
    if (!notification) {
      return;
    }

    notification.visible = true;

    if (notification.timer) {
      // TODO
    }

    this.notificationQueue.push(notification);
    const i: number = this.notificationQueue.length - 1;

    // On default, use 15s autoClose, if set as 0 no autoClose
    if (notification.autoClose === undefined || notification.autoClose > 0) {
      let seconds: number = notification.autoClose || 15;

      if (notification.timer) {
        seconds = moment(this.currentNotification.timer).diff(moment(), "s");
      }

      setTimeout(() => {
        this.closeNotification(i);
      }, seconds * 1000);
    }
  }
}
