/**
 * Created by Topi on 27.7.2018
 */
import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
  ViewChild
} from "@angular/core";
import { GameTimeslot } from "../../data-model/timeslot.type";
import { GameSeason, SeasonWithTimeSlots } from "../../data-model/game-season.type";
import { GameSeasonService } from "../../core/service/game-season.service";
import { GameTimeslotService } from "../../core/service/timeslot.service";
import { Organization } from "../../data-model/organization.type";
import { MaterialInterface } from "../../data-model/material.interface";
import { UserService } from "../../core/service/user.service";
import { MaterialService } from "../../core/service/material.service";
import { IntroAssignmentType } from "../../data-model/introduction-tutorial.type";
import { GameTutorialService } from "../../core/service/game-tutorial.service";
import { CalendarBodyLang } from "../../data-model/email.interface";
import { EmailEditorService } from "../../core/service/email-editor.service";
import { Router } from "@angular/router";
import { GameSessionLog } from "../../data-model/game-session-log.type";
import { User } from "../../data-model/user.type";
import { NgScrollbar } from "ngx-scrollbar";
import { MediaUtils } from "../../../lib/media-utils";
import { DataUpdate } from "../../core/service/data-update.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { SocketService } from "../../core/service/socket.service";
import { GameSession } from "../../data-model/game-session.interface";
import { GameSessionService } from "../../core/service/game-session.service";
import { DiscussionCardEvent } from "./discussion-card.component";
import { GeneralUtils } from "../../../lib/general-utils";

const moment = require("moment");

@Component({
  selector: "upcoming-sessions",
  template: `
    <div>
      <confirm-modal
        *ngIf="showConfirmation"
        [confirmMessage]="confirmMessage"
        [allowedElementIds]="['force-start-button-']"
        [customWidth]="30"
        [customWidthFormat]="'vw'"
        (sendConfirmation)="checkConfirmation($event)"
      ></confirm-modal>

      <confirm-modal
        *ngIf="errorMessage"
        [confirmMessage]="errorMessage"
        [confirmButtonText]="'game-timeslot.close-error'"
        [allowedElementIds]="['join-button-']"
        [customWidth]="30"
        [customWidthFormat]="'vw'"
        [hideSecondButton]="true"
        (sendConfirmation)="errorMessage = null"
      ></confirm-modal>

      <session-join-modal
        *ngIf="joinedTimeSlot"
        [timeSlot]="joinedTimeSlot"
        [season]="joinedTimeSlot?.gameSeasonId"
        [language]="currentUser?.language"
        [calendarContent]="calendarContent"
        (hideInfoModal)="hideInfoModal()"
        [currentUser]="currentUser"
        [upcomingSessionModal]="true"
        [customWidth]="30"
        [customWidthFormat]="'vw'"
        [allowedElementIds]="['discussion-join-button-']"
      ></session-join-modal>

      <ng-scrollbar
        #drawerScroll
        *ngIf="seasonsWithTimeSlots"
        class="loge-scrollbar"
        [track]="'vertical'"
        [ngStyle]="{'min-height': '50vh'}"
      >
        <div class="season-container" *ngIf="!seasonsWithTimeSlots?.length || !totalTimeSlots">
          {{'player-progress-view.discussions-none' | translate}}
        </div>

        <div class="season-container" *ngIf="seasonsWithTimeSlots?.length && totalTimeSlots">
          <div class="seasons-overview" *ngIf="currentOrganization?.organizationType !== 2">
            {{'player-progress-view.discussions-overview' | translate: {
            seasons: seasonsWithTimeSlots?.length,
            timeSlots: totalTimeSlots
          } }}
          </div>

          <div class="upcoming-discussion-card" *ngIf="currentOrganization?.organizationType !== 2">
            <raised-css-button
              [buttonText]="selectedSeasonNames?.length ? 'player-progress-view.hide-all-discussions' : 'player-progress-view.show-all-discussions'"
              [fontSize]="'2vmin'"
              (onClick)="toggleShowAllTimeSlots()"
              [marginTop]="'0'"
              [marginBottom]="'0'"
              [buttonClasses]="'loge-colors'"
            ></raised-css-button>
          </div>

          <ng-container *ngFor="let seasonTimeSlotObject of seasonsWithTimeSlots">
            <div *ngIf="seasonTimeSlotObject" class="upcoming-discussion-card"
                 [ngClass]="{'user-completed': userCompletedSeasons?.indexOf(seasonTimeSlotObject.seasonName) !== -1}">
              <h2>{{seasonTimeSlotObject.seasonName}}</h2>

              <div class="main-card">
                <div class="half">
                  <div class="season-image">
                    <img [src]="getActualFilePath(seasonTimeSlotObject.seasonImage)"/>
                  </div>
                </div>
                <div class="half">
                <span *ngIf="userCompletedSeasons?.indexOf(seasonTimeSlotObject.seasonName) !== -1"
                      class="completed-notification">{{'player-progress-view.discussion-completed' | translate}}</span>
                  <div *ngIf="seasonTimeSlotObject.seasonDescription">{{seasonTimeSlotObject.seasonDescription}}</div>

                  <div class="season-material">
                    <div id="material-button-{{seasonMaterial._id}}" class="material-image"
                         *ngFor="let seasonMaterial of seasonTimeSlotObject.seasonMaterial"
                         (click)="selectMaterial(seasonMaterial)">
                      <span [ngClass]="getMaterialIconClass(seasonMaterial)"></span>
                      {{seasonMaterial.title}}
                    </div>
                  </div>

                  <div class="buttons" *ngIf="currentOrganization?.organizationType !== 2">
                    <raised-css-button
                      [buttonText]="'player-progress-view.' + (selectedSeasonNames.indexOf(seasonTimeSlotObject.seasonName) === -1 ?
                  'show-discussions' : 'hide-discussions') | translate"
                      [fontSize]="'2vmin'"
                      (onClick)="showDiscussionsForSeason(seasonTimeSlotObject.seasonName)"
                      [marginTop]="'2vmin'"
                      [marginBottom]="'1vmin'"
                      [buttonClasses]="'loge-colors'"
                    ></raised-css-button>
                  </div>
                </div>
              </div>

              <mat-accordion>
                <mat-expansion-panel
                  [expanded]="showAllTimeSlots || selectedSeasonNames.indexOf(seasonTimeSlotObject.seasonName) !== -1"
                >
                  <!-- Elements should only exist when visible -->
                  <ng-container *ngIf="showAllTimeSlots || selectedSeasonNames.indexOf(seasonTimeSlotObject.seasonName) !== -1">
                    <!-- Discussions that have started, but in lobby (no need to show to managers, as they see them all) -->
                    <div class="time-slots" *ngIf="!forceStartPrivileges && seasonTimeSlotObject?.lobbyDiscussions?.length">
                      <div class="slot-title">Last chance</div>

                      <ng-container
                        *ngFor="
                        let lobbyDiscussion of seasonTimeSlotObject?.lobbyDiscussions;
                        let discussionIndex = index
                      "
                      >
                        <discussion-card
                          [discussion]="lobbyDiscussion"
                          [seasonName]="seasonTimeSlotObject?.seasonName"
                          [maxDuration]="seasonTimeSlotObject?.maxDuration"
                          [calendarContent]="calendarContent"
                          [currentUser]="currentUser"
                          [listType]="'last-chance'"
                          (onUserEvent)="onCardEvent($event, lobbyDiscussion, discussionIndex)"
                        ></discussion-card>
                      </ng-container>
                    </div>

                    <!-- Discussions that have started, for managers -->
                    <div class="time-slots" *ngIf="forceStartPrivileges && seasonTimeSlotObject?.onGoingDiscussions?.length">
                      <div class="slot-title">Active discussions</div>

                      <ng-container
                        *ngFor="
                        let onGoingDiscussion of seasonTimeSlotObject?.onGoingDiscussions;
                        let discussionIndex = index
                      "
                      >
                        <discussion-card
                          [discussion]="onGoingDiscussion"
                          [seasonName]="seasonTimeSlotObject?.seasonName"
                          [maxDuration]="seasonTimeSlotObject?.maxDuration"
                          [calendarContent]="calendarContent"
                          [currentUser]="currentUser"
                          [listType]="'on-going'"
                          [startPermission]="forceStartPrivileges"
                          (onUserEvent)="onCardEvent($event, onGoingDiscussion, discussionIndex)"
                        ></discussion-card>
                      </ng-container>
                    </div>

                    <!-- Time slots the current user has joined -->
                    <div class="time-slots" *ngIf="seasonTimeSlotObject?.joinedTimeSlots?.length">
                      <ng-container>
                        <div class="gradient-line"></div>
                        <div class="slot-title">{{'player-progress-view.upcoming-joined-slots' | translate}}</div>
                      </ng-container>

                      <ng-container
                        *ngFor="let joinedSlot of seasonTimeSlotObject?.joinedTimeSlots;
                      let slotIndex = index"
                      >
                        <discussion-card
                          [timeSlot]="joinedSlot"
                          [seasonName]="seasonTimeSlotObject?.seasonName"
                          [maxDuration]="seasonTimeSlotObject?.maxDuration"
                          [calendarContent]="calendarContent"
                          [currentUser]="currentUser"
                          [startPermission]="forceStartPrivileges"
                          [listType]="'joined'"
                          (onUserEvent)="onCardEvent($event, joinedSlot, slotIndex)"
                        ></discussion-card>
                      </ng-container>
                    </div>

                    <!-- Time slots the current can join -->
                    <div class="time-slots" *ngIf="seasonTimeSlotObject?.availableTimeSlots?.length">
                      <div class="gradient-line"></div>

                      <div class="slot-title">
                        {{'player-progress-view.upcoming-open-slots' | translate}}
                      </div>

                      <ng-container
                        *ngFor="let timeSlot of seasonTimeSlotObject?.availableTimeSlots;
                      let slotIndex = index"
                      >
                        <discussion-card
                          *ngIf="forceStartPrivileges || timeSlot.players.length < seasonTimeSlotObject.seasonGroupSize"
                          [timeSlot]="timeSlot"
                          [seasonName]="seasonTimeSlotObject?.seasonName"
                          [maxDuration]="seasonTimeSlotObject?.maxDuration"
                          [calendarContent]="calendarContent"
                          [currentUser]="currentUser"
                          [startPermission]="forceStartPrivileges"
                          [joinAllowed]="!checkAlreadyJoinedSameTime(timeSlot)"
                          (onUserEvent)="onCardEvent($event, timeSlot, slotIndex)"
                        ></discussion-card>
                      </ng-container>
                    </div>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <raised-css-button
            [buttonText]="'player-progress-view.scroll-top'"
            [fontSize]="'2vmin'"
            (onClick)="scrollTop()"
            [marginTop]="'2vmin'"
            [marginBottom]="'2vmin'"
            [buttonClasses]="'loge-colors'"
          ></raised-css-button>
        </div>
      </ng-scrollbar>
    </div>
  `
})
export class UpcomingSessionsComponent extends OnDestroyMixin implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgScrollbar) drawerScroll: NgScrollbar;

  @Input() currentUser: User;
  @Input() userSessions: GameSessionLog[];
  @Input() seasonNameOrder: string[];
  @Input() currentIntroTutorial: string;
  @Input() currentOrganization: Organization;

  @Output() modalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() errorModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onJoinedDiscussionsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  // public hasCompletedSessionTutorial: boolean;
  public calendarContent: CalendarBodyLang;

  public gameTimeSlots: GameTimeslot[];

  public filters: string[] = [];
  public selectedSeasonNames: string[] = [];
  public showAllTimeSlots: boolean;

  public dateFormat: string = "dddd D.M.";
  public timeFormat: string = "H:mm";

  // Material
  public preSessionMaterial: MaterialInterface[];

  @Input() selectedMaterial: MaterialInterface;
  @Output() materialSelected: EventEmitter<MaterialInterface> = new EventEmitter();

  // Discussion join
  public joinedTimeSlot: GameTimeslot;

  public userCompletedSeasons: string[];

  public forceStartPrivileges: boolean;
  public managerPrivileges: boolean;
  public waitingConfirmationTimeslot: GameTimeslot;
  public showConfirmation: boolean;
  public confirmMessage: string;
  public errorMessage: string;

  public seasonsWithTimeSlots: SeasonWithTimeSlots[];
  public totalTimeSlots: number;

  public joinedSlotTimes: string[] = [];

  constructor(
    private gameTimeSlotService: GameTimeslotService,
    private gameSeasonService: GameSeasonService,
    private userService: UserService,
    private materialService: MaterialService,
    private tutorialService: GameTutorialService,
    private emailService: EmailEditorService,
    private router: Router,
    private socketService: SocketService,
    private gameSessionService: GameSessionService
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.drawerScroll = null;
  }

  ngAfterViewInit() {
    this.updateScrollBar();
  }

  ngOnInit() {
    this.gameSeasonService.getSeasonsWithTimeSlots()
      .subscribe(seasonsWithTimeSlots => {
        this.sortSeasons(seasonsWithTimeSlots);
        this.checkJoinedSlotTimes(seasonsWithTimeSlots);

        this.totalTimeSlots = seasonsWithTimeSlots.reduce((total, current) => {
          return total + current.availableTimeSlots.length + current.joinedTimeSlots.length;
        }, 0);
      });

    this.materialService.orgMaterial$
      .subscribe(
        material => {
          this.preSessionMaterial = material;
        }
      );

    this.emailService.calendarBodies$
      .subscribe(
        calendarBodies => {
          if (!calendarBodies) {
            return;
          }

          const emailTimeSlotBody = calendarBodies.find(calendarBody => {
            return calendarBody.templateName === "emailTimeslot";
          });

          this.calendarContent = emailTimeSlotBody[this.currentUser.language];
        }
      );

    this.socketService.gameSeasonUpdate$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(
        (update: DataUpdate) => {
          if (update.subType === "new") {
            // Check if should be added to existing season with same name
            const matchingSeasonIndex: number = this.seasonsWithTimeSlots ? this.seasonsWithTimeSlots.findIndex(seasonWithTimeSlot => {
              return update.gameSeason && seasonWithTimeSlot.seasonName === update.gameSeason.name;
            }) : -1;

            if (matchingSeasonIndex !== -1) {
              if (!this.seasonsWithTimeSlots[matchingSeasonIndex].seasonIds) {
                this.seasonsWithTimeSlots[matchingSeasonIndex].seasonIds = [];
              }

              this.seasonsWithTimeSlots[matchingSeasonIndex].seasonIds.push(update.gameSeason._id);
              this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots =
                GeneralUtils.concatAndSort(this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots, update.createdTimeSlots);

              return;
            }

            const seasonMaterial: MaterialInterface[] = this.preSessionMaterial ? this.preSessionMaterial.filter(item => {
              return item.theme === update.gameSeason.themes[0];
            }) : [];

            if (!this.seasonsWithTimeSlots) {
              this.seasonsWithTimeSlots = [];
            }

            this.seasonsWithTimeSlots.push({
              seasonName: update.gameSeason.name,
              seasonIds: [update.gameSeason._id],
              seasonImage: update.gameSeason.previewImagePath,
              seasonDescription: update.gameSeason.description,
              seasonGroupSize: update.gameSeason.groupSize,
              maxDuration: update.maxDuration,

              seasonMaterial: seasonMaterial,
              availableTimeSlots: update.createdTimeSlots,
              joinedTimeSlots: [],
              onGoingDiscussions: update.onGoingDiscussions,
              lobbyDiscussions: update.lobbyDiscussions
            });

            this.sortSeasons(this.seasonsWithTimeSlots);

            this.totalTimeSlots += update.createdTimeSlots.length;
          }
        }
      );

    this.socketService.timeSlotUpdate$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(
        (update: DataUpdate) => {
          const matchingSeasonIndex: number = this.seasonsWithTimeSlots ? this.seasonsWithTimeSlots.findIndex(seasonWithTimeSlot => {
            return update.gameSeason && seasonWithTimeSlot.seasonIds.indexOf(update.gameSeason._id) !== -1;
          }) : -1;

          if (matchingSeasonIndex === -1) {
            return;
          }

          if (update.subType === "new") {
            if (!this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots) {
              this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots = [];
            }

            this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots =
              GeneralUtils.concatAndSort(this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots, [update.timeSlot]);
            return;
          }

          const matchingTimeSlotIndex: number = this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots.findIndex(existingTimeSlot => {
            return existingTimeSlot && existingTimeSlot._id.toString() === update.timeSlot._id.toString();
          });

          const matchingJoinedTimeSlotIndex: number = this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots.findIndex(existingTimeSlot => {
            return existingTimeSlot && existingTimeSlot._id.toString() === update.timeSlot._id.toString();
          });

          if (matchingTimeSlotIndex === -1 && matchingJoinedTimeSlotIndex === -1) {
            return;
          }

          if (update.subType === "players") {
            const userHasJoined: boolean = this.userHasJoined(update.timeSlot);

            if (matchingTimeSlotIndex !== -1) {
              // Update player list
              this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots[matchingTimeSlotIndex].players = update.timeSlot.players;

              // Move timeSlot to joined list
              if (userHasJoined) {
                this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots = GeneralUtils.concatAndSort(
                  this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots,
                  [this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots[matchingTimeSlotIndex]]
                );

                this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots.splice(matchingTimeSlotIndex, 1);
                this.checkJoinedSlotTimes(this.seasonsWithTimeSlots);
              }
            } else if (matchingJoinedTimeSlotIndex !== -1) {
              // Update player list
              this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots[matchingJoinedTimeSlotIndex].players = update.timeSlot.players;

              // Move timeSlot to available list
              if (!userHasJoined) {
                this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots = GeneralUtils.concatAndSort(
                  this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots,
                  [this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots[matchingJoinedTimeSlotIndex]]
                );

                this.seasonsWithTimeSlots[matchingSeasonIndex].joinedTimeSlots.splice(matchingJoinedTimeSlotIndex, 1);
                this.checkJoinedSlotTimes(this.seasonsWithTimeSlots);
              }
            }

            return;
          }

          if (update.subType === "started") {
            // Remove timeSlot from lists
            if (matchingTimeSlotIndex !== -1) {
              this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots.splice(matchingTimeSlotIndex, 1);
            }

            if (matchingJoinedTimeSlotIndex !== -1) {
              this.seasonsWithTimeSlots[matchingSeasonIndex].availableTimeSlots.splice(matchingJoinedTimeSlotIndex, 1);

              // Player has signed up, join discussion
              this.gameSessionService.getCurrentSession(true);
            }

            this.totalTimeSlots -= 1;
            this.checkJoinedSlotTimes(this.seasonsWithTimeSlots);
          }
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userSessions && this.userSessions) {
      this.userCompletedSeasons = this.userSessions
        .map(session => {
          if (!session.session || !session.session.gameSeasonId) {
            return null;
          }

          return session.session.gameSeasonId.name;
        })
        .filter((seasonName, index, seasonNameArray) => {
          if (!seasonName) {
            return false;
          }

          return seasonNameArray.indexOf(seasonName) === index;
        });

      // this.markUserCompletedSeasons(this.userCompletedSeasons, this.filteredGameSeasons);
    }

    if (changes.currentUser && this.currentUser) {
      if (!this.currentOrganization || (this.currentOrganization && this.currentOrganization.organizationType !== 2)) {
        this.forceStartPrivileges = this.userService.checkGameManager();
        this.managerPrivileges = this.userService.checkCurrentUserEditPrivileges();
      }

      this.emailService.getCalendarData(this.currentUser.orgId);
    }

    if (changes.currentOrganization && this.currentOrganization) {
      if (this.currentOrganization.showTimeZone) {
        this.timeFormat = "H:mm [(UTC ]Z[)]";
      }

      if (this.currentOrganization.organizationType === 2) {
        if (this.seasonsWithTimeSlots) {
          this.selectedSeasonNames = this.seasonsWithTimeSlots.map(item => {
            return item.seasonName;
          });
        }

        this.forceStartPrivileges = true;
      }
    }

    if (changes.currentIntroTutorial && this.currentIntroTutorial === "join-session") {
      this.toggleShowAllTimeSlots(true);
    }

    if (changes.seasonNameOrder && this.seasonNameOrder) {
      this.sortSeasons(this.seasonsWithTimeSlots);
    }
  }

  /*public updateList() {
    this.gameSeasonService.fetchOrganizationSeasons(this.currentUser.orgId);
    this.gameTimeSlotService.updateCurrentUserList();
    this.emailService.getCalendarData(this.currentUser.orgId);
    // this.userSessionNotesService.updateList(this.currentUser._id);

    this.gameTimeSlotService.fetchUserTimeslots(this.currentUser._id)
      .subscribe(userTimeSlots => {
        if (!userTimeSlots) {
          this.joinedTimeSlots = [];
          return;
        }

        this.joinedTimeSlots = userTimeSlots;
        this.setActiveDiscussionCheckInterval();
        this.filterSeasonsWithoutTimeSlots(this.gameSeasons, this.gameTimeSlots, this.joinedTimeSlots);
      });
  }*/

  public scrollTop() {
    this.drawerScroll.scrollTo({ top: 0 });
  }

  public showDiscussionsForSeason(seasonName: string) {
    const seasonNameIndex: number = this.selectedSeasonNames.indexOf(seasonName);
    if (seasonNameIndex === -1) {
      this.selectedSeasonNames.push(seasonName);
    } else {
      this.selectedSeasonNames.splice(seasonNameIndex, 1);
    }

    this.updateScrollBar();
  }

  public toggleShowAllTimeSlots(setAsVisible?: boolean) {
    if (this.selectedSeasonNames.length && !setAsVisible) {
      this.selectedSeasonNames = [];
    } else if (this.seasonsWithTimeSlots) {
      this.selectedSeasonNames = this.seasonsWithTimeSlots.map(item => {
        return item.seasonName;
      });
    }

    this.updateScrollBar();
  }

  /*public joinTimeSlot(timeSlot: GameTimeslot) {
    this.gameTimeSlotService.join(timeSlot)
      .subscribe(updatedTimeSlot => {
          if (!updatedTimeSlot) {
            this.errorMessage = "game-timeslot.slot-undefined";
            return;
          }

          this.joinedTimeSlot = updatedTimeSlot;
          this.modalOpened.emit(true);
          this.tutorialService.setIntroTutorialAsCompleted("sign-up", this.currentUser);
          this.onJoinedDiscussionsChanged.emit();

          // Update list
          const matchingSeasonIndex: number = this.seasonsWithTimeSlots ? this.seasonsWithTimeSlots.findIndex(seasonWithTimeSlot => {
            return updatedTimeSlot.gameSeasonId && seasonWithTimeSlot.seasonIds.indexOf(updatedTimeSlot.gameSeasonId._id) !== -1;
          }) : -1;

          if (matchingSeasonIndex === -1) {
            return;
          }

          const matchingTimeSlotIndex: number = this.seasonsWithTimeSlots[matchingSeasonIndex].timeSlots.findIndex(existingTimeSlot => {
            return existingTimeSlot._id.toString() === updatedTimeSlot._id.toString();
          });

          if (matchingTimeSlotIndex === -1) {
            return;
          }

          this.seasonsWithTimeSlots[matchingSeasonIndex].timeSlots[matchingTimeSlotIndex].players = updatedTimeSlot.players;
          this.checkJoinedSlotTimes(this.seasonsWithTimeSlots);
        },
        err => {
          console.error("Failed joining timeSlot", err);

          if (err.status === 403) {
            this.errorMessage = "game-timeslot.slot-full";
            return;
          }

          if (err.status === 404) {
            this.errorMessage = "game-timeslot.slot-removed";
            return;
          }

          if (err.status === 429) {
            this.errorMessage = "game-timeslot.slot-already-joined";
            return;
          }

          this.errorMessage = err.message;
        });
  }

  public leaveTimeSlot(timeSlot: GameTimeslot) {
    this.gameTimeSlotService.leave(timeSlot._id)
      .subscribe((updatedTimeSlot) => {
        // Update list
        const matchingSeasonIndex: number = this.seasonsWithTimeSlots ? this.seasonsWithTimeSlots.findIndex(seasonWithTimeSlot => {
          return updatedTimeSlot.gameSeasonId && seasonWithTimeSlot.seasonIds.indexOf(updatedTimeSlot.gameSeasonId._id) !== -1;
        }) : -1;

        if (matchingSeasonIndex === -1) {
          return;
        }

        const matchingTimeSlotIndex: number = this.seasonsWithTimeSlots[matchingSeasonIndex].timeSlots.findIndex(existingTimeSlot => {
          return existingTimeSlot._id.toString() === updatedTimeSlot._id.toString();
        });

        if (matchingTimeSlotIndex === -1) {
          return;
        }

        this.seasonsWithTimeSlots[matchingSeasonIndex].timeSlots[matchingTimeSlotIndex].players = updatedTimeSlot.players;
        this.checkJoinedSlotTimes(this.seasonsWithTimeSlots);

        this.onJoinedDiscussionsChanged.emit();
      });
  }*/

  public selectMaterial(material: MaterialInterface) {
    if (material.type === "link") {
      window.open(material.path, "_blank");
      return;
    }

    if (material.type === "file") {
      const fileType = material.path.substring(material.path.lastIndexOf(".") + 1).toLowerCase();

      if (fileType === "pdf") {
        window.open(MediaUtils.getOrgFile(material.path, material.orgId), "_blank");
        return;
      }
    }

    this.materialSelected.emit(material);
  }

  public hideInfoModal() {
    this.joinedTimeSlot = null;
  }

  public forceSessionStart(timeslot: GameTimeslot, seasonName: string) {
    if (!this.forceStartPrivileges) {
      return;
    }

    this.waitingConfirmationTimeslot = timeslot;
    this.confirmMessage = "Are you sure you want to force start discussion belonging to season " + seasonName +
      " starting at " + moment(timeslot.beginTime).format("DD.MM. H:mm") + "?";

    this.showConfirmation = true;
  }

  public checkConfirmation(event: boolean): void {
    this.showConfirmation = false;

    if (!event) {
      this.waitingConfirmationTimeslot = null;
      return;
    }

    this.gameTimeSlotService.startSession(this.waitingConfirmationTimeslot)
      .subscribe(() => {
        this.waitingConfirmationTimeslot = null;
      });
  }

  /*public toTutorial(): void {
    this.router.navigate(['/game-tutorial']);
  }

  public checkHasCompletedTutorial() {
    if (!this.currentUser || !this.currentUser.completedIntroTutorials) {
      return false;
    }

    if (this.currentUser.hideIntroTutorial) {
      return true;
    }

    return !!this.currentUser.completedIntroTutorials.find(tutorial => {
      return tutorial.identifier === "session-tutorial";
    });
  }*/

  public getMaterialIconClass(material: MaterialInterface): string {
    if (!material) {
      return "glyphicon glyphicon-file";
    }

    if (material.type === "videoId") {
      return "glyphicon glyphicon-film";
    }

    if (material.type === "link") {
      return "glyphicon glyphicon-link";
    }

    if (material.type === "file") {
      // Check file type
      const mediaType: string = MediaUtils.getMediaType(material.path);

      if (mediaType === "file") {
        return "glyphicon glyphicon-file";
      }

      if (mediaType === "image") {
        return "glyphicon glyphicon-picture";
      }

      if (mediaType === "video") {
        return "glyphicon glyphicon-film";
      }
    }

    console.error("Unknown material type", material.type);
    return "glyphicon glyphicon-file";
  }

  public getActualFilePath(fileName: string): string {
    if (!fileName) {
      return "/assets/image/logo.png";
    }

    return MediaUtils.getOrgFile(fileName, this.currentUser.orgId);
  }

  public updateScrollBar(): void {
    setTimeout(() => {
      if (this.drawerScroll) {
        this.drawerScroll.update();
      }
    }, 200);
  }

  public checkJoined(timeSlots: GameTimeslot[], joined: boolean = true): boolean {
    if (!timeSlots || !timeSlots.length || !this.currentUser) {
      return false;
    }

    for (const timeSlot of timeSlots) {
      const hasJoined: boolean = this.userHasJoined(timeSlot);

      if (!joined && !hasJoined) {
        return true;
      }

      if (joined && hasJoined) {
        return true;
      }
    }

    return false;
  }

  public userHasJoined(timeSlot: GameTimeslot): boolean {
    if (!timeSlot || !timeSlot.players.length || !this.currentUser) {
      return false;
    }

    return !!timeSlot.players.find(player => {
      if (!player || !player["_id"]) {
        return false;
      }

      return player["_id"].toString() === this.currentUser._id.toString();
    });
  }

  public userIsInDiscussion(gameSession: GameSession): boolean {
    if (!gameSession || !this.currentUser) {
      return false;
    }

    const isPlayer: boolean = !!gameSession.players.find((player) => {
      return player["_id"].toString() === this.currentUser._id.toString();
    });

    if (isPlayer) {
      return true;
    }

    return !!gameSession.spectators.find((player) => {
      return player["_id"].toString() === this.currentUser._id.toString();
    });
  }

  public sortSeasons(seasonsWithTimeSlots: SeasonWithTimeSlots[]): void {
    if (!seasonsWithTimeSlots || !seasonsWithTimeSlots.length) {
      return;
    }

    // Sort by organization name order
    if (this.seasonNameOrder && this.seasonNameOrder.length) {
      seasonsWithTimeSlots = seasonsWithTimeSlots.sort((a, b) => {
        const aIndex: number = this.seasonNameOrder.indexOf(a.seasonName);
        const bIndex: number = this.seasonNameOrder.indexOf(b.seasonName);

        if (aIndex === -1 && bIndex === -1) {
          return 0;
        }

        if (aIndex === -1 || bIndex === -1) {
          return aIndex === -1 ? 1 : -1;
        }

        return aIndex > bIndex ? 1 : -1;
      });
    }

    // Sort by completion
    if (this.userCompletedSeasons && this.userCompletedSeasons.length) {
      seasonsWithTimeSlots = seasonsWithTimeSlots.sort((a, b) => {
        const aIndex: number = this.userCompletedSeasons.indexOf(a.seasonName);
        const bIndex: number = this.userCompletedSeasons.indexOf(b.seasonName);

        // Keep same order if both not completed or both completed
        if ((aIndex === -1 && bIndex === -1) || (aIndex > -1 && bIndex > -1)) {
          return 0;
        }

        return aIndex !== -1 ? 1 : -1;
      });
    }

    this.seasonsWithTimeSlots = seasonsWithTimeSlots;

    if (this.currentOrganization && this.currentOrganization.organizationType === 2) {
      this.selectedSeasonNames = this.seasonsWithTimeSlots.map(item => {
        return item.seasonName;
      });
    }

    if (this.currentIntroTutorial && this.currentIntroTutorial === "join-session") {
      this.toggleShowAllTimeSlots(true);
    }
  }

  public checkJoinedSlotTimes(seasonsWithTimeSlots: SeasonWithTimeSlots[]): void {
    let joinedTimes: string[] = [];

    for (const season of seasonsWithTimeSlots) {
      joinedTimes = joinedTimes.concat(season.joinedTimeSlots.map((slot) => {
        return moment(slot.beginTime).format("D.M.YYYY H:mm");
      }));
    }

    this.joinedSlotTimes = joinedTimes;
  }

  public checkAlreadyJoinedSameTime(timeSlot: GameTimeslot): boolean {
    if (!timeSlot || !timeSlot.beginTime || !this.joinedSlotTimes || !this.joinedSlotTimes.length) {
      return false;
    }

    return !!this.joinedSlotTimes.find((formattedTime: string) => {
      return moment(timeSlot.beginTime).format("D.M.YYYY H:mm") === formattedTime;
    });
  }

  public onCardEvent(event: DiscussionCardEvent, discussion: GameSession | GameTimeslot, discussionIndex: number): void {
    if (event.type === "timeSlotLeave" || event.type === "timeSlotJoin") {
      // Tell parent to update joined list
      this.onJoinedDiscussionsChanged.emit();
    }
  }
}
