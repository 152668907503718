import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {
  public youTubeApiReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public youTubeAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor() {

  }

  /**
   * Load YouTube API and emit ready once loaded
   */
  loadYouTubeApi() {
    // Only load API once
    if (!window.document.getElementById('youtube-api')) {
      const apiScript = window.document.createElement("script");
      apiScript.type = "text/javascript";
      apiScript.src = "https://www.youtube.com/iframe_api";
      apiScript.id = 'youtube-api';
      window.document.body.appendChild(apiScript);
    }

    // Emit API when ready
    window['onYouTubeIframeAPIReady'] = () => {
      console.log("YouTube Iframe API ready");
      this.youTubeApiReady$.next(true);
    };
  }

  testBlocked(): void {
    const favicon: HTMLImageElement = new Image();
    favicon.src = "https://youtube.com/favicon.ico";

    favicon.onload = () => {
      // The user can access youtube
      this.youTubeAllowed$.next(true);
    };
    favicon.onerror = () => {
      // The user can't access youtube
      this.youTubeAllowed$.next(false);
    };
  }
}
