import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Player, Reaction} from "../../data-model/player.type";
import {ScaleVoteSelection} from "../../data-model/game-state.interface";
import {User} from "../../data-model/user.type";
import {ScaleItem} from "../../data-model/scale-vote.interface";

@Component({
  selector: 'player-reasoning',
  template: `
    <div class="reasoning-container">
      <user-profile-card
        [cardSize]="'small'"
        [cardColor]="'extra-dark'"
        [playerDoc]="playerDoc"
        [userDoc]="userDoc"
        [showStatus]="false"
        [disableHover]="true"
        [hideName]="true"
      ></user-profile-card>

      <div class="reasoning" [ngClass]="{'not-written': !reasoning, 'group-phase': phase === 'group', 'scaling-suggestions': scaleSuggestions?.length}">
        <ng-container *ngIf="reasoning || showNoReasoning">
          {{reasoning ? reasoning : ('situation.no-written-answer' | translate)}}
        </ng-container>
        
        <div class="scaling-table unselectable" *ngIf="scalingSelections?.length">
          <div class="boundaries horizontal-only" id="personal-boundary">
            <div class="crosshair"></div>
            <div class="crosshair-horizontal"></div>

            <ng-container *ngFor="let userSelection of scalingSelections">
              <div
                *ngIf="userSelection?.position"
                cdkDrag
                cdkDragDisabled
                [cdkDragFreeDragPosition]="userSelection.position"
                class="drag-bubble draggable"
              >
                <svg-ball-component
                  [content]="''"
                  [active]="true"
                  [customFill]="userSelection.item.color"
                  [customSize]="'100%'"
                ></svg-ball-component>

                <div class="tooltip">
                  {{userSelection.item.title}}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="scaleSuggestions?.length">
          <div class="suggested-item" *ngFor="let suggestion of scaleSuggestions">
            {{suggestion.title}}
          </div>
        </ng-container>

        <player-reaction
          [voteUserId]="playerDoc ? playerDoc?.userId : userDoc?._id"
          [currentPlayerUserId]="currentPlayerId"
          [reactions]="reactions"
          [fakeReactions]="fakeReactions"
          (newReaction)="newReaction.emit(true)"
        ></player-reaction>
      </div>
    </div>
  `,
  styles: []
})
export class PlayerReasoningComponent {
  @Input() userDoc: User;
  @Input() playerDoc: Player;
  @Input() reasoning: string;
  @Input() showNoReasoning: boolean;
  @Input() phase: string;
  @Input() fakeReactions: boolean; // For tutorial

  @Input() currentPlayerId: string;
  @Input() reactions: Reaction[];
  @Input() scalingSelections: ScaleVoteSelection[];
  @Input() scaleSuggestions: ScaleItem[];

  @Output() newReaction: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
}
