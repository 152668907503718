import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService } from 'app/core/service/organization.service';
import { MediaUtils } from 'lib/media-utils';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bi-report',
  templateUrl: './templates/bi-report.view.html'
})
export class BiReportView implements OnInit {

  public reportIframe: string;
  public pageStyle: Object = {};

  constructor(private orgService: OrganizationService, private router: Router) { }

  ngOnInit(): void {
    const stopSubscribe = new BehaviorSubject<boolean>(false);
    this.orgService.currentOrgContent$
      .pipe(takeUntil(stopSubscribe.pipe(filter(val => !!val))))
      .subscribe(content => {
        if (!!content.pbiIframe) {
          this.reportIframe = content.pbiIframe;
          stopSubscribe.next(true);
          stopSubscribe.complete();
        }
        console.log(content);
        if (!!content.backGroundImage) {
          this.pageStyle = {'background-image': "url('" + MediaUtils.getOrgFile(content.backGroundImage, content.orgId) + "')"}
        }
      });
  }

  toLandingPage() {
    this.router.navigate(['landing-page']);
  }

}
