/**
 * Created by Topi on 01.11.2018
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {User} from "../../data-model/user.type";
import {TestOrgService} from "../../core/service/test-org.service";

@Component({
  selector: 'landing-checkbox',
  template: `
    <div class="checkbox-container" [ngClass]="{'visible': !formSubmitted}">
      <div class="flex-container" [ngStyle]="{'cursor': 'pointer'}" (click)="checkBoxChecked = !checkBoxChecked">
        <div>{{'test-organization.contact-me-message' | translate}}</div>
        <span class="custom-checkbox pull-right">
          <span *ngIf="checkBoxChecked" class="glyphicon glyphicon-ok"></span>
        </span>
      </div>

      <div class="checkbox-inputs" [ngClass]="{'visible': checkBoxChecked}">
        <div class="input-container">
          <label>{{'participant-info-card.name' | translate}}</label>
          <input [placeholder]="'Name'" [(ngModel)]="contactInputs.name" [disabled]="sendingForm">
        </div>

        <div class="input-container">
          <label>{{'participant-info-card.phone-number' | translate}}</label>
          <input [placeholder]="'Phone No.'" [(ngModel)]="contactInputs.phone" [disabled]="sendingForm">
        </div>

        <div class="input-container">
          <label>{{'participant-info-card.email' | translate}}</label>
          <input [placeholder]="'Email'" [(ngModel)]="contactInputs.email" [disabled]="sendingForm">
        </div>

        <raised-css-button
          [buttonText]="'test-organization.confirm-button'"
          (onClick)="confirmFormSubmission()"
          [buttonClasses]="'full-width'"
          [disabled]="sendingForm"
          [fontSize]="'2vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'0'"
        ></raised-css-button>
      </div>
    </div>
  `
})

export class LandingCheckboxComponent implements OnChanges {
  @Input() currentUser: User;

  @Output() onSent: EventEmitter<boolean> = new EventEmitter();

  public checkBoxChecked: boolean;
  public sendingForm: boolean;
  public formSubmitted: boolean;

  public contactInputs: { name: string, phone: string, email: string } = {
    name: "",
    phone: "",
    email: ""
  };

  constructor(private testOrgService: TestOrgService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser) {
      this.contactInputs.name = this.currentUser.name;
      this.contactInputs.email = this.currentUser.email;
    }
  }

  public confirmFormSubmission() {
    this.sendingForm = true;

    this.testOrgService.sendContactRequestEmail(this.contactInputs)
      .subscribe(() => {
        this.sendingForm = false;
        this.formSubmitted = true;
      });
  }
}
