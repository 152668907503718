<div id='bi-report-container' [ngStyle]="pageStyle">
  <div id='bi-report-frame'>
    <iframe
      width="100%"
      height="100%"
      [src]="reportIframe | iFrameSanitation"
      frameborder="0"></iframe>
    <div id="hideShare"></div>
  </div>
  <button (click)="toLandingPage()" aria-label="Close" class="close" type="button" id="closeBiReportButton">
    <span>×</span>
  </button>
</div>