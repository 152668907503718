export class SurveyAnswer {
  constructor(public question: string,
              public answer: string,
              public thumbRating: number,
              public engagementTag: string) {
  }
}

export class UserSurveyAnswer {
  constructor(public question: string,
              public thumbRatings: number[],
              public averageRating: number,
              public engagementTag: string) {
  }

  static QuestionAverageThumbAnswer(question: string, thumbRating: number, tag: string) {
    return new this(question, [thumbRating], 0, tag);
  }
}

export class GeneralFeedbackForProgressReport {
  constructor(public answer: string,
              public rating: number) {

  }
}

export interface DepartmentDataForProgressReport {
  departmentName: string;
  participationPercentage: boolean;
  discussionsPerPerson: boolean;
  amountOfDepartmentDiscussions: boolean;
}

