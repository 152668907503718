import { Moment } from "moment";

/**
 * Created by Topi on 08.02.2019
 */

const moment = require("moment");
import * as _ from 'lodash';

export class GeneralUtils {
  static clickedOutside(elementId, allowedElementIds): boolean {
    if (!allowedElementIds || !allowedElementIds.length) {
      // Everything is allowed (?)
      return false;
    }

    if (!elementId) {
      // Nothing to check
      return true;
    }

    for (const allowedId of allowedElementIds) {
      if (elementId.indexOf(allowedId) !== -1) {
        // Match found, clicked inside
        return false;
      }
    }

    return true;
  }

  static getAverage(arr: number[]): number {
    if (!arr || !arr.length) {
      return 0;
    }

    return arr.reduce((p: number, c: number) => p + c, 0) / arr.length;
  }

  static roundNumber(value: number, precision: number = 0) {
    if (!value) {
      return 0;
    }

    const multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }

  static getHslColor(index: number = 0) {
    const modifier: number = (index / 10) * 2;
    const hue = (modifier + 0.618033988749895) * 360;
    return 'hsla(' + hue + ', 75%, 50%, 1)';
  }

  static concatAndSort(array1: any[], array2: any[]): any[] {
    return array1
      .concat(array2)
      .sort((a, b) => {
        return moment(a.beginTime).isAfter(moment(b.beginTime)) ? 1 : -1;
      });
  }

  static shuffleArray(array: any[]): any[] {
    return _.shuffle(array);
  }

  static formatDate(date: Date | Moment, format?: string): string {
    if (format) {
      return moment(date).format(format);
    }

    const isCurrentYear: boolean = moment(date).get('year') === moment().get('year');
    return moment(date).format(isCurrentYear ? "DD.MM. HH:mm" : "DD.MM.YYYY HH:mm");
  }

  static getTranslationOrEmpty(obj: any, objPath: string[]): string {
    let root  = _.clone(obj);

    if (!root) {
      return "";
    }

    for (let i = 0; i < objPath.length; i++) {
      const part: any = objPath[i];

      if (root[part] && root.hasOwnProperty(part)) {
        root = _.clone(root[part]);
      }
      else {
        return "";
      }
    }

    if (typeof root === "string") {
      // Found translation
      return root;
    }

    // Something went wrong
    console.error("Didn't find translation?", obj, objPath, root);
    return "";
  }

  static setObjectValue(obj: any, objPath: string[], value): any {
    if (!objPath?.length) {
      // Reached end
      return obj;
    }

    // Set first path as current
    const currentPath: string = objPath[0];
    const currentValue: any = obj[currentPath];

    if (objPath.length === 1) {
      // Last obj path, set value here
      obj[currentPath] = value;
      return currentValue;
    }

    if (!currentValue) {
      // Set obj currentPath as an empty object if it doesn't exist
      obj[currentPath] = {};
    }

    // Go deeper
    return GeneralUtils.setObjectValue(obj[currentPath], objPath.slice(1), value);
  }

  static isValidEmail(email: string): boolean {
    return email && email.indexOf("@") !== -1 && email.indexOf(".") !== -1;
  }
}
