/**
 * Created by TopSal on 21.8.2017.
 */

import {Component} from '@angular/core';

@Component({
  selector: 'privacy-policy-view',
  template: `
    <div class="policy-pdf">
      <!--<pdf-viewer
        [src]="tosUrl"
        [original-size]="false"
        [render-text]="true"
        [show-all]="true"
        style="display: block;"
      ></pdf-viewer>-->
    </div>
  `
})

export class PrivacyPolicyView {
  public tosUrl: string = "/static/legal/privacy_policy.pdf";

  constructor() {

  }
}
