/**
 * Created by Topi on 3.11.2017.
 */

import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges, OnChanges } from "@angular/core";
import { GeneralUtils } from "../../../lib/general-utils";

@Component({
  selector: "confirm-modal",
  template: `
    <div
      class="modal-container confirmation animated slideInDownSmall"
      [ngStyle]="customWidth && {'width': customWidth + customWidthFormat, 'margin-left': -(customWidth / 2) + customWidthFormat}"
      (elementClicked)="clickedOutside($event)"
    >
      <button (click)="emitConfirmation(false)" class="pop-up-close-button">
        <span class="glyphicon glyphicon-remove"></span>
      </button>

      <h2 class="pop-up-header" [innerHtml]="confirmMessage | translate"></h2>

      <div class="gradient-line"></div>

      <div class="flex-row-centered">
        <raised-css-button
          (onClick)="emitConfirmation(true)"
          [buttonText]="confirmButtonText"
          [buttonClasses]="'loge-colors'"
          [fontSize]="'3vh'"
        ></raised-css-button>

        <raised-css-button
          *ngIf="!hideSecondButton"
          (onClick)="emitConfirmation(false)"
          [buttonText]="cancelButtonText"
          [buttonClasses]="'loge-colors'"
          [ngStyle]="{'margin-left': '0.5vw'}"
          [fontSize]="'3vh'"
        ></raised-css-button>
      </div>
    </div>
  `
})
export class ConfirmModalComponent implements OnInit, OnChanges {

  @Input() confirmMessage: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;
  @Input() hideSecondButton: boolean;

  @Input() confirmMessageTranslation: string;
  @Input() confirmButtonTextTranslation: string;
  @Input() cancelButtonTextTranslation: string;

  @Input() customWidth: number; // Width of container
  @Input() customWidthFormat: string; // Width of container
  @Input() allowedElementIds: string[]; // For clickedOutside check

  @Output() sendConfirmation: EventEmitter<boolean> = new EventEmitter();

  constructor() {

  }

  ngOnInit() {
    if (!this.confirmMessage) {
      this.confirmMessage = "confirmation.default";
    }

    if (!this.confirmButtonText) {
      this.confirmButtonText = this.hideSecondButton ? "confirmation.default-confirm" : "confirmation.default-yes";
    }

    if (!this.cancelButtonText) {
      this.cancelButtonText = "confirmation.default-no";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["confirmMessageTranslation"] && this.confirmMessageTranslation) {
      this.confirmMessage = this.confirmMessageTranslation;
    }

    if (changes["confirmButtonTextTranslation"] && this.confirmButtonTextTranslation) {
      this.confirmMessage = this.confirmButtonTextTranslation;
    }

    if (changes["cancelButtonTextTranslation"] && this.cancelButtonTextTranslation) {
      this.confirmMessage = this.cancelButtonTextTranslation;
    }
  }

  public emitConfirmation(event: boolean): void {
    this.sendConfirmation.emit(event);
  }

  public clickedOutside(elementId: string): void {
    if (GeneralUtils.clickedOutside(elementId, this.allowedElementIds)) {
      this.emitConfirmation(false);
    }
  }
}
