import {NetworkUser} from "./user.type";

export class NetworkItemType implements d3.SimulationNodeDatum {
  index?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | null;
  fy?: number | null;

  user: NetworkUser;
  id: string;
  linkCount: number = 0;
  totalUsers: number = 0;
  initials: string = "";

  isCurrentUser: boolean;

  constructor(itemContent, totalContents, isCurrentUser, linkAmount, initials) {
    if (itemContent._id) {
      this.id = itemContent._id.toString();
      this.user = itemContent;
    }
    else {
      this.id = itemContent;
    }

    this.totalUsers = totalContents;
    this.isCurrentUser = isCurrentUser;
    this.linkCount = linkAmount;
    this.initials = initials;
  }

  get r() {
    if (this.isCurrentUser) {
      return this.getViewpointMin(5);
    }

    return this.getNormalSize(5);
  }

  public getViewpointMin(amount: number): number {
    const w: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    const min: number = w < h ? w : h;

    return (amount * min) / 100;
  }

  public getNormalSize(amount?: number): number {
    const vmin: number = this.getViewpointMin(amount ? amount : 5);
    return Math.sqrt(this.linkCount / this.totalUsers) * vmin;
  }
}
