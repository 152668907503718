import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { DeviceInfo } from "./audio-device.service";

export interface LogeNotification {
  id?: string; // Same as for DataUpdate
  title?: string;
  message?: string;
  message_html?: string;
  recipients?: string; // "admins", "managers", "everyone"
  icon?: string; // Any icon class (ie. "icon-audio-error")
  timer?: Date;

  personal?: boolean;
  visible?: boolean;
  autoClose?: number;
  preview?: string;

  confirm?: boolean;
  customParams?: {
    device?: DeviceInfo; // For device detector new device confirmation
    translateParams?: Record<string, any>;
    errorMsg?: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notification$: Subject<LogeNotification> = new Subject<LogeNotification>();

  private _currentNotification: LogeNotification;
  private _notificationHistory: LogeNotification[] = [];

  get notificationHistory(): LogeNotification[] {
    return this._notificationHistory;
  }

  public setNotification(notification: LogeNotification): void {
    this.notification$.next(notification);

    this._currentNotification = notification;
    this._notificationHistory.push(notification);
  }

  public clearNotification(notificationId?: string): void {
    // Unset current notification if matching given ID, or if no ID given
    if (!notificationId || this._currentNotification?.id === notificationId) {
      this.notification$.next(null);
      this._currentNotification = null;
    }
  }
}
