/**
 * Created by Topi on 31.05.2018
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ParsedPollChoice, PollChoiceSelection} from "../../data-model/game-state.interface";

@Component({
  selector: 'dashed-circle',
  template: `
    <div *ngIf="sortedChartValues" class="poll-circle-chart">
      <div *ngFor="let value of sortedChartValues; let choiceIndex = index" class="poll-circle-container">
        <div (mouseenter)="choiceHovered = choiceIndex"
             (mouseleave)="choiceHovered = -1"
             (click)="selectAsGroupChoice(value)"
             [ngStyle]="userIsChairman && {'cursor': 'pointer'}"
             class="choice-flex"
        >
          <div class="choice-title" [ngClass]="{'group-selected': checkSelected(value)}">
            {{(value.choiceTitle ? value.choiceTitle : 'situation.poll-free-choice') | translate}}
          </div>

          <div class="title-line">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.938 11.733">
              <path class="arrow-title-line"
                    [ngClass]="{'most-choices': mostChoices === value.selections, 'group': phase === 'group', 'group-selected': checkSelected(value),
                    'hovered': userIsChairman && phase !== 'review' && choiceHovered === choiceIndex}"
                    d="M51.535,11.733l-6.664-8.358H1.688C0.756,3.375,0,2.619,0,1.688S0.756,0,1.688,0h44.809l4.906,6.153L55.845,0h45.405
                    c0.932,0,1.688,0.756,1.688,1.688s-0.756,1.688-1.688,1.688H57.571L51.535,11.733z"/>
            </svg>
          </div>

          <div class="outer-circle">
            <svg viewBox="0 0 120 120" [ngStyle]="{'transform': 'rotate(-90deg) scale(1, -1)'}">
              <defs>
                <clipPath id="halfClip">
                  <rect width="120" height="60" x="0" y="60"/>
                </clipPath>
              </defs>

              <circle class="dashed-circle"
                      [ngStyle]="{'stroke-dasharray': getCircleDashArray(50)}"
                      [ngClass]="{'most-choices': mostChoices === value.selections,
                      'group': phase === 'group', 'group-selected': checkSelected(value), 'hovered': userIsChairman && phase !== 'review' && choiceHovered === choiceIndex}"
                      cx="60" cy="60" r="50" fill="none"/>

              <circle class="overlay-circle"
                      *ngIf="!value.selections || totalVotes > value.selections"
                      [attr.clip-path]="(value.selections && value.selections >= (totalVotes / 2)) ? 'url(#halfClip)' : ''"
                      [ngStyle]="{'stroke-dasharray': getOverlayDashArray(50, value.selections)}"
                      [ngClass]="{'group': phase !== 'review'}"
                      cx="60" cy="60" r="50" fill="none"/>
            </svg>

            <div class="inner-circle"
                 [ngClass]="{'most-choices': mostChoices === value.selections,
                 'group': phase === 'group', 'group-selected': checkSelected(value), 'hovered': userIsChairman && phase !== 'review' && choiceHovered === choiceIndex}">
              <div class="circle-text">{{round((value.selections / totalVotes) * 100, 0) + '%'}}</div>
            </div>
          </div>

          <div class="dashed-circle-title" *ngIf="showVotesAmount">
            <h4>
              {{value.selections + "/" + totalVotes + " votes"}}
            </h4>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DashedCircleComponent implements OnChanges {
  @Input() sortedChartValues: PollChoiceSelection[];
  @Input() totalVotes: number;
  @Input() phase: string;
  @Input() showVotesAmount: boolean;
  @Input() userIsChairman: boolean;
  @Input() groupVote: PollChoiceSelection[];
  @Input() maxSelections: number;

  public choiceHovered: number = -1;
  @Output() onChoiceHovered: EventEmitter<number> = new EventEmitter();
  @Output() onGroupChoiceSelected: EventEmitter<PollChoiceSelection> = new EventEmitter();

  public mostChoices: number;

  // 3.6 degrees per bar

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.sortedChartValues) && this.sortedChartValues && this.sortedChartValues.length) {
      this.mostChoices = this.sortedChartValues[0].selections ? this.sortedChartValues[0].selections : 1;
    }
  }

  public round(value, precision) {
    if (!value) {
      return 0;
    }

    return +value.toFixed(precision);
  }

  public getOverlayDashArray(radius: number, selections: number): number {
    if (!selections) {
      return 0;
    }

    const circumference: number = 2 * Math.PI * radius;
    const percentage: number = selections / this.totalVotes;

    return Math.ceil(circumference * (1 - percentage));
  }

  public getCircleDashArray(radius: number): number {
    // Divide circumference with 100 to get length of 1%
    // Halve the result so that the line and the stroke-dasharray are 0.5%
    return (2 * Math.PI * radius) / 100 / 2;
  }

  public checkSelected(parsedValue: ParsedPollChoice): boolean {
    if (!this.groupVote || !this.groupVote.length || !parsedValue) {
      return false;
    }

    return !!this.groupVote.find(value => {
      return value && parsedValue.settingsIndex === value.settingsIndex && ((parsedValue.isOpen && value.isOpen === parsedValue.isOpen) || value.choiceTitle === parsedValue.choiceTitle);
    });
  }

  public selectAsGroupChoice(choice: PollChoiceSelection): void {
    if (!this.userIsChairman) {
      return;
    }

    this.choiceHovered = -1;
    this.onGroupChoiceSelected.emit(choice);
  }
}
