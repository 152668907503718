import {
  AfterViewInit,
  Component, Input, OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import {SituationSourceData} from "../../data-model/situation-source-data.type";
import {DragScrollComponent} from "ngx-drag-scroll";

@Component({
  selector: 'discussion-progress',
  template: `
    <div class="line-container">
      <div class="discussion-progress-line">
        <ng-container *ngFor="let situationPhases of discussionSituationPhases; let situationIndex = index">
          <div class="progress-line-material custom-icon icon-file"
               *ngIf="!situationPhases.length"
               [ngClass]="{'filled glyphicon glyphicon-ok-after': situationIndex < currentSituationIndex,
             'last-circle': situationIndex === discussionSituationPhases.length - 1,
             'shortened-path': amountOfSituations > 6 && situationIndex !== currentSituationIndex}"></div>

          <div class="progress-line-part large-circle"
               *ngIf="situationPhases.length"
               [ngClass]="{'filled glyphicon glyphicon-ok-after': situationIndex < currentSituationIndex,
             'last-circle': amountOfSituations > 6 &&
             situationIndex === discussionSituationPhases.length - 1 && currentSituationIndex !== discussionSituationPhases.length - 1,
             'shortened-path': amountOfSituations > 6 && situationIndex !== currentSituationIndex}">
            <span class="circle-number" *ngIf="actualSituationNumbers && actualSituationNumbers[situationIndex]">
              {{actualSituationNumbers[situationIndex]}}
            </span>
          </div>

          <div *ngFor="let availablePhase of situationPhases; let phaseIndex = index"
               class="progress-line-part"
               [ngClass]="{first: availablePhase === 0,
             'phase-completed': situationIndex < currentSituationIndex ||
              (situationIndex === currentSituationIndex && availablePhase < currentSituationPhaseIndex),
             current: situationIndex === currentSituationIndex && availablePhase === currentSituationPhaseIndex,
             'non-active': situationIndex !== currentSituationIndex,
             'last-phase': phaseIndex === situationPhases.length - 1,
             'last-circle': situationIndex === discussionSituationPhases.length - 1 && phaseIndex === situationPhases.length - 1,
             'shortened-path': amountOfSituations > 6 && situationIndex !== currentSituationIndex}"
          ></div>
        </ng-container>
      </div>

      <div class="phase-arrow" [ngStyle]="{'margin-left': titleLeftMargin}" [ngClass]="{'animated-margin': showArrowAnimation, 'large-circle': situationType === 4}"></div>
    </div>

    <h2 class="static-progress-title">
      <ng-container *ngIf="situationType !== 4">
        {{'situation.situation-title' | translate}} {{actualSituationNumbers ? actualSituationNumbers[currentSituationIndex] : ""}}
        <p class="phase-title">{{situationPhaseName | translate}}</p>
      </ng-container>

      <ng-container *ngIf="situationType === 4">
        {{'situation.material-title' | translate}}
      </ng-container>
    </h2>
  `,
})
export class DiscussionProgressComponent implements OnChanges, AfterViewInit {
  @ViewChild('phaseDragScroll', { read: DragScrollComponent }) phaseDragScroll: DragScrollComponent;

  @Input() discussionSituations: SituationSourceData[];
  @Input() amountOfSituations: number;
  @Input() currentSituationIndex: number;
  @Input() currentSituationPhaseIndex: number;
  @Input() situationType: number;
  public discussionSituationPhases: number[][];
  public actualSituationNumbers: number[] = [];

  /*public barWidth: number = 3 + (2 * 0.6); // Default in vmin (0.6 margin)
  public circleWidth: number = 3;
  public defaultStartPoint: number = this.circleWidth + (this.barWidth / 2); // Center of first bar*/

  public spaceBetweenSituations: number = 5;
  public circleWidth: number = 2;
  public bigCircleWidth: number = 3;

  public situationPhaseName: string;
  public titleLeftMargin: string;
  public showArrowAnimation: boolean;
  public currentTransform: string = 'scale(1)';
  public scrollPadding: number = 10;
  public titleElemWidth: number = 20; // in vmin

  private scrollToIndex: number = 0;

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.followCurrentTitleScroll();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.discussionSituations && this.discussionSituations) {
      this.actualSituationNumbers = this.getActualQuestionNumbers();
      this.amountOfSituations = this.discussionSituations.length;

      this.discussionSituationPhases = this.discussionSituations.map(situation => {
        if (situation.type === 4) {
          return [];
        }

        if (situation.personal) {
          return [0, 2];
        }

        return [0, 1, 2];
      });
    }
    else if (changes.amountOfSituations && this.amountOfSituations) {
      this.discussionSituationPhases = new Array(this.amountOfSituations).fill([0, 1, 2]);
      this.actualSituationNumbers = this.getActualQuestionNumbers(this.amountOfSituations);
    }

    if (this.discussionSituationPhases && this.amountOfSituations) {
      this.newSetMargin();
    }
  }

  public newSetMargin(): void {
    const situationStartWidth: number = (this.bigCircleWidth + this.circleWidth) / 2;
    const actualIndex: number = this.discussionSituationPhases ? this.discussionSituationPhases[this.currentSituationIndex].indexOf(this.currentSituationPhaseIndex) : -1;
    const passedPhasesWidth: number = (actualIndex > -1 ? actualIndex : 0) * this.circleWidth;
    const passedSituationsWidth: number = this.currentSituationIndex * this.spaceBetweenSituations;
    let visibleCompletedPhases: number = 0;

    if (this.discussionSituationPhases && this.discussionSituationPhases.length <= 6) {
      for (let i = 0; i < this.currentSituationIndex; i++) {
        if (this.discussionSituationPhases[i].length === 0) {
          continue;
        }

        visibleCompletedPhases += situationStartWidth - 0.5 + ((this.discussionSituationPhases[i].length - 1) * this.circleWidth);
      }
    }

    /*const passedPhasesSize: number = this.discussionSituationPhases
      .filter((availablePhases, index) => {
        return index < this.currentSituationIndex;
      })
      .map((availablePhases) => {
        return availablePhases.length;
      })
      .reduce((totalAmount, amount) => {
        return totalAmount + (amount * (this.barWidth + this.circleWidth));
      }, 0);*/

    this.titleLeftMargin = (passedSituationsWidth + passedPhasesWidth + visibleCompletedPhases +
      (!this.discussionSituationPhases[this.currentSituationIndex].length ? 0 : situationStartWidth)) + "vmin";

    this.showArrowAnimation = true;

    setTimeout(() => {
      this.showArrowAnimation = false;
    }, 2 * 500);
  }

  /*public setMargin(): void {
    // Get amount of total circles passed
    const previousCirclesWidth: number = this.currentSituationIndex * this.circleWidth;

    // Get amount of total bars passed
    const previousBarsAmount: number = this.discussionSituationPhases
      .filter((availablePhases, index) => {
        return index < this.currentSituationIndex;
      })
      .map((availablePhases) => {
        return availablePhases.length;
      })
      .reduce((totalAmount, amount) => {
        return totalAmount + amount;
      }, 0);

    let phaseNumber: number = this.currentSituationPhaseIndex;

    // Fix title going too far with personal situations
    if (this.discussionSituations && this.discussionSituations[this.currentSituationIndex] &&
      this.discussionSituations[this.currentSituationIndex].personal && this.currentSituationPhaseIndex === 2) {
      phaseNumber = 1;
    }

    const previousBardsWidth: number = previousBarsAmount * this.barWidth;

    const previousElementsWidth: number =
      this.currentSituationIndex > 0 ? this.defaultStartPoint + previousCirclesWidth + previousBardsWidth : this.defaultStartPoint;

    const extraDragMargin: number = (this.amountOfSituations > 5) ? this.scrollPadding : 0;

    this.titleLeftMargin = (previousElementsWidth + extraDragMargin + (phaseNumber * this.barWidth) - (this.titleElemWidth / 2)) + "vmin";
  }*/

  public getPhaseName() {
    if (this.situationType === 4) {
      return 'situation.phase-title-material';
    }

    if (!this.currentSituationPhaseIndex) {
      return 'situation.phase-title-personal';
    }

    if (this.currentSituationPhaseIndex === 1) {
      return 'situation.phase-title-group';
    }

    return 'situation.phase-title-review';
  }

  public followCurrentTitleScroll(): void {
    if (!this.phaseDragScroll || !this.phaseDragScroll._children || this.phaseDragScroll._children.length === 0) {
      return;
    }

    this.scrollToIndex = 2 + (this.currentSituationIndex * 4) + this.currentSituationPhaseIndex;
    this.phaseDragScroll.moveTo(this.scrollToIndex);
  }

  /*public scrollToTarget() {
    const scrollToOptions: ScrollToConfigOptions = {
      target: 'scroll-parent',
      duration: 650,
      offset: 0
    };

    // Scroll to top of page
    this._scrollToService.scrollTo(scrollToOptions);

    // Horizontal scroll to current phase
    this.followCurrentTitleScroll();
  }*/

  public getActualQuestionNumbers(amountOfSituations?: number): number[] {
    if (amountOfSituations) {
      return new Array(amountOfSituations).fill(null).map((item, index) => index + 1);
    }

    if (!this.discussionSituations || !this.discussionSituations.length) {
      return [];
    }

    const questionIndexes: number[] = [];
    let currentSituationNumber: number = 0;
    for (const question of this.discussionSituations) {
      if (question.type === 4) {
        questionIndexes.push(null);
        continue;
      }

      currentSituationNumber++;
      questionIndexes.push(currentSituationNumber);
    }

    return questionIndexes;
  }
}
