export const voiceHostName: string = "rtc.loge.fi";

export const ROOM_OPTIONS = {
  requestTimeout: 10000,
  transportOptions: {
    tcp: false
  },
  turnServers: [
    {
      "urls": "turn:turn.loge.fi?transport=udp",
      "credential": "test",
      "username": "test"
    },
    {
      "urls": "turn:turn.loge.fi?transport=tcp",
      "credential": "test",
      "username": "test"
    },
    {
      "urls": "stun:turn.loge.fi",
      "credential": "test",
      "username": "test"
    }
  ]
};

export const getChatRoomUrl = (peerName, roomId) => {
  return `wss://${voiceHostName}/?peerName=${peerName}&roomId=${roomId}`;
}
