/**
 * Created by janne on 30.8.2016.
 */
import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {WelcomeView} from './view/welcome.view';
import {GreetingView} from "./view/greeting.view";
import {RecoverView} from "./view/recover.view";
import {ActivationView} from "./view/activation.view";
import {TermsOfServiceView} from "./view/tos.view";
import {DomainErrorView} from "./view/domain-error.view";
import {PrivacyPolicyView} from "./view/privacy-policy.view";
import {AuthGuard} from "../lib/auth-guard";
import {SessionGuard} from "../lib/session-guard";
import {LoginGuard} from "../lib/login-guard";
import {NoLoginGuard} from "../lib/no-login-guard";
import {ConfirmDeactivateGuard} from "../lib/can-deactivate-guard";
import {LandingPageView} from "./view/landing-page.view";
import {BiReportView} from './view/bi-report.view';
import { BiGuardGuard } from 'lib/bi-guard.guard';

const appRoutes: Routes = [
  { // Default route
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  }, {
    path: 'welcome',
    component: WelcomeView,
    canActivate: [NoLoginGuard]
  }, {
    path: 'greeting',
    component: GreetingView,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  }, {
    path: 'settings',
    component: GreetingView,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  }, {
    path: 'recover-password/:uid/:key',
    component: RecoverView
  }, {
    path: 'activation/:uid/:key',
    component: ActivationView
  },
  {
    path: 'domain-error',
    component: DomainErrorView
  }, {
    path: 'terms-of-use',
    component: TermsOfServiceView
  }, {
    path: 'privacy-policy',
    component: PrivacyPolicyView
  }, {
    path: 'game-session',
    loadChildren: () => import('app/session/session.module').then(m => m.SessionModule),
    canActivate: [SessionGuard],
    canLoad: [SessionGuard],
    canDeactivate: [ConfirmDeactivateGuard]
  }, {
    path: 'admin',
    redirectTo: 'admin/organization',
  },  {
    path: 'admin/organization',
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  }, {
    path: 'dashboard/:orgId',
    loadChildren: () => import('app/guest/guest.module').then(m => m.GuestModule)
  }, {
    path: 'landing-page',
    component: LandingPageView,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: "bi-report", redirectTo: "bi-report/", pathMatch: "full"
  },
  {
    path: "bi-report/",
    component: BiReportView,
    canActivate: [BiGuardGuard]
  },
  {
    path: "progress-report", redirectTo: "progress-report/", pathMatch: "full"
  },
  {
    path: "progress-report/:orgId",
    loadChildren: () => import('app/progress-report/progress-report-routed.module').then(m => m.ProgressReportRoutedModule)
  }, {
    path: '**',
    redirectTo: '/welcome'
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  {
    /* Use Angular 1 style URL paths that separate the Angular router part with
     * a hash-symbol. This simplifies server implementation. */
    useHash: true,
    relativeLinkResolution: 'legacy'
}
);
