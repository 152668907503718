/**
 * Created by Topi on 14.03.2019
 */

import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'question-feedback',
  template: `
    <div class="summary-header">{{'situation.review-question-explanation' | translate}}</div>

    <div class="feedback-container">
      <div *ngIf="questionExplanation" [innerHtml]="questionExplanation"></div>
      <div *ngIf="tutorialExplanation">
        {{tutorialExplanation | translate}}
      </div>
    </div>
  `
})

export class QuestionFeedbackComponent implements OnChanges {
  @Input() questionExplanation: SafeHtml;
  @Input() tutorialExplanation: string;

  ngOnChanges(changes: SimpleChanges) {
  }
}
