import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'orderBy',
  pure: false
})
@Injectable()
export class OrderByPipe implements PipeTransform {

  /**
   * Sort array
   * @param a
   * @param b
   * @returns {number}
   * @private
   */
  static _orderByComparator(a: any, b: any): number {
    if (isNaN(Number(a)) || isNaN(Number(b))) {
      // If inputs are NaN, value is string
      if (!a || !b) {
        if (!a && !b) {
          return 0;
        }

        return a ? -1 : 1;
      }

      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    }
    else {
      // Parse strings as number just in case
      if (Number(a) < Number(b)) {
        return -1;
      }
      if (Number(a) > Number(b)) {
        return 1;
      }
    }

    // Return 0 if equal
    return 0;
  }

  /**
   * Input is the array being sorted
   * Config is a key of the array:
   * '+' or '' is for ascending order
   * '-' is for descending order
   * @param {Array<any>} input
   * @param {string[]} config
   * @returns {any}
   */
  transform(input: Array<any>, config?: string[]): any {
    if (!Array.isArray(input)) {
      // Skip if not array
      return input;
    }

    if (!config) {
      // If no keys, order as normal string array
      return input.sort((a: string, b: string) => {
        // TODO Actually allow descending
        const ascending: boolean = true;

        return ascending
          ? OrderByPipe._orderByComparator(a, b)
          : -OrderByPipe._orderByComparator(a, b);
      });
    }

    if (config.length === 1) {
      // Order is ascending if there is no - (so + or nothing)
      const ascending = !(config[0].substr(0, 1) === '-');

      // Check for + and - signs in key
      const key =
        config[0].substr(0, 1) === '+' || config[0].substr(0, 1) === '-'
          ? config[0].substr(1)
          : config[0];

      // If only one key, sort by that key
      return input.sort(function (a: any, b: any) {
        if (key.indexOf('.') === -1) {
          return ascending
            ? OrderByPipe._orderByComparator(a[key], b[key])
            : -OrderByPipe._orderByComparator(a[key], b[key]);
        }
        else {
          // If key has a dot (example gameSeasonId.name), parse key
          const mainKey = key.substr(0, key.indexOf('.'));
          const subKey = key.substr(key.indexOf('.') + 1, key.length);
          return ascending
            ? OrderByPipe._orderByComparator(a[mainKey][subKey], b[mainKey][subKey])
            : -OrderByPipe._orderByComparator(a[mainKey][subKey], b[mainKey][subKey]);
        }
      });
    }

    // If several keys, loop and sort
    return input.sort(function (a: any, b: any) {
      for (let i = 0; i < config.length; i++) {
        // Order is ascending if there is no - (so + or nothing)
        const ascending = !(config[i].substr(0, 1) === '-');

        // Check for + and - signs in key
        const key =
          config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
            ? config[i].substr(1)
            : config[i];

        let comparison = null;
        if (key.indexOf('.') === -1) {
          comparison =
            ascending
              ? OrderByPipe._orderByComparator(a[key], b[key])
              : -OrderByPipe._orderByComparator(a[key], b[key]);
        }
        else {
          // If key has a dot (example gameSeasonId.name), parse key
          const mainKey = key.substr(0, key.indexOf('.'));
          const subKey = key.substr(key.indexOf('.') + 1, key.length);
          comparison =
            ascending
              ? OrderByPipe._orderByComparator(a[mainKey][subKey], b[mainKey][subKey])
              : -OrderByPipe._orderByComparator(a[mainKey][subKey], b[mainKey][subKey]);
        }

        // Skip returning equal arrays
        if (comparison !== 0) {
          return comparison;
        }
      }

      return 0;
    });
  }
}
