/**
 * Created by Topi on 11.6.2018
 */

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {User} from "../../data-model/user.type";
import {IntroAssignmentType} from "../../data-model/introduction-tutorial.type";
import {GameTutorialService, TutorialItem} from "../../core/service/game-tutorial.service";
import {UserService} from "../../core/service/user.service";

@Component({
  selector: 'intro-tutorial-component',
  template: `
    <div class="intro-container">
      <div class="intro-square-list">
        <div class="intro-square"
             *ngFor="let tutorial of tutorialAssignments"
             [ngClass]="{'half-height': tutorial.identifier === 'update-profile' || tutorial.identifier === 'sign-up'}"
        >
          <h4>{{('introduction-tutorial.' + tutorial.identifier) | translate}}</h4>

          <span class="custom-checkbox">
            <span *ngIf="tutorial.completed" class="glyphicon glyphicon-ok"></span>
          </span>

          <audio-circle
            *ngIf="tutorial.identifier === 'test-microphone'"
            [currentUser]="currentUser"
            (successEmitter)="setTutorialCompleted(tutorial.identifier)"
          ></audio-circle>

          <tutorial-selector
            *ngIf="tutorial.identifier === 'session-tutorial'"
            [slimTutorial]="true"
            (tutorialSelectionEmitter)="openTutorial($event)"
          ></tutorial-selector>

          <ng-container
            *ngIf="tutorial.identifier === 'update-profile' || tutorial.identifier === 'sign-up'"
          >
            <span>
              {{'introduction-tutorial.' + tutorial.identifier + '-description' | translate}}
            </span>

            <raised-css-button
              [buttonText]="'introduction-tutorial.' + tutorial.identifier + '-button'"
              [buttonId]="tutorial.identifier"
              [fontSize]="'2vmin'"
              [marginTop]="'1vmin'"
              [marginBottom]="'1vmin'"
              (onClick)="emitOpenTab(tutorial.identifier)"
            ></raised-css-button>
          </ng-container>
        </div>
      </div>

      <div [ngStyle]="{display: 'flex'}">
        <raised-css-button
          [buttonText]="'contact-form.contact-form-open-button'"
          [fontSize]="'2vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'0'"
          (onClick)="openContact()"
        ></raised-css-button>
      </div>
    </div>
  `
})

export class IntroductionTutorialComponent implements OnChanges, OnInit {
  @Input() currentUser: User;
  @Input() isNewLandingPage: boolean; // Show different instructions
  @Input() visible: boolean;

  @Output() exitTutorial: EventEmitter<boolean> = new EventEmitter();
  @Output() onContactUs: EventEmitter<boolean> = new EventEmitter();
  @Output() tutorialSelectionEmitter: EventEmitter<TutorialItem> = new EventEmitter<TutorialItem>();
  @Output() openTabEmitter: EventEmitter<string> = new EventEmitter<string>();

  public tutorialAssignments: IntroAssignmentType[];
  public userIsAdmin: boolean;

  constructor(private tutorialService: GameTutorialService,
              private userService: UserService) {

  }

  ngOnInit(): void {
    this.tutorialService.tutorialAssignments$
      .subscribe(assignments => {
        this.tutorialAssignments = assignments;
        this.checkAssignmentsCompletion();
      });

    this.tutorialService.getTutorialAssignments();

    this.userIsAdmin = this.userService.isCurrentUserSystemAdmin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentUser'] && this.currentUser) {
      if (this.currentUser.hideIntroTutorial) {
        return;
      }

      this.checkAssignmentsCompletion();
    }
  }

  public openContact(): void {
    this.onContactUs.emit(true);
  }

  public openTutorial(tutorial: TutorialItem): void {
    this.tutorialSelectionEmitter.emit(tutorial);
  }

  public emitOpenTab(identifier: string): void {
    this.openTabEmitter.emit(identifier);
  }

  public setTutorialCompleted(identifier: string): void {
    this.tutorialService.setIntroTutorialAsCompleted(identifier, this.currentUser);
  }

  private checkAssignmentsCompletion(): void {
    if (!this.currentUser || !this.tutorialAssignments) {
      return;
    }

    this.tutorialAssignments = this.tutorialService.checkCompletion(this.currentUser, this.tutorialAssignments);
  }
}
