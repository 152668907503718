/**
 * Created by Topi on 30.11.2018.
 */
import {User} from "../app/data-model/user.type";
import { SafeHtml } from "@angular/platform-browser";
import { SituationSourceData } from "../app/data-model/situation-source-data.type";

export enum MediaTypeEnum {
  IMAGE = "image",
  VIDEO = "video",
  FILE = "file",
  AUDIO = "audio",
  LINK = "link"
}

export class MediaUtils {
  private static supportedImageFormats: string[] = ["jpg", "jpeg", "png", "bmp", "ico", "svg", "webp", "gif", "apng"];
  private static supportedVideoFormats: string[] = ["mp4", "webm", "ogg"];
  private static supportedAudioFormats: string[] = ["mp3"]; // Add more when needed
  private static supportedFileFormats: string[] = ["pdf"];

  private static imageTag: string = "<img";
  private static linkTag: string = "<a";
  private static audioTag: string = "<audio";
  private static videoTag: string = "<video";

  private static supportedMaterialFormats: string[] = [...MediaUtils.supportedImageFormats, ...MediaUtils.supportedFileFormats, ...MediaUtils.supportedVideoFormats];

  constructor() {

  }

  static createRandomHsl(): string {
    const hue = (Math.random() + 0.618033988749895) * 360;
    return 'hsl(' + hue + ', 75%, 50%)';
  }

  static getFileType(fileName: string): string {
    if (!fileName) {
      return;
    }

    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
  }

  static getFormats(type: MediaTypeEnum | string): string[] {
    if (type === "image") {
      return MediaUtils.supportedImageFormats;
    }

    if (type === "video") {
      return MediaUtils.supportedVideoFormats;
    }

    if (type === "audio") {
      return MediaUtils.supportedAudioFormats;
    }

    if (type === "file") {
      return MediaUtils.supportedFileFormats;
    }

    if (type === "material") {
      return MediaUtils.supportedMaterialFormats;
    }

    return [];
  }

  static getHtmlString(prompt: SafeHtml): string {
    if (!prompt) {
      return "";
    }

    const div: HTMLDivElement = document.createElement("div");
    div.innerHTML = prompt as string;

    return div.innerHTML;
  }

  static questionHasMediaTag(prompt: string, mediaType?: MediaTypeEnum): boolean {
    if (!prompt) {
      return false;
    }

    if (typeof prompt !== "string") {
      prompt = MediaUtils.getHtmlString(prompt).toString();
    }

    if (mediaType) {
      switch (mediaType) {
        case MediaTypeEnum.IMAGE:
          return prompt.indexOf(this.imageTag) !== -1;

        case MediaTypeEnum.VIDEO:
          return prompt.indexOf(this.videoTag) !== -1;

        case MediaTypeEnum.AUDIO:
          return prompt.indexOf(this.audioTag) !== -1;

        case MediaTypeEnum.FILE || MediaTypeEnum.LINK:
          return prompt.indexOf(this.linkTag) !== -1;

        default:
          return false;
      }
    }

    return (
      prompt.indexOf(this.imageTag) !== -1 ||
      prompt.indexOf(this.videoTag) !== -1 ||
      prompt.indexOf(this.audioTag) !== -1 ||
      prompt.indexOf(this.linkTag) !== -1
    );
  }

  static getMediaEnumType(fileName: string): MediaTypeEnum | null {
    if (!fileName) {
      return null;
    }

    const fileType: string = MediaUtils.getFileType(fileName);

    if (MediaUtils.supportedImageFormats.indexOf(fileType) !== -1) {
      return MediaTypeEnum.IMAGE;
    }

    if (MediaUtils.supportedFileFormats.indexOf(fileType) !== -1) {
      return MediaTypeEnum.FILE;
    }

    if (MediaUtils.supportedVideoFormats.indexOf(fileType) !== -1) {
      return MediaTypeEnum.VIDEO;
    }

    if (MediaUtils.supportedAudioFormats.indexOf(fileType) !== -1) {
      return MediaTypeEnum.AUDIO;
    }

    return MediaTypeEnum.LINK;
  }

  static getMediaType(fileName: string): string {
    if (!fileName) {
      return null;
    }

    const fileType: string = MediaUtils.getFileType(fileName);

    if (MediaUtils.supportedImageFormats.indexOf(fileType) !== -1) {
      return "image";
    }

    if (MediaUtils.supportedFileFormats.indexOf(fileType) !== -1) {
      return "file";
    }

    if (MediaUtils.supportedVideoFormats.indexOf(fileType) !== -1) {
      return "video";
    }

    return "link";
  }

  static checkIsImage(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    return MediaUtils.supportedImageFormats.indexOf(MediaUtils.getFileType(fileName)) !== -1;
  }

  static getActualPath(fileName: string): string {
    if (!fileName) {
      return "";
    }

    if (fileName.indexOf('http://') !== -1 || fileName.indexOf('https://') !== -1) {
      // Use link
      return fileName;
    }

    if (fileName.indexOf('/static/') !== -1 || fileName.indexOf('/assets/') !== -1) {
      // Already going to right place
      return fileName;
    }

    return '/static/media/' + fileName;
  }

  static getAvatar(user: User): string {
    if (!user) {
      return "";
    }

    return '/static/media/' + user.orgId + "/" + user._id + "/avatar.png";
  }

  static getOrgFile(fileName: string, orgId: string): string {
    if (!fileName || fileName.indexOf('/static/image/') !== -1) {
      console.error("Invalid filename", fileName);
      return null;
    }

    if (fileName.indexOf('http://') !== -1 || fileName.indexOf('https://') !== -1) {
      // Use link
      return fileName;
    }

    if (fileName.indexOf('/assets/image/') !== -1 || fileName.indexOf('/static/media/') !== -1) {
      // Already valid
      return fileName;
    }

    // If org was populated
    if (typeof orgId === 'object') {
      orgId = orgId['_id'];
    }

    if (!orgId) {
      // Might be old path
      return "/static/media/" + fileName;
    }

    return "/static/media/" + orgId + "/" + fileName;
  }
}
