import {ModuleWithProviders, NgModule} from '@angular/core';

import {AuthGuard} from "../../lib/auth-guard";
import {LoginGuard} from "../../lib/login-guard";
import {NoLoginGuard} from "../../lib/no-login-guard";
import {SessionGuard} from "../../lib/session-guard";
import {ConfirmDeactivateGuard} from "../../lib/can-deactivate-guard";

@NgModule({
  declarations: [

  ],
  entryComponents: [

  ],
  imports: [

  ],
  providers: [
    AuthGuard,
    SessionGuard,
    LoginGuard,
    NoLoginGuard,
    ConfirmDeactivateGuard
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}
