import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import {NetworkUser, User} from "../../data-model/user.type";
import {SurveyService} from "../../core/service/survey.service";
import {Organization} from "../../data-model/organization.type";
import {GameSessionLog, UserReactions} from "../../data-model/game-session-log.type";
import {UserService} from "../../core/service/user.service";
import {UserSurveyAnswer} from "../../data-model/survey-answer.type";
import {TutorialItem} from "../../core/service/game-tutorial.service";
import {filter, takeUntil} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { Content } from "../../data-model/content.type";

@Component({
  selector: 'user-statistics',
  template: `
    <!--<div class="statistics-container" *ngIf="!userSessions?.length">
      <div class="statistics-area">
        <div class="header-title">{{"new-tutorial.mic-test-title" | translate}}</div>

        <div class="gradient-line"></div>

        <audio-circle
          [currentUser]="currentUser"
        ></audio-circle>
      </div>

      <user-statistics-row
        [statisticsType]="'overview'"
        [userSessions]="userSessions"
        [userReactions]="userReactions"
      ></user-statistics-row>

      <user-statistics-row
        [statisticsType]="'engagement'"
        [currentUser]="currentUser"
        [userSurveyAnswers]="userSurveyAnswers"
        [maxEngagementValue]="5"
        [additionalClass]="'center'"
      ></user-statistics-row>

      &lt;!&ndash;<user-statistics-row
        [statisticsType]="'network'"
        [currentUser]="currentUser"
        [currentOrganization]="currentOrganization"
        [userSessions]="userSessions"
        [organizationUsers]="organizationUsers"
      ></user-statistics-row>&ndash;&gt;
    </div>-->

    <div class="statistics-container">
      <div class="statistics-area" *ngIf="currentOrganization?.voiceChat === 'production'">
        <div class="header-title">{{"new-tutorial.mic-test-title" | translate}}</div>

        <div class="gradient-line"></div>

        <div class="intro-info-text">
          {{"new-tutorial.voice-test-info-2" | translate}}
        </div>

        <div class="statistics-content">
          <!--<audio-circle
            [currentUser]="currentUser"
          ></audio-circle>-->

          <audio-device-test
            [currentUser]="currentUser"
          ></audio-device-test>
        </div>
      </div>

      <div class="statistics-area" *ngIf="!userSessions?.length">
        <div class="header-title">{{"new-tutorial.pop-up-header" | translate}}</div>

        <div class="gradient-line"></div>

        <div class="statistics-content">
          <tutorial-selector
            [slimTutorial]="true"
            (tutorialSelectionEmitter)="openTutorial($event)"
          ></tutorial-selector>
        </div>
      </div>

      
      <user-statistics-row
        *ngIf="currentOrganization?.landingPageAddOns?.engagement"
        [statisticsType]="'engagement'"
        [currentUser]="currentUser"
        [userSurveyAnswers]="userSurveyAnswers"
        [maxEngagementValue]="5"
        [additionalClass]="'center'"
      ></user-statistics-row>

      <!--<div class="statistics-area" *ngIf="userSessions?.length">
        <user-statistics-row
          [statisticsType]="'overview'"
          [userSessions]="userSessions"
          [userReactions]="userReactions"
        ></user-statistics-row>
      </div>-->

      <div class="statistics-area" *ngIf="currentOrganizationContent?.customization?.showSignUpButton !== false">
        <div class="header-title">{{"new-tutorial.sign-up-title" | translate}}</div>

        <div class="gradient-line"></div>

        <div class="statistics-content">
          <div class="intro-info-text">
            {{"new-tutorial.sign-up-info" | translate}}
          </div>

          <div class="content-big-icon glyphicon glyphicon-calendar"></div>

          <raised-css-button
            [buttonText]="'new-tutorial.sign-up-button'"
            [iconClass]="'glyphicon glyphicon-calendar'"
            (onClick)="openSignUp()"
            [marginTop]="'0'"
            [marginBottom]="'2vmin'"
          ></raised-css-button>
        </div>
      </div>
    </div>
  `,
})
export class UserStatisticsComponent extends OnDestroyMixin implements OnChanges, OnInit {
  @Input() currentUser: User;
  @Input() currentOrganization: Organization;
  @Input() currentOrganizationContent: Content;
  @Input() userSessions: GameSessionLog[];
  @Input() showingIntroTutorial: boolean;

  @Output() tutorialSelectionEmitter: EventEmitter<TutorialItem> = new EventEmitter<TutorialItem>();
  @Output() openSignUpEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public organizationUsers: NetworkUser[];
  public userReactions: UserReactions[];
  public userSurveyAnswers: UserSurveyAnswer[];

  constructor(private surveyService: SurveyService,
              private userService: UserService) {
    super();
  }

  ngOnInit(): void {
    this.userService.orgUsers$
      .pipe(
        untilComponentDestroyed(this),
        filter(users => !!users)
      )
      .subscribe(
        users => {
          this.organizationUsers = users.map(user => {
            return {
              name: user.name,
              _id: user._id,
              imagePath: user.imagePath,
              department: user.department
            };
          });
        }
      );

    this.surveyService.userThumbSurveys$
      .subscribe(surveys => {
        if (!surveys) {
          return;
        }

        this.parseSurveyAnswers(surveys);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser && this.currentUser) {
      this.getUserSurveys(this.currentUser);
    }

    if (changes.userSessions && this.userSessions) {
      this.parseUserReactions(this.userSessions);
    }
  }

  public getUserSurveys(user: User) {
    const actionsQuery = {
      userId: user._id,
      orgId: user.orgId,
      thumbAnswers: {$exists: true, $ne: []}
    };

    this.surveyService.getUserEngagementData(actionsQuery);
  }

  public parseUserReactions(logs: GameSessionLog[]): void {
    if (!logs || !logs.length) {
      return;
    }

    // Reset array
    this.userReactions = UserReactions.DefaultReactionArray();
    let atLeastOneReaction: boolean;

    for (const log of logs) {
      if (!log.answerData) {
        continue;
      }

      for (const answerLog of log.answerData) {
        if (!answerLog.playerData || !answerLog.playerData.length) {
          continue;
        }

        const personalData = answerLog.playerData.find(entry => {
          return entry.user.toString() === this.currentUser._id.toString();
        });

        if (!personalData || !personalData.reactions) {
          continue;
        }

        for (const givenReaction of personalData.reactions.given) {
          const matchingReaction = this.userReactions.find(reactionValue => {
            return reactionValue.type === givenReaction.type;
          });

          if (matchingReaction) {
            matchingReaction.givenAmount += 1;
            atLeastOneReaction = true;
          }
        }

        for (const receivedReaction of personalData.reactions.received) {
          const matchingReaction = this.userReactions.find(reactionValue => {
            return reactionValue.type === receivedReaction.type;
          });

          if (matchingReaction) {
            matchingReaction.receivedAmount += 1;
            atLeastOneReaction = true;
          }
        }
      }
    }

    if (!atLeastOneReaction) {
      this.userReactions = [];
    }
  }

  public parseSurveyAnswers(surveys: any[]) {
    const thumbAnswerQuestions: UserSurveyAnswer[] = [];

    for (const survey of surveys) {
      for (const answer of survey.thumbAnswers) {
        const questionInArray = thumbAnswerQuestions.find(question => {
          return question.question === answer.question && question.engagementTag === answer.engagementTag;
        });

        if (!questionInArray) {
          thumbAnswerQuestions.push(UserSurveyAnswer.QuestionAverageThumbAnswer(answer.question, answer.thumbRating, answer.engagementTag));
          continue;
        }

        questionInArray.thumbRatings.push(answer.thumbRating);
      }
    }

    for (const question of thumbAnswerQuestions) {
      const answerSum: number = question.thumbRatings.reduce((a, b) => a + b, 0);
      question.averageRating = +(answerSum / question.thumbRatings.length).toFixed(2);
    }

    this.userSurveyAnswers = thumbAnswerQuestions;
  }

  public openSignUp(): void {
    this.openSignUpEmitter.emit();
  }

  public openTutorial(tutorial: TutorialItem): void {
    this.tutorialSelectionEmitter.emit(tutorial);
  }
}
