import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tutorial-multi-select',
  template: `    
    <div class="multi-select-container">
      <ng-container *ngIf="phase !== 'review'">
        <div class="multi-select-choice" *ngFor="let choice of tutorialChoices; let i = index"
             [ngClass]="{'no-selection': selectedIndex === -1, selected: i === selectedIndex, locked: answerLocked}">
          <div class="choice-box" (click)="selectedIndex = i">
            <div class="index">{{i + 1}}.</div>
            <div class="body" [innerHtml]="('tutorial-situations.' + choice | translate) | newLine"></div>
          </div>

          <div class="votes" *ngIf="phase !== 'personal' && i === 0">
            <player-reasoning
              [userDoc]="{name: ('tutorial-user-1.name' | translate), _id: 'tutorial'}"
              [reasoning]="'tutorial-user-1.multi-choice-answer-1' | translate"
              [showNoReasoning]="true"
              [phase]="phase"
              [fakeReactions]="true"
            ></player-reasoning>
          </div>

          <div *ngIf="i === selectedIndex" class="reason-input">
              <textarea [disabled]="answerLocked" [(ngModel)]="voteReasons[i]" [auto-focus]="true"
                        [placeholder]="'situation.answer-placeholder' | translate"></textarea>
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="phase === 'review'">
        <div class="summary-header">
          <ng-container>{{'situation.review-positive-choice' | translate}}</ng-container>
          {{'situation.review-points-choice' | translate: {points: 20} }}
        </div>
        
        <h3>{{'situation.review-group-choice-title' | translate}}</h3>
        
        <div class="multi-select-choice no-selection review">
          <div class="choice-box">
            <div class="index">{{1}}</div>
            <div class="body" [innerHtml]="('tutorial-situations.' + tutorialChoices[0] | translate) | newLine"></div>
            <div class="points best-score">
              {{'situation.points' | translate: {score: 20} }}
            </div>
          </div>
        </div>

        <div class="verdict">
          <div [innerHtml]="'new-tutorial.animations.multi-select-feedback' | translate"></div>
        </div>
      </ng-container>
    </div>
  `,
  styles: []
})
export class TutorialMultiSelectComponent implements OnInit {
  @Input() answerLocked: boolean;
  @Input() phase: string = 'personal';

  public tutorialChoices: string[] = [
    "multi-select-choice-1-text",
    "multi-select-choice-2-text",
    "multi-select-choice-3-text"
  ];

  public selectedIndex: number = -1;
  public voteReasons: string[] = new Array(this.tutorialChoices.length).fill("");

  constructor() { }

  ngOnInit() {

  }

}
