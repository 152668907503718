
import {throwError, Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";

/**
 * Created by TopSal on 21.04.2017.
 */

@Injectable({
  providedIn: 'root'
})
export class TestOrgService {

  constructor(private http: HttpClient) {

  }

  public sendContactRequestEmail(body: Object): Observable<{status: string}> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.post('/api/test-organization/send-contact-request-email/', body, options)
      .pipe(
        map(res => {
          return res as {status: string};
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
