import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface ConfirmMessage {
  identifier: string;
  title: string;
  text: string;
  textParams: Object;
  buttons: ConfirmButton[];
  links?: ConfirmLink[];
  timer?: number; // Auto-close
}

export interface ConfirmLink {
  text: string;
  link: string;
}

export interface ConfirmButton {
  text: string;
  action?: string;
}

export interface ModalConfirmation {
  identifier: string;
  action?: string;
}

@Injectable({
  providedIn: "root"
})
export class CustomConfirmService {
  public currentConfirm$: BehaviorSubject<ConfirmMessage> = new BehaviorSubject<ConfirmMessage>(undefined);
  public confirmedMessage$: BehaviorSubject<ModalConfirmation> = new BehaviorSubject<ModalConfirmation>(undefined);

  private defaultButtons: ConfirmButton[] = [
    {
      text: "custom-confirm.confirm-button",
      action: "confirm"
    },
    {
      text: "custom-confirm.cancel-button",
      action: "cancel"
    }
  ];

  constructor() {
  }

  public setConfirmation(identifier: string, action: string): void {
    this.confirmedMessage$.next({
      identifier: identifier,
      action: action
    });
  }

  public setMessage(identifier: string, title: string, text: string, textParams: Object, buttons?: ConfirmButton[], links?: ConfirmLink[], timer?: number): void {
    this.currentConfirm$.next({
      identifier: identifier,
      title: title,
      text: text,
      textParams: textParams,
      buttons: buttons || this.defaultButtons,
      links: links,
      timer: timer
    });
  }

  public unsetConfirm(): void {
    this.currentConfirm$.next(null);
    this.confirmedMessage$.next(null);
  }
}
