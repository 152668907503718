/**
 * Created by Topi on 31.01.2019
 */
import {
  Component, EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { User } from "../../data-model/user.type";
import { GameTimeslot } from "../../data-model/timeslot.type";
import { ClockRoundComponent } from "./clock-round.component";
import { Duration } from "moment";
import { GameSessionService } from "../../core/service/game-session.service";
import { filter, first, takeUntil } from "rxjs/operators";
import { GameSession } from "../../data-model/game-session.interface";
import { MaterialInterface } from "../../data-model/material.interface";
import { MaterialService } from "../../core/service/material.service";
import { MediaUtils } from "../../../lib/media-utils";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { GameStateSnapshot } from "../../data-model/game-state.interface";
import { GameSeasonService } from "../../core/service/game-season.service";
import { GameSessionLogService } from "../../core/service/game-session-log.service";
import { Measure } from "../../data-model/measure-question.interface";

const moment = require("moment");

export interface IncompleteFeedback {
  questions: Measure[];
  feedback: boolean;
  seasonName: string;
  seasonId: string;
  sessionId: string;
}

@Component({
  selector: "next-discussion-info",
  template: `
    <div class="next-discussion-wrapper" *ngIf="nextTimeSlot || activeDiscussion || incompleteFeedback">
      <div class="next-discussion-container animated slideInDown">
        <h2 *ngIf="activeDiscussion">{{activeDiscussion?.gameSeasonId['name']}}</h2>

        <ng-container *ngIf="!activeDiscussion && incompleteFeedback">
          <h2>{{incompleteFeedback.seasonName}}</h2>
          <div>{{"discussion-feedback.forgot-to-answer" | translate}}</div>
        </ng-container>

        <h2 *ngIf="!activeDiscussion && !incompleteFeedback && nextTimeSlot"> {{nextTimeSlot?.gameSeasonId?.name}}</h2>

        <div class="description-scroll-container" *ngIf="!activeDiscussion && nextTimeSlot?.gameSeasonId?.description">
          <ng-scrollbar class="loge-scrollbar" [appearance]="'standard'">
            <div class="discussion-info-text">
              {{nextTimeSlot?.gameSeasonId?.description}}
            </div>
          </ng-scrollbar>
        </div>

        <div class="next-discussion-body">
          <img
            *ngIf="activeDiscussion?.gameSeasonId && activeDiscussion?.gameSeasonId['previewImagePath']"
            class="discussion-image"
            [src]="getActualFilePath(activeDiscussion.gameSeasonId['previewImagePath'], activeDiscussion.orgId)"
            alt=""
          />

          <ng-container *ngIf="!activeDiscussion">
            <img
              *ngIf="nextTimeSlot?.gameSeasonId?.previewImagePath"
              class="discussion-image"
              [src]="getActualFilePath(nextTimeSlot.gameSeasonId.previewImagePath, nextTimeSlot.orgId)"
              alt=""
            />

            <div class="discussion-info">
              <!--<h3>{{'assignment-view.pre-material' | translate}}</h3>-->

              <div class="discussion-pre-material">
                <div class="material-image"
                     id="material-button-{{seasonMaterial._id}}"
                     *ngFor="let seasonMaterial of upcomingMaterial"
                     (click)="selectMaterial(seasonMaterial)">
                  <span [ngClass]="getMaterialIconClass(seasonMaterial)"></span>
                  {{seasonMaterial.title}}
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="next-discussion-timer" *ngIf="!activeDiscussion && nextTimeSlot">
          <div class="discussion-time-text" [ngStyle]="{marginRight: ((!showCountdown || gameStateSnapShot) ? '0' : '1vw')}">
            {{'player-progress-view.next-discussion-later' | translate: { beginTime: nextTimeSlot?.beginTime | logeDate: "ddd D.M. H:mm": currentUser?.language: true } }}

            <ng-container *ngIf="estimatedDurationString">
              {{" – "}}{{'player-progress-view.next-discussion-duration' | translate: { durationString: estimatedDurationString } }}
            </ng-container>
          </div>

          <clock-round
            #timeSlotTimer
            [hidden]="!showCountdown || gameStateSnapShot"
          ></clock-round>
        </div>

        <div class="active-discussion-join" *ngIf="activeDiscussion">
          <div class="active-discussion-info">
            {{'player-progress-view.started-already' | translate}}
          </div>

          <raised-css-button
            *ngIf="durationToNextDiscussion"
            [buttonText]="gameStateSnapShot?.sessionPhase !== 'Lobby' ? 'situation.session-join-button' : 'player-progress-view.join-lobby'"
            [fontSize]="'2vmin'"
            [marginTop]="'1vmin'"
            [marginBottom]="'0.1vmin'"
            (onClick)="joinDiscussion()"
          ></raised-css-button>
        </div>

        <div class="active-discussion-join" *ngIf="!activeDiscussion && incompleteFeedback">
          <raised-css-button
            [buttonText]="'discussion-feedback.answer-questions'"
            [buttonId]="'incomplete-feedback'"
            [fontSize]="'2vmin'"
            [marginTop]="'1vmin'"
            [marginBottom]="'0.1vmin'"
            (onClick)="openDiscussionFeedback()"
          ></raised-css-button>
        </div>
      </div>
    </div>

    <centered-pop-up
      *ngIf="selectedMaterial"
      [popUpVisible]="true"
      (popUpEmitter)="selectedMaterial = null"
      [headerText]="selectedMaterial.title"
      [orgId]="currentUser.orgId"
      [materialContent]="selectedMaterial"
      [materialType]="getFileType(selectedMaterial?.path)"
      [containerClass]="{
        'has-youtube': selectedMaterial?.type === 'videoId',
        'has-pdf': checkFileType(selectedMaterial?.path, 'file'),
        'has-image': checkFileType(selectedMaterial?.path, 'image'),
        'has-video': checkFileType(selectedMaterial?.path, 'video')
      }"
      [allowedElementIds]="['material-button-']"
    ></centered-pop-up>

    <centered-pop-up
      *ngIf="showFeedbackEditor"
      [popUpVisible]="true"
      (popUpEmitter)="showFeedbackEditor = $event; incompleteFeedback = null"
      [headerText]="'discussion-feedback.title'"
      [allowedElementIds]="['incomplete-feedback']"
      [measureQuestions]="incompleteFeedback?.questions"
      [userId]="currentUser?._id"
      [orgId]="currentUser?.orgId"
      [seasonId]="incompleteFeedback?.seasonId"
      [sessionId]="incompleteFeedback?.sessionId"
      [allowOutsideClick]="true"
    ></centered-pop-up>
  `
})
export class NextDiscussionInfoComponent extends OnDestroyMixin implements OnChanges, OnInit {
  @ViewChild("timeSlotTimer") timeSlotTimer: ClockRoundComponent;

  @Input() currentUser: User;
  @Input() upcomingDiscussions: GameTimeslot[];
  @Input() activeDiscussion: GameSession;
  public incompleteFeedback: IncompleteFeedback;

  public nextTimeSlot: GameTimeslot;
  public showCountdown: boolean;
  public gameStateSnapShot: GameStateSnapshot;
  public durationToNextDiscussion: Duration;

  public organizationMaterial: MaterialInterface[];
  public upcomingMaterial: MaterialInterface[];
  public selectedMaterial: MaterialInterface;

  public estimatedDurationString: string;

  public showFeedbackEditor: boolean;

  constructor(private gameSessionService: GameSessionService,
              private materialService: MaterialService,
              private gameSeasonService: GameSeasonService,
              private sessionLogService: GameSessionLogService) {
    super();
  }

  ngOnInit(): void {
    this.materialService.orgMaterial$
      .pipe(
        untilComponentDestroyed(this),
        filter(material => !!material)
      )
      .subscribe(
        material => {
          this.organizationMaterial = material;
          this.filterMaterial();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser) {
      this.sessionLogService.checkMissingFeedback()
        .subscribe(data => {
          // Null if not missing (aka answered)
          this.incompleteFeedback = data;
        });
    }

    if (changes.activeDiscussion && this.activeDiscussion) {
      const durationToNext: Duration = moment.duration(moment(this.activeDiscussion.beginTime).diff(moment()));

      this.durationToNextDiscussion = durationToNext;
      this.setTimer(durationToNext);

      this.filterMaterial();

      // Check if populated
      const actualOrgId: string = typeof this.activeDiscussion.orgId === "object" ? this.activeDiscussion.orgId["_id"] : this.activeDiscussion.orgId;

      this.gameSessionService.getGameState(
        {
          _id: this.activeDiscussion._id,
          orgId: actualOrgId
        })
        .pipe(
          first()
        )
        .subscribe(matchingSnapShot => {
          this.gameStateSnapShot = matchingSnapShot;
        });

      this.gameSeasonService.getSeasonDiscussionLength(this.activeDiscussion.gameSeasonId)
        .subscribe((durationInSeconds: number) => {
          this.estimateDuration(durationInSeconds);
        });
    }

    if (changes.upcomingDiscussions && this.upcomingDiscussions && !this.activeDiscussion) {
      if (!this.upcomingDiscussions.length) {
        this.nextTimeSlot = null;
        return;
      }

      this.nextTimeSlot = this.upcomingDiscussions[0];

      const durationToNext: Duration = moment.duration(moment(this.nextTimeSlot.beginTime).diff(moment()));

      this.durationToNextDiscussion = durationToNext;
      this.setTimer(durationToNext);
      this.filterMaterial();

      this.gameSeasonService.getSeasonDiscussionLength(this.upcomingDiscussions[0].gameSeasonId._id)
        .subscribe((durationInSeconds: number) => {
          this.estimateDuration(durationInSeconds);
        });
    }
  }

  public estimateDuration(durationInSeconds: number): void {
    if (!durationInSeconds) {
      this.estimatedDurationString = null;
      return;
    }

    let durationString: string = "";
    const duration: Duration = moment.duration({ "seconds": durationInSeconds });

    if (duration.get("h")) {
      durationString += duration.get("h") + "h";
    }

    if (duration.get("m")) {
      if (durationString) {
        durationString += " ";
      }

      durationString += duration.get("m") + "min";
    }

    this.estimatedDurationString = durationString;
  }

  public getActualFilePath(fileName: string, orgId: string): string {
    return MediaUtils.getOrgFile(fileName, orgId);
  }

  public joinDiscussion(): void {
    this.gameSessionService.joinSession();
  }

  public openDiscussionFeedback(): void {
    this.showFeedbackEditor = true;
  }

  public compareDuration(seconds: number) {
    if (!this.durationToNextDiscussion) {
      return false;
    }

    return this.durationToNextDiscussion.asSeconds() > seconds;
  }

  public selectMaterial(material: MaterialInterface) {
    if (material.type === "link") {
      window.open(material.path, "_blank");
      return;
    }

    if (material.type === "file") {
      const fileType = material.path.substring(material.path.lastIndexOf(".") + 1).toLowerCase();

      if (fileType === "pdf") {
        window.open(MediaUtils.getOrgFile(material.path, material.orgId), "_blank");
        return;
      }
    }

    this.selectedMaterial = material;
  }

  public getMaterialIconClass(material: MaterialInterface): string {
    if (!material) {
      return "glyphicon glyphicon-file";
    }

    if (material.type === "link") {
      return "glyphicon glyphicon-link";
    }

    if (material.type === "videoId") {
      return "glyphicon glyphicon-film";
    }

    if (material.type === "file") {
      if (material.path.indexOf(".pdf") !== -1) {
        return "glyphicon glyphicon-file";
      }

      return "glyphicon glyphicon-picture";
    }

    console.error("Unknown material type", material.type);
    return "glyphicon glyphicon-file";
  }

  public filterMaterial(): void {
    if ((!this.activeDiscussion && !this.nextTimeSlot) || !this.organizationMaterial) {
      return;
    }

    const themeToCheck: string = this.activeDiscussion ? this.activeDiscussion.gameSeasonId["themes"][0] : this.nextTimeSlot.gameSeasonId.themes[0];

    this.upcomingMaterial = this.organizationMaterial.filter(material => {
      return material.theme === themeToCheck;
    });
  }

  public getFileType(path: string): string {
    if (!path) {
      return null;
    }

    return MediaUtils.getMediaType(path);
  }

  public checkFileType(path: string, wanted: string): boolean {
    if (!path || !wanted) {
      return false;
    }

    return MediaUtils.getMediaType(path) === wanted;
  }

  private setTimer(duration: Duration, retry?: number): void {
    if (retry > 10 || !duration) {
      this.showCountdown = false;
      return;
    }

    if (!this.timeSlotTimer) {
      setTimeout(() => {
        this.setTimer(duration, retry ? retry + 1 : 1);
      }, 500);

      return;
    }

    this.showCountdown = duration.asSeconds() < 3600;
    this.timeSlotTimer.setTime(duration.asSeconds());
  }
}
