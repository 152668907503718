/**
 * Created by Topi on 31.01.2018
 */

import {Component, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GameTimeslot} from "app/data-model/timeslot.type";
import {CalendarBodyLang} from "app/data-model/email.interface";
import {User} from "app/data-model/user.type";
import {GameSeason} from "../../data-model/game-season.type";
import {Moment} from "moment";
import {GeneralUtils} from "../../../lib/general-utils";
import {GameSeasonService} from "../../core/service/game-season.service";

const moment = require('moment');

@Component({
  selector: 'session-join-modal',
  template: `
    <div
      *ngIf="timeSlot"
      class="modal-container confirmation animated slideInDownSmall"
      [ngStyle]="customWidth && {'width': customWidth + customWidthFormat, 'margin-left': -(customWidth / 2) + customWidthFormat}"
      (elementClicked)="clickedOutside($event)"
    >
      <button (click)="hideModal()" class="pop-up-close-button">
        <span class="glyphicon glyphicon-remove"></span>
      </button>

      <h2 class="pop-up-header" [innerHtml]="'session-join-modal.title' | translate"></h2>
      <h1 [innerHtml]="season?.name | translate"></h1>

      <div class="gradient-line"></div>

      <ng-container *ngIf="timeSlot?.beginTime">
        <div class="pop-up-text">
          {{'session-join-modal.session-info' | translate: {time: timeSlot.beginTime | logeDate: "dddd D.M. H:mm": language} }}
        </div>

        <div class="pop-up-text">
          {{'session-join-modal.email-reminder' | translate}}
        </div>
      </ng-container>

      <ng-container *ngIf="!timeSlot?.beginTime">
        <div class="pop-up-text">
          {{'test-discussions.pop-up-info' | translate}}
        </div>
      </ng-container>

      <ng-container *ngIf="calendarContent && timeSlot?.beginTime">
        <div [ngStyle]="{'margin-bottom': '1vh'}">
          <a target="_blank"
             [href]="googleCalendarLink">
            {{'session-join-modal.add-to-google-calendar' | translate}}
          </a>
        </div>

        <div>
          <a target="_blank"
             [href]="'https://experience.loge.fi/api/calendar/event/' + timeSlot?._id + '/' + language">
            {{'session-join-modal.add-to-outlook-calendar' | translate}}
          </a>
        </div>
      </ng-container>
    </div>
  `
})
export class SessionJoinModalComponent implements OnChanges {

  @Input() timeSlot: GameTimeslot;
  @Input() season: GameSeason;
  @Input() language: string = 'en';
  @Input() calendarContent: CalendarBodyLang;
  @Input() currentUser: User;
  @Input() upcomingSessionModal: boolean;
  @Input() allowedElementIds: string[];
  @Input() customWidth: number;
  @Input() customWidthFormat: string;

  @Output() hideInfoModal: EventEmitter<any> = new EventEmitter();

  public googleCalendarLink: string;
  private durationInSeconds: number;

  constructor(private gameSeasonService: GameSeasonService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.season && this.season) {
      this.gameSeasonService.getSeasonDiscussionLength(this.season._id)
        .subscribe((durationInSeconds: number) => {
          if (!durationInSeconds) {
            return;
          }

          this.durationInSeconds = durationInSeconds;
          this.updateGoogleLink();
        });
    }

    if (changes['calendarContent'] || changes['timeSlot']) {
      this.updateGoogleLink();
    }
  }

  public updateGoogleLink(): void {
    if (!this.calendarContent || !this.timeSlot) {
      return;
    }

    this.googleCalendarLink =
      'https://calendar.google.com/calendar/render?action=TEMPLATE&text=' +
      "LOGE - " + this.season.name + // Title
      '&dates=' +
      moment(this.timeSlot.beginTime).utc().format("YYYYMMDDTHHmm[00Z]") + // BeginTime ISO String (seconds 00)
      '/' +
      this.getTimeSlotDurationEstimate(this.timeSlot) + // EndTime ISO String
      '&details=' +
      this.calendarContent.description + // Description
      '&location=' +
      'https://experience.loge.fi/' // Location
    ;
  }

  public hideModal() {
    this.hideInfoModal.emit();
  }

  public clickedOutside(elementId: string): void {
    if (GeneralUtils.clickedOutside(elementId, this.allowedElementIds)) {
      this.hideModal();
    }
  }

  private getTimeSlotDurationEstimate(timeSlot: GameTimeslot): string {
    if (this.durationInSeconds) {
      return moment(this.timeSlot.beginTime).add(this.durationInSeconds, 's').utc().format("YYYYMMDDTHHmm[00Z]");
    }

    if (!timeSlot.gameSeasonId || !timeSlot.gameSeasonId._id) {
      // Set as 1 hour if unknown
      return moment(this.timeSlot.beginTime).add(1, 'h').utc().format("YYYYMMDDTHHmm[00Z]");
    }

    const singleSituationEstimate: number =
      timeSlot.gameSeasonId.phaseTiming.situation.personal +
      timeSlot.gameSeasonId.phaseTiming.situation.group +
      timeSlot.gameSeasonId.phaseTiming.situation.review;

    const totalTimeEstimate: number =
      timeSlot.gameSeasonId.phaseTiming.lobby +
      (timeSlot.gameSeasonId.situationCount * singleSituationEstimate);

    return moment(this.timeSlot.beginTime).add(totalTimeEstimate, 's').utc().format("YYYYMMDDTHHmm[00Z]");
  }
}
