import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ScalePosition, ScaleVote, ScaleVoteSelection} from "../../../data-model/game-state.interface";
import {ScaleItem} from "../../../data-model/scale-vote.interface";
import {CdkDragEnd} from "@angular/cdk/drag-drop";

@Component({
  selector: 'tutorial-scaling',
  template: `
    <div
      class="scaling-container"
      [ngStyle]="{'display': 'flex', 'margin-left': (tutorialIdentifier === 'scaling-general' || tutorialIdentifier === 'scaling-square' ? '1vh' : 0)}"
      (window:resize)="updateSelectionOnResize()"
    >
      <!-- Line area -->
      <ng-container *ngIf="tutorialIdentifier === 'scaling-line'">
        <div class="scaling-table-list">
          <div class="scaling-table horizontal-only unselectable">
            <div id="test-boundaries" class="boundaries horizontal-only" #testBoundaries>

              <div class="crosshair"></div>
              <div class="crosshair-horizontal"></div>

              <ng-container *ngIf="phase !== 'personal'">
                <ng-container *ngFor="let scaleItem of [0, 1, 2]">
                  <div
                    cdkDrag
                    cdkDragDisabled
                    [cdkDragFreeDragPosition]="randomSelections[scaleItem]"
                    class="drag-bubble draggable faded"
                  >
                    <svg-ball-component
                      [content]="''"
                      [active]="true"
                      [centerStar]="false"
                      [customSize]="'100%'"
                      [customFill]="selectionColors[scaleItem]"
                    ></svg-ball-component>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngFor="let scaleItem of [0, 1, 2]">
                <div
                  *ngIf="checkSelected(scaleItem)"
                  cdkDrag
                  cdkDragBoundary=".boundaries"
                  [cdkDragFreeDragPosition]="getPosition(scaleItem)"
                  class="drag-bubble draggable pointer"
                >
                  <svg-ball-component
                    [content]="''"
                    [active]="true"
                    [centerStar]="true"
                    [customSize]="'100%'"
                    [customFill]="selectionColors[scaleItem]"
                  ></svg-ball-component>
                </div>
              </ng-container>
            </div>

            <div class="scaling-titles horizontal-only">
              <div class="title">
                <h3>{{'tutorial-situations.scaling-horizontal-left' | translate}}</h3>
              </div>
              <div class="title">
                <h3>{{'tutorial-situations.scaling-horizontal-right' | translate}}</h3>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Square area -->
      <ng-container *ngIf="tutorialIdentifier === 'scaling-general' || tutorialIdentifier === 'scaling-square'">
        <div class="scaling-table unselectable" [ngStyle]="{'margin-left': '2vmin'}">
          <div
            id="test-boundaries"
            class="boundaries"
            #testBoundaries
          >
            <div class="crosshair"></div>
            <div class="crosshair-horizontal"></div>

            <ng-container *ngIf="phase !== 'personal'">
              <ng-container *ngFor="let scaleItem of [0, 1, 2]">
                <div
                  cdkDrag
                  cdkDragDisabled
                  [cdkDragFreeDragPosition]="randomSelections[scaleItem]"
                  class="drag-bubble draggable faded"
                >
                  <svg-ball-component
                    [content]="''"
                    [active]="true"
                    [centerStar]="false"
                    [customSize]="'100%'"
                    [customFill]="selectionColors[scaleItem]"
                  ></svg-ball-component>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let scaleItem of [0, 1, 2]">
              <div
                *ngIf="checkSelected(scaleItem)"
                cdkDrag
                cdkDragBoundary=".boundaries"
                [cdkDragFreeDragPosition]="getPosition(scaleItem)"
                class="drag-bubble draggable pointer"
              >
                <svg-ball-component
                  [content]="''"
                  [active]="true"
                  [centerStar]="phase !== 'personal'"
                  [customSize]="'100%'"
                  [customFill]="selectionColors[scaleItem]"
                ></svg-ball-component>
              </div>
            </ng-container>
          </div>

          <!-- Vertical titles -->
          <div class="scaling-titles horizontal">
            <ng-container>
              <div class="title">
                <h3>{{'tutorial-situations.scaling-vertical-down' | translate}}</h3>
              </div>
              <div class="title">
                <h3>{{'tutorial-situations.scaling-vertical-up' | translate}}</h3>
              </div>
            </ng-container>
          </div>

          <!-- Horizontal titles -->
          <div class="scaling-titles" [ngStyle]="{marginLeft: 0}">
            <ng-container>
              <div class="title">
                <h3>{{'tutorial-situations.scaling-horizontal-left' | translate}}</h3>
              </div>
              <div class="title">
                <h3>{{'tutorial-situations.scaling-horizontal-right' | translate}}</h3>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="options-list">
          <div *ngFor="let scaleItem of [0, 1, 2]"
               class="single-option situation group tutorial-margin">
            <div class="flex-row">
              <div id="vote_title_{{scaleItem}}" class="drag-bubble title">
                <svg-ball-component
                  [content]="''"
                  [boundaryId]="'test-boundaries'"
                  [active]="true"
                  [customSize]="'100%'"
                  [fadedBorder]="checkSelected(scaleItem)"
                  [showDraggableClone]="!checkSelected(scaleItem)"
                  [showDragAnimation]="!tutorialScaleAnswer?.selections?.length && scaleItem === 0"
                  [cloneExtraPixels]="50"
                  (circlePlacedEmitter)="onCloneMoveEnd($event, scaleItem)"
                  [customFill]="selectionColors[scaleItem]"
                ></svg-ball-component>
              </div>

              <label for="vote_title_{{scaleItem}}" class="option-label">{{('tutorial-situations.scaling-scale-item-' + scaleItem) | translate}}</label>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Suggestions -->
      <ng-container *ngIf="tutorialIdentifier === 'scaling-suggestions'">
        <div class="flex-column-centered" [ngStyle]="{'width': '100%'}">
          <written-answer
            [ngStyle]="{'width': '100%'}"
            [answerString]="suggestionItem"
            [placeholder]="'scaling-suggestion.own-suggestion'"
            [dark]="true"
            [oneLineText]="true"
            [customWidth]="'100%'"
            (answerEmitter)="suggestionListItem = $event"
          ></written-answer>

          <div class="single-option situation group tutorial-margin">
            <div class="flex-row">
              <div class="drag-bubble title">
                <svg-ball-component
                  [content]="''"
                  [boundaryId]="'test-boundaries'"
                  [active]="true"
                  [customSize]="'100%'"
                ></svg-ball-component>
              </div>

              <label class="option-label">{{suggestionListItem ? suggestionListItem : ('new-tutorial.example-suggestion' | translate)}}</label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="options-list horizontal" *ngIf="tutorialIdentifier === 'scaling-line'">
      <div *ngFor="let scaleItem of [0, 1, 2]"
           class="single-option situation group tutorial-margin">
        <div class="flex-row">
          <div id="vote_title_{{scaleItem}}" class="drag-bubble title">
            <svg-ball-component
              [content]="''"
              [boundaryId]="'test-boundaries'"
              [active]="true"
              [customSize]="'100%'"
              [fadedBorder]="checkSelected(scaleItem)"
              [showDraggableClone]="!checkSelected(scaleItem)"
              [showDragAnimation]="!tutorialScaleAnswer?.selections?.length && scaleItem === 0"
              [tutorialAnimation]="true"
              [cloneExtraPixels]="50"
              (circlePlacedEmitter)="onCloneMoveEnd($event, scaleItem)"
              [customFill]="selectionColors[scaleItem]"
            ></svg-ball-component>
          </div>

          <label for="vote_title_{{scaleItem}}" class="option-label">{{('tutorial-situations.scaling-scale-item-' + scaleItem) | translate}}</label>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class TutorialScalingComponent implements OnChanges {
  @ViewChild('testBoundaries') testBoundaries: ElementRef<HTMLDivElement>;

  @Input() phase: string = 'personal';
  @Input() tutorialIdentifier: string;

  public tutorialScaleAnswer: { selections: { item: number, position: ScalePosition }[], reason: string, color } = {selections: [], reason: "", color: ""};
  public selectionColors: string[] = [];
  public randomSelections: ScalePosition[] = [];

  public suggestionItem: string = "";
  public suggestionListItem: string = "";

  constructor() {
  }

  public static getHslColor(colorValue: number) {
    const hue = (colorValue + 0.618033988749895) * 360;
    return 'hsla(' + hue + ', 75%, 50%, 1)';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tutorialIdentifier && this.tutorialIdentifier && this.tutorialIdentifier !== 'scaling-suggestions') {
      setTimeout(() => {
        this.randomSelections = [0, 1, 2].map(() => {
          return this.getRandomPosition(this.tutorialIdentifier === 'scaling-line');
        });

        this.selectionColors = [0, 1, 2].map((value) => {
          return TutorialScalingComponent.getHslColor((value / 10) * 2);
        });
      }, 200);
    }
  }

  public onCloneMoveEnd(event: CdkDragEnd, tutorialItem: number, horizontal?: boolean) {
    const boundaryRect: DOMRect = this.getBoundaryRect();

    if (!boundaryRect) {
      return;
    }

    const boundaryMax: number = this.getBoundaryMax();
    const boundaryMin: number = 0;
    const elementRect: DOMRect = event.source.element.nativeElement.getBoundingClientRect() as DOMRect;

    const actualX: number = elementRect.x - boundaryRect.x;
    const actualY: number = horizontal ? this.getBoundaryMax('height') / 2 : (elementRect.y - boundaryRect.y);

    this.tutorialScaleAnswer.selections.push({
      item: tutorialItem,
      position: {
        x: actualX > boundaryMax ? boundaryMax : (actualX < boundaryMin ? boundaryMin : actualX),
        y: actualY > boundaryMax ? boundaryMax : (actualY < boundaryMin ? boundaryMin : actualY),
        max: boundaryMax
      }
    });
  }

  public checkSelected(tutorialItem: number): boolean {
    return !!this.tutorialScaleAnswer.selections.find(value => {
      return value.item === tutorialItem;
    });
  }

  // Re-align items to fit user's screen
  public updateSelectionOnResize() {
    const boundaryMax: number = this.getBoundaryMax();

    if (!boundaryMax) {
      return;
    }

    if (this.tutorialScaleAnswer && this.tutorialScaleAnswer.selections.length) {
      this.tutorialScaleAnswer.selections.forEach((selection) => {
        selection.position = this.calculateNewSelection(selection.position, boundaryMax);
      });
    }
  }

  public getPosition(item: number): ScalePosition {
    const existingPosition = this.tutorialScaleAnswer.selections.find(scaleItem => {
      return scaleItem.item === item;
    });

    if (existingPosition) {
      return existingPosition.position;
    }

    return {
      x: 0,
      y: 0,
      max: 1
    };
  }

  public calculateNewSelection(position: ScalePosition, newMax: number): ScalePosition {
    if (!position) {
      return {
        x: newMax / 2,
        y: newMax / 2,
        max: newMax
      };
    }

    const newX: number = (position.max ? (position.x / position.max) : 0) * newMax;
    const newY: number = (position.max ? (position.y / position.max) : 0) * newMax;

    return {
      x: newX,
      y: newY,
      max: newMax
    };
  }

  public getBoundaryRect(): DOMRect {
    const boundsElement: HTMLDivElement = document.getElementById("test-boundaries") as HTMLDivElement;

    if (!boundsElement) {
      return null;
    }

    return boundsElement.getBoundingClientRect() as DOMRect;
  }

  public getBoundaryMax(value: string = 'width'): number {
    const draggableElement: HTMLDivElement = document.getElementsByClassName('drag-bubble')[0] as HTMLDivElement;

    if (!this.testBoundaries || !draggableElement) {
      return 0;
    }

    const boundarySize: number = this.testBoundaries.nativeElement.getBoundingClientRect()[value];
    const itemSize: number = draggableElement.getBoundingClientRect()[value];

    return boundarySize - itemSize;
  }

  public getRandomPosition(horizontal?: boolean): ScalePosition {
    const boundaryMax: number = this.getBoundaryMax();

    return {
      x: Math.random() * boundaryMax,
      y: horizontal ? 0 : Math.random() * boundaryMax,
      max: boundaryMax
    };
  }
}
