import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { User } from "../../data-model/user.type";
import { GroupSettings, Organization } from "../../data-model/organization.type";
import { UserService } from "../../core/service/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "first-time-login",
  template: `
    <div
      class="slide-container"
      [ngStyle]="{
        width: (pages.length * 100) + '%',
        transform: 'translateX(-' + (100 / pages.length) * currentTab + '%)'
      }"
    >
      <div
        *ngFor="let page of pages"
        class="slide-page"
      >
        <ng-container
          *ngIf="page === 'general'"
        >
          <ng-container *ngIf="!currentUser?.privacyPolicyAccepted">
            <h2>{{'greeting-view.main-title' | translate}}</h2>
            <div>{{'greeting-view.fill-in-info' | translate}}</div>
          </ng-container>

          <h2 *ngIf="currentUser?.privacyPolicyAccepted">{{'greeting-view.settings-title' | translate}}</h2>

          <user-profile-card
            [userDoc]="currentUser"
            [zIndex]="1"
            [editable]="true"
          ></user-profile-card>

          <div class="form">
            <!-- Confirm name -->
            <div class="form-group">
              <div class="form-label">
                {{'participant-info-card.name' | translate}}
              </div>

              <input class="text-input" [(ngModel)]="currentUser.name" [placeholder]="'greeting-view.name-placeholder' | translate"
                     (ngModelChange)="checkInfo()">
            </div>

            <!-- Add description -->
            <div class="form-group">
              <div class="form-label">
                {{'participant-info-card.additional-info' | translate}}
              </div>

              <input class="text-input" [(ngModel)]="currentUser.additionalInfo" [placeholder]="'greeting-view.additional-info-placeholder' | translate"
                     (ngModelChange)="checkInfo()">
            </div>

            <!-- Select organization -->
            <div class="form-group" *ngIf="availableOrganizations?.length > 1">
              <div class="form-label">
                {{'greeting-view.select-sub-org' | translate}}
              </div>

              <select class="text-input" id="greeting_sub_organization"
                      [(ngModel)]="selectedOrganization"
                      (ngModelChange)="checkInfo(); updateList()"
                      [ngClass]="{'invalid missing-selection': !selectedOrganization}"
              >
                <option [selected]="!selectedOrganization" hidden [ngValue]="undefined">
                  {{'greeting-view.org-placeholder' | translate}}
                </option>

                <option
                  *ngFor="let subOrg of availableOrganizations | orderBy : ['name']"
                  [ngValue]="subOrg"
                  [selected]="selectedOrganization._id.toString() === subOrg._id.toString()"
                >
                  {{subOrg.name}}
                </option>
              </select>
            </div>

            <!-- Department -->
            <div class="form-group" *ngIf="selectedOrganization?.departments?.length">
              <div class="form-label">
                {{'greeting-view.select-department' | translate}}
              </div>

              <select class="text-input" id="greeting_department" [(ngModel)]="selectedDepartment"
                      (ngModelChange)="checkInfo()"
                      [ngClass]="{'invalid missing-selection': !currentUser.department && !selectedDepartment}">
                <option [selected]="!currentUser.department" [ngValue]="undefined" class="missing-selection">
                  {{('greeting-view.department-placeholder' | translate)}}
                </option>

                <option [selected]="currentUser.department" hidden [ngValue]="currentUser.department">
                  {{currentUser.department}}
                </option>

                <option
                  *ngFor="let department of selectedOrganization.departments | orderBy: ['name']"
                  [ngValue]="department"
                  [selected]="selectedDepartment === department.name"
                >
                  {{department.name}}
                </option>
              </select>
            </div>

            <!-- Location -->
            <div class="form-group" *ngIf="selectedOrganization?.locations?.length > 1">
              <div class="form-label">
                {{'greeting-view.select-location' | translate}}
              </div>

              <select class="text-input" id="greeting_location" [(ngModel)]="selectedLocation"
                      (ngModelChange)="checkInfo()"
                      [ngClass]="{'invalid missing-selection': !currentUser.location && !selectedLocation}">
                <option [selected]="true" hidden [ngValue]="undefined">
                  {{('greeting-view.location-placeholder' | translate)}}
                </option>

                <option [selected]="!currentUser.location" [ngValue]="undefined" class="missing-selection">
                  {{('greeting-view.department-placeholder' | translate)}}
                </option>

                <option [selected]="currentUser.location" hidden [ngValue]="currentUser.location">
                  {{currentUser.location}}
                </option>

                <option
                  *ngFor="let location of selectedOrganization.locations | orderBy: ['name']"
                  [ngValue]="location"
                  [selected]="selectedLocation === location.name"
                >
                  {{location.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="flex-column-centered" *ngIf="!currentUser?.privacyPolicyAccepted">
            <div>{{'greeting-view.updated-policy-text' | translate}}</div>

            <div class="flex-row-centered bold-text" [ngStyle]="{marginTop: '10px'}">
              <span class="custom-checkbox" [ngStyle]="{marginLeft: 0, marginRight: '10px', cursor: 'pointer'}"
                    (click)="togglePrivacyPolicy()">
                <span *ngIf="privacyPolicyAccepted" class="glyphicon glyphicon-ok"></span>
              </span>

              <div [ngStyle]="{margin: 0}">{{'greeting-view.privacy-policy-prompt' | translate}}</div>

              <a target="_blank" href="/static/legal/Privacy_policy_LOGE.pdf"
                 title="{{'player-progress-view.in-new-tab' | translate}}">
                <div [ngStyle]="{margin: 0, marginLeft: '5px'}">{{'greeting-view.privacy-policy-link' | translate}}</div>
              </a>

              <div [ngStyle]="{margin: 0, marginLeft: '5px'}">{{'greeting-view.privacy-policy-and' | translate}}</div>

              <a target="_blank" href="/static/legal/Terms_of_use_LOGE.pdf"
                 title="{{'player-progress-view.in-new-tab' | translate}}">
                <div [ngStyle]="{margin: 0, marginLeft: '5px'}">{{'greeting-view.terms-of-user-link' | translate}}</div>
              </a>
            </div>
          </div>

          <!-- Save changes and return -->
          <raised-css-button
            *ngIf="currentUser?.privacyPolicyAccepted"
            [disabled]="!validForm"
            [buttonText]="'greeting-view.save-changes-button'"
            (onClick)="saveForm(); exitPage()"
          ></raised-css-button>

          <!-- Save changes and continue to first-time-login mic test -->
          <raised-css-button
            *ngIf="!currentUser?.privacyPolicyAccepted"
            [disabled]="!validForm"
            [buttonText]="'greeting-view.next-button'"
            (onClick)="saveForm(); nextPage()"
          ></raised-css-button>
        </ng-container>

        <ng-container
          *ngIf="page === 'voice'"
        >
          <h2>{{'greeting-view.audio-chat-title' | translate}}</h2>
          <div [ngStyle]="{marginTop: '3vmin'}">{{'greeting-view.test-mic-text' | translate}}</div>

          <!--<audio-circle
            *ngIf="currentTab === 1"
            (successEmitter)="micTested()"
            [currentUser]="currentUser"
          ></audio-circle>-->

          <audio-device-test
            [currentUser]="currentUser"
          ></audio-device-test>

          <!--<div class="bold-text" *ngIf="micSuccess">{{'greeting-view.test-mic-success' | translate}}</div>
          <div *ngIf="!micSuccess">{{'greeting-view.test-mic-later' | translate}}</div>-->

          <raised-css-button
            [buttonText]="'greeting-view.next-button'"
            (onClick)="nextPage()"
          ></raised-css-button>
        </ng-container>

        <ng-container
          *ngIf="page === 'complete'"
        >
          <h2>{{'greeting-view.set-up-complete' | translate}}</h2>
          <div>{{'greeting-view.complete-text' | translate}}</div>

          <raised-css-button
            [buttonText]="'greeting-view.ready-button'"
            (onClick)="exitPage()"
          ></raised-css-button>
        </ng-container>
      </div>
    </div>
  `,
  styles: []
})
export class FirstTimeLoginComponent implements OnInit, OnChanges {
  @Input() currentUser: User;
  @Input() pages: ("general" | "voice" | "complete")[] = ["general", "voice", "complete"];

  @Input() availableOrganizations: Organization[];

  public currentTab: number = 0;

  public selectedOrganization: Organization;
  public selectedDepartment: GroupSettings;
  public selectedLocation: GroupSettings;
  public privacyPolicyAccepted: boolean;

  public validForm: boolean;

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser && this.currentUser && this.currentUser.privacyPolicyAccepted) {
      this.pages = ["general"];
    }

    if (changes.availableOrganizations || changes.currentUser) {
      this.updateList();
    }
  }

  public updateList(): void {
    if (!this.currentUser || !this.availableOrganizations || !this.availableOrganizations.length) {
      return;
    }

    // Set as accepted if previously accepted
    if (this.currentUser.privacyPolicyAccepted) {
      this.privacyPolicyAccepted = true;
    }

    // Check organization
    this.selectedOrganization = this.availableOrganizations.find((org: Organization) => {
      return org._id.toString() === this.currentUser.orgId.toString();
    });

    if (!this.selectedOrganization) {
      // If current one is not valid, select the first one
      this.selectedOrganization = this.availableOrganizations[0];
    }

    // Check department
    if (this.currentUser.department && this.selectedOrganization.departments?.length) {
      // Select the one the user has currently
      this.selectedDepartment = this.selectedOrganization.departments.find((department: GroupSettings) => {
        return department.name === this.currentUser.department;
      });

      // Allow current if not valid anymore
      if (!this.selectedDepartment) {
        this.selectedDepartment = {
          name: this.currentUser.department
        };
      }
    }

    // Check location
    if (this.currentUser.location && this.selectedOrganization.locations?.length) {
      // Select the one the user has currently
      this.selectedLocation = this.selectedOrganization.locations.find((location: GroupSettings) => {
        return location.name === this.currentUser.location;
      });

      // Allow current if not valid anymore
      if (!this.selectedLocation) {
        this.selectedLocation = {
          name: this.currentUser.department
        };
      }
    }

    this.checkInfo();
  }

  public setCurrent(tab: number): void {
    this.currentTab = tab;

  }

  public checkInfo(): void {
    if (this.selectedOrganization?.anonymizeNewUsers && this.privacyPolicyAccepted) {
      // Anonymized users can go without adding a name
      this.validForm = true;
      return;
    }

    if (!this.currentUser || !this.currentUser.name || !this.selectedOrganization || !this.privacyPolicyAccepted) {
      // Invalid user, organization, or policy not accepted
      this.validForm = false;
      return;
    }

    // No real need to force location/department to users
    this.validForm = true;

    /*let validLocation: boolean = true;
    let validDepartment: boolean = true;

    if (this.selectedOrganization.locations && this.selectedOrganization.locations.length) {
      // Invalid location
      validLocation = !!this.selectedLocation;
    }

    if (this.selectedOrganization.departments && this.selectedOrganization.departments.length) {
      // Invalid department
      validDepartment = !!this.selectedDepartment;
    }

    // Valid if either valid
    this.validForm = validLocation || validDepartment;*/
  }

  public saveForm(): void {
    if (!this.currentUser || !this.validForm) {
      // Not valid
      return;
    }

    // Save changes
    this.userService.saveUser(
      this.currentUser._id,
      {
        name: this.currentUser.name,
        additionalInfo: this.currentUser.additionalInfo,
        orgId: this.selectedOrganization ? this.selectedOrganization._id : this.currentUser.orgId,
        department: this.selectedDepartment ? this.selectedDepartment.name : null,
        location: this.selectedLocation ? this.selectedLocation.name : null,
        privacyPolicyAccepted: new Date()
      }
    );
  }

  public nextPage(): void {
    // Check that inputs are valid
    // Show confirmation if something is missing
    this.setCurrent(this.currentTab + 1);
  }

  public togglePrivacyPolicy(): void {
    this.privacyPolicyAccepted = !this.privacyPolicyAccepted;
    this.checkInfo();
  }

  public exitPage(): void {
    this.router.navigate(['/landing-page']);
  }
}
