/**
 * Created by TopSal on 6.7.2017.
 */

import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {UserService} from "app/core/service/user.service";
import {User} from "app/data-model/user.type";
import {GameTutorialService} from "../../core/service/game-tutorial.service";
import {MediaUtils} from "../../../lib/media-utils";
import {FileItem, FileUploader} from "ng2-file-upload";
import {environment} from "../../../environments/environment.prod";
import {Organization} from "../../data-model/organization.type";

@Component({
  selector: 'picture-upload',
  template: `
    <ng-container *ngIf="editable">
      <raised-css-button
        *ngIf="!uploaderType || uploaderType === 'button'"
        [buttonText]="'participant-info-card.profile-picture-edit-2'"
        (onClick)="fileInput.click()"
        [fontSize]="'2vmin'"
        [marginTop]="'0'"
        [marginBottom]="'0'"
      ></raised-css-button>

      <div *ngIf="uploaderType === 'input'" class="bordered-input" (click)="fileInput.click()" [ngStyle]="{cursor: 'pointer', textAlign: 'left'}">
        {{'participant-info-card.profile-picture-edit-2' | translate}}
      </div>

      <div *ngIf="uploaderType === 'overlay'" class="overlay-button" (click)="fileInput.click()"></div>
    </ng-container>


    <span class="hidden-uploader">
      <!--<input type="file" name="files" #fileInput ng2FileSelect [uploader]="uploader"/>-->
      <input type="file" #fileInput (change)="fileChangeEvent($event)"/>
    </span>

    <image-crop-modal
      *ngIf="showModal"
      [userDoc]="userDoc"
      [waitForSave]="waitForSave"
      [imageChangedEvent]="imageChangedEvent"
      (closeModal)="onCloseModal()"
      (onNewImage)="onNewCroppedImage($event)"
    ></image-crop-modal>
  `
})

export class ProfilePictureUploadComponent implements OnChanges {
  @Input() uploaderType: string;
  @Input() editable: boolean;
  @Input() showUpload: boolean;

  @Input() userDoc: User;
  @Input() organization: Organization;
  @Input() pictureType: string; // avatar, logo, background
  @Input() cropAllowed: boolean = true;

  @Output() hideUploadButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showModal: boolean;
  public waitForSave: boolean;
  public imageChangedEvent: Event;

  public uploadedImage: FileItem;
  public uploader: FileUploader = new FileUploader({
    url: environment.host + "api/media/upload/",
    itemAlias: "files"
  });

  constructor(public userService: UserService,
              private tutorialService: GameTutorialService) {
    this.uploader.onCompleteAll = () => {
      this.waitForSave = false;
      this.uploader.clearQueue();

      this.onUploadCompleted();
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      this.uploadedImage = fileItem;

      const fileName = this.uploadedImage.file.name;
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length);

      this.uploadedImage.file.name = this.pictureType + fileType;
      this.uploader.uploadAll();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organization && this.organization && (this.pictureType === "avatar" || this.pictureType === "avatar")) {
      this.uploader.options.url = environment.host + "api/media/upload/" + this.organization._id;
    }

    if (changes.userDoc && this.userDoc && this.pictureType === "avatar") {
      this.uploader.options.url = environment.host + "api/media/profile-image/" + this.userDoc._id;
    }
  }

  fileChangeEvent(event: Event): void {
    if (!event.target['files'] || !event.target['files'].length) {
      return;
    }

    const uploadedFile: File = event.target['files'][0];

    if (!MediaUtils.checkIsImage(uploadedFile.name)) {
      return;
    }

    if (!this.cropAllowed) {
      this.uploadImage(uploadedFile);
      return;
    }

    this.imageChangedEvent = event;
    this.showModal = true;
  }

  public onNewCroppedImage(newFile: File): void {
    this.waitForSave = true;
    this.uploader.addToQueue([newFile]);
  }

  public uploadImage(newFile: File): void {
    this.uploader.addToQueue([newFile]);
  }

  public onCloseModal(): void {
    this.imageChangedEvent = null;
    this.showModal = false;
  }

  public onUploadCompleted() {
    this.onCloseModal();
    this.imageUpdated.emit(true);

    // Check tutorial assignment completion if changing avatar
    if (!this.userDoc || this.pictureType !== "avatar") {
      return;
    }

    const newImagePath: string = MediaUtils.getAvatar(this.userDoc);

    this.tutorialService.setIntroTutorialAsCompleted('update-profile', this.userDoc);

    if (newImagePath) {
      this.userService.saveUser(
        this.userDoc._id,
        {
          imagePath: newImagePath
        });
    }
  }
}
