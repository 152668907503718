import { Injectable }    from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router
} from '@angular/router';

import {UserService} from "../app/core/service/user.service";

@Injectable()
export class NoLoginGuard implements CanActivate {
  constructor(private userService: UserService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn: boolean = !!this.userService.currentUser$.value;

    if (isLoggedIn) {
      this.router.navigate(['/landing-page']);
    }

    return !isLoggedIn;
  }
}
