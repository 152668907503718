import { Component, OnDestroy, OnInit } from "@angular/core";
import { ConfirmMessage, CustomConfirmService } from "../../core/service/custom-confirm.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { EmailEditorService } from "../../core/service/email-editor.service";

@Component({
  selector: "custom-confirm",
  template: `
    <div class="overlay animated short" *ngIf="currentMessage" (click)="handleClick()"
         [ngClass]="{fadeIn: currentMessage && !showFadeAnimation, fadeOut: showFadeAnimation}"
    >
      <div class="confirm-container animated short"
           (click)="$event.stopPropagation()"
           [ngClass]="{fadeInUpSmall: currentMessage && !showFadeAnimation, fadeOutUpSmall: showFadeAnimation}"
      >
        <div class="confirm-title">
          {{currentMessage.title | translate : currentMessage.textParams}}
        </div>

        <div class="confirm-text" *ngIf="currentMessage.text" [ngStyle]="{width: '80%', marginTop: '10px'}">
          {{currentMessage.text | translate : currentMessage.textParams}}
        </div>

        <div class="flex-column-centered confirm-links" [ngStyle]="{marginTop: '10px'}" *ngIf="currentMessage.links?.length">
          <a target="_blank"
             *ngFor="let link of currentMessage.links"
             [href]="link.link">
            {{link.text | translate}}
          </a>
        </div>

        <div class="flex-row-centered" [ngStyle]="{marginTop: '10px'}">
          <raised-css-button
            *ngFor="let button of currentMessage.buttons"
            [ngStyle]="{margin: '0 10px'}"
            [buttonText]="button.text | translate"
            (onClick)="handleClick(button.action)"
          ></raised-css-button>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class CustomConfirmComponent extends OnDestroyMixin implements OnInit {
  public currentMessage: ConfirmMessage;
  public showFadeAnimation: boolean;

  constructor(
    private customConfirmService: CustomConfirmService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.customConfirmService.currentConfirm$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe((current: ConfirmMessage) => {
        this.currentMessage = current;
      });
  }

  public handleClick(action?: string): void {
    this.showFadeAnimation = true;

    setTimeout(() => {
      this.showFadeAnimation = false;
      this.customConfirmService.unsetConfirm();
    }, 300);

    if (action) {
      this.customConfirmService.setConfirmation(this.currentMessage.identifier, action);
    }
  }
}
