import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import {OrganizationService} from "app/core/service/organization.service";
import {takeUntil} from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import {MediaUtils} from "../../../lib/media-utils";
import {Content} from "../../data-model/content.type";

@Component({
  selector: 'logo-badge',
  template: `
    <div *ngIf="floating" class="floating-logo-badge">
      <img customDefault [src]="logoUrl" alt="LOGE" />
    </div>

    <img customDefault *ngIf="!floating" [src]="logoUrl" alt="LOGE" />
  `
})

export class LogoBadgeComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() content: Content;
  @Input() floating: boolean;

  public defaultLogo: string = "/assets/image/logo.png";
  public logoUrl: string = this.defaultLogo;

  constructor(private orgService: OrganizationService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content && this.content) {
      this.logoUrl = MediaUtils.getOrgFile(this.content.logoUrl, this.content.orgId);
    }
  }

  ngOnInit(): void {
    if (this.content) {
      // Got content from Input
      return;
    }

    this.orgService.currentOrgContent$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe(
        (content: Content) => {
          if (!content || !content.logoUrl) {
            this.logoUrl = this.defaultLogo;
            return;
          }

          this.logoUrl = MediaUtils.getOrgFile(content.logoUrl, content.orgId);
        }
      );
  }
}
