/**
 * Created by NordicEdu on 21.11.2016.
 */

import {Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit} from "@angular/core";
import {SessionEvaluationQuestion} from "../../data-model/content.type";
import {MeasureQuestion} from "../../data-model/measure-question.interface";

@Component({
  selector: 'scale-rating',
  template: `
    <div class="ratingbar" *ngIf="ratingsArray">
      <div class="rate-symbol" *ngFor="let number of ratingsArray" [class.full]="rating >= number"
           (click)="rate(number)"></div>
    </div>
  `
})

export class ScaleRatingComponent implements OnChanges, OnInit {
  @Input() question: MeasureQuestion;
  @Input() disabled: boolean;
  @Input() rating: number = 0;

  @Output() onRated: EventEmitter<number> = new EventEmitter<number>();

  public ratingsArray: number[];

  public rate(value: number) {
    if (this.disabled) {
      return;
    }

    this.rating = value;
    this.onRated.emit(this.rating);
  }

  ngOnInit(): void {
    if (!this.ratingsArray) {
      // Get default 5 thumbs
      this.ratingsArray = new Array(5)
        .fill(0)
        .map((start, index) => {
          return index + 1;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['question'] && this.question) {
      this.ratingsArray = new Array(this.question.scale ? this.question.scale : 5)
        .fill(0)
        .map((start, index) => {
          return index + 1;
        });
    }
  }

}
