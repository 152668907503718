import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {User} from "../../data-model/user.type";
import {MediaUtils} from "../../../lib/media-utils";
import {filter, first} from "rxjs/operators";
import {UserService} from "../../core/service/user.service";
import {Player} from "../../data-model/player.type";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

export interface UserProfileInterface {
  _id: string;
  orgId: string;
  name: string;
  department: string;
  location: string;
  additionalInfo: string;
  imagePath: string;
}

@Component({
  selector: 'user-profile-card',
  template: `
    <div class="profile-card-container" (mouseenter)="showInfo = true" (mouseleave)="showInfo = false">
      <div class="profile-header">
        <div class="change-image-button">
          <img *ngIf="profileImage" class="profile-image"
               [src]="profileImage" [alt]="" [ngStyle]="{zIndex: zIndex + 2}"
               [ngClass]="{'small': cardSize === 'small', 'extra-small': cardSize === 'extra-small',
             'light': cardColor === 'light', 'extra-dark': cardColor === 'extra-dark', 'no-shadow': noShadow,
             'is-online': (showStatus || showOnline) && playerDoc?.status?.online, 'hide-border': hideBorder}"
          >

          <img *ngIf="!profileImage && userDoc?.imagePath" class="profile-image"
               [src]="getActualFilePath(userDoc?.imagePath, userDoc?.orgId) + '?' + imageIdentifier"
               [alt]="userInitials || '?'" [ngStyle]="{zIndex: zIndex + 2}"
               [ngClass]="{'small': cardSize === 'small', 'extra-small': cardSize === 'extra-small',
             'light': cardColor === 'light', 'extra-dark': cardColor === 'extra-dark', 'no-shadow': noShadow,
             'is-online': (showStatus || showOnline) && playerDoc?.status?.online, 'hide-border': hideBorder}"
          >

          <div *ngIf="!profileImage && !userDoc?.imagePath" class="profile-image unselectable" [ngStyle]="{zIndex: zIndex + 2}"
               [ngClass]="{'small': cardSize === 'small', 'extra-small': cardSize === 'extra-small', 'no-shadow': noShadow,
             'light': cardColor === 'light', 'is-online': (showStatus || showOnline) && playerDoc?.status?.online, 'hide-border': hideBorder}">
            <div class="initials">
              {{userInitials || '?'}}
            </div>
          </div>

          <picture-upload
            *ngIf="editable"
            [pictureType]="'avatar'"
            [uploaderType]="'overlay'"
            [userDoc]="userDoc"
            [editable]="true"
            (imageUpdated)="updateImageIdentifier()"
          ></picture-upload>
        </div>

        <div class="profile-name"
             *ngIf="!hideName"
             [ngStyle]="{zIndex: zIndex + 1}" [ngClass]="{'small': cardSize === 'small', 'extra-small': cardSize === 'extra-small',
             'chairman': chairmanCard, 'light': cardColor === 'light'}">
          {{userDoc?.name || ('participant-info-card.name-placeholder' | translate)}}
        </div>

        <ng-container *ngIf="playerDoc && showStatus && playerDoc?.status?.online">
          <div class="profile-status-icon" [ngStyle]="{zIndex: zIndex + 3}" *ngIf="playerDoc?.status.ready">
            <span class="glyphicon glyphicon-ok"></span>
          </div>

          <div class="profile-status-icon bottom-icon active-animation" [ngStyle]="{zIndex: zIndex + 3}" *ngIf="playerDoc?.status?.speaking">
            <span class="icon-audio-loud"></span>
          </div>

          <div class="profile-status-icon bottom-icon" [ngStyle]="{zIndex: zIndex + 3}" *ngIf="!playerDoc?.status?.micEnabled">
            <span class="icon-mic-off"></span>
          </div>

          <div class="profile-status-icon bottom-icon active-animation" [ngStyle]="{zIndex: zIndex + 3}" *ngIf="playerDoc?.status?.writing && !playerDoc?.status?.speaking">
            <span class="icon-writing"></span>
          </div>
        </ng-container>
      </div>

      <div class="profile-content-container" *ngIf="!disableHover"
           [ngClass]="{'visible': showInfo, 'small': cardSize === 'small', 'extra-small': cardSize === 'extra-small', 'light': cardColor === 'light'}" [ngStyle]="{zIndex: zIndex}"
           (click)="onContentClick()">
        <div class="profile-content">
          <ng-container *ngIf="userDoc?.department">
            <label>{{'participant-info-card.department' | translate}}</label>
            <div>{{userDoc.department}}</div>
          </ng-container>

          <ng-container *ngIf="userDoc?.location">
            <label>{{'participant-info-card.location' | translate}}</label>
            <div>{{userDoc.location}}</div>
          </ng-container>

          <ng-container *ngIf="userDoc?.additionalInfo">
            <label>{{'participant-info-card.additional-info' | translate}}</label>
            <div>{{userDoc.additionalInfo}}</div>
          </ng-container>

          <label *ngIf="!userDoc?.department && !userDoc?.location && !userDoc?.additionalInfo">
            <label>{{'participant-info-card.no-information' | translate}}</label>
          </label>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class UserProfileCardComponent implements OnChanges {
  @Input() userDoc: User | UserProfileInterface;
  @Input() playerDoc: Player;
  @Input() userId: string;
  @Input() customImage: string;
  public profileImage: SafeResourceUrl;

  @Input() zIndex: number = 0;
  @Input() cardSize: string;
  @Input() cardColor: string;
  @Input() chairmanCard: boolean;
  @Input() disableHover: boolean;
  @Input() hideName: boolean;
  @Input() noShadow: boolean;
  @Input() hideBorder: boolean;

  public baseIndex: number = 0;
  public userInitials: string;
  public imageIdentifier: number = 0;
  public showInfo: boolean;

  @Input() showStatus: boolean;
  @Input() showOnline: boolean;
  @Input() hideWritingStatus: boolean;
  @Input() editable: boolean;

  constructor(private userService: UserService,
              private sanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customImage) {
      this.profileImage = this.customImage ? this.sanitizer.bypassSecurityTrustResourceUrl(this.customImage) : null;
    }

    if (changes.userDoc && this.userDoc) {
      this.updateImageIdentifier();
      this.userInitials = User.getInitials(this.userDoc.name);
    }

    if (changes.playerDoc && this.playerDoc && this.playerDoc.user) {
      this.playerDoc.user
        .pipe(
          filter(userDoc => !!userDoc)
        )
        .subscribe((userDoc: User) => {
          this.userDoc = userDoc;

          this.updateImageIdentifier();
          this.userInitials = User.getInitials(this.userDoc.name);
        });
    }

    if (changes.userId && this.userId) {
      this.userService.getUser(this.userId)
        .pipe(
          filter(userDoc => !!userDoc),
          first()
        )
        .subscribe(
          (userDoc: User) => {
            this.userDoc = userDoc;

            this.updateImageIdentifier();
            this.userInitials = User.getInitials(userDoc.name);
          }
        );
    }
  }

  public getActualFilePath(fileName: string, orgId: string): string {
    return MediaUtils.getOrgFile(fileName, orgId);
  }

  /**
   * Set random identifier to force image cache to refresh on change
   */
  public updateImageIdentifier() {
    this.imageIdentifier = Math.random() * Math.random() * Math.random();
  }

  public onContentClick(): void {
    this.showInfo = false;
  }
}
