/**
 * Created by janne on 28.9.2016.
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {GameSessionLog} from "../../data-model/game-session-log.type";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {GroupScoreTheme} from "../../data-model/GroupScoreTheme.interface";
import {SeasonData} from "../../progress-report/progress-report.component";
import { GameSeason } from "../../data-model/game-season.type";

export interface CombinedSeasonData {
  validSeasons: GameSeason[];
  seasonData: SeasonData[];
}

@Injectable({
  providedIn: 'root'
})
export class GameSessionLogService {

  public gameSessionLog$ = new BehaviorSubject<GameSessionLog[]>(null);
  public orgGameSessionLog$ = new BehaviorSubject<GameSessionLog[]>(null);
  public orgAverageGameTime$ = new BehaviorSubject<GameSessionLog[]>(null);

  private getOrglistUrl: string = '/api/game-session-log/list-by-organization/';
  private getOrgGameTimeURL: string = '/api/game-session-log/average-game-time-by-organization/';
  private getGuestOrgGameTimeURL: string = '/api/guests/average-game-time-by-organization/';
  private getReportDataUrl: string = '/api/progress-report/';

  constructor(private http: HttpClient) {
  }

  /**
   * Fetch logs by organization
   * @param {string} orgId
   * @param {boolean} getAll - On default, only get logs from last month
   */
  public fetchByOrganization(orgId: string, getAll: boolean = false): void {
    this.http.get(this.getOrglistUrl + orgId + "/" + getAll)
      .subscribe(
        res => {
          this.orgGameSessionLog$.next(res as GameSessionLog[]);
        },
        err => {
          console.error("Fetching logs failed", err);
          this.orgGameSessionLog$.next(null);
        }
      );
  }

  public updateLogSettings(logId: string, changeSet: Object): void {
    this.http.put('/api/game-session-log/settings/' + logId, changeSet)
      .subscribe(
        res => res,
        err => {
          console.error("Failed saving sessionLog settings", err);
        }
      );
  }

  /**
   * Fetch game times by organization
   * @param {Object} query
   * @param {Object} filters
   */
  public fetchGameTimesByOrganization(query: Object, filters?: Object): void {
    const body = {
      query: query,
      filters: filters // Not used currently
    };

    this.http.put(this.getOrgGameTimeURL, body)
      .subscribe(
        res => {
          this.orgAverageGameTime$.next(res as GameSessionLog[]);
        },
        err => {
          console.error("Fetching game times failed", err);
          this.orgAverageGameTime$.next(null);
        }
      );
  }

  /**
   * Fetch game times by organization for guests
   * @param {Object} query
   * @param {Object} filters
   */
  public fetchGuestGameTimesByOrganization(pw: string, query: Object, filters?: Object): void {
    const body = {
      query: query,
      filters: filters // Not used currently
    };

    this.http.put(this.getGuestOrgGameTimeURL + pw, body)
      .subscribe(
        res => {
          this.orgAverageGameTime$.next(res as GameSessionLog[]);
        },
        err => {
          console.error("Fetching game times failed", err);
          this.orgAverageGameTime$.next(null);
        }
      );
  }

  public getSessionLogs(query: Object, select?: string): Observable<GameSessionLog[]> {
    const body = {
      query: query,
      select: select
    };

    return this.http.put('/api/game-session-log/user-sessions/', body)
      .pipe(
        map(res => {
          return res as GameSessionLog[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getUserSessionLogs(): Observable<GameSessionLog[]> {
    return this.http.get('/api/game-session-log/current-user-sessions/')
      .pipe(
        map(res => {
          return res as GameSessionLog[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getStatistics(orgId: string, limit: number = 100, skip: number = 0): Observable<GameSessionLog[]> {
    return this.http.get('/api/game-session-log/statistics/' + orgId + "/" + limit + "/" + skip)
      .pipe(
        map(res => {
          return res as GameSessionLog[];
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getReportData(reportDataName: string, orgId: string, body?: any): Observable<any> {
    return this.http.put(this.getReportDataUrl + reportDataName + '/' + orgId, body)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /*public getCombinedSeasonData(orgId: string, seasonNames: string[]): Observable<SeasonData> {
    return this.http.put<SeasonData>("/api/progress-report/combined-season-data/", {orgId: orgId, seasonNames: seasonNames})
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }*/

  public getTopScores(query: any): Observable<GroupScoreTheme[]> {
    return this.http.put('/api/progress-report/top-scores/' + query.orgId, query)
      .pipe(
        map((res: GroupScoreTheme[]) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public checkMissingFeedback(): Observable<any> {
    return this.http.get('/api/game-session-log/check-missing-feedback/')
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
