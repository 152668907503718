/**
 * Created by janne on 28.9.2016.
 */

import {throwError, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {GameTimeslot} from "../../data-model/timeslot.type";
import {User} from "../../data-model/user.type";
import {HttpClient} from "@angular/common/http";
import {GameSessionService} from "app/core/service/game-session.service";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GameTimeslotService {

  public gameTimeslots$: BehaviorSubject<GameTimeslot[]> = new BehaviorSubject<GameTimeslot[]>(null);
  public currentUserGameTimeslots$: BehaviorSubject<GameTimeslot[]> = new BehaviorSubject<GameTimeslot[]>(null);
  public currentUserJoinedGameTimeslots$: BehaviorSubject<GameTimeslot[]> = new BehaviorSubject<GameTimeslot[]>(null);

  constructor(private http: HttpClient,
              private gameSessionService: GameSessionService) {
  }

  /**
   * Update list of users in timeSlot
   */
  public updateCurrentUserList(): void {
    this.http.get('/api/game-timeslot/list-user-org')
      .subscribe(
        res => {
          this.currentUserGameTimeslots$.next(res as GameTimeslot[]);
        },
        err => {
          console.error("Fetching current GameTimeslot listing failed", err);
          this.currentUserGameTimeslots$.next(null);
        }
      );
  }


  /**
   * Update list of users in timeSlot
   */
  public updateCurrentUserJoinedList(): void {
    this.http.get('/api/game-timeslot/current-user-joined/')
      .subscribe(
        res => {
          this.currentUserJoinedGameTimeslots$.next(res as GameTimeslot[]);
        },
        err => {
          console.error("Fetching current GameTimeslot listing failed", err);
          this.currentUserJoinedGameTimeslots$.next(null);
        }
      );
  }

  /**
   * Join timeSlot
   * @param {GameTimeslot} timeSlot
   */
  public join(timeSlot: GameTimeslot): Observable<GameTimeslot> {
    return this.http.post(`/api/game-timeslot/join`, {
      timeSlotId: timeSlot._id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
      .pipe(
        map(res => {
          return res as GameTimeslot;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Leave timeSlot
   * @param {string} timeSlotId
   */
  public leave(timeSlotId: string): Observable<GameTimeslot> {
    return this.http.post(`/api/game-timeslot/leave`, {
      _id: timeSlotId
    })
      .pipe(
        map(res => {
          return res as GameTimeslot;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Admin functions

  /**
   * Get changes to all organization timeSlots
   * @param {string} orgId
   */
  public updateOrgTimeSlotList(orgId: string): void {
    this.http.get('/api/game-timeslot/list/' + orgId)
      .subscribe(
        res => {
          this.gameTimeslots$.next(res as GameTimeslot[]);
        },
        err => {
          console.error("Fetching current GameTimeslot listing failed", err);
          this.gameTimeslots$.next(null);
        }
      );
  }

  /**
   * Add user to timeSlot
   * @param {string} timeslotId
   * @param {User} user
   * @param {boolean} sendEmail
   */
  public addToTimeslot(timeslotId: string, user: User, sendEmail: boolean): Observable<GameTimeslot> {
    const query = {
      timeslotId: timeslotId,
      userId: user._id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sendEmail: sendEmail
    };

    return this.http.post(`/api/game-timeslot/add-to`, query)
      .pipe(
        map(res => {
          return res as GameTimeslot;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Remove user from timeSlot
   * @param {string} timeslotId
   * @param {User} user
   */
  public removeFromTimeslot(timeslotId: string, user: User): Observable<GameTimeslot> {
    const query = {
      timeslotId: timeslotId,
      userId: user._id
    };

    return this.http.post(`/api/game-timeslot/remove-from`, query)
      .pipe(
        map(res => {
          return res as GameTimeslot;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Force start game session
   * @param {GameTimeslot} timeslot
   * @param {(res: Response) => void} resultCB
   */
  public startSession(timeslot: GameTimeslot): Observable<GameTimeslot> {
    return this.http.post<GameTimeslot>(`/api/game-timeslot/start/` + timeslot._id, {})
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Modify timeSlot skypeRoom
   * @param {GameTimeslot} timeSlot
   */
  public updateTimeSlotSkype(timeSlot: GameTimeslot): void {
    this.http.post(`/api/game-timeslot/updateSkypeRoom/` + timeSlot._id, timeSlot)
      .subscribe(
        res => {
          this.updateOrgTimeSlotList(timeSlot.orgId);
        },
        err => {
          console.error("Failed to update skypeRoom", err);
        }
      );
  }

  /**
   * Modify timeSlot beginTime
   * @param {GameTimeslot} timeSlot
   */
  public updateTimeSlotTime(timeSlot: GameTimeslot): void {
    this.http.post(`/api/game-timeslot/updateTime/` + timeSlot._id, timeSlot)
      .subscribe(
        res => {
          this.updateOrgTimeSlotList(timeSlot.orgId);
        },
        err => {
          console.error("Failed to update timeSlot time", err);
        }
      );
  }

  /**
   * Remove timeSlot
   * @param {GameTimeslot} timeSlot
   */
  public deleteTimeslot(timeSlot: GameTimeslot): void {
    this.http.delete('/api/game-timeslot/delete/' + timeSlot._id)
      .subscribe(
        res => {
          this.updateOrgTimeSlotList(timeSlot.orgId);
        },
        err => {
          console.error("Failed to remove timeSlot", err);
        }
      );
  }

  /**
   * Fetch TimeSlots with given UserID as a player
   * @param {string} userId
   * @returns {Observable<GameTimeslot[]>}
   */
  public fetchUserTimeslots(userId: string): Observable<GameTimeslot[]> {
    return this.http.get('/api/game-timeslot/user-timeslots/' + userId)
      .pipe(
        map(res => {
          return res as GameTimeslot[];
        }),
        catchError(err => {
          console.error("Failed to get notes for user", err);
          return [];
        })
      );
  }

  /**
   * Add user as spectator to TimeSlot
   * @param {string} timeslotId
   * @param {User} user
   */
  public addSpectator(timeslotId: string, user: User): void {
    this.http.post(`/api/game-timeslot/add-spectator`, {
      timeslotId: timeslotId,
      userId: user._id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
      .subscribe(
        res => {
          this.updateOrgTimeSlotList(user.orgId);
        },
        err => {
          console.error("Failed to add spectator", err);
        }
      );
  }

  /**
   * Remove user from TimeSlot spectators
   * @param {string} timeslotId
   * @param {User} user
   */
  public removeSpectator(timeslotId: string, user: User): void {
    this.http.post(`/api/game-timeslot/remove-spectator`, {
      timeslotId: timeslotId,
      userId: user._id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
      .subscribe(
        res => {
          this.updateOrgTimeSlotList(user.orgId);
        },
        err => {
          console.error("Failed to remove spectator", err);
        }
      );
  }

  public duplicateTimeSlot(timeSlot: GameTimeslot, clone: boolean, forced?: boolean): Observable<GameTimeslot> {
    const body = {
      timeSlot: timeSlot,
      force: forced,
      asClone: clone
    };

    return this.http.post('api/game-timeslot/clone-time-slot', body)
      .pipe(
        map(res => {
          return res as GameTimeslot;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
