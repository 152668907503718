/**
 * Created by Nedu on 7.7.2017.
 */

import { throwError, BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Media } from "../../data-model/media.interface";
import { Tag } from "../../data-model/tag.interface";

@Injectable({
  providedIn: "root"
})
export class MediaService {

  public mediaList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public orgMediaList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public orgMediaTagList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient) {
  }

  public static TagsAsStrings(tags: Tag[]): string[] {
    const tagStrings = tags.map(tag => {
      return tag.tagName;
    });
    const retStrings = [];
    for (const val of tagStrings) {
      if (!retStrings.includes(val)) {
        retStrings.push(val);
      }
    }
    return retStrings;
  }

  /**
   * Get list of all media
   */
  public fetchMedia() {
    this.http.get("/api/media/list")
      .toPromise()
      .then(res => {
        this.mediaList$.next(res);
      })
      .catch(res => {
        this.mediaList$.next([]); // null
      });
  }

  /**
   * Get media list for organization
   * @param {string} orgId
   */
  public fetchOrgMedia(orgId: string) {
    this.http.get("/api/media/list/" + orgId)
      .toPromise()
      .then(res => {
        this.orgMediaList$.next(res);
      })
      .catch(res => {
        this.orgMediaList$.next([]); // null
      });
  }

  public deleteMedia(media: Media) {
    this.http.delete("api/media/delete/" + media._id)
      .toPromise()
      .then(() => {
        console.log("Delete done");
        if (media.orgId) {
          this.fetchOrgMedia(media.orgId);
        } else {
          this.fetchMedia();
        }
      })
      .catch(() => {
        // No changes
      });
  }

  /**
   * Read txt file contents (for change log component)
   * @param {string} path
   * @returns {Observable<string>}
   */
  public readTextFile(path: string): Observable<string> {
    return this.http.get(path, { responseType: "text" })
      .pipe(
        map(res => {
          return res as string;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  /**
   * Get media tag list for organization
   * @param {string} orgId
   */
  public fetchOrgTags(orgId: string) {
    this.http.get("/api/media/tag-list/" + orgId)
      .toPromise()
      .then(res => {
        this.orgMediaTagList$.next(res);
      })
      .catch(res => {
        this.orgMediaTagList$.next([]); // null
      });
  }

  public updateMediaData(newMediaData: Media): void {

    const body = {
      mediaId: newMediaData._id,
      orgId: newMediaData.orgId,
      tags: newMediaData.tags,
      description: newMediaData.description
    };

    this.http.post("/api/media/update-media-filter-data/", body)
      .toPromise()
      .then(res => {

        if (res == null) {
          console.error("Media update returned no result media.");
          return;
        }

        this.fetchOrgTags(newMediaData.orgId);

      })
      .catch(res => {
        this.orgMediaTagList$.next([]); // null
      });
  }

}
