import {Directive, Input, HostListener, ElementRef, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: 'img[customDefault]'
})

export class ImagePreloadDirective {
  @Input() defaultImage: string = "/assets/image/logo_transparent.png";

  constructor(
    private element: ElementRef
  ) {}

  @HostListener('error') setDefaultImage() {
    this.element.nativeElement.src = this.defaultImage;
  }
}
