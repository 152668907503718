import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'iFrameSanitation'
})
export class IFrameSanitationPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(iFrame: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(iFrame);
  }

}
