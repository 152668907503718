/**
 * Created by TopSal on 6.7.2017.
 */

import {
  Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges,
  ChangeDetectorRef, ViewChildren
} from "@angular/core";
import { FileItem, FileUploader } from "ng2-file-upload";
import { MediaService } from "app/core/service/media.service";
import { environment } from "../../../environments/environment";
import { Media } from "app/data-model/media.interface";
import { UserService } from "app/core/service/user.service";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { MediaUtils } from "../../../lib/media-utils";
import { Tag } from "app/data-model/tag.interface";
import { MediaExpansionPanelComponent } from "./media-expansion-panel.component";

@Component({
  selector: "file-selector-modal",
  template: `
    <div *ngIf="visible" class="fixed-backdrop">
      <div class="file-selector-container">
        <button (click)="hideSelector()" class="pop-up-close-button">
          <span class="glyphicon glyphicon-remove"></span>
        </button>

        <!-- UPLOAD SECTION -->

        <h3>
          Upload new files
        </h3>

        <div class="file-list content-border" *ngIf="uploader">
          <div *ngIf="uploadError" class="flex-container-fill">
            <h3 [ngStyle]="{'color': 'red'}">{{uploadError}}</h3>
          </div>

          <div class="flex-container-fill">
            <raised-css-button
              [buttonText]="'Choose files'"
              (click)="hiddenFileInput.click()"
              [fontSize]="'2vmin'"
              [marginTop]="'0'"
              [marginBottom]="'1.5vh'"
            ></raised-css-button>
            <h3 *ngIf="uploader?.queue?.length" [ngStyle]="{'color':'black', 'margin-left':'10px'}">{{'file-selector.instruction' | translate}}</h3>

            <span class="hidden-uploader">
              <input #hiddenFileInput type="file" name="files" ng2FileSelect [uploader]="uploader" multiple>
            </span>
          </div>

          <ng-scrollbar class="loge-scrollbar dark">
            <div *ngIf="statusMessage && !uploader?.queue?.length" class="flex-container-fill">
              <h4>{{statusMessage}}</h4>
            </div>
            <!--  one-line -->
            <div class="files-scrollbar-container">
              <div class="file-list">
                <media-expansion-panel *ngFor="let media of queueAsMediaList; let mediaIndex = index"
                                       [media]="media"
                                       [dynamicId]="'queueMedia' + mediaIndex"
                                       [(tagOptions)]="tagListStrings"
                                       [isUploadMedia]=true
                                       (onRemove)="$event.stopPropagation(); deleteMedia(media)"
                >
                </media-expansion-panel>

              </div>
            </div>
          </ng-scrollbar>

          <div class="flex-container-fill upload-buttons">
            <label *ngIf="orgId && userService.isCurrentUserSystemAdmin()" class="checkbox-inline grow-self">
              <input type="checkbox" (change)="togglePublic()">
              File visible to all organizations
            </label>

            <label *ngIf="!orgId" class="grow-self">
              (All test files will be saved as public)
            </label>

            <raised-css-button
              [buttonText]="uploader.queue.length > 1 ? 'Upload files' : 'Upload file'"
              [disabled]="!uploader.getNotUploadedItems().length || !!uploadError"
              (click)="uploader.uploadAll()"
              [fontSize]="'2vh'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>

            <raised-css-button
              [ngStyle]="{'margin-left': '0.5vmin'}"
              [buttonText]="'Clear'"
              [disabled]="!uploader.queue.length"
              (click)="uploader.clearQueue(); statusMessage = 'File queue cleared'"
              [fontSize]="'2vh'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>
          </div>
        </div>


        <!-- FILE SELECT SECTION -->

        <h3>
          Select an existing file
        </h3>

        <div [ngStyle]="{ 'width':'100%', 'display': 'flex', 'flex-direction': 'row' }">
          <span class="glyphicon glyphicon-search pull-left" id="search-icon" aria-hidden="true"></span>
          <div [ngStyle]="{ 'width':'90%'}">
            <tag-selector
              [optionList]="tagListStrings"
              [(tagList)]="filterTagsList"
              [inputPlaceholder]="'Choose a tag from list'"
              [inputLabel]="'file-selector.filter-tags-placeholder' | translate"
              [allowMultiple]=false
              [allowNew]=false
              (tagListChange)="filterAllowedMedia()"
              [ngStyle]="{
                'max-width': '50%'
                }"
            ></tag-selector>

            <input matInput
                   [placeholder]="'file-selector.filter-description-placeholder' | translate"
                   [(ngModel)]="descriptionFilter"
                   (ngModelChange)="filterAllowedMedia()"
                   [ngStyle]="{
                'max-width': '50%'
                }"
            />
          </div>
        </div>

        <div class="content-border" [ngStyle]="{'min-height': '200px'}">
          <ng-scrollbar class="loge-scrollbar dark" [ngStyle]="{'min-height': '200px'}">
            <div class="files-scrollbar-container" [ngStyle]="{'min-height': '200px'}">
              <ng-container *ngIf="orgId && filteredOrgMediaList?.length">
                <div id="selection-header">
                  <h4>Organization files</h4>
                  <mat-slide-toggle [(ngModel)]="modifyOn" (change)="onModifyChange()">Modify</mat-slide-toggle>
                </div>

                <div class="file-list">
                  <media-expansion-panel *ngFor="let media of filteredOrgMediaList; let mediaIndex = index"
                                         [media]="media"
                                         [dynamicId]="'orgMedia' + mediaIndex"
                                         [(tagOptions)]="tagListStrings"
                                         [isUploadMedia]=false
                                         (alternateOnPictureClick)="handleMediaChoiceClick(media, $event)"
                                         (onRemove)="$event.stopPropagation(); deleteMedia(media)"
                  >
                  </media-expansion-panel>

                </div>
              </ng-container>

              <ng-container *ngIf="filteredMediaList?.length">
                <h4>Public files</h4>

                <div class="file-list">
                  <media-expansion-panel *ngFor="let media of filteredMediaList; let mediaIndex = index"
                                         [media]="media"
                                         [dynamicId]="'pubMedia' + mediaIndex"
                                         [(tagOptions)]="tagListStrings"
                                         [isUploadMedia]=false
                                         (alternateOnPictureClick)="handleMediaChoiceClick(media, $event)"
                                         (onRemove)="$event.stopPropagation(); deleteMedia(media)"
                  >
                  </media-expansion-panel>

                </div>
              </ng-container>
            </div>
          </ng-scrollbar>
        </div>


        <!-- EXTERNAL LINK -->

        <h3>
          External file
        </h3>

        <div class="content-border flex-container-fill">
          <div class="flex-container" [ngStyle]="{'flex-grow': 1, 'align-items': 'center'}">
            <h3 [ngStyle]="{margin: 0, width: '8vw'}">Link</h3>
            <input class="form-control" [ngStyle]="{'float': 'unset', 'margin-right': '1vh', height: '4vh'}" [(ngModel)]="linkPath"/>

            <raised-css-button
              [buttonText]="'Select'"
              (click)="selectLink(linkPath)"
              [fontSize]="'2vh'"
              [marginTop]="'0'"
              [marginBottom]="'0'"
            ></raised-css-button>
          </div>

          <div class="image-preview" *ngIf="checkIsImage(linkPath)">
            <img customDefault [src]="linkPath" alt="">
          </div>

          <raised-css-button
            [ngStyle]="{'margin-left': '5vw'}"
            [buttonText]="'Clear file selection'"
            (click)="select(null)"
            [fontSize]="'2vh'"
            [marginTop]="'0'"
            [marginBottom]="'0'"
          ></raised-css-button>
        </div>
      </div>
    </div>
  `
})
export class FileSelectorModalComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() visible: boolean;
  @Input() orgId: string;

  @Input() filename: string;
  @Input() fileType: string;

  @Output() pathChange: EventEmitter<string> = new EventEmitter();

  public mediaList: Media[] = [];
  public orgMediaList: Media[] = [];
  public tagList: Tag[] = [];
  public tagListStrings: string[] = [];
  public filterTagsList: string[] = [];
  public descriptionFilter: string = "";

  public filteredMediaList: Media[] = [];
  public filteredOrgMediaList: Media[] = [];
  public queueAsMediaList: Media[] = [];

  public file;
  public uploadError: string;
  public statusMessage: string;
  public linkPath: string;

  public uploader: FileUploader = new FileUploader({
    url: environment.host + "api/media/upload/",
    itemAlias: "files"
  });

  public publicFile: boolean = false;

  public document: Document;
  public modifyOn: boolean = false;
  private fileTypes: string[] = [];
  @ViewChildren(MediaExpansionPanelComponent) private mediaExpansionPanels: MediaExpansionPanelComponent[];

  constructor(private mediaService: MediaService,
              public userService: UserService,
              private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.document = document;
  }

  ngOnInit() {
    this.mediaService.mediaList$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(mediaList => {
        this.mediaList = mediaList;

        this.filteredMediaList = mediaList.filter(media => {
          return this.checkFileType(media.filename);
        });
      });

    this.mediaService.orgMediaList$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(orgMediaList => {
        this.orgMediaList = orgMediaList;

        this.filteredOrgMediaList = orgMediaList.filter(media => {
          return this.checkFileType(media.filename);
        });

        this.changeDetectorRef.detectChanges();

      });

    this.mediaService.orgMediaTagList$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(orgMediaTagList => {
        this.tagList = orgMediaTagList;

        /*this.tagList = orgMediaTagList.filter(tag => {
          return tag.fileType === this.fileType;
        });*/

        this.tagListStrings = MediaService.TagsAsStrings(this.tagList);

        this.changeDetectorRef.detectChanges();

      });
  }


  public filterAllowedMedia(): void {
    this.filteredMediaList = this.mediaList ? this.mediaList.filter(media => {
      return this.checkFileType(media.filename);
    }) : [];

    this.filteredOrgMediaList = this.orgMediaList ? this.orgMediaList.filter(media => {
      return this.checkFileType(media.filename);
    }) : [];

    if (this.filterTagsList?.length !== 0) {
      this.filteredMediaList = this.filteredMediaList.filter(media => {
        return this.filterTags(media);
      });

      this.filteredOrgMediaList = this.filteredOrgMediaList.filter(media => {
        return this.filterTags(media);
      });
    }

    if (this.descriptionFilter?.length !== 0) {
      this.filteredMediaList = this.filteredMediaList.filter(media => {
        const descriptionBool = media.description?.toLowerCase().includes(this.descriptionFilter.toLowerCase());
        const fileNameBool = media.filename.toLowerCase().includes(this.descriptionFilter.toLowerCase());
        return descriptionBool || fileNameBool;
      });

      this.filteredOrgMediaList = this.filteredOrgMediaList.filter(media => {
        const descriptionBool = media.description?.toLowerCase().includes(this.descriptionFilter.toLowerCase());
        const fileNameBool = media.filename.toLowerCase().includes(this.descriptionFilter.toLowerCase());
        return descriptionBool || fileNameBool;
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orgId && this.orgId) {
      this.setUploader("api/media/upload/" + this.orgId);
      this.fetchMedia();
      this.fetchTags();
    }

    if (changes.fileType && this.fileType) {
      this.fileTypes = MediaUtils.getFormats(this.fileType);
      this.filterAllowedMedia();
    }
  }

  /**
   * Select media from list
   * @param {Media} file
   */
  public select(file: Media) {
    this.pathChange.emit(file ? file.path : null);
    this.hideSelector();
  }

  public selectLink(link: string) {
    if (!link) {
      return;
    }

    this.pathChange.emit(link);
    this.hideSelector();
  }

  /**
   * Delete media from list and delete file
   * @param {Media} media
   */
  public deleteMedia(media: Media) {
    const r: boolean = confirm("Are you sure you want to delete the file " + media.filename + " from LOGE?" +
      "If the file is in use anywhere a broken image will be displayed instead.");
    if (r === true) {
      this.mediaService.deleteMedia(media);
      this.fetchTags();
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Show file selector
   */
  public showSelector(fileType: string = "material"): void {
    this.fileTypes = MediaUtils.getFormats(fileType);

    if (this.mediaList && this.mediaList.length) {
      this.filteredMediaList = this.mediaList.filter(media => {
        return this.checkFileType(media.filename);
      });
    }

    if (this.orgMediaList && this.orgMediaList.length) {
      this.filteredOrgMediaList = this.orgMediaList.filter(media => {
        return this.checkFileType(media.filename);
      });
    }

    this.visible = true;
    this.changeDetectorRef.detectChanges();
  }

  public hideSelector(): void {
    this.visible = false;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Toggle file as public (shown in all organizations)
   */
  public togglePublic() {
    this.publicFile = !this.publicFile;

    this.setUploader(this.publicFile ? "api/media/upload/" : ("api/media/upload/" + this.orgId));
  }

  /**
   * Check that file type is allowed
   * @param {string} filename
   * @returns {boolean}
   */
  public checkFileType(filename: string): boolean {
    if (!this.fileTypes) {
      return false;
    }

    return (this.fileTypes.indexOf(MediaUtils.getFileType(filename)) !== -1);
  }

  public checkIsImage(path: string): boolean {
    return MediaUtils.checkIsImage(path);
  }

  public removeFromQueue(media: Media, mediaIndex: number): void {
    const queueIndex = this.uploader.queue.findIndex((item) => {
      return item.file.name === media.filename;
    });
    this.uploader.queue.splice(queueIndex, 1);
    this.queueAsMediaList.splice(mediaIndex, 1);
    this.changeDetectorRef.detectChanges();
  }

  // public modifyQueueData(media: Media): void {
  //   this.changeDetectorRef.detectChanges();
  // }

  public setUploader(newUrl?: string): void {
    if (newUrl) {

      const prevQueue: File[] = this.uploader.queue.map(fileItem => fileItem._file);

      this.uploader.destroy();

      this.uploader = new FileUploader({
        url: environment.host + newUrl,
        itemAlias: "files"
      });

      if (prevQueue != null && prevQueue.length !== 0) {
        this.uploader.addToQueue(prevQueue);
        this.queueAsMediaList = this.uploader.queue.map(fileItem => this.queueItemIntoMedia(fileItem));
      } else {
        this.queueAsMediaList = [];
        // this.statusMessage = "File queue cleared";
      }

      this.changeDetectorRef.detectChanges();
    }

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.queueAsMediaList = [];
      this.uploadError = null;

      this.statusMessage = "Files uploaded";

      this.fetchMedia();
      this.fetchTags();

      this.changeDetectorRef.detectChanges();
    };

    this.uploader.onCancelItem = () => {
      this.uploadError = null;
    };

    /**
     * Add file to queue and allow uploading if valid
     * @param {FileItem} file
     */
    this.uploader.onAfterAddingFile = (file) => {
      this.file = file;
      this.uploadError = null;

      // Check that file type is supported
      if (!this.checkFileType(file.file.name)) {
        file.remove();
        this.uploadError = "File type not supported";
        return;
      }

      // Check, that two files with the same name aren't uploaded
      let firstMatch: boolean;
      for (const _file of this.uploader.queue) {
        if (_file.file.name === file.file.name) {
          if (!firstMatch) {
            firstMatch = true;
            continue;
          }

          file.remove();
          this.uploadError = "File already chosen";
          return;
        }
      }

      // Check that file with a same name isn't already uploaded
      for (const _file of this.mediaList) {
        if (_file.filename === file.file.name) {
          file.remove();
          this.uploadError = "File already exists as public";
          return;
        }
      }

      if (!this.publicFile) {
        for (const _file of this.orgMediaList) {
          if (_file.filename === file.file.name && _file.orgId && _file.orgId === this.orgId) {
            file.remove();
            this.uploadError = "File already exists in organization";
            return;
          }
        }
      }

      // Add file to custom queue that handles extra info for the file
      this.queueAsMediaList.push(this.queueItemIntoMedia(file));

      this.changeDetectorRef.detectChanges();
    };


    // Handles tag and description data for upload
    this.uploader.onBeforeUploadItem = (item) => {

      const customData = this.queueAsMediaList.find(fileCustomData => {
        return fileCustomData.filename === item.file.name;
      });

      this.uploader.options.additionalParameter = {
        name: item.file.name,
        tags: JSON.stringify(customData.tags),
        description: customData.description,
        fileType: MediaUtils.getMediaEnumType(item.file.name).toString()
      };
    };

  }

  public handleMediaChoiceClick(media: Media, onClickEvent: any): void {

    if (this.modifyOn) {
      return;
    }

    onClickEvent.stopPropagation();
    this.select(media);

  }

  public onModifyChange(): void {

    for (const panel of this.mediaExpansionPanels) {

      if (panel.isUploadMedia) {
        continue;
      }

      panel.close();

    }

    this.changeDetectorRef.detectChanges();

  }

  private fetchMedia() {
    this.mediaService.fetchMedia();

    if (this.orgId) {
      this.mediaService.fetchOrgMedia(this.orgId);
      this.mediaService.fetchOrgTags(this.orgId);
    } else {
      this.mediaService.fetchOrgTags("0");
    }

  }

  private fetchTags(): void {
    if (this.orgId) {
      this.mediaService.fetchOrgTags(this.orgId);
    } else {
      this.mediaService.fetchOrgTags("0");
    }

    this.changeDetectorRef.detectChanges();
  }

  private filterTags(media: Media): boolean {

    for (const tag of media.tags) {
      if (!!this.filterTagsList.find(filterTag => {
        return tag === filterTag;
      })) {
        return true;
      }
    }

    return false;

  }

  private queueItemIntoMedia(file: FileItem): Media {

    const retVal = {};

    retVal["_id"] = null;
    retVal["filename"] = file.file.name;
    retVal["path"] = file._file;
    retVal["orgId"] = this.orgId;
    retVal["tags"] = [];
    retVal["description"] = "";

    return retVal as Media;
  }


}

// class CustomFileData {
//   file: FileItem;
//   tags: string[];
//   description: string;
//   fileType: MediaTypeEnum;

//   public static ReturnInitedFile(file: FileItem): CustomFileData {
//     const retFile = new CustomFileData();
//     retFile.file = file;
//     retFile.tags = [];
//     retFile.description = '';
//     retFile.fileType = MediaUtils.getMediaEnumType(file.file.name);
//     return retFile;
//   }

// }
