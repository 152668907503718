/**
 * Created by tkok on 19.12.2016.
 */

import {throwError, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {UserService} from "./user.service";
import {User} from "../../data-model/user.type";

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  public allTranslations$: BehaviorSubject<Map<string, any>> = new BehaviorSubject<Map<string, any>>(null);
  public orgTranslations$: BehaviorSubject<Map<string, any>> = new BehaviorSubject<Map<string, any>>(null);
  public availableTranslations: string[] = ['fi', 'en', 'sv', 'ru'];
  private translations: Map<string, any> = new Map<string, any>();
  private orgTranslations: Map<string, any> = new Map<string, any>();

  constructor(private http: HttpClient,
              private translate: TranslateService,
              private userService: UserService) {
  }

  /**
   * Load all translation
   */
  public loadAllTranslations() {
    for (const lang of this.availableTranslations) {
      this.loadDefaultTranslations(lang);
    }
  }

  /**
   * Load all organization specific translations
   * @param {string} orgId
   * @param {boolean} setTranslations
   * @param {string} userLang
   */
  public loadAllOrgTranslations(orgId: string, setTranslations?: boolean, userLang?: string) {
    for (const lang of this.availableTranslations) {
      this.loadOrgTranslations(lang, orgId, setTranslations, lang === userLang);
    }
  }

  /**
   * Load organization translations and set as languages
   * @param {string} lang
   * @param {string} orgId
   * @param {boolean} setTranslations
   * @param {boolean} isUserLang
   */
  public loadOrgTranslations(lang: string, orgId: string, setTranslations: boolean, isUserLang: boolean) {
    this.http.get('static/i18n/' + lang + "_" + orgId + ".json") // This will return {} if file is missing
      .subscribe(
        res => {
          this.orgTranslations.set(lang, res);
          this.orgTranslations$.next(this.orgTranslations);

          // Append org translations
          if (setTranslations) {
            this.translate.setTranslation(lang + "_" + orgId, res);
          }

          if (isUserLang) {
            this.translate.use(lang + "_" + orgId);
            this.translate.setDefaultLang(lang);
          }
        },
        err => {
          // This should never happen, but log just in case
          console.error("Error loading organization translations", err);
        }
      );
  }

  /**
   * Load translations and set as languages
   * @param {string} lang
   */
  public loadDefaultTranslations(lang: string) {
    this.http.get('static/i18n/' + lang + '.json')
      .subscribe(
        res => {
          this.translate.setTranslation(lang, res);

          this.translations.set(lang, res);
          this.allTranslations$.next(this.translations);
        },
        err => {
          console.error("Get Language failed", err);
        }
      );
  }

  /**
   * Save changes to translations
   * @param {Map<string, any>} translations
   * @param orgId
   * @returns {Observable<any>}
   */
  // Save languages one-by-one to prevent "Payload too large" error
  // save(translations: Map<string, any>, orgId?: string): Observable<any> {
  //   const langArray = [];
  //   for (const lang of this.availableTranslations) {
  //     if (!orgId) {
  //       langArray.push({
  //         'lang': lang,
  //         'json': translations.get(lang)
  //       });
  //     }
  //     else {
  //       langArray.push({
  //         'lang': lang,
  //         'json': translations.get(lang),
  //         'orgId': orgId
  //       });
  //     }
  //   }
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   const options = {headers: headers};
  //
  //   return this.http.post('/api/content/update-language', JSON.stringify({langArray}), options)
  //     .map(res => res)
  //     .catch(err => Observable.throw(err));
  // }

  /**
   * Save single language
   * @param {} translations
   * @param language
   * @param orgId
   * @returns {Observable<any>}
   */
  saveLanguage(translations: Object, language: string, orgId?: string): Observable<any> {
    let languageBody;

    if (!orgId) {
      languageBody = {
        'lang': language,
        'json': translations
      };
    }
    else {
      languageBody = {
        'lang': language,
        'json': translations,
        'orgId': orgId
      };
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {headers: headers};

    return this.http.post('/api/content/update-single-language', JSON.stringify(languageBody), options)
      .pipe(
        map(res => {
          const updatedTranslation: {lang: string, orgId: string, json: any} = res as {lang: string, orgId: string, json: any};

          if (orgId) {
            this.orgTranslations.set(language, updatedTranslation.json);
            this.orgTranslations$.next(this.orgTranslations);
          }
          else {
            this.translations.set(language, updatedTranslation.json);
            this.allTranslations$.next(this.translations);
          }

          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public setLanguage(lang: string, user?: User): void {
    // Use organization translations if logged in
    const wantedLang: string = user ? lang + "_" + user.orgId : lang;

    if (user && this.translate.getLangs().indexOf(wantedLang) === -1) {
      // Load and set language, if not loaded yet
      this.loadOrgTranslations(lang, user.orgId, true, true);
    }
    else {
      // Set language, if already loaded
      this.translate.use(wantedLang);
      this.translate.setDefaultLang(lang);
    }

    if (user && user.language !== lang) {
      this.userService.saveUser(
        user._id,
        {language: lang}
      );
    }
  }
}
