/**
 * Created by Topi on 30.05.2018
 */
import {OnDestroy, Output, EventEmitter, Component, Input, ElementRef} from '@angular/core';
import * as moment from 'moment';
import {ScaleItem} from "../../data-model/scale-vote.interface";
import {CdkDragEnd} from "@angular/cdk/drag-drop";

@Component({
  selector: 'svg-ball-component',
  template: `
    <div
      class="svg-ball-container"
      [ngStyle]="{'width': customSize, 'height': customSize}"
      [ngClass]="{'dragged': fadedBorder}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7">
        <g>
          <circle cx="12.35" cy="12.35" r="12.35" class="svg-ball"
                  [ngClass]="{'filled': active, 'situation-filled': situationActive, 'hovered': hovered}"
                  [ngStyle]="customFill && {'fill': customFill}"/>
          <ellipse cx="7.8" cy="5.2" rx="5.2" ry="2.6" transform="translate(-1.55 4.6) rotate(-30)"
                   class="svg-ball-highlight" [ngClass]="{'filled': active}"/>
          <polygon *ngIf="centerStar" points="13,3 7,20 22,9 4,9 19,20" [attr.fill]="'gold'" stroke="gold" stroke-width="1" stroke-linejoin="round"/>
        </g>
      </svg>
      <div class="content unselectable">
        <b>{{content}}</b>
      </div>
    </div>

    <div
      *ngIf="showDraggableClone"
      class="svg-ball-container draggable-clone"
      cdkDrag
      [cdkDragFreeDragPosition]="draggablePosition"
      (cdkDragEnded)="onMoveEnd($event)"
      [ngStyle]="{'width': customSize, 'height': customSize}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7">
        <g>
          <circle cx="12.35" cy="12.35" r="12.35" class="svg-ball"
                  [ngClass]="{'filled': active, 'situation-filled': situationActive, 'hovered': hovered}"
                  [ngStyle]="customFill && {'fill': customFill}"/>
          <ellipse cx="7.8" cy="5.2" rx="5.2" ry="2.6" transform="translate(-1.55 4.6) rotate(-30)"
                   class="svg-ball-highlight" [ngClass]="{'filled': active}"/>
          <polygon *ngIf="centerStar" points="13,3 7,20 22,9 4,9 19,20" [attr.fill]="'gold'" stroke="gold" stroke-width="1" stroke-linejoin="round"/>
        </g>
      </svg>
    </div>

    <div
      class="svg-ball-container drag-animation"
      [ngClass]="{'reverse-animation': reverseAnimation, 'line-animation': lineAnimation, 'tutorial-animation': tutorialAnimation}"
      [ngStyle]="{'width': customSize, 'height': customSize}"
      *ngIf="showDragAnimation"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7">
        <g>
          <circle cx="12.35" cy="12.35" r="12.35" class="svg-ball"
                  [ngClass]="{'filled': active, 'situation-filled': situationActive, 'hovered': hovered}"
                  [ngStyle]="customFill && {'fill': customFill}"/>
          <ellipse cx="7.8" cy="5.2" rx="5.2" ry="2.6" transform="translate(-1.55 4.6) rotate(-30)"
                   class="svg-ball-highlight" [ngClass]="{'filled': active}"/>
          <polygon *ngIf="centerStar" points="13,3 7,20 22,9 4,9 19,20" [attr.fill]="'gold'" stroke="gold" stroke-width="1" stroke-linejoin="round"/>
        </g>
      </svg>

      <span class="icon-pointer"></span>
    </div>
  `
})
export class SvgBallComponent {
  @Input() boundaryId: string;

  @Input() content: string;
  @Input() active: boolean;
  @Input() situationActive: boolean;
  @Input() hovered: boolean;
  @Input() customFill: string;
  @Input() centerStar: boolean;
  @Input() customSize: string;
  @Input() cloneExtraPixels: number = 0;

  @Input() fadedBorder: boolean;
  @Input() showDraggableClone: boolean;
  @Input() showDragAnimation: boolean;
  @Input() reverseAnimation: boolean;
  @Input() tutorialAnimation: boolean;
  @Input() lineAnimation: boolean;

  @Output() circlePlacedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public draggablePosition: { x: number, y: number };

  constructor() {

  }

  public onMoveEnd(event: CdkDragEnd): void {
    if (!event) {
      return;
    }

    const isInsideBounds: boolean = this.checkIsInsideBounds(event.source.element.nativeElement.getBoundingClientRect() as DOMRect);

    if (!isInsideBounds) {
      // Return to starting point
      this.draggablePosition = {
        x: 0,
        y: 0
      };

      return;
    }

    // Place actual circle and hide clone circle
    this.circlePlacedEmitter.emit(event);
  }

  public checkIsInsideBounds(elementRect: DOMRect): boolean {
    if (!elementRect) {
      return false;
    }

    const boundaries: HTMLDivElement = (this.boundaryId ? document.getElementById(this.boundaryId) : document.getElementsByClassName("boundaries")[0]) as HTMLDivElement;

    if (!boundaries) {
      return false;
    }

    const boundsRect: DOMRect = boundaries.getBoundingClientRect() as DOMRect;

    if (elementRect.x < (boundsRect.x - this.cloneExtraPixels) || elementRect.y < (boundsRect.y - this.cloneExtraPixels)) {
      return false;
    }

    if ((elementRect.x + elementRect.width) > (boundsRect.x + boundsRect.width + this.cloneExtraPixels)) {
      return false;
    }

    return !((elementRect.y + elementRect.height) > (boundsRect.y + boundsRect.height + this.cloneExtraPixels));
  }
}
