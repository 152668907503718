/**
 * Created by Topi on 29.12.2017
 */

import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[auto-focus]'
})
export class AutoFocusDirective implements OnChanges {
  @Input('auto-focus') focus: boolean;

  constructor(
    private el: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.focus && this.focus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 200);
    }
  }
}
