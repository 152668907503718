import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { GameSession } from "../../data-model/game-session.interface";
import { GameTimeslot } from "../../data-model/timeslot.type";
import { User } from "../../data-model/user.type";
import { ArrayToStringPipe } from "../pipe/arrayToString.pipe";
import { GameTimeslotService } from "../../core/service/timeslot.service";
import { GameSessionService } from "../../core/service/game-session.service";
import { GameTutorialService } from "../../core/service/game-tutorial.service";
import { CustomConfirmService, ModalConfirmation } from "../../core/service/custom-confirm.service";
import { filter } from "rxjs/operators";
import { OnDestroyMixin, untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { CalendarBodyLang } from "../../data-model/email.interface";

const moment = require("moment");

export interface DiscussionCardEvent {
  type: string;
  message?: string;
  timeSlot?: GameTimeslot;
  discussion?: GameSession;
}

@Component({
  selector: "discussion-card",
  template: `
    <div
      class="time-slot-card"
      *ngIf="cardVisible"
    >
      <ng-container *ngIf="(discussion || timeSlot)?.beginTime">
        <!-- Date -->
        <div class="text-content">
          <span class="glyphicon glyphicon-calendar"></span>
          {{ (discussion || timeSlot)?.beginTime | logeDate: dateFormat: currentUser?.language }}
        </div>

        <!-- Hours and minutes -->
        <div class="text-content">
          <span class="glyphicon glyphicon-time"></span>
          {{ (discussion || timeSlot)?.beginTime | logeDate: timeFormat: currentUser?.language }}
        </div>

        <!-- Duration -->
        <div class="text-content">
          <span class="glyphicon glyphicon-comment"></span>
          {{ maxDuration | duration}}
        </div>
      </ng-container>

      <!-- No beginTime, has to be force started -->
      <ng-container *ngIf="!(discussion || timeSlot)?.beginTime">
        <div class="text-content" [ngStyle]="{ width: '40%' }">
          <span class="glyphicon glyphicon-calendar"></span>
          {{'test-discussions.discussion-start' | translate}}
        </div>
      </ng-container>

      <div
        class="text-content"
        [ngClass]="{'empty-slot': !(discussion || timeSlot)?.players.length}"
        [matTooltip]="playerNames"
        [matTooltipDisabled]="!startPermission"
        [matTooltipPosition]="'above'"
      >
        <span class="glyphicon glyphicon-user"></span>

        <ng-container *ngIf="discussion">
          {{
          (discussion?.participants?.length || 0) +
          "/" +
          (discussion?.players?.length || 0) +
          " " +
          ("player-progress-view.players" | translate)
          }}
        </ng-container>

        <ng-container *ngIf="timeSlot">
          {{
          (timeSlot?.players?.length || 0) +
          "/" +
          (timeSlot?.gameSeasonId?.groupSize || 0) +
          " " +
          ("player-progress-view.players" | translate)
          }}
        </ng-container>
      </div>

      <div class="button-container">
        <ng-container *ngIf="timeSlot">
          <raised-css-button
            *ngIf="!signedUp"
            [buttonText]="'game-timeslot.join'"
            [fontSize]="'1.3vmin'"
            (onClick)="signUp()"
            [disabled]="!joinAllowed"
            [marginTop]="'0'"
            [marginBottom]="'0'"
            [buttonClasses]="'join-colors'"

            [matTooltip]="'game-timeslot.already-joined' | translate"
            [matTooltipPosition]="'above'"
            [matTooltipDisabled]="joinAllowed"
          ></raised-css-button>

          <raised-css-button
            *ngIf="signedUp"
            [buttonText]="'game-timeslot.leave'"
            [fontSize]="'1.3vmin'"
            (onClick)="leave()"
            [marginTop]="'0'"
            [marginBottom]="'0'"
            [buttonClasses]="'leave-colors'"
          ></raised-css-button>

          <raised-css-button
            *ngIf="startPermission"
            [buttonId]="'force-start-button-'"
            [buttonText]="'Start'"
            [fontSize]="'1.3vmin'"
            (onClick)="confirmStart()"
            [marginTop]="'0'"
            [marginBottom]="'0'"
            [disabled]="!timeSlot?.players?.length"
          ></raised-css-button>
        </ng-container>

        <ng-container *ngIf="discussion">
          <raised-css-button
            [buttonText]="'game-timeslot.late-sign-up' | translate"
            [fontSize]="'1.3vmin'"
            (onClick)="confirmJoin()"
            [marginTop]="'0'"
            [marginBottom]="'0'"
          ></raised-css-button>

          <raised-css-button
            *ngIf="startPermission"
            [buttonText]="'game-timeslot.spectate' | translate"
            [fontSize]="'1.3vmin'"
            (onClick)="confirmJoin(true)"
            [marginTop]="'0'"
            [marginBottom]="'0'"
          ></raised-css-button>
        </ng-container>
      </div>
    </div>
  `,
  styles: []
})
export class DiscussionCardComponent extends OnDestroyMixin implements OnChanges, OnInit {
  @Input() discussion: GameSession;
  @Input() timeSlot: GameTimeslot;
  @Input() seasonName: string;
  @Input() maxDuration: number;
  @Input() calendarContent: CalendarBodyLang;

  @Input() listType: string;
  @Input() joinAllowed: boolean = true;
  @Input() cardVisible: boolean = true;

  @Input() currentUser: User;
  @Input() startPermission: boolean;

  @Output() onUserEvent: EventEmitter<DiscussionCardEvent> = new EventEmitter<DiscussionCardEvent>();
  public dateFormat: string = "dddd D.M.";
  public timeFormat: string = "H:mm";
  public signedUp: boolean;
  public playerNames: string;

  private currentId: string;

  constructor(
    private arrayToStringPipe: ArrayToStringPipe,
    private gameTimeSlotService: GameTimeslotService,
    private gameSessionService: GameSessionService,
    private tutorialService: GameTutorialService,
    private ref: ChangeDetectorRef,
    private customConfirmService: CustomConfirmService
  ) {
    super();
  }

  ngOnInit(): void {
    this.customConfirmService.confirmedMessage$
      .pipe(
        untilComponentDestroyed(this),
        filter(confirmation => {
          if (!confirmation || !this.currentId) {
            // Missing info
            return false;
          }

          return confirmation.identifier === this.currentId;
        })
      )
      .subscribe((confirmation: ModalConfirmation) => {
        if (!confirmation.action || confirmation.action === "cancel") {
          // Canceled
          return;
        }

        // Force start time-slot
        if (this.timeSlot) {
          if (confirmation.action === "force-start") {
            this.start();
          }

          return;
        }

        // Join existing discussion
        else if (this.discussion) {
          if (confirmation.action === "participate") {
            this.joinDiscussion();
          } else if (confirmation.action === "spectate") {
            this.joinDiscussion(true);
          }

          return;
        }

        console.error("Unknown confirmation action", confirmation);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.discussion || changes.timeSlot) {
      if (!this.discussion && !this.timeSlot) {
        this.currentId = null;
        return;
      }

      this.currentId = this.discussion ? this.discussion._id.toString() : this.timeSlot._id.toString();

      if (this.startPermission) {
        this.playerNames = this.arrayToStringPipe.transform((this.discussion || this.timeSlot).players, { key: "name" });
      }

      this.signedUp = this.checkSignUp();
    }
  }

  public checkSignUp(): boolean {
    if (!this.currentUser) {
      // No-one to check
      return false;
    }

    // Users should be populated
    const signUps: User[] = (this.discussion ?
        this.discussion.players.concat(this.discussion.spectators) :
        this.timeSlot.players.concat(this.timeSlot.spectators)
    ) as unknown as User[];

    // Check is player is in list
    return !!signUps.find((user: User) => {
      if (!user) {
        return false;
      }

      const playerId: string = user._id ? user["_id"] : (user as unknown as string);
      return playerId.toString() === this.currentUser._id.toString();
    });
  }

  public signUp(): void {
    if (!this.timeSlot) {
      return;
    }

    this.joinTimeSlot(this.timeSlot);
  }

  public joinDiscussion(spectate?: boolean): void {
    if (!this.discussion) {
      return;
    }

    this.gameSessionService
      .joinToExistingDiscussion(this.currentId, this.currentUser._id, spectate)
      .subscribe(() => {
        // Join successful
        this.gameSessionService.getCurrentSession(true);
      });
  }

  public emitChange(eventType: string, message?: string): void {
    this.onUserEvent.emit({
      type: eventType,
      message: message
    });
  }

  public leave(): void {
    if (!this.timeSlot) {
      return;
    }

    this.gameTimeSlotService.leave(this.currentId)
      .subscribe((updatedTimeSlot) => {
      });

    this.emitChange("timeSlotLeave");
  }

  public confirmStart(): void {
    if (!this.timeSlot) {
      return;
    }

    this.customConfirmService.setMessage(
      this.currentId,
      "custom-confirm.confirm-start",
      "",
      {
        seasonName: this.seasonName,
        formattedTime: moment(this.timeSlot.beginTime).format("DD.MM. H:mm")
      },
      [
        {
          text: "custom-confirm.confirm-button",
          action: "force-start"
        },
        {
          text: "custom-confirm.cancel-button",
          action: "cancel"
        }
      ]
    );
  }

  public confirmJoin(spectate?: boolean): void {
    if (!this.discussion) {
      return;
    }

    this.customConfirmService.setMessage(
      this.currentId,
      spectate ? "custom-confirm.confirm-spectate" : "custom-confirm.confirm-participate",
      "",
      {
        seasonName: this.seasonName,
        phaseName: this.discussion.currentPhase
      },
      [
        {
          text: "custom-confirm.confirm-button",
          action: spectate ? "spectate" : "participate"
        },
        {
          text: "custom-confirm.cancel-button",
          action: "cancel"
        }
      ]
    );
  }

  public joinTimeSlot(timeSlot: GameTimeslot) {
    this.gameTimeSlotService.join(timeSlot)
      .subscribe(updatedTimeSlot => {
          if (!updatedTimeSlot) {
            this.emitChange("errorModal", "game-timeslot.slot-undefined");
            return;
          }

          this.tutorialService.setIntroTutorialAsCompleted("sign-up", this.currentUser);

          const ISOFormat: string = "YYYYMMDDTHHmm[00Z]";
          const googleCalendarLink: string =
            "https://calendar.google.com/calendar/render?action=TEMPLATE&text=" +
            "LOGE - " + this.seasonName + // Title
            "&dates=" +
            moment(this.timeSlot.beginTime).utc().format(ISOFormat) + // BeginTime ISO String
            "/" +
            moment(this.timeSlot.beginTime).add(this.maxDuration, "s").utc().format(ISOFormat) + // EndTime ISO String
            "&details=" +
            (this.calendarContent ? this.calendarContent.description : "") + // Description
            "&location=" +
            "https://experience.loge.fi/" // Location
          ;

          this.customConfirmService.setMessage(
            this.currentId,
            "session-join-modal.title",
            "session-join-modal.session-info",
            {
              seasonName: this.seasonName,
              formattedTime: moment(this.timeSlot.beginTime).format("DD.MM. H:mm"),
              time: moment(this.timeSlot.beginTime).locale(this.currentUser.language).format("dddd D.M. H:mm")
            },
            [
              {
                text: "custom-confirm.ok",
                action: "confirm"
              }
            ],
            [
              {
                text: "session-join-modal.add-to-outlook-calendar",
                link: "https://experience.loge.fi/api/calendar/event/" + this.timeSlot._id + "/" + this.currentUser.language
              },
              {
                text: "session-join-modal.add-to-google-calendar",
                link: googleCalendarLink
              }
            ]
          );
        },
        err => {
          console.error("Failed joining timeSlot", err);

          if (err.status === 403) {
            this.emitChange("errorModal", "game-timeslot.slot-full");
            return;
          }

          if (err.status === 404) {
            this.emitChange("errorModal", "game-timeslot.slot-removed");
            return;
          }

          if (err.status === 429) {
            this.emitChange("errorModal", "game-timeslot.slot-already-joined");
            return;
          }

          this.emitChange("errorModal", err.message);
        });

    this.emitChange("timeSlotJoin");
  }

  private start(): void {
    this.gameTimeSlotService.startSession(this.timeSlot)
      .subscribe(() => {
        this.emitChange("timeSlotStart", this.currentId);
      });
  }
}
