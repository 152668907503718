import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MediaService } from "app/core/service/media.service";
import { Media } from "app/data-model/media.interface";

@Component({
  selector: "media-expansion-panel",
  template: `
    <mat-expansion-panel hideToggle [ngStyle]="{'background-color':'transparent'}"
                         (opened)="changeDetectorRef.detectChanges()"
                         (closed)="onClose(); changeDetectorRef.detectChanges()">
      <!-- IMAGE IN THE DROPDOWN HEADER -->
      <mat-expansion-panel-header
        [ngStyle]="{'padding':0}"
        [collapsedHeight]="'14vh'"
        [expandedHeight]="'14vh'"
      >

        <!-- NOTE: The image is given a dynamic id, which is then used to calculate appropriate max-width for dropdown content. -->
        <!--
        TODO: This works, but feels like a hack. It requires that the changes are checked after pictures are removed from queue.
              I couldn't quickly figure out the correct way to implement dynamic width for content.

              IF TIME, FIGURE THE CORRECT WAY TO DO THIS!
              - Konsta

        -->
        <div
          class="image-preview"
          [ngStyle]="{'box-shadow':'none'}"
          id="{{ dynamicId }}"
          (click)="alternateOnPictureClick.emit($event)"
        >
          <button (click)="unsavedChanges = false; onRemove.emit($event)" class="preview-remove">
            <span class="glyphicon glyphicon-remove"></span>
          </button>

          <img *ngIf="isUploadMedia" filePreview [file]="media.path" src="" [alt]="media.filename">
          <img *ngIf="!isUploadMedia" customDefault [src]="media.path" [alt]="media.filename">
          <div class="image-preview-file-name">
            {{media.filename}}
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="flex-row">
        <tag-selector
          [optionList]="tagOptions"
          [(tagList)]="media.tags"
          [ngStyle]="{
            'max-width': [document.getElementById(dynamicId).offsetWidth] + 'px'
            }"
          (tagListChange)="unsavedChanges = true"
        ></tag-selector>
      </div>

      <input matInput
             [placeholder]="'file-selector.description-placeholder' | translate"
             [(ngModel)]="media.description"
             [ngStyle]="{
          'max-width': [document.getElementById(dynamicId).offsetWidth] + 'px'
          }"
             (ngModelChange)="unsavedChanges = true"
      />

    </mat-expansion-panel>

  `,
  styles: []
})
export class MediaExpansionPanelComponent implements OnInit, OnDestroy {
  @Input() public media: Media;
  @Input() public dynamicId: string;
  @Input() public tagOptions: string[];
  @Input() public isUploadMedia: boolean;

  @Output() public tagOptionsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output() public alternateOnPictureClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public onRemove: EventEmitter<Event> = new EventEmitter<Event>();

  public unsavedChanges = false;

  public document: Document;

  @ViewChild(MatExpansionPanel) private expansionPanel: MatExpansionPanel;

  constructor(public changeDetectorRef: ChangeDetectorRef, private mediaService: MediaService) {
    this.document = document;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.unsavedChanges && !this.isUploadMedia) {
      this.mediaService.updateMediaData(this.media);
    }
  }

  public close() {
    this.expansionPanel.close();
  }

  public onClose() {

    if (this.isUploadMedia || !this.unsavedChanges) {
      return;
    }

    this.mediaService.updateMediaData(this.media);

    this.unsavedChanges = false;

  }

}
