import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import Socket = SocketIOClient.Socket;
import {HttpClient} from "@angular/common/http";
import * as io from "socket.io-client";
import { DataUpdate } from "./data-update.service";
import {catchError, map} from "rxjs/operators";
import { NotificationService } from "./notification.service";

export interface DiscussionUpdate {
  topic: string;
  data: any;
  coreId?: any;
}

export interface DiscussionStatus {
  session: string;
  connected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public timeSlotUpdate$: Subject<DataUpdate> = new Subject<DataUpdate>();
  public gameSeasonUpdate$: Subject<DataUpdate> = new Subject<DataUpdate>();
  public gameSessionStarted$: Subject<DataUpdate> = new Subject<DataUpdate>();
  public connectionStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public discussionConnectionStatus$: BehaviorSubject<DiscussionStatus> = new BehaviorSubject<DiscussionStatus>(undefined);
  public discussionUpdate$: Subject<DiscussionUpdate> = new Subject<DiscussionUpdate>();

  private IOSocket: Socket;

  private retryAmount: number = 0;
  private hasConnected: boolean;

  private onData: Function = this.discussionUpdateListener.bind(this);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  public createSocket() {
    if (this.IOSocket && this.IOSocket.connected) {
      // Disconnect old
      this.IOSocket.disconnect();
    }

    if (this.retryAmount === 0) {
      this.hasConnected = false;
    }

    const hostname: string = window.location.hostname + ":" + window.location.port;

    this.createToken()
      .subscribe(
        token => {
          this.IOSocket = io(
            `//${hostname}/?token=${token}`, {
              transports: ['websocket'],
              rejectUnauthorized: false
            }
          );

          this.IOSocket.on("connect", () => {
            console.log("Socket successfully connected");
            this.connectionStatus$.next("connected");
            this.hasConnected = true;
            this.retryAmount = 0;
          });

          this.IOSocket.on("data-update", (update) => {
            console.log("Received data update", update);

            if (update.type === "timeSlot") {
              this.timeSlotUpdate$.next(update);
              return;
            }

            if (update.type === "gameSeason") {
              this.gameSeasonUpdate$.next(update);
              return;
            }

            if (update.type === "addedToSession" || update.type === "sessionStarted") {
              this.gameSessionStarted$.next(update);
              return;
            }
          });

          this.IOSocket.on("notification", (update: DataUpdate) => {
            console.log("Received notification", update?.notification);
            this.notificationService.setNotification(update?.notification);
          });

          this.IOSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected", reason);
            this.connectionStatus$.next("disconnected");
            this.discussionConnectionStatus$.next({
              session: null,
              connected: false
            });
          });

          this.IOSocket.on("error", (error) => {
            console.error("Socket errored", error);
            this.connectionStatus$.next("never-connected");
          });
        });
  }

  public connected(): boolean {
    return this.IOSocket && this.IOSocket.connected;
  }

  public confirmReceived(message: any): void {
    // TODO
  }

  public sendMessage(topic: string, data: any): void {
    if (!this.connected()) {
      return;
    }

    this.IOSocket.emit(topic, data);
  }

  public isInRoom(roomId: string): boolean {
    if (!this.connected()) {
      return false;
    }

    // TODO Check
    return false;
  }

  public joinRoom(roomId: string, isDiscussion?: boolean): void {
    if (!this.connected()) {
      return;
    }

    if (isDiscussion) {
      console.log("Join discussion room", roomId);

      this.IOSocket.on("discussion-update", this.onData);
      this.IOSocket.emit("discussion-join", roomId);

      this.discussionConnectionStatus$.next({
        session: roomId,
        connected: true
      });
    }
  }

  public leaveRoom(roomId: string, isDiscussion?: boolean): void {
    if (!this.connected()) {
      return;
    }

    if (isDiscussion) {
      console.log("Leave discussion room", roomId);

      this.IOSocket.emit("discussion-leave", roomId);
      this.IOSocket.off("discussion-update", this.onData);

      this.discussionConnectionStatus$.next({
        session: roomId,
        connected: false
      });
    }
  }

  private createToken(): Observable<string> {
    return this.http.get<{ token: string }>("/api/user/create-token/")
      .pipe(
        map(res => {
          return res.token;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private discussionUpdateListener(update: DiscussionUpdate): void {
    if (update.topic !== "player-status") {
      // We don't want to spam the console with constant status updated from writing and speaking
      console.log("Discussion update", update);
    }

    this.discussionUpdate$.next(update);
  }
}
