import { Pipe, PipeTransform } from '@angular/core';
import { DurationInputArg2, Moment } from "moment";
const moment = require("moment");

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, valueFormat: DurationInputArg2 = "s"): string {
    // Create a moment with duration
    const tempDate: Moment = moment("2015-01-01")
      .startOf('day')
      .add(value, valueFormat);

    const hours: string = tempDate.get("h") > 0 ? tempDate.get("h") + "h" : "";
    const minutes: string = tempDate.get("m") > 0 ? tempDate.get("m") + "min" : "";

    return (hours + " " + minutes).trim();
  }

}
