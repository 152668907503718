/**
 * Created by TopSal on 19.6.2017.
 */

import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'domain-error-view',
  template: `
    <div class="login-area">
      <div class="login-flex">
        <h1>{{'domain-error-view.title' | translate}}</h1>
        <div class="instructions">{{'domain-error-view.instruction' | translate}}</div>

        <raised-css-button
          [buttonText]="'domain-error-view.return-button'"
          (onClick)="returnToFrontPage()"
          [fontSize]="'3vmin'"
          [marginTop]="'1vmin'"
          [marginBottom]="'0.5vmin'"
          [animatedAppear]="true"
        ></raised-css-button>
      </div>
    </div>
  `
})

export class DomainErrorView {
  constructor(protected router: Router) {

  }

  /**
   * Return user to front page
   */
  public returnToFrontPage(): void {
    this.router.navigate(['/']);
  }
}
