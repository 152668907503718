import { Injectable }    from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route
} from '@angular/router';

import {UserService} from "../app/core/service/user.service";

@Injectable()
export class LoginGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.userService.currentUser$.value) {
      this.router.navigate(['/welcome']);
    }

    return !!this.userService.currentUser$.value;
  }

  canLoad(route: Route): boolean {
    return !!this.userService.currentUser$.value;
  }
}
