import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MeasureAnswer} from "../../data-model/measure-question.interface";
import {User} from "../../data-model/user.type";
import {MeasureService} from "../../core/service/measure.service";
import {Moment} from "moment";

const moment = require("moment");

@Component({
  selector: 'user-measure',
  template: `
    <!--<ng-scrollbar class="loge-scrollbar" [appearance]="'compact'">
      <div class="measure-scroll">
        <div class="measure-box" *ngFor="let measureAnswer of newestMeasureAnswers; index as measureIndex"
             (click)="openContainer(measureIndex)">
          <span class="text-answer">{{measureAnswer.answer.measureAnswer}}</span>

          <span *ngIf="measureAnswer.showExclamation" class="glyphicon glyphicon-exclamation-sign"></span>

          <span *ngIf="!measureAnswer.showExclamation" class="custom-checkbox">
            <span *ngIf="measureAnswer.answer.measureCompleted" class="glyphicon glyphicon-ok"></span>
          </span>
        </div>
      </div>
    </ng-scrollbar>-->

    <div class="measure-box" *ngFor="let measureAnswer of newestMeasureAnswers; index as measureIndex"
         (click)="openContainer(measureIndex)">
      <span class="text-answer">{{measureAnswer.answer.measureAnswer}}</span>

      <span *ngIf="measureAnswer.showExclamation" class="glyphicon glyphicon-exclamation-sign"></span>

      <span *ngIf="!measureAnswer.showExclamation" class="custom-checkbox">
            <span *ngIf="measureAnswer.answer.measureCompleted" class="glyphicon glyphicon-ok"></span>
          </span>
    </div>

    <div class="measure-edit-container" *ngIf="showEditor">
      <div class="measure-edit-box">
        <h1>{{'user-measure.title' | translate}}</h1>
        <span class="glyphicon glyphicon-remove" (click)="showEditor = false"></span>

        <div class="scroll-container">
          <ng-scrollbar
            class="loge-scrollbar dark wide faded-ends">
            <div class="measure-answer-editor" *ngFor="let measureAnswer of userMeasureAnswers; index as measureIndex">
              <span *ngIf="measureAnswer.showExclamation" class="glyphicon glyphicon-exclamation-sign"></span>

              <div class="measure-question">
                {{measureAnswer.answer.measure['measureQuestion'].prompt}}
              </div>

              <div class="previous-answer">
                {{measureAnswer.answer.measureAnswer}}
              </div>

              <div class="measure-buttons">
                <raised-css-button
                  [buttonText]="'user-measure.completed'"
                  [buttonDown]="measureAnswer.answer.measureCompleted"
                  (onClick)="measureAnswer.answer.measureCompleted = true; measureAnswer.changed = true"
                  [marginTop]="'0.1vmin'"
                  [marginBottom]="'0.1vmin'"
                  [buttonClasses]="measureAnswer.answer.measureCompleted ? 'join-colors' : 'loge-colors'"
                ></raised-css-button>

                <raised-css-button
                  [buttonText]="'user-measure.not-completed'"
                  [buttonDown]="measureAnswer.answer.measureCompleted === false"
                  (onClick)="measureAnswer.answer.measureCompleted = false; measureAnswer.changed = true"
                  [marginTop]="'0.1vmin'"
                  [marginBottom]="'0.1vmin'"
                  [buttonClasses]="(measureAnswer.answer.measureCompleted === false ? 'leave-colors' : 'loge-colors')"
                ></raised-css-button>
              </div>

              <div class="measure-question post-question">
                {{measureAnswer.answer.measure['postMeasureQuestion'].prompt}}
              </div>

              <written-answer
                [placeholder]="'user-measure.textarea-placeholder' | translate"
                [answerString]="measureAnswer.answer.postMeasureAnswer"
                (answerEmitter)="measureAnswer.answer.postMeasureAnswer = $event; measureAnswer.changed = true"
              ></written-answer>

              <div *ngIf="measureAnswer.saveStatus">
                {{measureAnswer.saveStatus | translate}}
              </div>

              <raised-css-button
                [buttonText]="'user-measure.save-button'"
                [fontSize]="'1.5vmin'"
                [marginTop]="'1vmin'"
                [marginBottom]="'0.1vmin'"
                [disabled]="!measureAnswer.changed"
                (onClick)="saveMeasureAnswer(measureIndex)"
              ></raised-css-button>
            </div>
          </ng-scrollbar>
        </div>
      </div>
    </div>

    <div *ngIf="!userMeasureAnswers?.length" class="no-measures">
      {{'user-measure.no-measures' | translate}}
    </div>
  `,
  styles: []
})
export class UserMeasureComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentUser: User;
  @Input() measureAnswers: MeasureAnswer[];

  public newestMeasureAnswers: {
    showExclamation: boolean,
    answer: MeasureAnswer,
    changed: boolean,
    saveStatus: string
  }[];

  public userMeasureAnswers: {
    showExclamation: boolean,
    answer: MeasureAnswer,
    changed: boolean,
    saveStatus: string
  }[];

  public showEditor: boolean;

  constructor(private measureService: MeasureService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.measureAnswers && this.measureAnswers) {
      this.sortMeasureArrays(this.measureAnswers);
    }
  }

  ngOnDestroy(): void {

  }

  public openContainer(measureIndex: number): void {
    // Move selected measure to top
    const selectedMeasure: any = this.userMeasureAnswers[measureIndex];
    this.userMeasureAnswers.splice(measureIndex, 1);
    this.userMeasureAnswers.unshift(selectedMeasure);

    this.showEditor = true;
  }

  public sortMeasureArrays(measureAnswers: MeasureAnswer[]): void {
    const showIconThreshold: Moment = moment().subtract(5, 'days');

    this.userMeasureAnswers = measureAnswers
      .filter(item => {
        return !!item.measure['postMeasureQuestion'].prompt && !item.measure['measureQuestion'].scale;
      })
      .map(item => {
        return {
          showExclamation: item.measureCompleted === null && !item.postMeasureAnswer && moment(item.updatedAt).isBefore(showIconThreshold),
          answer: item,
          changed: false,
          saveStatus: null
        };
      })
      .sort((a, b) => {
        if (a.showExclamation || b.showExclamation) {
          if (a.showExclamation && b.showExclamation) {
            return 0;
          }

          return a.showExclamation ? -1 : (b.showExclamation ? 1 : -1);
        }

        if (a.answer.measureCompleted || b.answer.measureCompleted) {
          return a.answer.measureCompleted ? 1 : -1;
        }

        return 0;
      });

    this.newestMeasureAnswers = this.userMeasureAnswers.filter((item, index) => {
      return index < 3;
    });
  }

  public saveMeasureAnswer(measureIndex: number): void {
    this.userMeasureAnswers[measureIndex].saveStatus = "user-measure.saving";
    this.userMeasureAnswers[measureIndex].showExclamation = false;

    this.measureService.updateMeasureCompletion(this.userMeasureAnswers[measureIndex].answer)
      .subscribe(
        (updatedAnswer: MeasureAnswer) => {
          this.userMeasureAnswers[measureIndex].answer = updatedAnswer;
          this.userMeasureAnswers[measureIndex].changed = false;
          this.userMeasureAnswers[measureIndex].saveStatus = "user-measure.save-completed";

        },
        error => {
          console.error("Failed saving measure", error);
          this.userMeasureAnswers[measureIndex].saveStatus = "user-measure.save-failed";
        });
  }
}
