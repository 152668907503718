<raised-css-button
  *ngIf="exitButtonType"
  [disabled]="exitDisabled"
  [ngStyle]="currentUser && showAdminButton && {'margin-left': '1vh'}"
  (onClick)="onPressExit()"
  [buttonClasses]="'loge-colors'"
  [buttonText]="'user-button-bar.exit-discussion' | translate"
></raised-css-button>

<raised-css-button
  *ngIf="showHelpButton"
  (onClick)="emitToggleHelp()"
  [buttonClasses]="'loge-colors'"
  [buttonText]="(showingHelp ? 'welcome-view.help-button-hide' : 'welcome-view.help-button') | translate"
></raised-css-button>

<raised-css-button
  *ngIf="currentUser && showBiButton"
  (onClick)="openBiReport()"
  [buttonClasses]="'loge-colors'"
  [buttonText]="('user-button-bar.to-bi-report') | translate"
></raised-css-button>

<raised-css-button
  *ngIf="currentUser && showAdminButton"
  [ngStyle]="{'margin-left': '1vh'}"
  (onClick)="showClose ? returnToLandingPage() : openAdminPage()"
  [buttonClasses]="'loge-colors'"
  [buttonText]="(showClose ? 'user-button-bar.close-admin' : 'user-button-bar.admin') | translate"
></raised-css-button>

<raised-css-button
  [buttonId]="'test-admin-button'"
  *ngIf="currentUser && currentOrganization?.organizationType === 2 && currentUser?.userType === 1 && checkIsLandingPage()"
  (onClick)="toggleMiniAdmin()"
  [buttonClasses]="'loge-colors'"
  [buttonText]="'test-admin.test-admin-button' | translate"
></raised-css-button>

<div class="settings-button-group" *ngIf="currentUser && !showClose && checkTutorialLocation()">
  <raised-css-button
    (onClick)="exitTutorial()"
    [buttonClasses]="'loge-colors'"
    [buttonText]="'user-button-bar.exit-discussion' | translate"
  ></raised-css-button>
</div>

<div class="settings-button-group" *ngIf="!disableLanguage && !showClose &&
  (!currentOrganization || currentOrganization?.allowedLanguages?.length !== 1 || currentOrganization?.allowedLanguages?.indexOf(currentUser?.language) === -1)">
  <raised-css-button
    id="settings-open-button"
    (onClick)="showSettingsMenu = false; showLanguageMenu = !showLanguageMenu"
    [buttonClasses]="'loge-colors'"
    [iconClass]="'flag-icon flag-' + (currentUser ? currentUser?.language : translate.getDefaultLang())"
  ></raised-css-button>

  <language-settings
    *ngIf="showLanguageMenu"
    [currentUser]="currentUser"
    [allowedLanguages]="currentOrganization?.allowedLanguages"
  ></language-settings>
</div>

<div class="settings-button-group" *ngIf="currentUser && !showClose && !hideSettings">
  <raised-css-button
    *ngIf="!checkTutorialLocation()"
    id="settings-open-button"
    (onClick)="showLanguageMenu = false; showSettingsMenu = !showSettingsMenu"
    [buttonClasses]="'loge-colors'"
    [buttonText]="currentUser?.name || 'participant-info-card.name-placeholder'"
    [iconClass]="'custom-icon icon-user'"
  ></raised-css-button>

  <user-settings
    *ngIf="showSettingsMenu"
    [currentUser]="currentUser"
    [currentOrganization]="currentOrganization"
    [allowedElementIds]="['settings-open-button', 'raised-button-', 'update-profile']"
    (onClose)="showSettingsMenu = $event"
  ></user-settings>
</div>